import React, { Children, useState } from 'react'
/* eslint-disable import/no-unresolved */
import { Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/css'
import 'swiper/css/navigation'
import './Carousel.less'

interface Props {
  children: React.ReactNode
}

const breakpoints = {
  0: { slidesPerView: 1.3, spaceBetween: 8 },
  405: { slidesPerView: 1.4, spaceBetween: 8 },
  450: { slidesPerView: 1.5, spaceBetween: 8 },
  500: { slidesPerView: 1.7, spaceBetween: 8 },
  560: { slidesPerView: 2, spaceBetween: 8 },
  830: { slidesPerView: 3, spaceBetween: 8 },
  1124: { slidesPerView: 4, spaceBetween: 8 },
}

const Carousel: React.FC<Props> = ({ children }) => {
  const childrenArray = Children.toArray(children)
  const [prevEl, setPrevEl] = useState<HTMLElement | null>(null)
  const [nextEl, setNextEl] = useState<HTMLElement | null>(null)
  return (
    <div className='CarouselWrapper'>
      <div className='Carousel'>
        <Swiper modules={[ Navigation ]}  navigation={{ prevEl, nextEl }} breakpoints={breakpoints} watchOverflow>
          {Children.map(childrenArray, (child, index) => <SwiperSlide key={index}>{child}</SwiperSlide>)}
        </Swiper>
        <div className='button-prev' ref={(node) => setPrevEl(node)}>
          <div className='arrow-right'></div>
        </div>
        <div className='button-next' ref={(node) => setNextEl(node)}>
          <div className='arrow-left'></div>
        </div>
      </div>
    </div>
  )
}

export default Carousel
