import React from 'react'
import { useTranslation } from 'react-i18next'
import NumberInput from '../../../common/input/numericInput/NumberInput'
import FormRow from '../common/FormRow'
import LoanAmountReadOnlyRow from '../common/LoanAmountReadOnlyRow'

export type FollowUpFinancingSettingsFormProps = {
  propertyValue: number,
  remainingDebt: number,
  loanAmount: number,
  loanAmountValid: boolean,
  onPropertyValueChanged: (val: number) => void,
  onRemainingDebtChanged: (val: number) => void,
}

export const MIN_PROPERTY_VALUE = 100_000
export const MIN_REMAINING_DEBT = 50_000
export const MAX_AMOUNT = 10_000_000

const FollowUpFinancingSettingsForm: React.FC<FollowUpFinancingSettingsFormProps> = (
  {
    propertyValue,
    remainingDebt,
    loanAmount,
    loanAmountValid,
    onPropertyValueChanged,
    onRemainingDebtChanged
  }) => {
  const { t } = useTranslation('leadEngine', { keyPrefix: 'followupFinancingSettingsPage' })
  return (
    <>
      <FormRow title={t('propertyValue')}
               tooltip={t('propertyValueTooltip')}
               tooltipPosition='top'
               className='border-bottom'>
        <div className='padding-vertical-s'>
          <NumberInput id='propertyValue'
                       data-testid='propertyValue'
                       value={propertyValue}
                       onChange={onPropertyValueChanged}
                       onBlur={() => propertyValue > MAX_AMOUNT && onPropertyValueChanged(MAX_AMOUNT)}
          />
        </div>
      </FormRow>
      <FormRow title={t('remainingDebt')} className='border-bottom'>
        <div className='padding-vertical-s'>
          <NumberInput id='remainingDebt'
                       data-testid='remainingDebt'
                       value={remainingDebt}
                       onChange={onRemainingDebtChanged}
                       onBlur={() => remainingDebt > MAX_AMOUNT && onRemainingDebtChanged(MAX_AMOUNT)}
          />
        </div>
      </FormRow>
      <LoanAmountReadOnlyRow loanAmount={loanAmount} loanAmountValid={loanAmountValid}/>
    </>
  )
}

export default FollowUpFinancingSettingsForm

