import { useEffect, useState } from 'react'
import { logError } from '../../../api/api/Logger'
import { getMonthlyRate } from '../../../api/api/MonthlyRate'
import { useDebounce } from '../../../utils/utils'
import FollowupCalculator from './FollowupCalculator'

interface IFollowUpCalculatorProps {
  initialRemainingDebt?: string;
  initialPropertyValue?: string;
  targetLink?: string;
  headline?: string;
  buttonText?: string;
  buttonColor?: string;
}

const FollowupCalculatorWrapper = ({
                                     targetLink,
                                     headline,
                                     initialPropertyValue = '200000',
                                     initialRemainingDebt = '100000',
                                     buttonColor,
                                     buttonText,
                                   }: IFollowUpCalculatorProps) => {

  const [ loanDetails, setLoanDetails ] = useState({
    propertyValue: Number(initialPropertyValue),
    remainingDebt: Number(initialRemainingDebt),
  });

  const [ monthlyRate, setMonthlyRate ] = useState(0);
  const [ isLoading, setIsLoading ] = useState(false);
  const debouncedDebt = useDebounce(loanDetails.remainingDebt, 300);

  useEffect(() => {
    const updateMonthlyRate = async () => {
      setIsLoading(true);

      try {
        const { monthlyRate } = await getMonthlyRate({ purchasePrice: loanDetails.remainingDebt })
        setIsLoading(false);
        setMonthlyRate(monthlyRate);
      } catch(e: any) {
        setIsLoading(false);
        logError('FollowupCalculator MonthlyRate Api', e)
      }

      setIsLoading(false);
    };

    updateMonthlyRate().catch((e) => logError('FollowupCalculator', e))
  }, [ debouncedDebt, loanDetails.remainingDebt ])

  return (
    <FollowupCalculator
      onSliderUpdate={setLoanDetails}
      monthlyRate={monthlyRate}
      loanDetails={loanDetails}
      buttonColor={buttonColor}
      buttonText={buttonText}
      targetLink={targetLink}
      isLoading={isLoading}
      headline={headline}
    />
  );
};

export default FollowupCalculatorWrapper;
