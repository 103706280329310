import React, { useEffect, useState } from 'react'
import { Button, ModalBackdrop, ModalContainer } from 'is24-corecss'
import { useTranslation } from 'react-i18next'
import InputRow from './inputRow/InputRow'
import './OwnCapitalModal.less'
import { toCurrency } from '../../../../services/NumberUtils'

export interface OwnCapitalModalProps {
  updateOwnCapital: (value: number) => void
  onClose: () => void;
  purchasePrice?: number
}

const MAX_VALUE = 10_000_000

const OwnCapitalModal: React.FC<OwnCapitalModalProps> = ({ updateOwnCapital, onClose, purchasePrice }) => {

  const { t } = useTranslation('widgets', { keyPrefix: 'financingCalculator.ownCapitalModal' })

  const [ inputValues, setInputValues ] = useState({
    savings: 0,
    stocks: 0,
    buildingSaving: 0,
    liveInsurance: 0,
    other: 0
  });
  const [ currentOwnCapital, setCurrentOwnCapital ] = useState(0)

  const isCurrentOwnCapitalValid = currentOwnCapital <= MAX_VALUE

  const onCloseHandler = () => {
    if (purchasePrice && currentOwnCapital > purchasePrice) {
      updateOwnCapital(purchasePrice)
    } else {
      updateOwnCapital(currentOwnCapital)
    }
    onClose()
  }

  const handleInputChange = (fieldName: string, value: number) => {
    setInputValues((previousValue) => ({ ...previousValue, [ fieldName ]: value, }));
  };

  useEffect(() => {
    const totalOwnCapital = Object.values(inputValues).reduce((sum, value) => sum + value, 0);
    setCurrentOwnCapital(totalOwnCapital);
  }, [ inputValues ]);

  return (
    <div>
      <ModalBackdrop visible={true} onClose={onClose}>
        <ModalContainer
          width={580}
          visible={true}
          modalTitle={t('title')}
          onClose={onClose}
          ariaLabelCloseButton='close'
          containerClassName='OwnCapitalModalContainer'
          contentWrapperClassName='OwnCapitalModalWrapper'
        >
          <div className='Description'>
            {t('description')}
          </div>
          <div className='InputRows border-bottom'>
            <InputRow text={t('savings')} tooltipMessage={t('savingsTooltipMessage')} value={inputValues.savings}
                      onChange={(value) => handleInputChange('savings', value)} maxValue={MAX_VALUE}/>
            <InputRow text={t('stocks')} tooltipMessage={t('stocksTooltipMessage')} value={inputValues.stocks}
                      onChange={(value) => handleInputChange('stocks', value)} maxValue={MAX_VALUE}/>
            <InputRow text={t('buildingSaving')} tooltipMessage={t('buildingSavingTooltipMessage')}
                      value={inputValues.buildingSaving}
                      onChange={(value) => handleInputChange('buildingSaving', value)} maxValue={MAX_VALUE}/>
            <InputRow text={t('liveInsurance')} tooltipMessage={t('liveInsuranceTooltipMessage')}
                      value={inputValues.liveInsurance} onChange={(value) => handleInputChange('liveInsurance', value)}
                      maxValue={MAX_VALUE}/>
            <InputRow text={t('other')} tooltipMessage={t('otherTooltipMessage')} value={inputValues.other}
                      onChange={(value) => handleInputChange('other', value)} maxValue={MAX_VALUE}/>
          </div>
          <div className='Footer'>
            <div>
              <div>{t('totalOwnCapital')}</div>
              <div className='Total'>{toCurrency(currentOwnCapital)}</div>
            </div>
            <div className='font-error absolute-content margin-top-xs'>
              {!isCurrentOwnCapitalValid && t('totalErrorMessage')}
            </div>
            <div className='Buttons'>
              <Button className='CancelButton' textStyle onClick={onClose}>{t('cancelButton')}</Button>
              <Button disabled={!isCurrentOwnCapitalValid} appearance='primary' data-testid='applyButton'
                      onClick={onCloseHandler}>{t('applyButton')}</Button>
            </div>
          </div>
        </ModalContainer>
      </ModalBackdrop>
    </div>
  )
}

export default OwnCapitalModal
