import * as React from 'react';
import { useCallback, useEffect, useRef } from 'react';
import Highcharts from 'highcharts'
import * as HighchartsConfig from './InterestChartConfig'
import { getMarketDataRanges } from '../../../services/MarketDataService';
import { FixedNominalInterestRate } from '../../../api/api/types/FixedNominalInterestRate'
import { Trans, useTranslation } from 'react-i18next'

const { FIFTEEN_YEARS, TEN_YEARS, FIVE_YEARS, TWENTY_YEARS } = FixedNominalInterestRate;

type InterestChartType = {
  width?: number,
  height?: number,
  title?: string;
  className?: string;
  showHeader?: boolean;
}

const InterestChart: React.FC<InterestChartType> = ({
  width,
  showHeader = true,
  height = 250,
  title,
  className = ''
}) => {
  const { t } = useTranslation('widgets')
  title = title ? title : t('interestChart.title')
  const chartRef = useRef(null)

  const renderHighcharts = useCallback((series: any) => {
    Highcharts.setOptions(HighchartsConfig.options)

    const config = HighchartsConfig.config;
    const chart = config.chart
    if (chart && chartRef.current) {
      chart.renderTo = chartRef.current as any
      chart.width = width
      chart.height = height

      config.series = [ {
        type: 'line',
        name: 'Zinsbindung 5 Jahre',
        color: '#5dccb3',
        data: series[ FIVE_YEARS ]
      }, {
        type: 'line',
        name: 'Zinsbindung 10 Jahre',
        color: '#ecca4e',
        data: series[ TEN_YEARS ]
      }, {
        type: 'line',
        name: 'Zinsbindung 15 Jahre',
        color: '#5097d5',
        data: series[ FIFTEEN_YEARS ]
      }, {
        type: 'line',
        name: 'Zinsbindung 20 Jahre',
        color: '#d9823b',
        data: series[ TWENTY_YEARS ]
      } ];

      new Highcharts.Chart(config)
    }
  }, [ height, width ]);

  useEffect(() => {
    getMarketDataRanges().then((marketDataRanges) => {
      if (marketDataRanges) {
        const series = getSeriesFromMarketData(marketDataRanges);
        if (series) {
          renderHighcharts(series)
        }
      }
    })
  }, [ renderHighcharts ])

  const getSeriesFromMarketData = (marketDataRanges: any) => {
    const series: any = {}

    Object.keys(marketDataRanges).forEach((time) => {
      const timeMillis = new Date(parseFloat(time.slice(0, 4)), parseFloat(time.slice(4, 6)) - 1, parseFloat(time.slice(6, 8))).getTime();
      const marketData = marketDataRanges[ time ]
      for ( const fixedNominalKey in marketData ) {
        series[ fixedNominalKey ] = series[ fixedNominalKey ] || []

        if (series) {
          series[ fixedNominalKey ].push({
            x: timeMillis,
            y: marketData[ fixedNominalKey ].averageInterestRate
          })
        }
      }
    })

    return series;
  }

  const lastUpdate = ''

  return (
    <div className={`interest-chart ${className}`}>
      {showHeader && title && <div className='align-center font-xl font-light margin-bottom'>{title}</div>}
      <div ref={chartRef} />

      <div className='chart-footer font-xs font-lightgray'>
        <Trans t={t} i18nKey='interestChart.text' values={{ lastUpdate }}>
          <br/>
        </Trans>
      </div>
    </div>
  );
}

export default InterestChart
