import { API_CONTACT_REQUEST_URL } from '../contextPaths'
import { postRequest } from '../request'
import { ResourceCreatedMessageAO } from './ContactRequest'
import { AppointmentRequestAO } from './types/AppointmentRequestAO'

type HeadersType = { [ key: string ]: string | boolean }
type QueryType = { [ key: string ]: string | boolean };

export const postAppointmentRequest = async (appointmentRequest: AppointmentRequestAO,
                                             requestSource?: string,
                                             headers?: HeadersType,
                                             query?: QueryType): Promise<ResourceCreatedMessageAO> =>
  postRequest({
    url: `${API_CONTACT_REQUEST_URL}/appointmentrequest`,
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      'X-IS24-Origin': requestSource ? `IS24:${requestSource}` : '',
      ...headers
    },
    query,
    body: appointmentRequest
  }).then(resp => resp.message[ 0 ])
