import React, { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  GACEntity,
  GACEntityObject,
  GACQueryType,
  GACQueryValue,
  getEntity,
  getLocations
} from '../../../../api/api/GAC'
import { isUndefined, useIsMounted } from '../../../../utils/utils'
import { TextInputSelect } from '../../locationInput/TextInputSelect'
import '../../../leadengine/financingSettings/FinancingSettings.less'
import locationIcon from '../../../../assets/images/location-icon.svg'

export type Region = {
  locationLabel?: string,
  postalCode?: string,
  city?: string,
  street?: string,
  geoCode?: string,
}

export interface RegionSelectionType {
  onRegionSelect: (region: Region) => void,
  valid?: boolean,
  value?: string,
  errorMessage?: string
  queryType?: 'street' | 'city' | 'cityAndStreet' | 'postcode'
  label?: string,
  placeholder?: string,
  showIcon?: boolean,
}

const cityQuery = [ GACQueryValue.postcodeWithQuarter, GACQueryValue.quarterOrTown ]
const streetQuery = [ GACQueryValue.street ]
const postcodeQuery = [ GACQueryValue.postcode ]

const inputTransformation = (entity: GACEntity) => entity.label
const entryTransformation = (entity: GACEntityObject) => entity.label

export const RegionSelection: React.FC<RegionSelectionType> = ({
                                                                 valid = true,
                                                                 value = '',
                                                                 errorMessage,
                                                                 onRegionSelect,
                                                                 queryType = 'city',
                                                                 label,
                                                                 placeholder,
                                                                 showIcon = false
                                                               }) => {
  const { t } = useTranslation('leadEngine')

  const [ inputValue, setInputValue ] = useState(value)
  const [ entries, setEntries ] = useState<GACEntityObject[]>([])
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null)

  const isMounted = useIsMounted();

  useEffect(() => {
    // Do not update inputValue when current inputValue length is 1, because in that case 'value' update was triggered by regionSelection reset.
    setInputValue(prevState => prevState.length === 1 ? prevState : value)
  }, [ value ])

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (timer) {
      clearTimeout(timer)
    }
    setEntries([])

    const searchString = e.target.value

    if (inputValue === '' && searchString.length === 1 && value) {
      // Reset region selection when 'value' was already set and user started entering data again.
      onRegionSelect({})
    }
    setInputValue(searchString)

    if (searchString === '') return

    let types: GACQueryType[]
    if (queryType === 'street') types = streetQuery
    if (queryType === 'city') types = cityQuery
    if (queryType === 'postcode') types = postcodeQuery

    setTimer(setTimeout(async () => {
      if (isMounted.current) {
        const result = await getLocations(searchString, types)
        setEntries(result?.map((e) => e.entity))
      }
    }, 250))
  }

  const onSelect = async (index: number) => {
    const entityObject = entries[ index ]

    if (entityObject) {
      const entity = await getEntity(entityObject.id)

      setInputValue(inputTransformation(entity))

      onRegionSelect({
        locationLabel: entity.label,
        postalCode: entity.address?.postcode,
        city: entity.address?.city,
        street: entity.address?.street,
        geoCode: entity.geoId,
      })
    }
  }

  const onFocus = () => {
    setInputValue('')
  }

  const getPlaceHolder = () => {
    if (value?.length > 0) {
      return value
    } else {
      return isUndefined(placeholder) ? t('regionSelection.placeholder') : placeholder
    }
  }

  const onBlur = () => {
    if (inputValue?.length === 0) {
      setInputValue(value)
    }

    setTimeout(() => {
      if (isMounted.current) {
        setEntries([])
      }
    }, 440)
  }

  return <TextInputSelect<GACEntityObject>
    value={inputValue}
    allowAutoComplete={false}
    entries={entries}
    placeholder={getPlaceHolder()}
    id={'locationInput'}
    name={'locationInput'}
    label={label}
    valid={valid}
    errorMessage={errorMessage}
    entryTransformation={entryTransformation}
    onSelect={onSelect}
    onChange={onChange}
    onBlur={onBlur}
    onFocus={onFocus}
    customIconImg={showIcon ? locationIcon : undefined}
  />
}
