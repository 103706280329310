import React from 'react';
import { FinancingSettings } from '../../leadengine/financingSettings/FinancingSettings';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../redux/store';
import { LeadEngineState } from '../../leadengine/state/reducer';
import { updateFinancing } from '../../leadengine/state/actions';
import { onUpdatePropertyData } from '../../leadengine/state/thunks/app';
import { withInitialization } from '../../leadengine/hoc/withInitialization';
import { IS24_HOST_URL } from '../../../api/contextPaths';
import { useLocation } from 'react-router';

const LeadEngineEntryWidget: React.FC = () => {
  const dispatch = useDispatch();
  const { financing, property, isLoading } = useSelector<ApplicationState,
    LeadEngineState>((state) => state.leadEngine);
  const LEADENGIN_URL = `${IS24_HOST_URL}/baufinanzierung/finanzierungsangebote/finanzierungsanfrage/finanzierungsstart`;

  const search = useLocation().search;
  const getRoutingParams = () => {
    return search ? `${search.replace('?','')}&` : ``;
  }
  const buildParams = () => `?` +
    `openForm=true&` +
    //Route all params which recieved by url before, like exposeid,cmp,...
    getRoutingParams() +
    //property params
    (property.geoCode ? `geoCode=${property.geoCode}&` : '') +
    `postalCode=${property.postalCode}&` +
    //financing params
    `financingType=${financing.financingType}&` +
    `purchasePrice=${financing.purchasePrice}&` +
    `ownFunds=${financing.ownFunds}&` +
    `remainingDebt=${financing.remainingDebt}&` +
    `propertyValue=${financing.propertyValue}&` +
    `numberOfBorrowers=${financing.numberOfBorrowers}`

  const onSubmitHandler = () => {
    window.open(LEADENGIN_URL + buildParams(),'_self');
  }

  return (
      <FinancingSettings
        onChange={(financing) => dispatch(updateFinancing(financing))}
        onLocationChange={(property) => dispatch(onUpdatePropertyData(property))}
        onSubmit={onSubmitHandler}
        financing={financing}
        property={property}
        loading={isLoading}/>
  )
}

export default withInitialization(LeadEngineEntryWidget, true);
