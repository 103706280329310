import { useMemo } from 'react'
import { useTranslation,  } from 'react-i18next'
import { TFunction } from 'i18next'

export const formatYearsAndMonths = (totalMonths: number, t: TFunction, useDativeForm = false) => {
  const years = Math.trunc(totalMonths / 12)
  const months = Math.trunc(totalMonths % 12)
  const yearsLabel = useDativeForm ? t('years_dativ') : t('years')
  const monthsLabel = useDativeForm ? t('months_dativ') : t('months')
  const monthsFormatted = months === 1 ? `1 ${t('month')}` : `${months} ${monthsLabel}`

  if (years > 0) {
    const yearsFormatted = years === 1 ? `1 ${t('year')}` : `${years} ${yearsLabel}`
    if (months > 0) {
      return `${yearsFormatted} ${t('and')} ${monthsFormatted}`
    }
    return yearsFormatted
  }
  return monthsFormatted
}

const useFormattedYearsAndMonths = (totalMonths = 0): string => {
  const { t } = useTranslation('odf', { keyPrefix: 'financingFunnel.resultPage.loanDevelopmentSection.summary'})
  return useMemo(() => formatYearsAndMonths(totalMonths, t), [ t, totalMonths ]);
}

export default useFormattedYearsAndMonths
