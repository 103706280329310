import React, { useEffect, useMemo, useState } from 'react'
import { Button, ModalBackdrop, ModalContainer, StatusMessage } from 'is24-corecss'
import { Trans, useTranslation } from 'react-i18next'
import FinancingDetailsSection from './financingDetailsSection/FinancingDetailsSection'
import './AppointmentRequestModal.less'
import { FinancingType, FinancingTypeValue } from '../../../../api/api/types/FinancingType'
import { PurchaseFinancingType } from '../../../../api/api/types/PurchaseFinancingType'
import { FinancingStartType } from '../../../../api/api/types/FinancingStartType'
import { FollowUpFinancingStartType } from '../../../../api/api/types/FollowUpFinancingStartType'
import { FinancingUseType, FinancingUseTypes } from '../../../../api/api/types/FinancingUseType.ds'
import PersonalDataSection from './personalDataSection/PersonalDataSection'
import { Salutation, SalutationType } from '../../../../api/api/types/Salutation.ds'
import { TitleType, TitleTypeValues } from '../../../../api/api/types/TitleType'
import { IncomeRangeType } from '../../../../api/api/types/IncomeRange.ds'
import AddressSection from './addressSection/AddressSection'
import { Region } from '../../../common/input/regionSelection/RegionSelection'
import { isNotEmptyString, isValidEmail } from '../../../../validations/validations'
import OthersSection from './othersSection/OthersSection'
import { BankDataObject } from '../../../../api/api/BankData'
import DatetimeSection from './datetimeSection/DatetimeSection'
import { AppointmentTimeRangeType, AppointmentTimeRangeTypes } from '../../../../api/api/types/AppointmentTimeRanges'
import { FixedNominalInterestRateType } from '../../../../api/api/types/FixedNominalInterestRate'
import { postAppointmentRequest } from '../../../../api/api/AppointmentRequest'
import { AppointmentRequestDto, createAppointmentRequest } from './mapper'
import { reportAppointmentRequestCreatedEvent } from './tracking'
import { AdditionalCostsInPercents } from '../model'
import { ProductTypeAO } from '../../../../api/api/types/ReferredOffersAO'


export type AppointmentRequestModalProps = {
  providerName: string
  providerId: number | undefined
  productType?: ProductTypeAO
  financingType: FinancingType
  purchasePrice: number
  ownCapital: number,
  amortizationRate: number
  fixedRateEndYear: FixedNominalInterestRateType
  additionalCostsInPercents: AdditionalCostsInPercents
  additionalCostsSum: number
  exposeId: number | undefined
  remainingDebt:  number
  propertyValue:  number
  regionExpose: Region | undefined
  onClose: () => void
}

type Validation = {
  purchaseFinancing: boolean,
  financingStart: boolean,
  followUpFinancingStart: boolean,
  forename: boolean,
  surname: boolean,
  email: boolean,
  phoneNumber: boolean,
  incomeRange: boolean
  region: boolean,
  houseNumber: boolean,
  primaryAppointmentDate: boolean
  secondaryAppointmentDate: boolean
}

export enum ValidationState {
  VALID, INVALID, PENDING
}

const AppointmentRequestModal: React.FC<AppointmentRequestModalProps> = ({
                                                                           providerName,
                                                                           providerId,
                                                                           productType,
                                                                           financingType,
                                                                           purchasePrice,
                                                                           ownCapital,
                                                                           amortizationRate,
                                                                           fixedRateEndYear,
                                                                           exposeId,
                                                                           remainingDebt,
                                                                           propertyValue,
                                                                           regionExpose,
                                                                           onClose,
                                                                           additionalCostsInPercents,
                                                                           additionalCostsSum,
                                                                         }) => {
  const { t } = useTranslation('widgets', { keyPrefix: 'financingCalculator.appointmentRequestModal' })
  const [ touchedSubmitButton, setTouchedSubmitButton ] = useState(false);
  const [ validation, setValidation ] = useState<Validation>({
    purchaseFinancing: false,
    financingStart: false,
    followUpFinancingStart: false,
    forename: false,
    surname: false,
    email: false,
    phoneNumber: false,
    incomeRange: false,
    region: false,
    houseNumber: false,
    primaryAppointmentDate: false,
    secondaryAppointmentDate: false
  })
  const [ purchaseFinancing, setPurchaseFinancing ] = useState<PurchaseFinancingType | undefined>(undefined)
  const [ financingStart, setFinancingStart ] = useState<FinancingStartType | undefined>(undefined)
  const [ followUpFinancingStart, setFollowUpFinancingStart ] = useState<FollowUpFinancingStartType | undefined>(undefined)
  const [ financingUseType, setFinancingUseType ] = useState<FinancingUseType>(FinancingUseTypes.OWNER_OCCUPATION)
  const [ salutation, setSalutation ] = useState<SalutationType>(Salutation.MR)
  const [ title, setTitle ] = useState<TitleType>(TitleTypeValues.NO_TITLE)
  const [ forename, setForename ] = useState<string | undefined>(undefined)
  const [ surname, setSurname ] = useState<string | undefined>(undefined)
  const [ email, setEmail ] = useState<string | undefined>(undefined)
  const [ phoneNumber, setPhoneNumber ] = useState<string | undefined>(undefined)
  const [ phoneNumberTouched, setPhoneNumberTouched ] = useState(false)
  const [ phoneNumberValid, setPhoneNumberValid ] = useState(ValidationState.INVALID)
  const [ incomeRange, setIncomeRange ] = useState<IncomeRangeType | undefined>(undefined)
  const [ primaryAppointmentDate, setPrimaryAppointmentDate ] = useState<Date | undefined>(undefined);
  const [ primaryAppointmentTime, setPrimaryAppointmentTime ] = useState<AppointmentTimeRangeType>(AppointmentTimeRangeTypes.TEN_UNTIL_ELEVEN);
  const [ secondaryAppointmentDate, setSecondaryAppointmentDate ] = useState<Date | undefined>(undefined);
  const [ secondaryAppointmentTime, setSecondaryAppointmentTime ] = useState<AppointmentTimeRangeType>(AppointmentTimeRangeTypes.TEN_UNTIL_ELEVEN);
  const [ region, setRegion ] = useState<Region | undefined>(undefined)
  const [ houseNumber, setHouseNumber ] = useState<string | undefined>(undefined)
  const [ dateOfBirth, setDateOfBirth ] = useState<string | undefined>(undefined)
  const [ bank, setBank ] = useState<BankDataObject | undefined>(undefined)
  const [ numberOfBorrowers, setNumberOfBorrowers ] = useState(1)
  const [ otherLoans, setOtherLoans ] = useState(0)
  const [ message, setMessage ] = useState<string | undefined>(undefined)
  const [ error, setError ] = useState<any | undefined>(undefined)

  const titleParam = useMemo(() => ({ exposeOwner: providerName, }), [ providerName ])

  useEffect(() => {
    setValidation({
      purchaseFinancing: financingType === FinancingTypeValue.FollowupFinancing ? true : purchaseFinancing !== undefined,
      financingStart: financingType === FinancingTypeValue.FollowupFinancing ? true : financingStart != undefined,
      followUpFinancingStart: financingType === FinancingTypeValue.FollowupFinancing ? followUpFinancingStart !== undefined : true,
      forename: isNotEmptyString(forename),
      surname: isNotEmptyString(surname),
      email: isValidEmail(email),
      phoneNumber: !phoneNumberTouched || phoneNumberValid === ValidationState.VALID,
      incomeRange: incomeRange !== undefined,
      region: region !== undefined,
      houseNumber: isNotEmptyString(houseNumber),
      primaryAppointmentDate: primaryAppointmentDate !== undefined,
      secondaryAppointmentDate: secondaryAppointmentDate !== undefined
    })
  }, [ purchaseFinancing, financingStart, followUpFinancingStart, salutation, title, forename, surname, email, phoneNumber,
    phoneNumberTouched, phoneNumberValid, houseNumber, region, incomeRange, financingType, primaryAppointmentDate, secondaryAppointmentDate ])

  const isFormValid = () => Object.values(validation).every(Boolean)

  const getHeadLine = (content: string) => {
    return <span className='font-h5 font-bold'>{content}</span>
  }

  const sendAppointmentRequest = async (): Promise<void> => {
    setTouchedSubmitButton(true)
    if (isFormValid()) {
      setError(undefined);
      const appointmentRequestDto: AppointmentRequestDto = {
        amortizationRate, regionExpose, fixedRateEndYear, financingUseType,
        salutation, surname, forename, email, phoneNumber, message,
        primaryAppointmentDate, secondaryAppointmentDate, primaryAppointmentTime, secondaryAppointmentTime,
        region, houseNumber, providerId, numberOfBorrowers, exposeId, incomeRange, dateOfBirth,
        remainingDebt, propertyValue, ownCapital, financingType, purchasePrice, financingStart, title, otherLoans,
        purchaseFinancing, additionalCostsInPercents, additionalCostsSum, productType: productType
      }
      const appointmentRequest = createAppointmentRequest(appointmentRequestDto)
      const requestSource = 'financecalculator'
      const { id: contactRequestId } = await postAppointmentRequest(appointmentRequest, requestSource)
      reportAppointmentRequestCreatedEvent(contactRequestId)
    }
  };

  return (
    <div className='AppointmentRequestModal' data-testid='appointment-request-modal'>
      <ModalBackdrop visible={true} onClose={onClose}>
        <ModalContainer
          width={900}
          visible={true}
          modalTitle={<Trans t={t} i18nKey='title' values={titleParam}></Trans>}
          onClose={onClose}
          ariaLabelCloseButton='close'
          containerClassName='AppointmentRequestModalContainer'
          contentWrapperClassName='AppointmentRequestModal'
        >
          {error &&
            (<StatusMessage
            headline={getHeadLine(t(`error.headline`))} message={t(`error.message`)} statusType='error'/>)
          }
          <FinancingDetailsSection
            financingType={financingType}
            touched={touchedSubmitButton}
            purchaseFinancing={purchaseFinancing}
            purchaseFinancingValid={validation.purchaseFinancing}
            setPurchaseFinancing={setPurchaseFinancing}
            financingStart={financingStart}
            financingStartValid={validation.financingStart}
            setFinancingStart={setFinancingStart}
            followUpFinancingStart={followUpFinancingStart}
            followUpFinancingStartValid={validation.followUpFinancingStart}
            setFollowUpFinancingStart={setFollowUpFinancingStart}
            financingUseType={financingUseType}
            setFinancingUseType={setFinancingUseType}
          />
          <PersonalDataSection
            submitted={touchedSubmitButton}
            salutation={salutation}
            setSalutation={setSalutation}
            title={title}
            setTitle={setTitle}
            forename={forename}
            forenameValid={validation.forename}
            setForename={setForename}
            surname={surname}
            surnameValid={validation.surname}
            setSurname={setSurname}
            email={email}
            emailValid={validation.email}
            setEmail={setEmail}
            setPhoneNumberTouched={setPhoneNumberTouched}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            phoneNumberValid={phoneNumberValid}
            setPhoneNumberValid={setPhoneNumberValid}
            incomeRange={incomeRange}
            incomeRangeValid={validation.incomeRange}
            setIncomeRange={setIncomeRange}
          />
          <DatetimeSection
            submitted={touchedSubmitButton}
            primaryAppointmentDate={primaryAppointmentDate}
            primaryAppointmentDateValid={validation.primaryAppointmentDate}
            setPrimaryAppointmentDate={setPrimaryAppointmentDate}
            primaryAppointmentTime={primaryAppointmentTime}
            setPrimaryAppointmentTime={setPrimaryAppointmentTime}
            secondaryAppointmentDate={secondaryAppointmentDate}
            secondaryAppointmentDateValid={validation.secondaryAppointmentDate}
            setSecondaryAppointmentDate={setSecondaryAppointmentDate}
            secondaryAppointmentTime={secondaryAppointmentTime}
            setSecondaryAppointmentTime={setSecondaryAppointmentTime}
          />
          <AddressSection submitted={touchedSubmitButton} region={region} regionValid={validation.region} setRegion={setRegion}
                          houseNumber={houseNumber} houseNumberValid={validation.houseNumber}
                          setHouseNumber={setHouseNumber}/>
          <OthersSection
            dateOfBirth={dateOfBirth}
            setDateOfBirth={setDateOfBirth}
            bank={bank}
            numberOfBorrowers={numberOfBorrowers}
            setNumberOfBorrowers={setNumberOfBorrowers}
            setBank={setBank}
            otherLoans={otherLoans}
            setOtherLoans={setOtherLoans}
            message={message}
            setMessage={setMessage}
          />
          <div>
            <span className='Terms' data-testid='terms'>Mit Klick auf „Anbieter” kontaktieren bist du damit einverstanden, dass dich unsere Finanzierungspartner zu Beratungszwecken anrufen und mittels elektronischer Post kontaktieren dürfen. ImmoScout24 wird hierzu deine Daten an den Anbieter weitergeben.</span>
            <div className='Footer'>
              <button className='button-primary Contact' data-testid='submit-button' onClick={() => {
                 sendAppointmentRequest()
                   .then(onClose)
                   .catch(setError)
              }}>{t(`sendRequestButtonLabel`)}
              </button>
            </div>
            <Button className='CancelButton' textStyle onClick={onClose}> {t(`cancelButtonLabel`)} </Button>
          </div>
        </ModalContainer>
      </ModalBackdrop>
    </div>
  )
}

export default AppointmentRequestModal
