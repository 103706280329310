import React, { useState } from 'react'
import { Button } from 'is24-corecss'
import { useTranslation } from 'react-i18next'
import './LeadEngineButton.less'
import { LEAD_ENGINE_ROAD_PAGE } from '../../../../config/contextPaths'
import { Region } from '../../../common/input/regionSelection/RegionSelection'
import { FinancingType, FinancingTypeValue } from '../../../../api/api/types/FinancingType'
import AppointmentRequestModal from '../appointmentRequest/AppointmentRequestModal'
import { FixedNominalInterestRateType } from '../../../../api/api/types/FixedNominalInterestRate'
import { AdditionalCostsInPercents } from '../model'
import { ProductTypeAO } from '../../../../api/api/types/ReferredOffersAO'

const LEAD_ENGINE_URL = `${LEAD_ENGINE_ROAD_PAGE}/finanzierungsstart`

export type LeadEngineButtonProps = {
  providerName: string,
  providerId?: number | undefined,
  productType?: ProductTypeAO
  financingType: FinancingType
  purchasePrice: number
  region?: Region
  additionalCostsInPercents: AdditionalCostsInPercents
  additionalCosts: number
  ownCapital: number
  propertyValue: number
  remainingDebt: number
  buttonText?: string
  description?: React.ReactChild
  hasBrandedOffer: boolean
  amortizationRate?: number
  fixedRateEndYear?: FixedNominalInterestRateType
  additionalCostsSum?: number
  exposeId?: number | undefined
}

const LeadEngineButton: React.FC<LeadEngineButtonProps> = (
  {
    providerName,
    providerId,
    productType,
    financingType,
    purchasePrice,
    region,
    additionalCostsInPercents,
    additionalCosts,
    ownCapital,
    propertyValue,
    remainingDebt,
    buttonText,
    description,
    hasBrandedOffer,
    amortizationRate,
    fixedRateEndYear,
    additionalCostsSum,
    exposeId
  }) => {
  const { t } = useTranslation('widgets', { keyPrefix: 'financingCalculator.leadEngineButton' })

  const [ isAppointmentRequestModalVisible, setAppointmentRequestModalVisible ] = useState(false)

  const buildLocationParams = (region?.geoCode ? `geoCode=${region?.geoCode}&` : '') + (region?.postalCode ? `postalCode=${region?.postalCode}&` : '')

  const standardUrl =
    `?openForm=true&` +
    buildLocationParams +
    `financingType=${financingType}&` +
    `purchasePrice=${purchasePrice}&` +
    `ownFunds=${ownCapital}&` +
    `additionalCosts=${additionalCosts}`

  const followUpFinancingUrl =
    `?openForm=true&` +
    buildLocationParams +
    `financingType=${FinancingTypeValue.FollowupFinancing}&` +
    `propertyValue=${propertyValue}&` +
    `remainingDebt=${remainingDebt}`

  const createUrlBasedOnFinancingType = (financingType: FinancingType) => financingType === FinancingTypeValue.FollowupFinancing ? followUpFinancingUrl : standardUrl

  const onClickHandler = () => {
    if (hasBrandedOffer) {
      setAppointmentRequestModalVisible(true)
    } else {
      window.open(LEAD_ENGINE_URL + createUrlBasedOnFinancingType(financingType))
    }
  }

  return (
    <>
      <div className='LeadEngineButton'>
        {(region?.geoCode || region?.postalCode) &&
          <div className='padding-top-l font-center'
               data-testid='lead-engine-button-container'>
            <Button className='Button palm-one-whole' appearance='primary'
                    onClick={onClickHandler}>
              {buttonText ? buttonText : t(hasBrandedOffer ? 'buttonBranded' : 'button')}
            </Button>
            {description ?
              description
              :
              <div className='Description palm-one-whole font-center padding-top-m padding-bottom-s'>
                {t('description')}
              </div>
            }
          </div>
        }
      </div>
      {isAppointmentRequestModalVisible &&
        <AppointmentRequestModal providerName={providerName}
                                 providerId={providerId}
                                 productType={productType}
                                 financingType={financingType}
                                 purchasePrice={purchasePrice}
                                 ownCapital={ownCapital}
                                 amortizationRate={amortizationRate!}
                                 fixedRateEndYear={fixedRateEndYear!}
                                 additionalCostsInPercents={additionalCostsInPercents}
                                 additionalCostsSum={additionalCostsSum!}
                                 exposeId={exposeId}
                                 remainingDebt={remainingDebt}
                                 propertyValue={propertyValue}
                                 regionExpose={region}
                                 onClose={() => setAppointmentRequestModalVisible(false)}/>}
    </>
  )
}

export default LeadEngineButton
