import { StepWrapper } from '../StepWrapper'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RoadPage } from '../../FinancingRequestRoad'
import { withPageViewReporting } from '../../hoc/withTrackPageView'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../../../redux/store'
import { LeadEngineState } from '../../state/reducer'
import { onFinancingRequestSubmit } from '../../state/thunks/app'
import { MenuBar } from '../../MenuBar'
import { NumberInput } from '../../../common/input/numericInput'
import { Button } from 'is24-corecss'
import { LogarithmicSlider } from '../../../common/logarithmicSlider/LogarithmicSlider'
import { toCurrency } from '../../../../services/NumberUtils'
import './IncomeSingleValuePage.less'
import { useNavigate } from 'react-router-dom'

const DEFAULT_VALUE = 3500
const MIN_VALUE = 1000
const MAX_VALUE = 25_000
const SLIDER_STEP = 100

const isValueValid = (value: number | undefined) => value != undefined && value >= MIN_VALUE && value <= MAX_VALUE

export type IncomeSingleValuePageProps = {
  nextPage: string,
  userFirstName?: string,
}

const IncomeSingleValuePage: React.FC<IncomeSingleValuePageProps> = ({ nextPage, userFirstName }) => {
  const { t } = useTranslation('leadEngine', { keyPrefix: 'netIncomeTotal' })
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { financing: { netIncomeTotal } } = useSelector<ApplicationState, LeadEngineState>(state => state.leadEngine)
  const [ value, setValue ] = useState(netIncomeTotal)
  const [ textInputValue, setTextInputValue ] = useState(netIncomeTotal || DEFAULT_VALUE)
  const [ valid, setValid ] = useState(isValueValid(netIncomeTotal))
  const [ touched, setTouched ] = useState(false)

  useEffect(() => setValid(isValueValid(value)), [ value ])

  useEffect(() => {
    if (!valid) {
      setValid(isValueValid(textInputValue))
    }
  }, [ valid, textInputValue ])

  const handleNextPage = () => dispatch(onFinancingRequestSubmit(nextPage, navigate)({ netIncomeTotal: value }))

  const title = t(userFirstName ? 'titleWithUserName' : 'title', { name: userFirstName })
  const validationMessage = t('rangeValidationError', { min: toCurrency(MIN_VALUE), max: toCurrency(MAX_VALUE) })

  const updateValue = (newValue: number) => setValue(oldValue => {
    if (newValue !== oldValue) {
      setTouched(true)
    }
    return newValue
  })

  const nextButtonDisabled = !valid || (value == undefined && (textInputValue === DEFAULT_VALUE || !isValueValid(textInputValue)))

  return <>
    <MenuBar page={RoadPage.INCOME_SINGLE_VALUE} hideClose/>
    <StepWrapper title={title}>
      <div className='IncomeSingleValuePage one-half lap-two-third palm-one-whole horizontal-center'>
        <div className='align-center'>{t('description')}</div>
        <div className='NetIncomeTotalInputWrapper'>
          <NumberInput
            id='netIncomeTotalInput'
            value={textInputValue}
            onChange={setTextInputValue}
            onBlur={() => updateValue(textInputValue)}
            valid={valid || !touched}
          />
        </div>
        <div className='NetIncomeTotalValidation font-error align-center'>{!valid && touched && validationMessage}</div>
        <LogarithmicSlider
          id='netIncomeTotalSlider'
          min={MIN_VALUE}
          max={MAX_VALUE}
          value={value ?? DEFAULT_VALUE}
          onRelease={newValue => {
            updateValue(newValue)
            setTextInputValue(newValue)
          }}
          logarithmicCurveFactor={0}
          step={SLIDER_STEP}
          showScaleLabels
        />
        <div className='grid-item align-right margin-top padding-top center'>
          <Button className='NetIncomeTotalNextButton' appearance='primary' onClick={handleNextPage} disabled={nextButtonDisabled}>
            {t('nextPage')}
          </Button>
        </div>
      </div>
    </StepWrapper>
  </>
}

export default withPageViewReporting(IncomeSingleValuePage, 'incomeSingleValue')
