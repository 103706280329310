import React from 'react'
import { Button } from 'is24-corecss'
import { toCurrency } from '../../../services/NumberUtils'
import './FinancingSettings.less'
import { useTranslation } from 'react-i18next'
import { isValidLoanAmount } from '../models/Financing'

export const Summary = ({ loanAmount }: { loanAmount: number }) => {
  const { t } = useTranslation('leadEngine')

  return (
    <div className="grid-flex flex-nowrap grid-align-center grid-justify-space-between">
      <div className="one-half summary">
        <div
          className={'loan-amount padding-m' + (isValidLoanAmount(loanAmount) ? ' ' : ' font-error')}>
          <span className="padding-right-s">{t('financingSettings.loanAmount')}</span>
          <strong>{toCurrency(loanAmount)}</strong>
        </div>
      </div>

      <div className="one-half align-right">
        <Button appearance="primary" type="submit">{t('common:button.acquire')}</Button>
      </div>
    </div>
  )
}
