import { fetchExposeEntity } from '../../../../api/api/Expose'
import optimizelyClient, { OptimizelyEvent } from '../../../../config/optimizely'
import { isEmptyObject } from '../../../../utils/utils'
import {
  enableLeadEngineTestRun,
  findMatchingProvidersRequest,
  findMatchingProvidersSuccess,
  initializeLeadEngine,
  initializeLeadEngineLoading,
  LeadEngineBorrowersDataUpdateAction,
  LeadEngineEnableTestRunAction,
  LeadEngineFinancingDataUpdateAction,
  LeadEngineInitAction,
  LeadEngineLoadAction,
  LeadEnginePropertyDataUpdateAction,
  LeadEngineProviderSearchAction,
  LeadEngineSendExtendedLeadError,
  LeadEngineSendExtendedLeadRequest,
  LeadEngineSendExtendedLeadSuccess,
  LeadEngineSendLeadError,
  LeadEngineSendLeadRequest,
  LeadEngineSendLeadSuccess,
  sendLeadEngineExtendedLeadError,
  sendLeadEngineExtendedLeadRequest,
  sendLeadEngineExtendedLeadSuccess,
  sendLeadEngineLeadError,
  sendLeadEngineLeadRequest,
  sendLeadEngineLeadSuccess,
  updateBorrowersData,
  updateFinancing,
  updatePropertyData
} from '../actions'
import { Dispatch } from 'redux'
import {
  buildFinancingByUrlParams,
  Financing,
  getAdditionalCosts,
  getAdditionalCostsPercentages, isOnObjectSearch, matchExposeToFinancing,
  PrimaryBorrower,
  Borrower
} from '../../models/Financing'
import { ApplicationState } from '../../../../redux/store'
import { ContactRequestAO } from '../../../../api/api/types/ContactRequestAO.ds'
import { ContactChannel } from '../../../../api/api/types/ContactChannel.ds'
import { IncomeRangeType } from '../../../../api/api/types/IncomeRange.ds'
import { postFinancingRequest, putExtendedFinancingRequest, validateContactRequest, REQUEST_SOURCE } from '../../../../api/api/ContactRequest'
import { BorrowersType, LeadEngineData } from '../reducer'
import { ExtendedContactRequestAO } from '../../../../api/api/types/ExtendedContactRequestAO.ds'
import { FinancingObjectAO } from '../../../../api/api/types/FinancingObjectAO.ds'
import { BorrowerAO } from '../../../../api/api/types/BorrowerAO.ds'
import { getISODateString } from '../../../../api/utils'
import {
  reportExtendedLeadEvent,
  reportFinancingRequest,
} from '../../services/FinanceTrackingService'
import {
  buildPropertyByUrlParams,
  fetchFirstPropertyLocation,
  fetchPropertyEntity, matchExposeToProperty,
  Property
} from '../../models/Property'
import { LeadEngineUrlParams } from '../../hoc/withInitialization'
import { RoadPage } from '../../FinancingRequestRoad'
import { goToFinancingStartPage, goToRoadPage } from '../../routing'
import { convertToContactRequestErrors } from '../../../../api/api/ContactRequestExceptions'
import { IS24_HOST_URL } from '../../../../api/contextPaths'
import { logInfo } from '../../../../api/api/Logger'
import { ProjectStateExtended } from '../../../../api/api/types/ProjectStateExtended'
import { ProjectState } from '../../../../api/api/types/ProjectState'
import { NavigateFunction } from 'react-router/dist/lib/hooks'

function isNotUndefined(value: any) {
  return value !== undefined;
}

function qualifiesForFinancingStart(urlParams: LeadEngineUrlParams) {
  return isNotUndefined(urlParams.openform);
}

export const onLeadEngineInitialization = (urlParams: LeadEngineUrlParams, navigate: NavigateFunction, asWidget=false) =>
  async (dispatch: Dispatch<LeadEngineInitAction | LeadEngineLoadAction | LeadEnginePropertyDataUpdateAction | LeadEngineFinancingDataUpdateAction | LeadEngineBorrowersDataUpdateAction>, getState: () => ApplicationState) => {
    dispatch(initializeLeadEngineLoading())

    dispatchDataFromUrlParams(urlParams)(dispatch)
    await dispatchDataFromProperty()(dispatch, getState)
    dispatchFinancingSetup(urlParams)(dispatch, getState)

    dispatch(initializeLeadEngine())

    //routing based on URL params (widget or standard)
    const isQualifiedForFinanceStart = qualifiesForFinancingStart(urlParams)
    if(asWidget && isQualifiedForFinanceStart) {
      const LEAD_ENGINE_URL = `${IS24_HOST_URL}/baufinanzierung/finanzierungsangebote/finanzierungsanfrage/finanzierungsstart`;
      window.open(LEAD_ENGINE_URL + window.location.search,'_self');
    }
    else if(isQualifiedForFinanceStart) {
      goToRoadPage(RoadPage.FINANCING_START, navigate)
    }
    else if (!asWidget) {
      goToFinancingStartPage(navigate)
    }
  }

export const onUpdatePropertyData = (propertyData: Partial<Property>) =>
  (dispatch: Dispatch<LeadEnginePropertyDataUpdateAction | LeadEngineFinancingDataUpdateAction>, getState: () => ApplicationState) => {
    let { property } = getState().leadEngine
    const newGeoCode = property.geoCode !== propertyData.geoCode ? propertyData.geoCode : undefined
    property = { ...property, ...propertyData }

    dispatch(updatePropertyData(property))

    if (newGeoCode) {
      const additionalCostsPercentages = getAdditionalCostsPercentages(newGeoCode)

      if (additionalCostsPercentages) {
        dispatch(updateFinancing({ additionalCostsPercentages }))
      }
    }
  }

export const onBorrowersDataSubmit = (goToPage: string, borrowers: Partial<BorrowersType>, navigate: NavigateFunction) =>
  (dispatch: Dispatch<LeadEngineBorrowersDataUpdateAction>) => {
    dispatch(updateBorrowersData(borrowers))
    goToRoadPage(goToPage, navigate)
  }

export const onFinancingRequestSubmit = (goToPage: string, navigate: NavigateFunction) => (financingData: Partial<Financing>) =>
  (dispatch: Dispatch<LeadEngineFinancingDataUpdateAction>, getState: () => ApplicationState) => {
    const financing = { ...getState().leadEngine.financing, ...financingData }
    dispatch(updateFinancing(financing))
    goToRoadPage(goToPage, navigate)
  }

export const onPropertySubmit = (goToPage: string, navigate: NavigateFunction) => (propertyData: Partial<Property>) =>
  (dispatch: Dispatch<LeadEnginePropertyDataUpdateAction>, getState: () => ApplicationState) => {
    const property = { ...getState().leadEngine.property, ...propertyData }
    dispatch(updatePropertyData(property))
    goToRoadPage(goToPage, navigate)
  }

export const onIncomeRangeSubmit = (netIncomeRange: IncomeRangeType) =>
  (dispatch: Dispatch<LeadEngineFinancingDataUpdateAction | LeadEngineProviderSearchAction>, getState: () => ApplicationState) => {
    const financing = { ...getState().leadEngine.financing, netIncomeRange }
    dispatch(updateFinancing(financing))

    dispatch(findMatchingProvidersRequest())
    setTimeout(() => {
      dispatch(findMatchingProvidersSuccess())
    }, 3000)
  }

export const recordContactDetails = (borrower: Partial<PrimaryBorrower>, goToPage: string, errorGoToPage: string, navigate: NavigateFunction) =>
  async (dispatch: Dispatch<LeadEngineBorrowersDataUpdateAction | LeadEngineSendLeadRequest | LeadEngineSendLeadSuccess | LeadEngineSendLeadError>, getState: () => ApplicationState) => {
    const { financing, borrowers: { primary }, property , isTestRun } = getState().leadEngine
    const primaryBorrower = { ...primary, ...borrower }

    dispatch(updateBorrowersData({ primary: borrower }))
    dispatch(sendLeadEngineLeadRequest())

    try {
      const request = convertToContactRequest(financing, primaryBorrower, property)
      const { updateToken, id, advisoryLink } =
        (!isTestRun)
          ? await postFinancingRequest(request, REQUEST_SOURCE.LEADENGINE, undefined, { maAppointmentLink: true })
          : await validateContactRequest(request)

      if (!isTestRun) {
        await reportFinancingRequest(id, financing, primaryBorrower, property)
      }

      optimizelyClient.track(OptimizelyEvent.LEAD_FINANCE_SUCCESS)
      optimizelyClient.track(OptimizelyEvent.LEAD_FINANCE_LEADENGINE_SUCCESS)

      goToRoadPage(goToPage, navigate)
      dispatch(sendLeadEngineLeadSuccess(updateToken, id, advisoryLink))
    } catch (e: any) {
      const errors = convertToContactRequestErrors(e)
      goToRoadPage(errorGoToPage, navigate)
      dispatch(sendLeadEngineLeadError(errors))
    }
  }

export const recordExtendedContactDetails = (goToPage: string, data: Partial<LeadEngineData>, navigate: NavigateFunction, advisoryLinkFeatureSwitch = false) =>
  async (dispatch: Dispatch<LeadEnginePropertyDataUpdateAction | LeadEngineBorrowersDataUpdateAction | LeadEngineSendExtendedLeadRequest | LeadEngineSendExtendedLeadSuccess | LeadEngineSendExtendedLeadError>,
         getState: () => ApplicationState) => {
    const leadEngineState = getState().leadEngine;
    const { contactRequestId, updateToken, financing, borrowers } = leadEngineState
    const property = { ...leadEngineState.property, ...data.property }

    const newBorrowers: BorrowersType = {
      primary: { ...borrowers.primary, ...data.borrowers?.primary },
      secondary: { ...borrowers.secondary, ...data.borrowers?.secondary }
    }

    dispatch(updatePropertyData(property))
    dispatch(updateBorrowersData(newBorrowers))

    const request = convertToExtendedRequest(financing, newBorrowers, property)

    dispatch(sendLeadEngineExtendedLeadRequest())
    try {
      const response = await putExtendedFinancingRequest(request, contactRequestId!, updateToken!, advisoryLinkFeatureSwitch)
      reportExtendedLeadEvent(financing, newBorrowers.primary, property)
      goToRoadPage(goToPage, navigate)
      dispatch(sendLeadEngineExtendedLeadSuccess(response))
    } catch (e: any) {
      goToRoadPage(goToPage, navigate)
      dispatch(sendLeadEngineExtendedLeadError(e))
    }
  }


export const convertToContactRequest = (financing: Financing, primaryBorrower: Partial<PrimaryBorrower>, property: Property): ContactRequestAO => {
  const {
    forename,
    surname,
    salutation,
    street,
    streetNumber,
    postalCode,
    location,
    email,
    phone,
    availability,
    employment,
    employmentSector
  } = primaryBorrower

  const {
    netIncomeRange,
    netIncomeTotal,
    financingStart,
    financingType,
    remainingDebt,
    propertyValue,
    financingUseType,
    amortizationRate,
    fixedNominalInterestRate,
    projectState,
    purchasePrice,
    ownFunds,
    additionalCostsPercentages,
    partnerTracking,
    exposeId
  } = financing

  const {
    brokerCommissionPercentage,
    entryLandRegisterPercentage,
    landTransferPercentage,
    notaryCostsPercentage
  } = additionalCostsPercentages

  const additionalCosts = getAdditionalCosts(financing)

  return {
    contactChannel: ContactChannel.TELEPHONE,
    email: email!,
    exposeId,
    forename: forename!,
    phoneNumber: phone!,
    salutation,
    surname: surname!,
    netIncomeRange,
    netIncome: netIncomeTotal,
    contactAddress: {
      street: street!,
      streetNumber: streetNumber!,
      postalCode: postalCode!,
      location: location!
    },
    financingTerms: {
      amortizationRate,
      employment,
      fixedNominalInterestRate,
      employmentSector,
      financing: {
        type: financingType,
        financingStart,
        projectState: mapProjectStateExtended(projectState),
        purchasePrice,
        ownFunds,
        additionalCosts,
        remainingDebt,
        propertyValue,
        additionalCostsPercentages: {
          brokerCommissionPercentage,
          entryLandRegisterPercentage,
          landTransferPercentage,
          notaryCostsPercentage
        }
      },
      geoCode: property.geoCode,
      postalCode: property.postalCode
    },
    useType: financingUseType,
    availability,
    partnerTracking,
    energyEfficiencyClass: property.energyEfficiencyClass,
    energeticModernization: property.energeticModernization
  }
}



export const convertToExtendedRequest = (financing: Financing, borrowers: BorrowersType, property: Property): ExtendedContactRequestAO => {
  const { primary, secondary } = borrowers
  const { numberOfBorrowers, borrowerRelationship, numberOfChildren } = financing

  const extendedRequest: ExtendedContactRequestAO = {
    dateOfBirth: getISODateString(primary.dateOfBirth),
    extendedContactRequest: {
      numberOfBorrowers: numberOfBorrowers,
      borrowerRelationship: borrowerRelationship!,
      numberOfChildren: numberOfChildren!,
      firstBorrower: convertToBorrowerAO(primary)
    }
  }

  if(!isOnObjectSearch(financing.projectState)) {
    extendedRequest.extendedContactRequest.financingObject = convertToFinancingObjectAO(property)
  }

  if (secondary && financing.numberOfBorrowers > 1) {
    extendedRequest.extendedContactRequest.secondBorrower = convertToBorrowerAO(secondary)
  }

  return extendedRequest
}

const convertToFinancingObjectAO = (property: Property): FinancingObjectAO => {
  const { street = '', streetNumber = '', postalCode = '', city = '', type = 'HOUSE_BUY', subType, livingArea, siteArea, constructionYear } = property

  return {
    street, streetNumber, postalCode, city, type, subType, livingArea, siteArea, constructionYear
  }
}

const convertToBorrowerAO = (borrower: Partial<Borrower>): BorrowerAO => {
  return {
    firstName: borrower.forename,
    lastName: borrower.surname,
    dateOfBirth: getISODateString(borrower.dateOfBirth),
    nationality: borrower.nationality,
    employment: borrower.employment,
    workContractType: borrower.workContractType,
    workContractEndDate: borrower.workContractEndDate,
    maritalStatus: borrower.maritalStatus,
    income: borrower.income,
    spending: borrower.expenses
  } as Required<BorrowerAO>
}

const dispatchDataFromUrlParams = (urlParams: LeadEngineUrlParams) => {
  return (dispatch: Dispatch<LeadEngineFinancingDataUpdateAction | LeadEnginePropertyDataUpdateAction | LeadEngineBorrowersDataUpdateAction | LeadEngineEnableTestRunAction>) => {
    const propertyByParams = buildPropertyByUrlParams(urlParams)
    const financingByParams = buildFinancingByUrlParams(urlParams)
    const isTestRun: boolean = 'test' in urlParams;

    !isEmptyObject(propertyByParams) && dispatch(updatePropertyData(propertyByParams))
    !isEmptyObject(financingByParams) && dispatch(updateFinancing(financingByParams))
    isTestRun && dispatch(enableLeadEngineTestRun())
  }
}

const dispatchDataFromProperty = () => {
  return async (dispatch: Dispatch<LeadEngineFinancingDataUpdateAction | LeadEnginePropertyDataUpdateAction>, getState: () => ApplicationState) => {
    const { property, financing } = { ...getState().leadEngine }

    if (financing.exposeId) {
      const expose = await fetchExposeEntity(financing.exposeId)
      if (expose) {
        dispatch(updatePropertyData(matchExposeToProperty(expose)))
        dispatch(updateFinancing(matchExposeToFinancing(expose, financing)))
        return
      }
    }

    if (property.geoCode) {
      try {
        const propertyEntity = await fetchPropertyEntity(property.geoCode)
        if (propertyEntity.geoCode) {
          dispatch(updatePropertyData(propertyEntity))
          return
        }
      } catch (error: unknown) {
        logInfo(`No address found for geoCode ${property.geoCode}. GeoCode will be skipped.`)
      }
    }

    if (property.postalCode) {
      const propertyEntity = await fetchFirstPropertyLocation(property.postalCode)
      if (propertyEntity) {
        dispatch(updatePropertyData(propertyEntity))
        return
      }
    }

    // reset property data if no valid data was found
    dispatch(updatePropertyData({ geoCode: undefined, postalCode: undefined }))
    dispatch(updateFinancing({ ...financing, exposeId: undefined }))
  }
}

const dispatchFinancingSetup = (urlParams: LeadEngineUrlParams) => {
  return (dispatch: Dispatch<LeadEngineFinancingDataUpdateAction | LeadEnginePropertyDataUpdateAction>, getState: () => ApplicationState) => {
    const OWN_FUNDS_PERCENTAGE_OF = 20
    const { financing } = getState().leadEngine

    if (!urlParams.ownfunds && financing.purchasePrice >= 0) {
      dispatch(updateFinancing({ ownFunds: financing.purchasePrice / 100 * OWN_FUNDS_PERCENTAGE_OF }))
    }
  }
}

export const mapProjectStateExtended = (projectStateExtended?: ProjectStateExtended): ProjectState => {
  switch (projectStateExtended) {
    case 'ON_OBJECT_SEARCH':
      return 'ON_OBJECT_SEARCH'
    case 'OBJECT_INTERESTED':
      return 'OBJECT_FOUND'
    case 'OBJECT_VIEWING':
      return 'OBJECT_FOUND'
    case 'PURCHASING_NEGOTIATIONS':
      return 'PURCHASING_NEGOTIATIONS'
    case 'AWARDED':
      return 'PURCHASING_NEGOTIATIONS'
    case 'NOTARY_APPOINTMENT_PLANNED':
      return 'NOTARY_APPOINTMENT_PLANNED'
    case 'PROPERTY_FOUND':
      return 'PROPERTY_FOUND'
    case 'ON_BUILDER_SEARCH':
      return 'ON_BUILDER_SEARCH'
    default:
      return 'NA'
  }
}
