import React, { ReactNode, useState } from 'react'
import { StatusMessage } from 'is24-corecss'
import { useTranslation } from 'react-i18next'
import { IS24_BAUFINANZIERUNG_URL } from '../../../api/contextPaths'
import { useContactedProviders } from '../../../hooks/useContactedProviders'
import { Loading } from '../../common/snippets/Loading'
import { RatingStars } from '../../common/rating/RatingStars'
import { withPageViewReporting } from '../hoc/withTrackPageView'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../../../redux/store'
import { LeadEngineState } from '../state/reducer'
import { AdvisoryRecommendation } from './AdvisoryRecommendation'
import ClaraAvatar from '../../common/clara/ClaraAvatar'

const TIPS_URL = `${IS24_BAUFINANZIERUNG_URL}/lp/baufi-fibel.html?cmp_id=10-04662&cmp_name=finance_content&cmp_position=finance_le_lp&cmp_creative=successpage_button_fibel`
const CHECKLISTS_AND_DOWNLOADS_URL = `${IS24_BAUFINANZIERUNG_URL}/checklisten-downloads.html?cmp_id=10-04663&cmp_name=finance_content&cmp_position=finance_le_lp&cmp_creative=successpage_button_checklist`

const NextStep: React.FC<{ type: 'done' | 'pending', text: ReactNode }> = ({ type, text}) => {
  const iconStyle = type === 'done' ? 'is24-icon-accept font-confirm' : 'is24-icon-info'
  return <div className='grid-item one-whole'>
    <div className='grid-item grid-item-fixed-width' style={{ width: '3em' }}>
      <i className={`font-xl ${iconStyle}`}/>
    </div>
    <div className='grid-item ten-twelfth'>
      {text}
    </div>
  </div>
}

const Advantage: React.FC<{ icon: string, text: string }> = ({ icon, text }) => {
  return <div className={`grid-flex grid-item palm-one-whole`}>
    <i className={`grid-item font-xxl ${icon} padding-right-s`}/>
    <p className={"grid-item padding-right-m"}>{text}</p>
  </div>
}


const LeadSuccessPage: React.FC<{advisorySwitchFeatureEnabled: boolean}> = ({ advisorySwitchFeatureEnabled = false }) => {
  const { t } = useTranslation('leadEngine')
  const { borrowers: { primary: { email = '' } }, contactRequestId, updateToken } = useSelector<ApplicationState, LeadEngineState>(state => state.leadEngine)
  const [ contactedProviders, isLoading ] = useContactedProviders(contactRequestId, updateToken)
  const [advisoryAvailable, setAdvisoryAvailable] = useState(true);


  if (advisorySwitchFeatureEnabled) {
    return <div className='padding-vertical-xxl align-center padding-left-l'>
      <ClaraAvatar />
      <h1>{t('advisorySuccess.statusHeadline')}</h1>
      {
        advisoryAvailable ?
          <>
            <div className="padding-bottom-l">
              <p className="text" style={{ whiteSpace: "pre-wrap" }}>{t('advisorySuccess.statusMessage')}</p>
            </div>
            <AdvisoryRecommendation onAdvisoryAvailable={setAdvisoryAvailable} showAdvisoryLinkFeatureEnabled={advisorySwitchFeatureEnabled} />
            <div className="grid grid-flex grid-justify-center padding-top-l">
              <Advantage icon="is24-icon-lock-unlock" text="Kostenfrei & unverbindlich" />
              <Advantage icon="is24-icon-message" text="Beraten durch unabhängige Expert:innen" />
              <Advantage icon="is24-icon-calendar-check" text="Flexible Termine" />
            </div>
          </>
          : <p className="text">{t('advisorySuccess.statusMessageWithoutAdvisory')}</p>
      }
      <h3 className="padding-top-xl">{t('advisorySuccess.nextStepsTitle')}</h3>
      <div className="grid grid-flex grid-justify-center padding-top-m">
        <div className="grid-item desk-two-thirds gutter-vertical-l">
          {
            advisoryAvailable ?
              <NextStep text={t('advisorySuccess.nextFirstStep')} type={'pending'}/> :
              <NextStep text={t('advisorySuccess.nextFirstStepNoAdvisory')} type={'pending'}/>
          }
          <NextStep text={t('advisorySuccess.nextSecondStep')} type={'pending'}/>
        </div>
      </div>
      <div className="font-bold padding-vertical-l">
        {t('advisorySuccess.wish')}
      </div>
    </div>
  }

  return <div className='padding-top'>
    <ClaraAvatar />
    <StatusMessage headline={t('success.statusHeadline')}
                   message={t('success.statusMessage', {email})} statusType="confirm"/>

    <p className='font-l padding-top-xl'>{t('success.message')}</p>

    {!isLoading ?
      contactedProviders.map((provider, index) => (
        <div key={index} className='grid grid-flex padding-top-xl padding-bottom-xl'>
          <div className='grid-align-center one-fifth palm-one-half align-center'>
            <img style={{ maxHeight: 30, maxWidth: 120 }} src={provider.logo?.url}
                 alt={provider.name}/>

            {provider.averageRating &&
            <RatingStars rating={provider.averageRating} votes={provider.numberOfRatings}/>}

          </div>
          <div className='grid-item four-fifth'>
            <div className='grid-item three-fifth'>
              <p className='padding-right-s'>{t('success.providerInformationAware')}</p>
            </div>
            <div className='grid-item one-fifth'>
              <a className='button' href={`${IS24_BAUFINANZIERUNG_URL}/anbieter/${provider.id}/`}
                 target="_blank" rel="noreferrer">{t('success.btnToProvider')}</a>
            </div>
          </div>
        </div>))
      : <div className='grid one-whole align-center padding-top-l padding-bottom-s'>
        <Loading size='s'/>
      </div>}

    <p className='font-l padding-top-xl'>{t('success.nextStepsTitle')}</p>

    <div className='grid grid-flex gutter-horizontal-s gutter-vertical-l padding-top-m'>
      <NextStep type='done' text={<>{t('success.nextFirstStep')}<p>{email}</p></>}/>
      <NextStep type='done' text={t('success.nextSecondStep')}/>
      <NextStep type='pending' text={<>{t('success.nextThirdStep')}
        <div>
          <a href={TIPS_URL}>
            <i
              className="is24-icon-book-with-euro-sign padding-right-s"/>{t('success.nextThirdStepTips')}
          </a></div>
        <div>
          <a href={CHECKLISTS_AND_DOWNLOADS_URL}>
            <i
              className="is24-icon-poll-survey padding-right-s"/>{t('success.nextThirdStepChecklistsAndDownloads')}
          </a>
        </div>
      </>}/>
      <NextStep type='pending' text={t('success.nextFourthStep')}/>
      <NextStep type='pending' text={t('success.nextFifthStep')}/>
    </div>
    <p className='font-bold padding-top-xl'>{t('success.wish')}</p>
  </div>
}

export default withPageViewReporting(LeadSuccessPage, 'success')



