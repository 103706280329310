import React, { useCallback, useEffect, useState } from 'react'
import { Slider } from 'is24-corecss'
import { formatNumber, toCurrency } from '../../../services/NumberUtils'

type LogarithmicSliderProps = {
  id: string,
  min: number,
  max: number,
  value: number,
  onChange?: (val: number) => void,
  onRelease: (val: number) => void,
  logarithmicCurveFactor?: number,
  step?: number,
  showScaleLabels?: boolean,
}

export const LogarithmicSlider: React.FC<LogarithmicSliderProps> = ({
  id,
  min,
  max,
  value,
  onChange,
  onRelease,
  logarithmicCurveFactor = 2 / 3,
  step,
  showScaleLabels = false,
}) => {
  const minPosition = 1
  const maxPosition = 1000
  const offset = logarithmicCurveFactor * min // offset keeps the log(x) curve steep enough even when starting not from 0
  const minLog = Math.log(min - offset);
  const maxLog = Math.log(max - offset)

  const scale = (maxLog - minLog) / (maxPosition - minPosition);

  const convertToPosition = useCallback((value: number): number => (Math.log(Math.max(value - offset, 2)) - minLog) / scale + minPosition,
    [ minLog, scale, minPosition, offset ])

  const [ sliderPosition, setSliderPosition ] = useState(convertToPosition(value))

  const convertToValue = (position: number): number => Math.exp(minLog + scale * (position - minPosition)) + offset

  const actualStep = step ?? ((convertToValue(sliderPosition) > 1_000_000) ? 10_000 : 1_000)

  const round = (value: number) => Math.round(value / actualStep) * actualStep

  const onSliderChange = (position: number) => {
    setSliderPosition(position)
    onChange?.(round(convertToValue(position)))
  }

  const onSliderRelease = (position: number) => {
    onRelease(round(convertToValue(position)))
  }

  useEffect(() => {
    setSliderPosition(convertToPosition(value))
  }, [ value, convertToPosition ])

  const middleValue = round(convertToValue(minPosition + (maxPosition - minPosition) / 2))

  return <div>
    <Slider
      id={`${id}-slider`}
      title={`${id}-slider`}
      maxValue={sliderPosition}
      min={minPosition}
      max={maxPosition}
      step={1}
      tooltip={position => toCurrency(round(convertToValue(position)))}
      onChange={onSliderChange}
      onRelease={onSliderRelease}
    />
    {showScaleLabels &&
      <div className='grid grid-item one-whole font-xs font-lightgray margin-bottom-xl'
           style={{ position: 'relative', top: '-10px' }}>
        <div className='grid-item one-third' data-testid='min-label'>{formatNumber(min)}</div>
        <div className='grid-item one-third align-center' data-testid='middle-label'>{formatNumber(middleValue)}</div>
        <div className='grid-item one-third align-right' data-testid='max-label'>{formatNumber(max)}</div>
      </div>
    }
  </div>
}
