import { toCurrency } from '../../../services/NumberUtils'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import { NumberInput } from '../../common/input/numericInput'
import { Button, Modal } from 'is24-corecss'

interface OwnFundsDetailsInfo {
  savings: number,
  stocks: number,
  buildingSaving: number,
  liveInsurance: number,
  other: number,
}

export const getTotalFromOwnFundsDetails = ({ savings, stocks, buildingSaving, liveInsurance, other }: OwnFundsDetailsInfo) => {
  return savings + stocks + buildingSaving + liveInsurance + other
}

type OwnFundsDetailsComponentType = {
  onChange: (total: number) => void
}
export const OwnFundsDetailsComponent: React.FC<OwnFundsDetailsComponentType> = ({ onChange }) => {
  const [ ownFundsDetails, setOwnFundsPercentages ] = useState<OwnFundsDetailsInfo>({
    savings: 0,
    stocks: 0,
    buildingSaving: 0,
    liveInsurance: 0,
    other: 0,
  })

  const total = getTotalFromOwnFundsDetails(ownFundsDetails)

  const handleChange = (id: keyof OwnFundsDetailsInfo, value: number) =>
    setOwnFundsPercentages({ ...ownFundsDetails, [ id ]: value })

  useEffect(() => onChange(total), [total, onChange])

  return <>
    <OwnFundsDetail id='savings' value={ownFundsDetails.savings} onChange={handleChange}/>
    <OwnFundsDetail id='stocks' value={ownFundsDetails.stocks} onChange={handleChange}/>
    <OwnFundsDetail id='buildingSaving' value={ownFundsDetails.buildingSaving} onChange={handleChange}/>
    <OwnFundsDetail id='liveInsurance' value={ownFundsDetails.liveInsurance} onChange={handleChange}/>
    <OwnFundsDetail id='other' value={ownFundsDetails.other} onChange={handleChange}/>

    <div className='grid-flex grid-justify-space-between padding-s'>
      <strong>= Eigenkapital gesamt</strong>
      <strong>{toCurrency(total)}</strong>
    </div>
  </>
}


type OwnFundsDetailType = {
  id: keyof OwnFundsDetailsInfo
  value: number
  onChange: (id: keyof OwnFundsDetailsInfo, value: number) => void
}

export const OwnFundsDetail: React.FC<OwnFundsDetailType> = ({ value, onChange, id }) => {
  const { t } = useTranslation('leadEngine')

  const handleOnChange = (id: keyof OwnFundsDetailsInfo) =>
    (value: number) =>
      onChange(id, value)

  const title = t(`ownFundsDetails.${id}.title`)
  return <div className='grid-flex grid-align-center grid-justify-space-between padding-bottom-m'>
    <div className="five-eighths palm-one-whole">
      {title}
    </div>
    {/*
      // Currently there is a bug in cosma which leads to a really long modal when the tooltip is at the bottom of it
      // Waiting for cosma bug fix
      <CustomTooltip title={title} text={t(`ownFundsDetails.${id}.tooltip`)}/>
    */}
    <div className="three-eighths palm-one-whole ">
      <NumberInput id={id} onChange={handleOnChange(id)} value={value}/>
    </div>
  </div>
}


type OwnFundsDetailsModalType = {
  visible: boolean,
  onSubmit: (total: number) => void,
  onClose: Function
}

export const OwnFundsDetailsModal: React.FC<OwnFundsDetailsModalType> = ({ visible, onSubmit, onClose }) => {
  const { t } = useTranslation('leadEngine')
  const [ total, setTotal ] = useState(0)

  return (
    <Modal visible={visible} title={t('ownFundsDetails.title')} onClose={() => onClose()}>
      <OwnFundsDetailsComponent onChange={setTotal}/>
      <div className="grid-flex grid-justify-space-between border-top padding-top">
        <Button onClick={() => onClose()}>{t('common:button.close')}</Button>
        <Button onClick={() => onSubmit(total)} appearance='primary'>{t('common:button.acquire')}</Button>
      </div>
    </Modal>
  )
}
