import { toCurrency } from '../../../services/NumberUtils'
import { AdditionalCostsPercentageKey, AdditionalCostsPercentages } from '../models/Financing'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import { PercentageInput } from '../../common/input/numericInput'
import { Button, Modal } from 'is24-corecss'

type AdditionalCostsDetailType = {
  purchasePrice: number
  value: number
  id: AdditionalCostsPercentageKey
  onChange: (prop: AdditionalCostsPercentageKey, val: number) => void
}

export const AdditionalCostsDetail: React.FC<AdditionalCostsDetailType> = ({ purchasePrice, value, onChange, id }) => {
  const { t } = useTranslation('leadEngine')
  const label = t(`additionalCostsDetail.${id}`)
  const additionalCost = Math.round(purchasePrice / 100 * value)

  const handleOnChange = (id: AdditionalCostsPercentageKey) =>
    (value: number) =>
      onChange(id, value)

  return <div className='grid-flex one-whole grid-align-center padding-bottom-m'>
    <div className='three-eighth'>{label}</div>
    <div className='three-eighth align-right padding-right'>
      {toCurrency(additionalCost)}
    </div>
    <div className='two-eighth'>
      <PercentageInput id={id} onChange={handleOnChange(id)} value={value}/>
    </div>
  </div>
}

type AdditionalCostsDetailsType = {
  additionalCostsPercentages: AdditionalCostsPercentages
  purchasePrice: number
  onChange: (prop: AdditionalCostsPercentageKey, val: number) => void
}
export const AdditionalCostsDetails: React.FC<AdditionalCostsDetailsType> = ({ additionalCostsPercentages, purchasePrice, onChange }) => {
  const { notaryCostsPercentage, landTransferPercentage, entryLandRegisterPercentage, brokerCommissionPercentage } = additionalCostsPercentages

  return <>
    <AdditionalCostsDetail id='notaryCostsPercentage' purchasePrice={purchasePrice}
                           value={notaryCostsPercentage} onChange={onChange}/>
    <AdditionalCostsDetail id='landTransferPercentage' purchasePrice={purchasePrice}
                           value={landTransferPercentage} onChange={onChange}/>
    <AdditionalCostsDetail id='entryLandRegisterPercentage' purchasePrice={purchasePrice}
                           value={entryLandRegisterPercentage} onChange={onChange}/>
    <AdditionalCostsDetail id='brokerCommissionPercentage' purchasePrice={purchasePrice}
                           value={brokerCommissionPercentage} onChange={onChange}/>
  </>
}

type AdditionalCostDetailsModalType = {
  visible: boolean,
  purchasePrice: number,
  percentages: AdditionalCostsPercentages
  onSubmit: (percentages: AdditionalCostsPercentages) => void,
  onClose: Function
}

export const AdditionalCostsDetailsModal: React.FC<AdditionalCostDetailsModalType> = ({ visible, purchasePrice, percentages, onSubmit, onClose }) => {
  const { t } = useTranslation('leadEngine')
  const [ additionalCostsPercentages, setAdditionalCostsPercentages ] = useState(percentages)

  useEffect(() => {
    setAdditionalCostsPercentages(percentages)
  }, [percentages])

  return (
    <Modal visible={visible} title={t('additionalCostsDetails.title')} onClose={() => onClose()}>
      <AdditionalCostsDetails
        additionalCostsPercentages={additionalCostsPercentages}
        purchasePrice={purchasePrice}
        onChange={(prop, val) => setAdditionalCostsPercentages({ ...additionalCostsPercentages, [ prop ]: val })}
      />
      <div className="grid-flex grid-justify-space-between border-top padding-top">
        <Button onClick={() => onClose()}>{t('common:button.close')}</Button>
        <Button onClick={() => onSubmit(additionalCostsPercentages)} appearance='primary'>
          {t('common:button.acquire')}
        </Button>
      </div>
    </Modal>
  )
}
