import React from 'react'
import { useTranslation } from 'react-i18next'
import { StatusMessage } from 'is24-corecss'

type Props = {
  errors: string[],
  headline?: string,
  message?: string
}

const FinancingFormStatusMessage: React.FC<Props> = ({
  errors,
  headline,
  message
}) => {
  const { t } = useTranslation('leadEngine')

  return <div className="margin-top">
    {errors.length === 0 ? (
        <StatusMessage
          headline={headline ?? t('financingRequest.hint.title')}
          message={message ?? t('financingRequest.hint.message')}
          statusType='confirm'
        />
      ) : (
        <StatusMessage
          headline={t('financingRequest.errors.headline')}
          message={<>{
            errors.map(error =>
              <p key={`financingRequest.errors.${error}`}> {t(`financingRequest.errors.${error}`)}</p>)
          }</>}
          statusType='error'
        />
      )
    }
  </div>
}

export default FinancingFormStatusMessage;
