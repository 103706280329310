import { useTranslation } from 'react-i18next'
import React from 'react'
import { Button, Loader } from 'is24-corecss'
import { calcPercentage, formatNumber } from '../../../../services/NumberUtils'
import Chart, { ChartProps } from './Chart'

type StickySectionProps = {
  interestRate: number
} & ChartProps

const StickySection: React.FC<StickySectionProps> =
  ({
     rentCosts,
     buyCosts,
     interestRate,
     geoCode,
     purchasePrice,
     realEstateType,
     isValidLocation,
     onChange,
     loading
   }) => {
    const { t } = useTranslation('widgets')

    const handleClick = () => {
      document.location.href = '/baufinanzierung/vergleich/?cmp_id=10-03538&cmp_name=finance_comparison&cmp_position=finance_content&cmp_creative=rentorbuy';
    }

    return (
      <div className='one-third palm-one-whole lap-one-whole padding-horizontal-l chart-container'>
        <div className='padding-top-l margin-top-xxl chart-content'>
          <div className='grid-flex grid-justify-center grid-align-center'>
            <div className='grid-flex grid-justify-center font-xl indicator indicator--brand'>
              {rentCosts === buyCosts ?
                t('rentVsBuy.chart.titleEqual')
                :
                <>
                  {rentCosts < buyCosts ? t('rentVsBuy.chart.titleBuy') : t('rentVsBuy.chart.titleRent')}
                  <span className='font-xl indicator indicator--brand margin-left-s'>
                  {Math.round(calcPercentage(rentCosts, buyCosts))}
                </span>% {t('rentVsBuy.chart.cheaper')}
                </>
              }
            </div>
            <Chart
              buyCosts={buyCosts}
              geoCode={geoCode}
              purchasePrice={purchasePrice}
              rentCosts={rentCosts}
              realEstateType={realEstateType}
              onChange={onChange}
              isValidLocation={isValidLocation}
              loading={loading}
            />
          </div>
          <div className='grid-flex grid-justify-space-between margin-bottom-s interest-rate-chart'>
            <div className='margin-left-m'>
              <div className='big-text'>
                {loading ? <Loader size='small'/> : <b>{formatNumber(interestRate, 2)} %</b>}
              </div>
              <div className='grey-text'>
                {t('rentVsBuy.chart.debitInterest')}
              </div>
            </div>
            <div className='margin-right-m'>
              <Button appearance='secondary'
                      size='medium'
                      onClick={handleClick}
              >
                {t('rentVsBuy.chart.compareRates')}
              </Button>
            </div>
          </div>
          <div className='align-center grey-text'>
            {t('rentVsBuy.chart.calculation')}
          </div>
        </div>
      </div>
    )
  }

export default StickySection
