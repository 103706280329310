import React, { useEffect, useState } from 'react';

import { interestRates as getInterestRates } from '../../../api/api/InterestRates'
import BudgetCalculator from './BudgetCalculator'

interface Props {
  targetLink: string,
  headline?: string,
}

const BudgetCalculatorWrapper = ({ targetLink = '', headline = '' }: Props) => {
  const [interestRates, setInterestRates] = useState({
    averageInterestRate: 0.8,
    effectiveInterestRate: 0.8
  });

  useEffect(() => {
    const updateAverageInterestRate = async () => {
      const interestRates = await getInterestRates()
      setInterestRates({
        averageInterestRate: interestRates.averageInterestRate,
        effectiveInterestRate: interestRates.effectiveInterestRate
      })
    }
    updateAverageInterestRate()
  }, [])

  return <BudgetCalculator interestRates={interestRates} targetLink={targetLink} headline={headline} />;
};

export default BudgetCalculatorWrapper;
