import { RefObject, useEffect } from 'react'

const useOnClickedOutside = (elementRef: RefObject<any>, onClickedOutside: (event: MouseEvent) => void) => {
  useEffect(() => {
    const mouseDownHandler = (event: MouseEvent) => {
      if (elementRef?.current && !elementRef?.current.contains(event.target)) {
        onClickedOutside(event)
      }
    }
    document.addEventListener("mousedown", mouseDownHandler);

    return () => document.removeEventListener("mousedown", mouseDownHandler);
  }, [elementRef, onClickedOutside])
}

export default useOnClickedOutside
