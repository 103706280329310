import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { LogarithmicSliderWithInput } from '../../../common/logarithmicSliderWithInput/LogarithmicSliderWithInput';
import LabelWithTooltip from '../labelWithTooltip/LabelWithTooltip';
import AdditionalCosts from '../additionalCosts/AdditionalCosts';
import { Button } from 'is24-corecss';
import OwnCapitalModal from '../ownCapitalModal/OwnCapitalModal';
import { AdditionalCostsInPercents } from '../model';

const MIN_PURCHASE_PRICE = 100_000
const MAX_PURCHASE_PRICE = 10_000_000
const MIN_OWN_CAPITAL = 20_000
const MAX_OWN_CAPITAL = 10_000_000

export type PurchaseFinancingInputsProps = {
  purchasePrice: number
  onPurchasePriceChanged: (value: number) => void
  additionalCostsInPercents: AdditionalCostsInPercents
  updateAdditionalCostsInPercents: (value: (((prevState: AdditionalCostsInPercents) => AdditionalCostsInPercents) | AdditionalCostsInPercents)) => void,
  ownCapital: number,
  onOwnCapitalChanged: (value: number) => void,
}
const PurchaseFinancingInputs = ({
                                          purchasePrice,
                                          onPurchasePriceChanged,
                                          ownCapital,
                                          onOwnCapitalChanged,
                                          additionalCostsInPercents,
                                          updateAdditionalCostsInPercents
                                        }: PurchaseFinancingInputsProps) => {
  const { t } = useTranslation('widgets', { keyPrefix: 'financingCalculator' })
  const [ isOwnCapitalModalVisible, setOwnCapitalModalVisible ] = useState(false)
  return <>
    <div className='padding-top-xl'>
      <LogarithmicSliderWithInput

        topLabel={<LabelWithTooltip title={t('purchasePrice')} tooltipText={t('purchasePriceTooltip')}/>}
        id='purchase-price'
        min={MIN_PURCHASE_PRICE}
        max={MAX_PURCHASE_PRICE}
        value={purchasePrice}
        onRelease={onPurchasePriceChanged}
      />
    </div>
    <AdditionalCosts
      purchasePrice={purchasePrice}
      additionalCostsInPercents={additionalCostsInPercents}
      updateAdditionalCostsInPercents={updateAdditionalCostsInPercents}
    />
    <div className='border-bottom padding-top-m'>
      <LogarithmicSliderWithInput
        topLabel={<LabelWithTooltip title={'- ' + t('ownCapital')} tooltipText={t('ownCapitalTooltip')}
                                    tooltipTitle={t('ownCapital')}/>}
        id='own-capital'
        min={MIN_OWN_CAPITAL}
        max={purchasePrice ? purchasePrice : MAX_OWN_CAPITAL}
        value={ownCapital}
        onRelease={onOwnCapitalChanged}
      />
      <Button className='padding-bottom-xl' textStyle
              onClick={() => setOwnCapitalModalVisible(true)}>{t('ownCapitalCalculator')}</Button>
      {isOwnCapitalModalVisible &&
        <OwnCapitalModal onClose={() => setOwnCapitalModalVisible(false)} updateOwnCapital={onOwnCapitalChanged}
                         purchasePrice={purchasePrice}/>}
    </div>
  </>
}

export default PurchaseFinancingInputs
