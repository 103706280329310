import React from 'react'
import { Is24Template } from './Is24Template'

/**
 * CAUTION: This HOC cannot be used multiple times within the same app.
 *
 * Navigating to a sub-route that also uses this HOC will re-add the script tag but the browser will not execute it
 * again. The navigation divs will stay uninitialized.
 */


const withIs24Template = <P extends object>(Component: React.ComponentType<P>, footer?: boolean) =>
  (props: P) => {
    return (
      <Is24Template
        headerType='default'
        backToOverview={false}
        withFooter={footer}
      >
        <Component {...props}/>
      </Is24Template>
    )
  }

export { withIs24Template }
