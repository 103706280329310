export const normalizeString = (value?: string): string | undefined => {
  if (value === undefined) {
    return undefined
  }

  return value.toLowerCase().replace(/[^a-z0-9]+/g, '')
}

export const normalizeStreetName = (streetName?: string): string | undefined => {
  if (streetName === undefined) {
    return undefined
  }

  return streetName
    .toLowerCase()
    .replace(/^(.*?)(str\.?|straße?)$/, '$1')
    .replace(/[^a-z\d]+/g, '')
    .replace(/ /g, '')
}

export const calculateSHA256Hash = async (value?: string): Promise<string | undefined> => {
  if (value === undefined) return undefined
  const encoder = new TextEncoder()
  const data = encoder.encode(value)
  const hashBuffer = await crypto.subtle.digest('SHA-256', data)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  return hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('')
}

export type PIIDataToHash = {
  email?: string
  phone?: string
  streetName?: string
  firstNane?: string
  lastName?: string
  postcode?: string
  city?: string
  state?: string
}

export type HashedPIIData = {
  cf_e?: string // Email
  cf_p?: string // Phone
  cf_s?: string // Street Name
  cf_fn?: string // First Name
  cf_ln?: string // Last Name
  cf_pc?: string // Postcode
  cf_c?: string // City
  cf_z?: string // State
}

export const normalizePIIData = (data: PIIDataToHash) :PIIDataToHash => ({
  email: normalizeString(data.email),
  phone: normalizeString(data.phone),
  streetName: normalizeStreetName(data.streetName),
  firstNane: normalizeString(data.firstNane),
  lastName: normalizeString(data.lastName),
  postcode: normalizeString(data.postcode),
  city: normalizeString(data.city),
  state: normalizeString(data.state)
})

export const hashPIIData = async (data: PIIDataToHash) :Promise<HashedPIIData> => {
  const normalizeData = normalizePIIData(data)

  return {
    cf_e: await calculateSHA256Hash(normalizeData.email),
    cf_p: await calculateSHA256Hash(normalizeData.phone),
    cf_s: await calculateSHA256Hash(normalizeData.streetName),
    cf_fn: await calculateSHA256Hash(normalizeData.firstNane),
    cf_ln: await calculateSHA256Hash(normalizeData.lastName),
    cf_pc: await calculateSHA256Hash(normalizeData.postcode),
    cf_c: await calculateSHA256Hash(normalizeData.city),
    cf_z: await calculateSHA256Hash(normalizeData.state)
  }
}
