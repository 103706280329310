import { ButtonGroup } from './ButtonGroup'
import { StepWrapper } from './StepWrapper'
import React from 'react'
import { EmploymentType } from '../../../api/api/types/Employment'
import { RoadPage } from '../FinancingRequestRoad'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../../redux/store'
import { LeadEngineState } from '../state/reducer'
import { useTranslation } from 'react-i18next'
import { onBorrowersDataSubmit } from '../state/thunks/app'
import { withPageViewReporting } from '../hoc/withTrackPageView'
import { MenuBar } from '../MenuBar'
import { useNavigate } from 'react-router-dom'

const EmploymentPage: React.FC<{ nextPage: string }> = ({ nextPage }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { borrowers: { primary: { employment } } } = useSelector<ApplicationState, LeadEngineState>(state => state.leadEngine)
  const { t } = useTranslation('leadEngine')

  const handleOnClick = (employment: EmploymentType) => dispatch(onBorrowersDataSubmit(nextPage, { primary: { employment } }, navigate))

  const values: EmploymentType[] = [
    'EMPLOYED',
    'CIVIL_SERVANT',
    'FREELANCER',
    'PENSIONER',
    'SELFEMPLOYED',
    'UNEMPLOYED',
    'WORKER',
    'HOUSEWIFE',
  ]
  return <>
    <MenuBar page={RoadPage.EMPLOYMENT} hideClose/>
    <StepWrapper title={t('employment.title')}>
      <ButtonGroup<EmploymentType>
        values={values}
        onClick={handleOnClick}
        value={employment}
      />
    </StepWrapper>
  </>
}

export default withPageViewReporting(EmploymentPage, 'employment')
