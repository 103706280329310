import React from 'react'
import { PercentageStepper } from '../../../affordability/resultPage/loanSettings/PercentageStepper'
import { sanitizeInput, } from '../../../affordability/resultPage/loanSettings/LoanSettingsService'
import { useTranslation } from 'react-i18next'
import './Settings.less'
import { toCurrency } from '../../../../services/NumberUtils'
import LabelWithTooltip from '../labelWithTooltip/LabelWithTooltip'

export type SettingsProps = {
  netLoan: number,
  amortizationRate: number,
  onAmortizationRateChange: (value: number) => void,
  fixedRateEndYear: number,
  onFixedRateEndYearChange: (value: number) => void,
  minAmortizationRatePercentage: number,
  maxAmortizationRatePercentage: number
}

const MIN_FIXED_RATE_END_YEAR = 5
const MAX_FIXED_RATE_END_YEAR = 40

export const Settings: React.FC<SettingsProps> = (
  {
    netLoan,
    amortizationRate,
    onAmortizationRateChange,
    fixedRateEndYear,
    onFixedRateEndYearChange,
    minAmortizationRatePercentage,
    maxAmortizationRatePercentage
  }) => {

  const { t } = useTranslation('widgets', { keyPrefix: 'financingCalculator.settings' })

  return (
    <div className='Settings'>
      <div className='NetLoan'>
        <div>{t('netLoan')}</div>
        <div>{toCurrency(netLoan)}</div>
      </div>
      <div className='grid grid-flex gutter-xl'>
        <div className='grid-item StepperContainer one-whole lap-one-half desk-one-half'>
          <PercentageStepper
            id='amortizationRate'
            label={<LabelWithTooltip title={t('amortizationRate')} tooltipText={t('amortizationRateTooltip')}/>}
            min={minAmortizationRatePercentage}
            max={maxAmortizationRatePercentage}
            defaultValue={amortizationRate?.toString()}
            onValueChange={onAmortizationRateChange}
            inputSanitization={sanitizeInput(minAmortizationRatePercentage, maxAmortizationRatePercentage)}
          />
          <span className='PercentValue'>%</span>
        </div>

        <div className='grid-item StepperContainer one-whole lap-one-half desk-one-half'>
          <PercentageStepper
            id='fixedRateEndYear'
            label={<LabelWithTooltip title={t('fixedRateEndYear')} tooltipText={t('fixedRateEndYearTooltip')}/>}
            min={MIN_FIXED_RATE_END_YEAR}
            max={MAX_FIXED_RATE_END_YEAR}
            defaultValue={fixedRateEndYear?.toString()}
            onValueChange={onFixedRateEndYearChange}
            inputSanitization={sanitizeInput(MIN_FIXED_RATE_END_YEAR, MAX_FIXED_RATE_END_YEAR)}
            step={5}
          />
          <span className='YearText'>Jahre</span>
        </div>
      </div>
    </div>
  )
}

export default Settings
