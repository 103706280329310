import React from 'react'
import { formatNumber } from '../../../../../../services/NumberUtils'
import { BROKER_COMMISSION_VALUES } from '../../../model'
import { useTranslation } from 'react-i18next'

export const BrokerCommissionTable: React.FC = () => {

  const { t } = useTranslation('widgets', { keyPrefix: 'financingCalculator.additionalCosts.modal.tooltip' })

  return (
    <table className='one-whole'>
      <tbody>
        <tr>
          <th><strong>{t('land')}</strong></th>
          <th><strong>{t('commission')}</strong></th>
        </tr>

        {BROKER_COMMISSION_VALUES.map(entry =>
          <tr key={entry.geoCode}>
            <td>{entry.label}</td>
            <td>{formatNumber(entry.value, 2, 2)} %</td>
          </tr>
        )}
      </tbody>
    </table>
  )
}

export default BrokerCommissionTable
