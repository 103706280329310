import React from 'react'
import { formatNumber } from '../../../../services/NumberUtils'
import { NumberInput, PercentageInput } from '../../../common/input/numericInput'
import { isNumber } from '../../../../utils/utils'

type CostsInputProps = {
  isPercentage?: boolean,
  value?: number,
  label: string | React.ReactChild,
  id: string,
  input: number,
  onChange: (value: number) => void,
}

const CostsInput: React.FC<CostsInputProps> =
  ({
     onChange,
     isPercentage,
     input,
     value,
     label,
     id
   }) => (
    <>
      <div className='margin-vertical-l grid grid-flex grid-align-center grid-justify-space-between'>
        <div className={'grid grid-flex six-tenth palm-two-fifths'}>
          {label}
        </div>
        <div className='palm-two-fifths rent-vs-buy-input'>
          {isPercentage ?
            <PercentageInput
              label=''
              value={input}
              valid={true}
              id={id}
              onChange={onChange}
            />
            :
            <NumberInput
              id={id}
              value={input}
              valid={true}
              className={`align-right`}
              onChange={onChange}
            />
          }
        </div>
        <div className='one-tenth palm-one-fifth align-right'>
          {isNumber(value) && `${formatNumber(value)} €`}
        </div>
      </div>
    </>
  )

export default CostsInput
