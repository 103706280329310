import React, { ChangeEvent, FormEvent, RefObject, useRef, useState } from 'react'
import { Button, DatePicker, SelectField, TextInput } from 'is24-corecss'

import { StepWrapper } from '../pages/StepWrapper'
import RegionCodes from '../../../api/api/types/RegionCodes'
import { useTranslation } from 'react-i18next'
import { Employment, EmploymentType, hasSalary } from '../../../api/api/types/Employment'
import { WorkContractType, WorkContractTypeValue } from '../../../api/api/types/WorkContractTypeValue'
import { DateInput } from '../../common/input/dateInput/DateInput'
import { MaritalStatusValue } from '../../../api/api/types/MaritalStatus.ds'
import { Borrower } from '../models/Financing'
import { MenuBar } from '../MenuBar'

export type BorrowerDataFormType = {
  borrowerNumber?: string,
  borrower: Partial<Borrower>,
  onSubmit: (data: Borrower) => void
  page: string;
}

export const BorrowerDataForm: React.FC<BorrowerDataFormType> = ({ borrowerNumber = '1', borrower, onSubmit, page }) => {
  const { t } = useTranslation('leadEngine')
  const forenameRef = useRef<HTMLInputElement>(null)
  const surnameRef = useRef<HTMLInputElement>(null)
  const employmentRef = useRef<HTMLSelectElement>(null)
  const dateOfBirthRef = useRef<HTMLFieldSetElement>(null)
  const maritalStatusRef = useRef<HTMLSelectElement>(null)
  const nationalityRef = useRef<HTMLSelectElement>(null)
  const workContractTypeRef = useRef<HTMLSelectElement>(null)
  const workContractEndRef = useRef<HTMLInputElement>(null)
  const refsCollection: React.RefObject<any>[] = [ forenameRef, surnameRef, employmentRef, dateOfBirthRef, maritalStatusRef,
    nationalityRef, workContractTypeRef, workContractEndRef ]

  const { forename = '', surname = '', employment, nationality, maritalStatus, workContractType, workContractEndDate, dateOfBirth } = borrower

  const [ touched, setTouched ] = useState<string[]>([])
  const [ initialValues ] = useState({ forename, surname, employment, nationality })
  const [ formData, setFormData ] = useState<Borrower>({
    forename,
    surname,
    employment,
    nationality,
    maritalStatus,
    workContractType,
    workContractEndDate,
    dateOfBirth
  })

  const isRefValid = (ref: RefObject<HTMLInputElement | HTMLSelectElement>): boolean => {
    return !ref.current || (ref.current.validity.valid && ref.current.value !== '')
  }

  const isRefUntouched = (ref: RefObject<HTMLInputElement | HTMLSelectElement | HTMLFieldSetElement>): boolean => {
    return ref.current === null || !touched.includes(ref.current.id)
  }

  const handleBlur = (event: ChangeEvent<HTMLInputElement>): void => {
    const { id } = event.target
    setTouched(state => ([ ...new Set([ ...state, id ]) ]))
  }

  const checkFieldsWithRef = () => {
    return refsCollection.filter(ref => ref.current).filter(ref => !isRefValid(ref)).length === 0
  }

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()

    const isFormValid = checkFieldsWithRef() && formData.dateOfBirth

    if (isFormValid) {
      onSubmit(formData)
    } else {
      setTouched(refsCollection.map(ref => ref.current?.id))
    }
  }

  const handleOnChange = (prop: keyof Borrower) =>
    (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) =>
      setFormData(state => ({ ...state, [ prop ]: e.target.value }))

  const handleOnChangeDate = (prop: keyof Borrower) =>
    (date?: Date) =>
      setFormData(state => ({ ...state, [ prop ]: date }))


  const isEmployeeWithSalary = (employment?: EmploymentType) => employment && hasSalary(employment)
  const isContractLimited = (workContractType?: WorkContractType) => workContractType && workContractType !== WorkContractTypeValue.PERMANENT

  const workContractEndTitle = formData?.workContractType === WorkContractTypeValue.PROBATION ? t('borrowerData.probationUntil') : t('borrowerData.limitedUntil')

  return <>
    <MenuBar page={page}/>
    <StepWrapper title={t('borrowerData.title', { count: Number(borrowerNumber) })}>
      <form noValidate={true} onSubmit={handleSubmit}>
        <div className="grid-flex gutter margin-bottom-l">
          <div className='grid-item one-half palm-one-whole'>
            <TextInput id='forename' name='forename'
                       label="Vorname"
                       placeholder=""
                       defaultValue={initialValues.forename}
                       ref={forenameRef}
                       valid={isRefUntouched(forenameRef) || isRefValid(forenameRef)}
                       onChange={handleOnChange('forename')}
                       onBlur={handleBlur}
                       required
            />
          </div>

          <div className='grid-item one-half palm-one-whole'>
            <TextInput id='surname' name='surname'
                       label="Nachname" placeholder=""
                       defaultValue={initialValues.surname}
                       ref={surnameRef}
                       valid={isRefUntouched(surnameRef) || isRefValid(surnameRef)}
                       onChange={handleOnChange('surname')}
                       onBlur={handleBlur}
                       required
            />
          </div>

          <div className='grid-item one-half palm-one-whole'>
            <SelectField
              required={true}
              id="employment"
              name="employment"
              ref={employmentRef}
              onChange={handleOnChange('employment')}
              isErroneous={!isRefUntouched(employmentRef) && !isRefValid(employmentRef)}
              defaultValue={initialValues.employment || ''}
              label='Beschäftigungsverhältnis'
            >
              <option value="">{t('common:label.pleaseSelect')}</option>
              {Object.keys(Employment).map(k =>
                <option key={k} value={k}>{t(`common:finance.values.${k}`)}</option>
              )}
            </SelectField>
          </div>

          <div className='grid-item one-half palm-one-whole'>
            <DateInput id="dateOfBirth" defaultValue={dateOfBirth}
                       maxDate={Date.now()} inputRef={dateOfBirthRef}
                       placeholderDay={t('common:placeholder.calendar.day')}
                       placeholderMonth={t('common:placeholder.calendar.month')}
                       placeholderYear={t('common:placeholder.calendar.year')}
                       showError={!isRefUntouched(dateOfBirthRef)}
                       label={t('common:label.birthDate')}
                       onBlur={handleBlur}
                       onChange={handleOnChangeDate('dateOfBirth')}

            />
          </div>

          <div className='grid-item one-half palm-one-whole'>
            <SelectField
              id="maritalStatus"
              name="maritalStatus"
              required={true}
              ref={maritalStatusRef}
              onChange={handleOnChange('maritalStatus')}
              isErroneous={!isRefUntouched(maritalStatusRef) && !isRefValid(maritalStatusRef)}
              label='Familienstand'
              defaultValue={maritalStatus}
            >
              <option value="">{t('common:label.pleaseSelect')}</option>
              {Object.keys(MaritalStatusValue).map(c =>
                <option key={c} value={c}>{t(`common:finance.values.${c}`)}</option>
              )}
            </SelectField>
          </div>

          <div className='grid-item one-half palm-one-whole'>
            <SelectField
              id="nationality"
              name="nationality"
              required={true}
              ref={nationalityRef}
              onChange={handleOnChange('nationality')}
              isErroneous={!isRefUntouched(nationalityRef) && !isRefValid(nationalityRef)}
              label='Staatsangehörigkeit'
              defaultValue={nationality}
            >
              <option value="">{t('common:label.pleaseSelect')}</option>
              {RegionCodes.map(c => <option key={c} value={c}>{t(`common:region.${c}`)}</option>)}
            </SelectField>
          </div>

          {isEmployeeWithSalary(formData.employment) &&
          <>
            <div className='grid-item one-half palm-one-whole'>
              <SelectField
                id="workContractType"
                name="workContractType"
                required={true}
                ref={workContractTypeRef}
                onChange={handleOnChange('workContractType')}
                isErroneous={!isRefUntouched(workContractTypeRef) && !isRefValid(workContractTypeRef)}
                label='Vertragsverhältnis'
                defaultValue={workContractType}
              >
                <option value="">{t('common:label.pleaseSelect')}</option>
                {Object.keys(WorkContractTypeValue).map(k =>
                  <option key={k} value={k}>{t(`common:finance.values.${k}`)}</option>
                )}
              </SelectField>
            </div>

            {isContractLimited(formData.workContractType) &&
            <div className='grid-item one-half palm-one-whole'>
              <DatePicker
                valid={isRefUntouched(workContractEndRef) || isRefValid(workContractEndRef)}
                id="workContractEndDate"
                label={workContractEndTitle}
                inputRef={workContractEndRef}
                preselectedDate={workContractEndDate}
                onChange={handleOnChangeDate('workContractEndDate')}
              />
            </div>
            }
          </>
          }

        </div>

        <div className='align-right border-top padding-top'>
          <Button appearance="secondary" type="submit">{t('common:button.acquire')}</Button>
        </div>
      </form>
    </StepWrapper>
  </>
}


