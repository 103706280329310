type TrackEntity = {
  pag_pagetitle: string,
  [ prop: string ]: string
}

type PageViewEntity = {} & TrackEntity

type EventEntity = {
  evt_ga_category: string,
  evt_ga_action: string,
  evt_ga_label: string
} & TrackEntity

type TrackingOption = {
  reportType?: 'UTAG_VIEW'
}

export type TrackingReporter = (
  entity: PageViewEntity | EventEntity,
  options?: TrackingOption
) => void


export class TrackingServiceImpl {
  private trackingContext = 'is24.de.baufinanzierung'

  constructor(private reporter: TrackingReporter) {
  }

  private buildPageTitle(pageName: string) {
    return `${this.trackingContext}.${pageName}`
  }

  reportPageView( pageName: string, additionalParams = {}, pageTitle?: string): void {
    this.reporter({
        pag_pagetitle: pageTitle ? `${pageTitle}.${pageName}` : this.buildPageTitle(pageName),
      ...additionalParams
      } as PageViewEntity,
      { reportType: 'UTAG_VIEW' }
    )
  }

  reportEvent(pageName: string, action: string, label: string, category: string, additionalParams = {}, pageTitle?: string): void {
    this.reporter({
      pag_pagetitle:  pageTitle ? `${pageTitle}.${pageName}` : this.buildPageTitle(pageName),
      evt_ga_category: category,
      evt_ga_action: action,
      evt_ga_label: label,
        ...additionalParams
      } as EventEntity
    )
  }
}
