import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Salutation } from '../../../api/api/types/Salutation.ds'
import { PrimaryBorrower } from '../models/Financing'
import { updateBorrowersData } from '../state/actions'
import { getUser, UserResponse } from '../../../services/UserService'

export const mapToPrimaryBorrower = (userResponse: UserResponse): PrimaryBorrower => {
  return {
    salutation: userResponse.userContactDetails?.salutation ? 'MALE' === userResponse.userContactDetails?.salutation.toUpperCase() ? Salutation.MR : Salutation.MRS : Salutation.UNKNOWN,
    email: userResponse.userContactDetails?.email ?? '',
    phone: userResponse.userContactDetails?.cellPhoneNumber ?? userResponse.userContactDetails?.phoneNumber ?? '',
    forename: userResponse.userContactDetails?.firstname ?? '',
    surname: userResponse.userContactDetails?.lastname ?? '',
    street: userResponse.userContactDetails?.address?.street ?? '',
    streetNumber: userResponse.userContactDetails?.address?.houseNumber ?? '',
    postalCode: userResponse.userContactDetails?.address?.postcode ?? '',
    location: userResponse.userContactDetails?.address?.city ?? ''
  }
}

export const withUserData = <P extends object>(WrappedComponent: React.ComponentType<P>): React.FC<P> => {
  return (props) => {
    const dispatch = useDispatch()

    useEffect(() => {
      getUser().then((userData) => {
          userData && dispatch(updateBorrowersData({ primary: mapToPrimaryBorrower(userData) }));
        }
      ).catch((error) => {
        console.warn('Couldn\'t fetch user profile!', error)
      })
    }, [ dispatch ])

    return <WrappedComponent {...props as P} />
  }
}
