import React, { ChangeEvent, ForwardRefRenderFunction, useEffect, useRef, useState } from 'react'
import { TextInput } from 'is24-corecss'
import { NumericInputType } from './common'
import { formatNumber } from '../../../../services/NumberUtils'

type PercentageInputType = {
  fraction?: number,
  sanitizeValue?: (val: number) => number
} & NumericInputType

const PercentageInput: ForwardRefRenderFunction<HTMLInputElement, PercentageInputType> = (props) => {
  const defaultInputRef = useRef<HTMLInputElement>(null)
  const {
    id,
    value = 0,
    onChange,
    placeholder = '',
    valid = true,
    label = '',
    className = '',
    disabled = false,
    fraction = 2,
    sanitizeValue = (val) => val
  } = props
  const [ formattedValue, setFormattedValue ] = useState(formatNumber(value, fraction))

  const removeChars = (value: string) => {
    return value.replace(',', '.').replace(/[^0-9.]/g, '')
  }

  useEffect(() => {
    setFormattedValue(formatNumber(value, fraction))
  }, [ value, fraction ])


  const handleOnBlur = () => {
    const newValue = sanitizeValue(+removeChars(formattedValue))
    setFormattedValue(formatNumber(newValue, fraction))
    onChange?.(newValue)
  }

  const handleOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.select()
  }

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    if(value.includes('.')) { return }
    setFormattedValue(value)
  }

  return <TextInput id={id}
                    ref={defaultInputRef}
                    placeholder={placeholder}
                    label={label}
                    value={formattedValue}
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                    onFocus={handleOnFocus}
                    affixRight='unit' unitRight='percent'
                    className={`align-right ${className}`}
                    type='tel'
                    disabled={disabled}
                    valid={valid}/>
}

export default PercentageInput
