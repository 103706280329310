import { Expose } from '../../../api/api/Expose'
import { FinancingStartType } from '../../../api/api/types/FinancingStart'
import { FinancingType, FinancingTypeValue, parseFinancingType } from '../../../api/api/types/FinancingType'
import { EmploymentType } from '../../../api/api/types/Employment'
import { SalutationType } from '../../../api/api/types/Salutation.ds'
import { IncomeRangeType } from '../../../api/api/types/IncomeRange.ds'
import { FinancingUseType } from '../../../api/api/types/FinancingUseType.ds'
import { AvailabilityType } from '../../../api/api/types/Availability.ds'
import { FixedNominalInterestRateType } from '../../../api/api/types/FixedNominalInterestRate'
import { WorkContractType } from '../../../api/api/types/WorkContractTypeValue'
import { BorrowerRelationshipType } from '../../../api/api/types/BorrowerRelationship.ds'
import { MaritalStatusType } from '../../../api/api/types/MaritalStatus.ds'
import { BorrowerIncomeAO } from '../../../api/api/types/BorrowerIncomeAO.ds'
import { BorrowerExpensesAO } from '../../../api/api/types/BorrowerExpensesAO.ds'
import { EmploymentSectorType } from '../../../api/api/types/EmploymentSector.ds'
import { getStateValueByGeoCode } from './StateValues'
import {
  BuildingProjectStateExtended,
  ProjectStateExtended,
  PurchaseProjectStateExtended
} from '../../../api/api/types/ProjectStateExtended'

export const MIN_LOAN_AMOUNT = 100000

export interface AdditionalCostsPercentages {
  brokerCommissionPercentage: number,
  notaryCostsPercentage: number,
  landTransferPercentage: number,
  entryLandRegisterPercentage: number
}

export type AdditionalCostsPercentageKey = keyof AdditionalCostsPercentages

export interface Borrower {
  forename: string
  surname: string
  dateOfBirth?: Date
  workContractType?: WorkContractType
  workContractEndDate?: Date
  nationality?: string
  employment?: EmploymentType,
  maritalStatus?: MaritalStatusType
  income?: BorrowerIncomeAO
  expenses?: BorrowerExpensesAO
}

export interface PrimaryBorrower extends Borrower {
  salutation: SalutationType
  postalCode: string
  street: string
  streetNumber: string
  location: string
  email: string
  phone?: string
  availability?: AvailabilityType
  employmentSector?: EmploymentSectorType,
  image?: string
}

export interface Financing {
  purchasePrice: number,
  usingDefaultPurchasePrice?: boolean
  additionalCostsPercentages: AdditionalCostsPercentages,
  ownFunds: number,
  financingType: FinancingType,
  remainingDebt: number,
  usingDefaultRemainingDebt?: boolean
  financingStart?: FinancingStartType,
  projectState?: ProjectStateExtended,
  propertyValue: number,
  financingUseType?: FinancingUseType,
  netIncomeRange?: IncomeRangeType,
  netIncomeTotal?: number,
  numberOfBorrowers: number
  amortizationRate?: number,
  fixedNominalInterestRate?: FixedNominalInterestRateType
  borrowerRelationship?: BorrowerRelationshipType,
  numberOfChildren?: number,
  partnerTracking?: string,
  exposeId?: number,
  lastModified: Date
}

export const getLoanAmount = (financing: Partial<Financing>) => {
  const { financingType, ownFunds = 0, purchasePrice = 0, remainingDebt = 0 } = financing
  return financingType === FinancingTypeValue.FollowupFinancing ?
    Math.max(remainingDebt, 0) :
    Math.max(purchasePrice + getAdditionalCosts(financing) - ownFunds, 0)
}

export const getAdditionalCosts = ({ additionalCostsPercentages, purchasePrice = 0 }: Partial<Financing>) => {
  if (!(purchasePrice > 0) || !additionalCostsPercentages) {
    return 0
  }

  const result = Object.entries(additionalCostsPercentages).reduce((acc: number, currVal: any) => {
    return acc + purchasePrice / 100 * currVal[ 1 ]
  }, 0)

  return Math.round(result)
}

export const isFollowupFinancing = (type?: FinancingType) => type === FinancingTypeValue.FollowupFinancing
export const isValidLoanAmount = (loanAmount: number) => loanAmount >= MIN_LOAN_AMOUNT
export const isOnObjectSearch = (projectState?: string) => projectState === PurchaseProjectStateExtended.ON_OBJECT_SEARCH || projectState === BuildingProjectStateExtended.ON_BUILDER_SEARCH

export const getAdditionalCostsPercentages = (geoCode: string): AdditionalCostsPercentages | undefined => {
  const stateValue = getStateValueByGeoCode(geoCode)
  if (stateValue) {
    return {
      brokerCommissionPercentage: stateValue.brokerCommission,
      entryLandRegisterPercentage: stateValue.landRegister,
      landTransferPercentage: stateValue.landTransferTax,
      notaryCostsPercentage: stateValue.notaryCosts
    }
  }
}


export type FinancingUrlParams = Partial<{
  geocode: string,
  postalcode: string,
  partnertracking: string
  financingtype: string,
  purchaseprice: string,
  ownfunds: string,
  remainingdebt: string,
  propertyvalue: string,
  exposeid: string,
  numberofborrowers?: string,
  netincomerange?: string,
}>

export const buildFinancingByUrlParams = (urlParams: FinancingUrlParams): Partial<Financing> | {} => {
  const { purchaseprice, ownfunds, remainingdebt, propertyvalue, financingtype, partnertracking, exposeid, numberofborrowers, netincomerange } = urlParams

  const financing: Partial<Financing> = {}

  const isNumericalParam = (param?: string): param is string =>
    !!param && !isNaN(+param)

  if (isNumericalParam(purchaseprice)) {
    financing.purchasePrice = +purchaseprice
    financing.usingDefaultPurchasePrice = false
  }

  if (isNumericalParam(ownfunds)) {
    financing.ownFunds = +ownfunds
  }

  if (isNumericalParam(remainingdebt)) {
    financing.remainingDebt = +remainingdebt
    financing.usingDefaultRemainingDebt = false
  }

  if (isNumericalParam(propertyvalue)) {
    financing.propertyValue = +propertyvalue
  }

  if (isNumericalParam(exposeid)) {
    financing.exposeId = +exposeid
  }

  if (financingtype) {
    financing.financingType = parseFinancingType(financingtype)
  }

  if (partnertracking) {
    financing.partnerTracking = partnertracking
  }

  if (numberofborrowers && isNumericalParam(numberofborrowers)) {
    financing.numberOfBorrowers = +numberofborrowers
  }

  if (netincomerange) {
    financing.netIncomeRange = netincomerange as IncomeRangeType;
  }

  return financing
}

export const matchExposeToFinancing = (expose: Expose, financing: Financing): Financing => {
  const { purchasePrice, hasCourtage, brokerCommissionPercentage, address } = expose
  const newFinancing = {
    ...financing,
    additionalCostsPercentages: { ...financing.additionalCostsPercentages }
  }

  if (address.geocode) {
    const additionalCostsPercentages = getAdditionalCostsPercentages(address.geocode)
    if (additionalCostsPercentages) {
      newFinancing.additionalCostsPercentages = additionalCostsPercentages
    }
  }

  newFinancing.additionalCostsPercentages.brokerCommissionPercentage = hasCourtage ? brokerCommissionPercentage : 0

  if (purchasePrice && purchasePrice >= 0) {
    newFinancing.purchasePrice = purchasePrice
  }

  return newFinancing
}


