import { logError } from '../../api/api/Logger'
import { TRACKING_API_URL } from '../../api/contextPaths'
import { TrackingReporter, TrackingServiceImpl } from './TrackingServiceImpl'

declare global {
  interface Window {
    IS24: {
      TEALIUM: { tracking: { report: Function } },
      navigation: { rerender: () => void }
    } | undefined;
  }
}

const initTrackingScript = (pageTitle: string) => {
  const script = document.createElement('script')
  script.src = `${TRACKING_API_URL}&cP--countername=${pageTitle}`
  script.async = true

  document.body.appendChild(script)
}

let initialized = false
const reporter: TrackingReporter = (entity, options) => {
  if(window.IS24?.TEALIUM?.tracking?.report) {
    try {
      window.IS24.TEALIUM.tracking.report(entity, options)
    } catch (e: any) {
      logError(`TrackingService failed. Entity: ${JSON.stringify(entity)}`, e)
    }
  } else {
    if(initialized) {
      const intervalInt = setInterval(() => {
        if (window.IS24?.TEALIUM?.tracking) {
          clearInterval(intervalInt)
          window.IS24.TEALIUM.tracking.report(entity, options)
        }
      }, 200)
    } else {
      initTrackingScript(entity.pag_pagetitle)
      initialized = true
    }

  }
}


export default new TrackingServiceImpl(reporter)
