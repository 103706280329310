import React, { ChangeEvent, forwardRef, ForwardRefRenderFunction } from 'react'
import { TextInput } from 'is24-corecss'

export type PhoneNumberInputType = {
  id?: string,
  name?: string,
  label?: string,
  placeholder?: string,
  value?: string,
  valid?: boolean,
  onChange: (value: string) => void,
  onBlur?: (value: string) => void,
  errorMessage?: string,
  required?: boolean,
  disabled?: boolean,
  allowAutoComplete?: boolean
}

const PhoneNumberInput: ForwardRefRenderFunction<HTMLInputElement, PhoneNumberInputType> = ({
                                                                                              id = 'phone',
                                                                                              name = 'phone',
                                                                                              label = '',
                                                                                              placeholder = '',
                                                                                              value = '',
                                                                                              errorMessage,
                                                                                              valid,
                                                                                              onChange,
                                                                                              onBlur,
                                                                                              required,
                                                                                              disabled,
                                                                                              allowAutoComplete = true,
                                                                                            }, ref) => (
  <TextInput
    type='tel'
    id={id}
    name={name}
    errorMessage={errorMessage}
    label={label}
    placeholder={placeholder}
    valid={valid}
    aria-invalid={!valid}
    value={value}
    ref={ref}
    required={required}
    disabled={disabled}
    onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
    onBlur={() => onBlur?.(value)}
    autoComplete={allowAutoComplete ? 'on' : 'new-password'}
  />
)

export default forwardRef(PhoneNumberInput)
