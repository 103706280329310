import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Modal } from 'is24-corecss'
import { sequence } from '../../../utils/utils'

const InfoModal: React.FC<{ onClose: () => void}> = ({ onClose }) => {
  const { t } = useTranslation('leadEngine', { keyPrefix: 'staticStarRating.infoModal' })
  return <Modal visible title={t('title')} onClose={onClose}>
    <div>
      <p className='padding-bottom-l'>{t('explanation')}</p>
      <p className='font-semibold padding-bottom-s'>{t('rules.caption')}</p>
      <ol>
        {sequence(1, 8).map(i => <li key={i} className='padding-bottom-s'>{t(`rules.${i}`)}</li>)}
      </ol>
    </div>
    <div className="align-center padding-vertical">
      <Button onClick={() => onClose()} appearance="secondary">
        {t('close')}
      </Button>
    </div>
  </Modal>
}

export default InfoModal
