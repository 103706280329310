import { useExperiment } from '@optimizely/react-sdk'
import { useLocation } from 'react-router-dom'

const useIncomePageWithInputField = (): boolean | undefined => {
  const experimentKey = '_bau-8306_netincome_mleflow'
  const newIncomePageVariationKey = 'netincome_treatment'
  const [ variation, clientReady, didTimeout ] = useExperiment(experimentKey, { autoUpdate: true });
  const { search } = useLocation()
  const variantOverride = new URLSearchParams(search).get('mle_income_page_variant')
  if (variantOverride === 'old') {
    return false
  }
  if (variantOverride === 'new') {
    return true
  }
  if (!clientReady && !didTimeout) {
    return undefined;
  }
  return variation === newIncomePageVariationKey;
}

export default useIncomePageWithInputField;
