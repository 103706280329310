import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next'
import { Button } from 'is24-corecss'

import { InputWithSliderBelow } from '../../common/inputWithSliderBelow/InputWithSliderBelow'
import './budgetCalculator.less'
import { formatNumber } from '../../../services/NumberUtils'
import CustomTooltip from '../../common/customTooltip/CustomTooltip'

type BudgetCalculatorType = {
  interestRates: {
    averageInterestRate: number,
    effectiveInterestRate: number
  }
  targetLink: string,
  headline?: string,
}

const BudgetCalculator = ({ interestRates, targetLink, headline }: BudgetCalculatorType) => {
  const { t } = useTranslation('widgets')

  const [monthlyRent, onMonthlyRent] = useState(1000);

  const { averageInterestRate, effectiveInterestRate } = interestRates
  const formattedEffectiveInterestRate = effectiveInterestRate.toFixed(2)

  const amortizationRate = 2
  const loan = Math.round(((monthlyRent * 12 * 100) / (amortizationRate * averageInterestRate)) / 1000) * 1000
  const formattedLoan = formatNumber(loan)

  const purchasePrice = (80 * loan) / 100
  const ownFunds = loan - purchasePrice

  const campaign = `?cmp_id=10-02404&cmp_name=finance_comparison&cmp_position=finance_content&cmp_creative=financecalculator&purchasePrice=${purchasePrice}&ownFunds=${ownFunds}&additionalCosts=0`
  const link = `${targetLink}${campaign}`

  return (
    <>
      <div className='grid grid-flex flex-row gutter-m grid-align-center padding-bottom-m'>
        <div className="two-thirds palm-one-whole">
          <h3>{headline || t('budgetCalculator.title')}</h3>
          <InputWithSliderBelow
            id="monthly-rent"
            title={t('budgetCalculator.monthlyRent')}
            value={monthlyRent}
            onChange={onMonthlyRent}
            max={5000}
            min={100}
            step={100}
          />
        </div>
        <div className="grid grid-flex grid-justify-center one-third palm-one-whole">
          <div role='status' aria-label='loan' className="one-whole loan">
            {formattedLoan} €
          </div>
          <div className="one-whole possibleLoan">
            {t('budgetCalculator.possibleLoan')}
          </div>
          <div className="one-whole margin-top-m calculatorButton">
            <Button
              appearance="secondary"
              renderContainer={(props: Object) =>
                <a {...props} href={link}>
                  {t('budgetCalculator.calculatorCTA')}
                </a>
              }
            />
          </div>
          <div className="one-whole calculatorButton">
            <CustomTooltip
              text={
                <Trans
                  t={t}
                  i18nKey="budgetCalculator.popup.content"
                  values={{ averageInterestRate, formattedEffectiveInterestRate, loan: formattedLoan }}
                />
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default BudgetCalculator;
