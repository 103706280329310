import React, { ReactElement } from 'react'
import PercentageInput from '../../../../common/input/numericInput/PercentageInput'
import './AdditionalCostsInputRow.less'
import { toCurrency } from '../../../../../services/NumberUtils'
import { calculateAmount } from '../../service';
import CustomTooltip from '../../../../common/customTooltip/CustomTooltip'

export type AdditionalCostsInputRowProps = {
  purchasePrice: number
  text: string
  tooltip?: ReactElement
  tooltipDesktopBodyPosition?: 'top' | 'bottom'
  value: number
  maxValue: number
  onChange: (value: number) => void
}

export const AdditionalCostsInputRow: React.FC<AdditionalCostsInputRowProps> = ({
  purchasePrice,
  text,
  tooltip,
  tooltipDesktopBodyPosition = 'top',
  value,
  onChange,
  maxValue
}) => {
  return (
    <div className='AdditionalCostInputRow grid grid-flex grid-align-center'>
      <div className='grid-item seven-twelfths palm-six-twelfths'>
        {text}
        {tooltip &&
          <div className='absolute-reference inline-block'>
            <div className='AdditionalCostInputRowTooltip palm-hide'>
              <CustomTooltip
                text={tooltip}
                bodyPosition={tooltipDesktopBodyPosition}
                arrowPosition='center'
                minWidth={280}
              />
            </div>
            <span className='AdditionalCostInputRowTooltip desk-hide lap-hide'>
              <CustomTooltip
                text={tooltip}
                bodyPosition='top'
                arrowPosition='center'
                minWidth={280}
              />
            </span>
          </div>
        }
      </div>
      <div className='grid-item three-twelfths palm-three-twelfths align-right padding-right padding-left'>
				<span>{toCurrency(calculateAmount(value, purchasePrice))}</span>
      </div>
      <div className='grid-item two-twelfths palm-three-twelfths input-field'>
        <PercentageInput
          id={text}
          valid={value <= maxValue}
          value={value}
          onChange={onChange}
          forceSelect={false}
          className='Input'
          ariaLabel={text}
        />
      </div>
    </div>
  )
}

export default AdditionalCostsInputRow
