import React from 'react'
import './Summary.less'

export type TileProps = {
  value: string
  label: string
}

const Tile: React.FC<TileProps> = ({ value, label }) => {
  return (
    <div className='Tile'>
      <div className='Value'>{value}</div>
      <div className='Label palm-order-one-up'>{label}</div>
    </div>
  )
}

export default Tile
