import { DateTime } from 'luxon';
import { DateAsISOString } from '../api/api/types/DateAsISOString'

export const ISO_DATE_FORMAT = 'yyyy-MM-dd';
export const GERMAN_DATE_FORMAT = 'dd.MM.yyyy';

export const isValidDateString = (dateString: string | undefined, format = ISO_DATE_FORMAT): boolean =>
  !!dateString && DateTime.fromFormat(dateString, format).isValid;

export const isDateStringBetween = (dateString: DateAsISOString, fromDate: DateAsISOString, toDate: DateAsISOString): boolean => {
  if (!isValidDateString(dateString)) {
    return false;
  }
  const date = DateTime.fromISO(dateString as string);
  const dateFrom = DateTime.fromISO(fromDate);
  const dateTo = DateTime.fromISO(toDate);

  return date >= dateFrom && date <= dateTo;
}

export const dateStringBeforeYears = (dateString: DateAsISOString, years: number): DateAsISOString =>
  DateTime.fromISO(dateString).minus({ year: years }).toFormat(ISO_DATE_FORMAT);

export const formatDateString = (dateString: string, formatFrom: string, formatTo: string): string =>
  dateString ? DateTime.fromFormat(dateString, formatFrom).toFormat(formatTo) : '';

export const currentDateAsISOString = (): DateAsISOString => DateTime.now().toISODate();

export const calculateDifferenceInYears = (endDate: DateAsISOString, startDate: DateAsISOString): number =>
  DateTime.fromISO(endDate).diff(DateTime.fromISO(startDate), 'years').years;

export const autoCompleteGermanDateString = (value: string): string => {
  if (/^\d\d$/.test(value) || /^\d\d\.\d\d$/.test(value)) {
    return value + '.'
  }
  if (/^\d\.$/.test(value)) {
    return '0' + value
  }
  if (/^\d\d\.\d\.$/.test(value)) {
    return  value.substring(0, 3) + '0' + value.substring(3, 5)
  }
  if (/\.\.$/.test(value)) {
    return value.substring(0, value.length - 1)
  }
  return value
}
