import React from 'react'
import { useTranslation } from 'react-i18next'
import { Stepper } from 'is24-corecss'

import { FormLabel } from '../input/FormLabel'

type IncomeSalaryStepperRowType = {
  primaryValue?: number,
  secondaryValue?: number,
  primaryOnChange: Function,
  secondaryOnChange?: Function,
  showPrimarySalary?: boolean,
  showSecondarySalary?: boolean,
  numberOfBorrowers: number,
  showTooltip?: boolean,
}

export const minNumberOfSalaries = 12

const IncomeSalaryStepperRow: React.FC<IncomeSalaryStepperRowType> = ({
  primaryValue,
  secondaryValue,
  primaryOnChange,
  secondaryOnChange,
  numberOfBorrowers,
  showPrimarySalary = true,
  showSecondarySalary= true,
  showTooltip = true,
}) => {
  const { t } = useTranslation('leadEngine')
  const labelFor = (name: string): string => t(`incomeForm.value.${name}.label`)
  const tooltipFor = (name: string): string => t(`incomeForm.value.${name}.tooltip`)
  const id = 'numberOfSalaries'
  const maxNumberOfSalaries = 15
  const tooltipText = showTooltip ? tooltipFor(id) : ""

  return <>
    {(showPrimarySalary || showSecondarySalary) && (
      <FormLabel label={labelFor(id)} tooltip={tooltipText}/>
    )}

    <div className="grid-flex gutter-l flex-nowrap">
      <div className='one-whole grid-item'>
        {showPrimarySalary && (
          <Stepper
            label=''
            aria-label={labelFor(id)}
            min={minNumberOfSalaries}
            max={maxNumberOfSalaries}
            step={0.5}
            defaultValue={`${primaryValue || minNumberOfSalaries}`}
            name={`primary-${id}`}
            id={`primary-${id}`}
            onChange={(event: any) => { primaryOnChange(id)(Number(event?.target?.value)) }}
          />
        )}
      </div>

      {numberOfBorrowers > 1 && (
        <div className='one-whole grid-item'>
          {showSecondarySalary && secondaryOnChange && (
            <Stepper
              label=''
              aria-label={labelFor(id)}
              min={minNumberOfSalaries}
              max={maxNumberOfSalaries}
              step={0.5}
              defaultValue={`${secondaryValue || minNumberOfSalaries}`}
              name={`secondary-${id}`}
              id={`secondary-${id}`}
              onChange={(event: any) => { secondaryOnChange(id)(Number(event?.target?.value)) }}
            />
          )}
        </div>
      )}
    </div>
  </>
}

export default IncomeSalaryStepperRow
