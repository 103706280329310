export const config = {
  priceEquityMinDifference: 50000,
  height: 50,
  floorValue: 2.7,
  reducedPercentValue: 50,
  realEstateTypeValues: {
    buy: 'buy',
    rent: 'rent',
  }
}

export const initialState = {
  geoCode: '',
  locationLabel: '',
  isValidLocation: true,
  years: 10,
  amortizationRate: 2,
  fixedInterestRate: 10,
  additionalCosts: 0,
  rent: 1000,
  price: 200000,
  equity: 40000,
  equityMax: 150000,
  propertyValue: 0,
  maintenanceCostHouse: 0,
  maintenanceCostApartment: 0,
  appreciation: 1150,
  operatingCosts: 0,
  opportunityInterestRate: 2,
  objectType: 'house',
  floorArea: 150,
  realEstateType: 'housebuy',
  lastPaymentYear: 0,
  transferTax: 6.0,
  transferTaxValue: 0,
  brokerageCommission: 3.57,
  brokerageCommissionValue: 0,
  notaryFees: 1.5,
  notaryFeesValue: 0,
  landRegister: 0.5,
  landRegisterValue: 0,
  misc: 0,
}
