import React from 'react'
import './BackButton.less'

export type BackButtonProps = {
  label: string
  href: string
}

export const BackButton: React.FC<BackButtonProps> = ({ href, label }) => {

  return (
    <div className='back-button' data-testid='back-button'>
      <a className='button-icon-standalone' href={href}>
        <span className='icon is24-icon-arrow-left'></span>
        <span className='text'>{label}</span>
      </a>
    </div>
  )
}
