import React, { ReactNode, useRef } from 'react'
import { useScrollWithShadow } from './hook/useScrollWithShadow'
import './HorizontalScrollContainerWithShadow.less'

const HorizontalScrollContainerWithShadow: React.FC<{ children?: ReactNode }> = ({ children }) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null)
  const { boxShadow, onScrollHandler } = useScrollWithShadow(scrollContainerRef);

  return <div className='absolute-reference'>
    <div className='ScrollContainerWithShadow' onScroll={onScrollHandler} ref={scrollContainerRef}>
      <div className={boxShadow} data-testid='shadow-overlay'/>
      {children}
    </div>
  </div>
}

export default HorizontalScrollContainerWithShadow
