import React from 'react'

export const RatingStars: React.FC<{ rating: number, maxRating?: number, votes?: number }> = ({ rating, maxRating = 5, votes }) => (
  <div>
  <span className="margin-right-s rating-stars font-m">
    <span className="front-stars" style={{ width: '80%', color: '#F7C820' }}>
      {Array.from({ length: rating }).map((_, index) => (
        <i key={`f_${index}`} className="fa fa-star"/>))}
    </span>

    {maxRating - rating > 0 &&
    <span className="back-stars">
        {Array.from({ length: maxRating - rating }).map((_, index) => (
          <i key={`o_${index}`} className="fa fa-star-o"/>))}
    </span>}
    {votes && <span className='font-s padding-left-s'>{votes}</span>}
</span>
  </div>
)
