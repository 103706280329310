import React, { ChangeEvent } from 'react'
import { SelectField } from 'is24-corecss'

type DropdownTileProps<T> = {
  listItems?: T[],
  label: string,
  id: string,
  onChange: (value: string) => void,
  labelFormatter: (value: T) => string,
  value: T
}

const DropdownTile = <T extends number | string>
({
   listItems,
   label,
   id,
   onChange,
   labelFormatter = (value) => value.toString(),
 }: DropdownTileProps<T>): React.ReactElement => (
  <div className='margin-vertical-l grid grid-flex grid-align-center grid-justify-space-between'>
    <div className='grid grid-flex six-tenth palm-five-tenths'>
      {label}
    </div>
    <div className='palm-two-fifths rent-vs-buy-input'>
      <SelectField
        id={id}
        label=''
        onChange={(e: ChangeEvent<HTMLSelectElement>) => onChange(e.target.value)}
      >
        {listItems && listItems.map((item) =>
          <option key={item} value={item}>{labelFormatter(item)}</option>
        )}
      </SelectField>
    </div>
    <div className='one-tenth palm-one-fifth align-right'/>
  </div>
)
export default DropdownTile
