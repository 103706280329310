import React from 'react'
import { FinancingResult } from '../../../financing/resultPage/model/FinancingResults'
import { useTranslation } from 'react-i18next'
import FinancingCostRow from './financingCostRow/FinancingCostRow'
import { toCurrency } from '../../../../services/NumberUtils'
import './FinancingCost.less'
import { PRE_APPROVAL_USER_FLOW } from '../../../../config/contextPaths'
import { CERTIFICATE_MODE_URL_PARAM } from '../../../preApproval/hook/usePreApprovalResultPageUrl'

export type FinancingCostProps = {
  financingResult?: FinancingResult
}

const FinancingCost: React.FC<FinancingCostProps> = ({ financingResult }) => {
  const { t } = useTranslation('widgets', { keyPrefix: 'financingCalculator.infoSection.financingCost' })

  const certificateButton =
    financingResult ?
      <a
        data-testid='certificateButton'
        href={`${PRE_APPROVAL_USER_FLOW}?${CERTIFICATE_MODE_URL_PARAM}`}>
        {t('certificateButton')}
      </a>
      :
      <span
        data-testid='disabledCertificateButton'
        className='DisabledCertificateButton grey-text'>{t('certificateButton')}
      </span>

  return (
    <div className='FinancingCost'>
      <div className='font-m font-bold padding-top-m'>{t('title')}</div>
      <div>
        <FinancingCostRow label={t('monthlyInstalmentSum')} value={toCurrency(financingResult?.monthlyInstalmentSum ?? 0)}/>
        <FinancingCostRow label={t('interestSum')} value={toCurrency(financingResult?.interestSum ?? 0)}/>
        <FinancingCostRow label={t('amortizationSum')} value={toCurrency(financingResult?.amortizationSum ?? 0)}/>
        <FinancingCostRow label={t('remainingDebtSum')} value={toCurrency(financingResult?.remainingDebtSum ?? 0)}/>
        <FinancingCostRow label={t('certificate')} value={certificateButton} borderBottom={false}/>
      </div>
    </div>
  )
}

export default FinancingCost
