import React, { ChangeEvent } from 'react'
import { SelectField } from 'is24-corecss'
import './FinancingSettings.less'
import { FinancingType, FinancingTypeValue } from '../../../api/api/types/FinancingType'
import { useTranslation } from 'react-i18next'

type FinancingTypSelectionType = {
  financingType: FinancingType | undefined,
  onChange: (financingType: FinancingType) => void,
  label?: string
  disabled?: boolean
}

export const FinancingTypeSelection: React.FC<FinancingTypSelectionType> = (
  {
    financingType,
    onChange,
    label,
    disabled = false
  }) => {
  const { t } = useTranslation()

  return (<>
      <SelectField
        label={label ?? ''}
        id='financingType'
        value={financingType}
        onChange={(e: ChangeEvent<HTMLSelectElement>) => onChange(e.target.value as FinancingType)}
        disabled={disabled}
      >
        {Object.keys(FinancingTypeValue).map(k =>
          <option key={k} value={k}>{t(`common:finance.values.${k}`)}</option>
        )}
      </SelectField>
    </>
  )
}
