import React from 'react'
import './FinancingSettings.less'
import { Financing } from '../models/Financing';
import { InputWithSlider } from '../../common/inputWithSlider/InputWithSlider'
import { useTranslation } from 'react-i18next'

type FollowUpFinancingSettingsType = {
  financing: Partial<Financing>,
  onChange: (data: Partial<Financing>) => void
}

export const FollowUpFinancingSettings: React.FC<FollowUpFinancingSettingsType> = ({
  financing,
  onChange,
}) => {
  const { t } = useTranslation('leadEngine')

  const handleOnChange = (prop: keyof Financing) =>
    (val: number) =>
      onChange({ [ prop ]: val })

  return (
    <>
      <InputWithSlider
        id='propertyValue'
        onChange={handleOnChange('propertyValue')}
        value={financing.propertyValue}
        title={t('followupFinancingSettings.propertyValue.title')}
        tooltipText={t('followupFinancingSettings.propertyValue.tooltipText')}
        min={100000}
      />

      <InputWithSlider
        id='remainingDebt'
        onChange={handleOnChange('remainingDebt')}
        value={financing.remainingDebt}
        title={t('followupFinancingSettings.remainingDebt.title')}
        min={20000}
      />
    </>
  )
}

