import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { ApplicationState } from '../../redux/store'
import { LeadEngineState } from './state/reducer'

import { PropertyType, PropertyTypeValue } from '../../api/api/types/PropertyType'
import PropertyTypePage from './pages/PropertyTypePage'
import BorrowerRelationPage from './pages/BorrowerRelationPage'
import PropertyDetailsPage from './pages/PropertyDetailsPage'
import NumberOfChildrenPage from './pages/NumberOfChildrenPage'
import PropertyAddressPage from './pages/PropertyAddressPage'
import ProjectStatePage from './pages/ProjectStatePage'
import ExpensesForm from './expense/Expense'
import LeadSuccessPage from './pages/LeadSuccessPage'
import IncomeRangePage from './pages/IncomeRangePage'
import NumberOfBorrowersPage from './pages/NumberOfBorrowersPage'
import FinancingUseTypePage from './pages/FinancingUseTypePage'
import EmploymentPage from './pages/EmploymentPage'
import FinancingStartPage from './pages/FinancingStartPage'
import { LoadingPage } from './pages/LoadingPage'
import PropertySubTypePage from './pages/PropertySubTypePage'
import Income from './income/Income'
import PrimaryBorrowerForm from './borrowerDataForm/PrimaryBorrowerForm'
import SecondaryBorrowerForm from './borrowerDataForm/SecondaryBorrowerForm'
import { Financing, isFollowupFinancing, isOnObjectSearch } from './models/Financing'
import { useTranslation } from 'react-i18next'
import { useFeature } from '@optimizely/react-sdk'
import { Feature } from '../../config/optimizely'
import PersonalData from './financingRequestForm/PersonalData/PersonalData'
import ContactDetails from './financingRequestForm/ContactDetails/ContactDetails'
import BookAppointmentPage from './pages/BookAppointmentPage';
import EnergeticModernizationPage from './pages/EnergeticModernizationPage'
import './FinancingRequestRoad.less'
import { BenefitBar } from '../common/benefitBar/BenefitBar'
import PurchasePricePage from './pages/purchasePrice/PurchasePricePage'
import LocationPage from './pages/location/LocationPage'
import { LoadingWithAnimation } from './loadingWithAnimation/LoadingWithAnimation'
import { Property } from './models/Property'
import FollowUpFinancingSettingsPage from './pages/followUpFinancingSettingsPage/FollowUpFinancingSettingsPage'
import { isUndefined } from '../../utils/utils'
import IncomeSingleValuePage from './pages/incomeSingleValue/IncomeSingleValuePage'
import useUserFirstName from './hook/useUserFirstName'
import useIncomePageWithInputField from './hook/useIncomePageWithInputField'
import { ProjectStateExtended } from '../../api/api/types/ProjectStateExtended'

export const RoadPage = {
  FINANCING_START: 'finanzierungsstart',
  PROJECT_STATE: 'projektstand',
  LOCATION: 'finanzierungsort',
  FOLLOW_UP_FINANCING_SETTINGS: 'nettodarlehen',
  PURCHASE_PRICE: 'kaufpreis',
  ENERGETIC_MODERNIZATION: 'modernisierung',
  FINANCING_USE_TYPE: 'nutzungssart',
  EMPLOYMENT: 'beschaeftigungsverhaeltnis',
  INCOME_RANGE: 'einkommensbereich',
  INCOME_SINGLE_VALUE: 'einkommen',
  PERSONAL_DATA: 'angaben',
  CONTACT_DETAILS: 'kontaktangaben',
  BOOK_APPOINTMENT: 'anfrage-gesendet',
  NUMBER_OF_BORROWERS: 'anzahl-darlehensnehmer',
  BORROWER_RELATION: 'verhaeltnis',
  BORROWERS_DATA: 'darlehensnehmer',
  BORROWERS_DATA_TWO: 'zweiter-darlehensnehmer',
  NUMBER_OF_CHILDREN: 'anzahl-kinder',
  INCOME_DETAILS: 'einnahmen',
  EXPENSES: 'ausgaben',
  PROPERTY_ADDRESS: 'objektadresse',
  PROPERTY_TYPE: 'objekttyp',
  PROPERTY_SUBTYPE: 'objektspezifizierung',
  PROPERTY_DETAILS: 'objektdetails',
  SUCCESS: 'erfolgreich'
}

export const showPurchasePricePage = (financing: Financing) =>
  !isFollowupFinancing(financing.financingType) && (financing.usingDefaultPurchasePrice || !financing.purchasePrice || financing.purchasePrice <= 0)

export const showFollowUpFinancingSettingsPage = (financing: Financing) =>
  isFollowupFinancing(financing.financingType) && (financing.usingDefaultRemainingDebt || !financing.remainingDebt || financing.remainingDebt <= 0)

export const showLocationPage = (property: Property): boolean =>
  (isUndefined(property.geoCode) && isUndefined(property.postalCode)) || property.geoCodeUpdated === true

const FinancingRequestRoad: React.FC = () => {
  const { t } = useTranslation('leadEngine')
  const { page } = useParams<{ page: string }>()
  const { isLoading, isFakeLoading, financing, property } = useSelector<ApplicationState, LeadEngineState>(state => state.leadEngine)
  const [ advisorySwitchFeatureEnabled ] = useFeature(Feature.SHOW_ADVISORY_LINK_ON_SUCCESS)
  const [ showAdvisoryLinkFeatureEnabled ] = useFeature(Feature.SHOW_ADVISORY_LINK_EARLY)
  const [ showLoadingWithAnimation ] = useFeature(Feature.LEAD_ENGINE_ANIMATED_LOADING)
  const shouldUseIncomePageWithInputField = useIncomePageWithInputField()
  const userFirstName = useUserFirstName()

  useEffect(() => {
    document.body.scrollIntoView(true)
  }, [ page ])

  if (isLoading) return <LoadingPage text={t('loading.default')} />
  if (isFakeLoading) {
    return showLoadingWithAnimation ? <LoadingWithAnimation/> : <LoadingPage text={t('loading.fake')}/>
  }

  const showEnergeticModernizationPage = !isFollowupFinancing(financing.financingType) && !property.hasSufficientEnergyEfficiency
  const pageAfterPurchasePrice = showEnergeticModernizationPage ? RoadPage.ENERGETIC_MODERNIZATION : RoadPage.FINANCING_USE_TYPE
  const pageAfterFollowUpFinancingSettings = showPurchasePricePage(financing) ? RoadPage.PURCHASE_PRICE : pageAfterPurchasePrice
  const pageAfterLocation = showFollowUpFinancingSettingsPage(financing) ? RoadPage.FOLLOW_UP_FINANCING_SETTINGS : pageAfterFollowUpFinancingSettings
  const pageAfterProjectState = showLocationPage(property) ? RoadPage.LOCATION : pageAfterLocation
  const pageAfterFinancingStart = isFollowupFinancing(financing.financingType) ? pageAfterProjectState : RoadPage.PROJECT_STATE
  const pageAfterPropertyType = (type: PropertyType) => type === PropertyTypeValue.LIVING_BUY_SITE ? RoadPage.PROPERTY_DETAILS : RoadPage.PROPERTY_SUBTYPE
  const pageAfterNumberOfBorrowers = (numberOfBorrowers: number) => numberOfBorrowers === 1 ? RoadPage.BORROWERS_DATA : RoadPage.BORROWER_RELATION
  const pageAfterPrimaryBorrowerForm = (numberOfBorrowers: number) => numberOfBorrowers === 1 ? RoadPage.NUMBER_OF_CHILDREN : RoadPage.BORROWERS_DATA_TWO
  const pageAfterExpenses = (projectState?: ProjectStateExtended) => isOnObjectSearch(projectState) ? RoadPage.SUCCESS : RoadPage.PROPERTY_ADDRESS
  const pageAfterEmployment = shouldUseIncomePageWithInputField ? RoadPage.INCOME_SINGLE_VALUE : RoadPage.INCOME_RANGE

  const pages = {
    [ RoadPage.FINANCING_START ]: <FinancingStartPage nextPage={pageAfterFinancingStart} userFirstName={userFirstName}/>,
    [ RoadPage.PROJECT_STATE ]: <ProjectStatePage nextPage={pageAfterProjectState}/>,
    [ RoadPage.LOCATION ]: <LocationPage nextPage={pageAfterLocation}/>,
    [ RoadPage.FOLLOW_UP_FINANCING_SETTINGS ]: <FollowUpFinancingSettingsPage nextPage={pageAfterFollowUpFinancingSettings}/>,
    [ RoadPage.PURCHASE_PRICE ]: <PurchasePricePage nextPage={pageAfterPurchasePrice}/>,
    [ RoadPage.ENERGETIC_MODERNIZATION ]: <EnergeticModernizationPage nextPage={RoadPage.FINANCING_USE_TYPE}/>,
    [ RoadPage.FINANCING_USE_TYPE ]: <FinancingUseTypePage nextPage={RoadPage.EMPLOYMENT}/>,
    [ RoadPage.EMPLOYMENT ]: <EmploymentPage nextPage={pageAfterEmployment}/>,
    [ RoadPage.INCOME_RANGE ]: <IncomeRangePage nextPage={RoadPage.PERSONAL_DATA}/>,
    [ RoadPage.INCOME_SINGLE_VALUE ]: <IncomeSingleValuePage nextPage={RoadPage.PERSONAL_DATA} userFirstName={userFirstName}/>,
    [ RoadPage.PERSONAL_DATA ]: <PersonalData nextPage={RoadPage.CONTACT_DETAILS} />,
    [ RoadPage.CONTACT_DETAILS ]: <ContactDetails nextPage={RoadPage.BOOK_APPOINTMENT} errorGoToPage={RoadPage.PERSONAL_DATA} />,
    [ RoadPage.BOOK_APPOINTMENT ]: <BookAppointmentPage nextPage={RoadPage.NUMBER_OF_BORROWERS} showAdvisoryLinkFeatureEnabled={showAdvisoryLinkFeatureEnabled}/>,
    [ RoadPage.NUMBER_OF_BORROWERS ]: <NumberOfBorrowersPage nextPage={pageAfterNumberOfBorrowers} skipPage={RoadPage.SUCCESS} />,
    [ RoadPage.BORROWER_RELATION ]: <BorrowerRelationPage nextPage={RoadPage.BORROWERS_DATA}/>,
    [ RoadPage.BORROWERS_DATA ]: <PrimaryBorrowerForm nextPage={pageAfterPrimaryBorrowerForm}/>,
    [ RoadPage.BORROWERS_DATA_TWO ]: <SecondaryBorrowerForm nextPage={RoadPage.NUMBER_OF_CHILDREN}/>,
    [ RoadPage.NUMBER_OF_CHILDREN ]: <NumberOfChildrenPage nextPage={RoadPage.INCOME_DETAILS}/>,
    [ RoadPage.INCOME_DETAILS ]: <Income nextPage={RoadPage.EXPENSES}/>,
    [ RoadPage.EXPENSES ]: <ExpensesForm nextPage={pageAfterExpenses} />,
    [ RoadPage.PROPERTY_ADDRESS ]: <PropertyAddressPage nextPage={RoadPage.PROPERTY_TYPE}/>,
    [ RoadPage.PROPERTY_TYPE ]: <PropertyTypePage nextPage={pageAfterPropertyType}/>,
    [ RoadPage.PROPERTY_SUBTYPE ]: <PropertySubTypePage nextPage={RoadPage.PROPERTY_DETAILS}/>,
    [ RoadPage.PROPERTY_DETAILS ]: <PropertyDetailsPage nextPage={RoadPage.SUCCESS} advisorySwitchFeatureEnabled={advisorySwitchFeatureEnabled} />,
    [ RoadPage.SUCCESS ]: <LeadSuccessPage advisorySwitchFeatureEnabled={advisorySwitchFeatureEnabled} />
  }

  const withBenefitBar = page === RoadPage.FINANCING_START || page === RoadPage.PERSONAL_DATA || page === RoadPage.CONTACT_DETAILS

  return (
    <>
      <div className={`lead-engine-flow content-wrapper ${withBenefitBar ? 'with-benefit-bar' : ''}`}>{pages[ page ?? '' ]}</div>
      {withBenefitBar && <div className='lead-engine-benefit-bar'><BenefitBar/></div>}
    </>
  )
}

export default FinancingRequestRoad
