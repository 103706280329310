import React from 'react'
import { formatNumber } from '../../../../../../services/NumberUtils'
import { LAND_TRANSFER_TAX_VALUES } from '../../../model'
import { useTranslation } from 'react-i18next'

export const LandTransferTaxTable: React.FC = () => {

  const { t } = useTranslation('widgets', { keyPrefix: 'financingCalculator.additionalCosts.modal.tooltip' })

  return (
    <table className='one-whole'>
      <tbody>
        <tr>
          <th><strong>{t('land')}</strong></th>
          <th><strong>{t('tax')}</strong></th>
        </tr>

        {LAND_TRANSFER_TAX_VALUES.map(entry =>
          <tr key={entry.geoCode}>
            <td>{entry.label}</td>
            <td>{formatNumber(entry.value, 1, 1)} %</td>
          </tr>
        )}
      </tbody>
    </table>
  )
}

export default LandTransferTaxTable
