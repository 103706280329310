import React, { useMemo } from 'react'
import { ModalBackdrop, ModalContainer } from 'is24-corecss'
import { Trans, useTranslation } from 'react-i18next'
import RepaymentSchedule from '../../../../financing/resultPage/repaymentSchedule/RepaymentSchedule'
import { FinancingResult } from '../../../../financing/resultPage/model/FinancingResults'
import './AmortizationModal.less'
import LeadEngineButton from '../../leadEngineButton/LeadEngineButton'
import { FinancingType } from '../../../../../api/api/types/FinancingType'
import { Region } from '../../../../common/input/regionSelection/RegionSelection'
import { AdditionalCostsInPercents } from '../../model'
import { ProductTypeAO } from '../../../../../api/api/types/ReferredOffersAO'

export type AmortizationModalProps = {
  financingResult: FinancingResult
  financingType: FinancingType
  purchasePrice: number
  region?: Region
  additionalCostsInPercents: AdditionalCostsInPercents
  additionalCosts: number
  ownCapital: number
  propertyValue: number
  remainingDebt: number
  onClose: () => void
  providerName: string
  providerId?: number
  productType?: ProductTypeAO
  hasBrandedOffer: boolean
}

const AmortizationModal: React.FC<AmortizationModalProps> = (
  {
    financingResult,
    financingType,
    purchasePrice,
    region,
    additionalCostsInPercents,
    additionalCosts,
    ownCapital,
    propertyValue,
    remainingDebt,
    onClose,
    providerName,
    providerId,
    productType,
    hasBrandedOffer,
  }) => {

  const { t } = useTranslation('widgets', { keyPrefix: 'financingCalculator.infoSection.amortization.modal' })

  const descriptionParams = useMemo(() => ({
      fixedRateEndYear: financingResult.fixedRateEndYear,
      averageInterestRate: financingResult.averageInterestRate + ' %',

    }),
    [ financingResult.fixedRateEndYear, financingResult.averageInterestRate ])

  return (
    <div className='AmortizationModal' data-testid='amortization-modal'>
      <ModalBackdrop visible={true} onClose={onClose}>
        <ModalContainer
          width={900}
          visible={true}
          modalTitle={t('title')}
          onClose={onClose}
          ariaLabelCloseButton='close'
          containerClassName='AmortizationModalContainer'
          contentWrapperClassName='AmortizationModal'
        >
          <div data-testid='amortization-description'>
            <Trans t={t} i18nKey='description' values={descriptionParams}></Trans>
          </div>
          {useMemo(() =>
            <RepaymentSchedule
              yearlyRepayments={financingResult?.yearlyRepayments}
              yearlyInstalmentSum={financingResult.monthlyInstalmentSum}
              yearlyInterestSum={financingResult.interestSum}
              yearlyAmortizationSum={financingResult.amortizationSum}
              yearlyOverpaymentSum={financingResult.overpaymentSum}
              yearlyRemainingDebtSum={financingResult.remainingDebtSum}
              fixedRateEndYear={financingResult.fixedRateEndYear}
              isAccordionVisible={false}
            />, [ financingResult.yearlyRepayments, financingResult.monthlyInstalmentSum,
            financingResult.interestSum, financingResult.amortizationSum, financingResult.overpaymentSum,
            financingResult.remainingDebtSum, financingResult.fixedRateEndYear ])
          }
          <div className='ButtonSection'>
            <LeadEngineButton
              providerName={providerName}
              providerId={providerId}
              productType={productType}
              financingType={financingType}
              region={region}
              purchasePrice={purchasePrice}
              additionalCostsInPercents={additionalCostsInPercents}
              additionalCosts={additionalCosts}
              ownCapital={ownCapital}
              propertyValue={propertyValue}
              remainingDebt={remainingDebt}
              buttonText={t('leadEngineButtonText')}
              description={
                <div className='padding-top-m' data-testid='button-description'>
                  <Trans t={t} i18nKey='leadEngineButtonDescription'><span className='font-bold'/></Trans>
                </div>}
              hasBrandedOffer={hasBrandedOffer}
            />
          </div>
        </ModalContainer>
      </ModalBackdrop>
    </div>
  )
}

export default AmortizationModal
