import { StepWrapper } from '../StepWrapper'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { withPageViewReporting } from '../../hoc/withTrackPageView'
import { onFinancingRequestSubmit } from '../../state/thunks/app'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../../../redux/store'
import { RoadPage } from '../../FinancingRequestRoad'
import { MenuBar } from '../../MenuBar'
import PurchasePriceForm from './PurchasePriceForm'
import { Financing, getLoanAmount, isValidLoanAmount } from '../../models/Financing'
import StickyNextButton from '../common/stickyNextButton/StickyNextButton'
import './PurchasePricePage.less'
import { useNavigate } from 'react-router-dom'

const PurchasePricePage: React.FC<{ nextPage: string }> = ({ nextPage }) => {
  const { t } = useTranslation('leadEngine')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const financing = useSelector<ApplicationState, Financing>(state => state.leadEngine.financing)
  const [ purchasePrice, setPurchasePrice ] = useState(financing.purchasePrice ?? 0)
  const [ ownFunds, setOwnFunds ] = useState(financing.ownFunds ?? 0)
  const loanAmount = getLoanAmount({ ...financing, purchasePrice, ownFunds })
  const [ loanAmountValid, setLoanAmountValid ] = useState(true)

  useEffect(() => {
    setLoanAmountValid(isValidLoanAmount(loanAmount))
  }, [ loanAmount ])
  const handleNextPage = () => dispatch(onFinancingRequestSubmit(nextPage, navigate)({ purchasePrice, ownFunds }))

  return (
    <>
      <MenuBar page={RoadPage.PURCHASE_PRICE} hideClose/>
      <StepWrapper title={t('purchasePrice.title')}>
        <div className='PurchasePricePage grid palm-one-whole lap-two-third' style={{ margin: 'auto' }}>
          <PurchasePriceForm
            purchasePrice={purchasePrice}
            ownFunds={ownFunds}
            loanAmount={loanAmount}
            loanAmountValid={loanAmountValid}
            onPurchasePriceChanged={setPurchasePrice}
            onOwnFundsChanged={setOwnFunds}
            additionalCostsPercentages={financing.additionalCostsPercentages}/>
          <StickyNextButton text={t('common:button.forward')} onClick={handleNextPage} disabled={!loanAmountValid}/>
        </div>
      </StepWrapper>
    </>
  )
}

export default withPageViewReporting(PurchasePricePage, 'purchaseprice')
