import { Expose} from '../../../api/api/Expose'
import { GACQueryValue, getEntity, getLocations } from '../../../api/api/GAC'
import { ApartmentType } from '../../../api/api/types/ApartmentType'
import { HouseType } from '../../../api/api/types/HouseType'
import { PropertyType } from '../../../api/api/types/PropertyType'
import { EnergeticModernizationType } from '../../../api/api/types/EnergeticModernizationType'

const QUARTER_GEOCODE_LENGTH = 13;
const POSTALCODE_LENGTH = 5;


export type Property = {
  type?: PropertyType
  subType?: ApartmentType | HouseType
  street?: string
  streetNumber?: string
  city?: string
  postalCode?: string
  geoCode?: string
  location?: string
  locationLabel?: string
  livingArea?: number
  siteArea?: number
  constructionYear?: number
  energeticModernization?: EnergeticModernizationType
  hasSufficientEnergyEfficiency?: boolean
  energyEfficiencyClass?: string
  geoCodeUpdated?: boolean
}

export const isValidLocation = ({ geoCode, postalCode, locationLabel }: Property): boolean =>
  ((geoCode?.length === QUARTER_GEOCODE_LENGTH) || (postalCode?.length === POSTALCODE_LENGTH)) && !!locationLabel


export type PropertyUrlParams = Partial<{
  geocode: string,
  postalcode: string
}>

export const buildPropertyByUrlParams = (urlParams: PropertyUrlParams): Partial<Property> | {}  => {
  const { geocode, postalcode} = urlParams

  const property: Partial<Property> = {}

  if(geocode) {
    property.geoCode = geocode
  }

  if (postalcode) {
    property.postalCode = postalcode
  }

  return property
}

export const matchExposeToProperty = (expose: Expose): Partial<Property> => {
  const { city, quarter, postcode, geocode, street } = expose.address
  const hasSufficientEnergyEfficiency = expose.hasSufficientEnergyEfficiency
  const energyEfficiencyClass = expose.energyEfficiencyClass

  if (geocode && city) {
    return {
      locationLabel: `${city}${quarter ? ' - ' + quarter : ''}`,
      postalCode: postcode,
      city: city,
      street: street,
      geoCode: geocode,
      hasSufficientEnergyEfficiency: hasSufficientEnergyEfficiency,
      energyEfficiencyClass: energyEfficiencyClass !== "" ? energyEfficiencyClass : undefined
    }
  } else {
    return {
      hasSufficientEnergyEfficiency: hasSufficientEnergyEfficiency,
      energyEfficiencyClass: energyEfficiencyClass !== "" ? energyEfficiencyClass : undefined
    }
  }
}

export const fetchPropertyEntity = async (id: string): Promise<Property> =>  {
  try {
    const result = await getEntity(id)
    return {
      locationLabel: result.label,
      postalCode: result.address?.postcode,
      city: result.address?.city,
      street: result.address?.street,
      geoCode: result.geoId
    }
  } catch (e) {
    return Promise.reject(new Error('Get property entity api failed: ' + e))
  }
}


export const fetchFirstPropertyLocation = async (postalCode: string): Promise<Property> => {
  const result = await getLocations(postalCode, [ GACQueryValue.postcodeWithQuarter ]) || []
  const [ first ] = result.map(location => ({ locationLabel: location.entity.label, postalCode }))
  return first
}
