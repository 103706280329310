import { LEAD_ENGINE_PAGE, LEAD_ENGINE_ROAD_PAGE } from '../../config/contextPaths'
import { NavigateFunction } from 'react-router/dist/lib/hooks'

export const goToRoadPage = (page: string, navigate: NavigateFunction) =>
  navigate({
    pathname: `${LEAD_ENGINE_ROAD_PAGE}/${page}`,
    search: location.search
  })

export const goToFinancingStartPage = (navigate: NavigateFunction) =>
  navigate({
    pathname: `${LEAD_ENGINE_PAGE}/`,
    search: location.search
  })
