import { StepWrapper } from './StepWrapper'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Stepper } from 'is24-corecss'
import { withPageViewReporting } from '../hoc/withTrackPageView'
import { onFinancingRequestSubmit } from '../state/thunks/app'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../../redux/store'
import { LeadEngineState } from '../state/reducer'
import { RoadPage } from '../FinancingRequestRoad'
import { MenuBar } from '../MenuBar'
import { useNavigate } from 'react-router-dom'

const NumberOfChildrenPage: React.FC<{ nextPage: string }> = ({ nextPage }) => {
  const { t } = useTranslation('leadEngine')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { financing: { numberOfChildren = 0 } } = useSelector<ApplicationState, LeadEngineState>(state => state.leadEngine)
  const [ count, setCount ] = useState(numberOfChildren)

  const handleNextPage = () => dispatch(onFinancingRequestSubmit(nextPage, navigate)({ numberOfChildren: count }))

  return (
    <>
      <MenuBar page={RoadPage.NUMBER_OF_CHILDREN}/>
      <StepWrapper title={t('numberOfChildren.title')} text={t('numberOfChildren.text')}>
        <div className="one-half palm-one-whole horizontal-center">
          <Stepper id='numberOfChildren' label=''
                   defaultValue={numberOfChildren.toString()}
                   onChange={(event: any) => setCount(Number(event.target.value))}
          />

          <div className='align-right margin-top border-top padding-top'>
            <Button appearance="secondary" onClick={handleNextPage}>
              {t('common:button.acquire')}
            </Button>
          </div>
        </div>
      </StepWrapper>
    </>
  )
}

export default withPageViewReporting(NumberOfChildrenPage, 'ext.children')

