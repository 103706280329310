import { Expose, ExposeAddress } from '../../../../../../api/api/Expose'
import { LocationType } from '../../../../../mortgageOfficer/state/reducer'

const trimNullable = (value: string | undefined | null) => value?.trim() || undefined

const createLocationLabel = (address: ExposeAddress | undefined): string | undefined => {
  const postcode = trimNullable(address?.postcode)
  const city = trimNullable(address?.city)
  const quarter = trimNullable(address?.quarter)
  const label = postcode && city && `${postcode} ${city}`
  return quarter ? `${label} - ${quarter}` : label
}
export const getLocationFromExpose = (expose?: Expose): LocationType => {
  const address = expose?.address

  return {
    locationLabel: createLocationLabel(address),
    geoCode: trimNullable(address?.geocode),
    postalCode: trimNullable(address?.postcode)
  } as LocationType
}
