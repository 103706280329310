import { useTranslation } from 'react-i18next'
import React from 'react'
import { InputWithSliderBelow } from '../../../common/inputWithSliderBelow/InputWithSliderBelow'
import { Slider } from 'is24-corecss'

type SlidesValues = {
  years: number,
  rent: number,
  price: number,
  equity: number,
}

type SlidesProps = {
  equityMax: number,
  onChange: (name: keyof SlidesValues, value: number) => void
} & SlidesValues

const slidersConfig = {
  time: {
    maxValue: 10,
    max: 30,
    min: 1,
    step: 1,
  },
  rent: {
    max: 5000,
    min: 100,
    step: 50,
  },
  cost: {
    max: 1000000,
    min: 20000,
    step: 5000,
  },
  equity: {
    min: 0,
    step: 5000,
  },
};

const Slides: React.FC<SlidesProps> = ({
   years,
   rent,
   price,
   equity,
   equityMax,
   onChange,
 }) => {
  const { t } = useTranslation('widgets')
  const handleChange = (key: keyof SlidesValues) =>
    (v: number) => onChange (key, v)

  return (
    <>
      <div className='margin-top-l'>
        <div className='grid grid-flex grid-align-center grid-justify-space-between margin-bottom-l'>
          <div className='eight-tenths palm-five-tenths'>
            <strong>{t('rentVsBuy.main.time')}</strong>
          </div>
          <div className='two-tenths palm-five-tenths'>
            <div className='grid grid-flex grid-align-center time-input-container'>
              <input
                type='text'
                value={years}
                readOnly={true}
                className='align-right padding-right-s time-input-content'
                id='years-input'/>
              {t('rentVsBuy.years', {count: years})}
            </div>
          </div>
        </div>
        <div>
          <Slider
            id={`years-slider`}
            title='years-slider'
            maxValue={slidersConfig.time.maxValue}
            max={slidersConfig.time.max}
            min={slidersConfig.time.min}
            step={slidersConfig.time.step}
            onChange={handleChange('years')}
          />
        </div>
      </div>
      <div className='margin-top-m palm-one-whole'>
        <InputWithSliderBelow
          id='rent'
          onChange={handleChange('rent')}
          value={rent}
          title={t('rentVsBuy.main.rent')}
          max={slidersConfig.rent.max}
          min={slidersConfig.rent.min}
          step={slidersConfig.rent.step}
          currencyClassName='two-tenth palm-five-tenths'
          titleClassName='palm-five-tenths'
        />
      </div>
      <div className='margin-top-m'>
        <InputWithSliderBelow
          id='price'
          onChange={handleChange('price')}
          value={price}
          title={t('rentVsBuy.main.cost')}
          max={slidersConfig.cost.max}
          min={slidersConfig.cost.min}
          step={slidersConfig.cost.step}
          currencyClassName='two-tenth palm-five-tenths'
          titleClassName='palm-five-tenths'
        />
      </div>
      <div className='margin-top-m'>
        <InputWithSliderBelow
          id='equity'
          onChange={handleChange('equity')}
          value={equity}
          title={t('rentVsBuy.main.equity')}
          max={equityMax}
          min={slidersConfig.equity.min}
          step={slidersConfig.equity.step}
          currencyClassName='two-tenth palm-five-tenths'
          titleClassName='palm-five-tenths'
        />
      </div>
    </>
  )
}

export default Slides
