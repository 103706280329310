import React, { ReactChild, useState } from 'react'
import '../RepaymentSchedule.less'

type Props = {
  rowContent: ReactChild
  className?: string,
  expandable?: boolean
  subContent?: JSX.Element
  showOnMonthOnChange?: () => void
}
const TableRow: React.FC<Props> = (
  {
    rowContent,
    className,
    expandable,
    subContent,
    showOnMonthOnChange
  }) => {
  const [ expanded, setExpanded ] = useState(false);

  const onClick = () => {
    setExpanded(!expanded)
    if (showOnMonthOnChange) {
      showOnMonthOnChange()
    }
  };

  return (
    <div className='table-row'>
      <div role='button'
           className={`row ${className} ${expanded && expandable ? 'focused' : ''}`}
           tabIndex={0}
           onClick={onClick}
      >
        {expandable ?
          <div className='chevron'>
            <i className={`${expanded ? 'is24-icon-chevron-up' : 'is24-icon-chevron-down'}`}
               aria-label={'chevron-icon'}/>
          </div>
          :
          <div className={'plug'}/>}
        <div>{rowContent}</div>
      </div>
      {expanded && subContent ?
        <div role='button' tabIndex={-1} onClick={onClick}>{subContent}</div> : null}
    </div>
  )
}

export default TableRow
