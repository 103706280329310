import { isFollowupFinancing } from '../models/Financing'
import { ButtonGroup } from './ButtonGroup'
import { StepWrapper } from './StepWrapper'
import React from 'react'
import { FinancingUseType } from '../../../api/api/types/FinancingUseType.ds'
import { RoadPage } from '../FinancingRequestRoad'
import { withPageViewReporting } from '../hoc/withTrackPageView'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../../redux/store'
import { LeadEngineState } from '../state/reducer'
import { useTranslation } from 'react-i18next'
import { onFinancingRequestSubmit } from '../state/thunks/app'
import { MenuBar } from '../MenuBar'
import { useNavigate } from 'react-router-dom'

const FinancingUseTypeValues: FinancingUseType[] = [
  'OWNER_OCCUPATION',
  'PARTIALLY_RENTING',
  'RENTING',
]

const FinancingUseTypePage: React.FC<{ nextPage: string }> = ({ nextPage }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { financing: { financingUseType, financingType } } = useSelector<ApplicationState, LeadEngineState>(state => state.leadEngine)
  const { t } = useTranslation('leadEngine')

  const handleOnClick = (financingUseType: FinancingUseType) =>
    dispatch(onFinancingRequestSubmit(nextPage, navigate)({ financingUseType }))

  const title = isFollowupFinancing(financingType) ?
    t('financingUseType.titleFollowupFinancing') : t('financingUseType.title')

  return <>
    <MenuBar page={RoadPage.FINANCING_USE_TYPE} hideClose/>
    <StepWrapper title={title}>
      <ButtonGroup<FinancingUseType>
        values={FinancingUseTypeValues}
        onClick={handleOnClick}
        value={financingUseType}
      />
    </StepWrapper>
  </>
}

export default withPageViewReporting(FinancingUseTypePage, 'usetype')

