export const PurchaseProjectState = {
  ON_OBJECT_SEARCH: 'ON_OBJECT_SEARCH',
  OBJECT_FOUND: 'OBJECT_FOUND',
  PURCHASING_NEGOTIATIONS: 'PURCHASING_NEGOTIATIONS',
  NOTARY_APPOINTMENT_PLANNED: 'NOTARY_APPOINTMENT_PLANNED',
  NA: 'NA',
} as const
export type PurchaseProjectStateType = keyof typeof PurchaseProjectState

export const BuildingProjectState = {
  PROPERTY_FOUND: 'PROPERTY_FOUND',
  NOTARY_APPOINTMENT_PLANNED: 'NOTARY_APPOINTMENT_PLANNED',
  ON_BUILDER_SEARCH: 'ON_BUILDER_SEARCH',
  NA: 'NA',
} as const
export type BuildingProjectStateType = keyof typeof BuildingProjectState

export type ProjectState = PurchaseProjectStateType | BuildingProjectStateType


