import { Loader } from 'is24-corecss'

import './loading.less'

type Props = {
  className?: string,
  size?: 'small' | 'medium' | 'large'
}

export const Loading = ({ size = 'medium', className = '' }: Props) => (
  <div className={`Loading ${className}`}>
    <Loader size={size}/>
  </div>
)
