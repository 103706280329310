import React from 'react'
import { useTranslation } from 'react-i18next'
import { Card, CardBody, CardDivider, CardHeader, StarRating } from 'is24-corecss'
import { MortgageProvider } from '../model'
import { formatNumber } from '../../../../services/NumberUtils'
import './CarouselCard.less'

interface CarouselCardProps {
  provider: MortgageProvider
}

const MAX_NAME_LENGTH = 50

const formatName = (name: string) => name.length < 50 ? name : name.substring(0, MAX_NAME_LENGTH) + '...'

const getProfileLink = (id: number) => `/baufinanzierung/anbieter/${id}/`

const CarouselCard: React.FC<CarouselCardProps> = ({ provider }) => {
  const { t } = useTranslation('widgets')
  const { id, name, logo, averageRating, numberOfRatings } = provider

  const formattedName = formatName(name)

  return (
    <Card id={`${id}`} className='CarouselCard' linkAddress={getProfileLink(id)}>
      <CardHeader className='CardHeader'>
        <img className='CardImage' src={logo} alt={formattedName}/>
      </CardHeader>
      <CardDivider/>
      <CardBody className='CardBody'>
        <div className='ProviderName'>{formattedName}</div>
        <div className='ProviderRating'>
          <StarRating rating={averageRating} editable={false} size={'small'}/>
          <div>{formatNumber(averageRating, 1, 1)} {t('mortgageProvidersTeaser.from')} 5 ({numberOfRatings})</div>
        </div>
      </CardBody>
    </Card>
  )
}

export default CarouselCard
