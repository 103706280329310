const MARKETDATARANGE_API_URL = 'https://www.immobilienscout24.de/baufinanzierung-api/restapi/api/financing/construction/v1.0/marketdatarange/';

export type MarketDataResourceParams = Partial<{
  geoCode: string,
  loanAmount: number,
  employment: string,
  amortizationRate: number,
  loanToValueRatio: number,
  timeFrameFrom: number,
  timeFrameUnit: string,
  timeFrameTo: number
}>

const MIN_TIME_FROM = 1597960800000 // 21.08.2020: There was an aggregation before this date. To avoid a high peak in the chart this date is set as minimum

export const loadMarketDataRanges = async (params: MarketDataResourceParams) => {
    if (!params.timeFrameFrom || params.timeFrameFrom < MIN_TIME_FROM) {
      params.timeFrameFrom = MIN_TIME_FROM
    }

    const url = new URL(MARKETDATARANGE_API_URL)
    Object.keys(params).forEach(key => url.searchParams.append(key, Object(params)[key]))
    return await fetch(url.toString(), {method: 'GET'});
}
