import { useEffect, useState } from 'react'
import { getContactedProvidersByUpdateToken } from '../api/api/ContactRequest'
import { MortgageProviderAO } from '../api/api/types/MortgageProviderAO.ds'
import { isDefined } from '../utils/utils'

type UseContactedProvidersType = (contactRequestId?: number, updateToken?: string) => [ MortgageProviderAO[], boolean ]

export const useContactedProviders: UseContactedProvidersType = (contactRequestId, updateToken) => {
  const [ mortgageProviders, setMortgageProviders ] = useState<MortgageProviderAO[]>([])
  const [ isLoading, setLoading ] = useState(false)

  useEffect(() => {
    (async () => {
        try {
          if(isDefined(contactRequestId) && !!updateToken) {
            setLoading(true)

            const { provider } = await getContactedProvidersByUpdateToken(contactRequestId, updateToken)
            setMortgageProviders(provider)
          }
        } catch (e) {
          console.error(e)
          setMortgageProviders([])
        } finally {
          setLoading(false)
        }
    })()
  }, [ contactRequestId, updateToken ])

  return [ mortgageProviders, isLoading ]
}
