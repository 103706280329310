import React, { useState } from 'react'
import { formatNumber, toCurrency } from '../../../../services/NumberUtils'
import './ShortlistObject.less'
import { useTranslation } from 'react-i18next'
import { ExposeEntry } from '../ShortlistDropdown'

export type ShortlistObjectProps = {
  shortlistEntry: ExposeEntry
  onSelect?: (selectedEntry: ExposeEntry) => void
}

const defaultExposeImagePlaceholderName = 'graphicformissingimage'

const ShortlistObject: React.FC<ShortlistObjectProps> = ({shortlistEntry, onSelect}) => {
  const { t } = useTranslation()
  const [ imageAvailable, setImageAvailable ] = useState(!!shortlistEntry?.imageUrl && shortlistEntry.imageUrl.indexOf(defaultExposeImagePlaceholderName) < 0)
  return shortlistEntry ? (
    <div className='expose-object-view' role='button' tabIndex={0} onClick={() => onSelect?.(shortlistEntry)}>
      <div className='grid-flex margin-bottom-none grid-justify-center'>
        <div className='image-size-limit grid-item one-sixth palm-three-twelfths grid-flex grid-justify-center margin-top-none'>
          <div className='image-wrapper'>
            <div className='image-border'>
              { imageAvailable ? <img src={shortlistEntry.imageUrl } alt='' onError={() => setImageAvailable(false)}/>
                : <div className='default-icon'><i className='is24-icon-immo-rating'/></div>}
            </div>
          </div>
        </div>
        <div className='object-detail-section grid-item five-sixths palm-nine-twelfths padding-left-s'>
          <div className='grid'>
            <div className='grid-item one-whole'>
              <strong className='title' data-test='title'>{ shortlistEntry.address }</strong>
            </div>
            { shortlistEntry.purchasePrice && <div className='object-detail grid-item one-fourth'>{ toCurrency(shortlistEntry.purchasePrice) }</div>}
            { shortlistEntry.numberOfRooms && <div className='object-detail grid-item  one-fourth'>{ formatNumber(shortlistEntry.numberOfRooms, 0, 1) }&nbsp;{t('shortlist.rooms')}</div>}
            { shortlistEntry.squareMeters && <div className='object-detail-small grid-item  one-eighth'>{ formatNumber(shortlistEntry.squareMeters) }&nbsp;{t('shortlist.squareMeters')}</div>}
            <div className='ExposeSourceTag indicator indicator--label' role='presentation'>
              <span>{t(shortlistEntry.externalExpose ? 'shortlist.sourceExpose' : 'shortlist.sourceShortlist')}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null
}

export default ShortlistObject
