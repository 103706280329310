import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { reportPageView } from '../services/FinanceTrackingService'
import { ApplicationState } from '../../../redux/store'
import { LeadEngineState } from '../state/reducer'

export const withPageViewReporting = <P extends object>(WrappedComponent: React.ComponentType<P>, pageTitle: string): React.FC<P> => {
  return (props) => {
    const { financing, borrowers, property, isTestRun } = useSelector<ApplicationState, LeadEngineState>(state => state.leadEngine)

    // Do not add a dependency
    // report should called only once when component is rendered
    useEffect(() => {
      if (isTestRun) return
      try {
        reportPageView(pageTitle, financing, borrowers.primary, property)
      } catch (e) {
        console.error(e)
      }
      // eslint-disable-next-line
    }, [])

    return <WrappedComponent {...props as P} />
  }
}
