import React, { ChangeEvent, useCallback } from 'react'
import Section from '../Section'
import { RadioButton, SelectField, TextInput } from 'is24-corecss'
import { Salutation, SalutationType } from '../../../../../api/api/types/Salutation.ds'
import { useTranslation } from 'react-i18next'
import './PersonalDataSection.less'
import { TitleType, TitleTypeValues } from '../../../../../api/api/types/TitleType'
import { IncomeRange, IncomeRangeType } from '../../../../../api/api/types/IncomeRange.ds'
import ValidatedPhoneNumberInput from '../../../../common/phoneNumberInput/ValidatedPhoneNumberInput'
import { ValidationState } from '../AppointmentRequestModal'

export type PersonalDataSectionProps = {
  submitted: boolean
  salutation: SalutationType
  setSalutation: (salutation: SalutationType) => void
  title: TitleType
  setTitle: (title: TitleType) => void
  forename?: string
  forenameValid: boolean
  setForename: (forename: string) => void
  surname?: string
  surnameValid: boolean
  setSurname: (surname: string) => void
  email?: string
  emailValid: boolean
  setEmail: (email: string) => void
  setPhoneNumberTouched: (touched: boolean) => void
  phoneNumber?: string
  setPhoneNumber: (phone: string) => void
  phoneNumberValid: ValidationState
  setPhoneNumberValid: (validationState: ValidationState) => void
  incomeRange?: IncomeRangeType
  incomeRangeValid: boolean
  setIncomeRange: (incomeRange: IncomeRangeType) => void
}

const PersonalDataSection: React.FC<PersonalDataSectionProps> = (
  {
    submitted,
    salutation,
    setSalutation,
    title,
    setTitle,
    forename,
    forenameValid,
    setForename,
    surname,
    surnameValid,
    setSurname,
    email,
    emailValid,
    setEmail,
    setPhoneNumberTouched,
    phoneNumber,
    setPhoneNumber,
    phoneNumberValid,
    setPhoneNumberValid,
    incomeRange,
    incomeRangeValid,
    setIncomeRange
  }) => {

  const { t } = useTranslation('widgets', { keyPrefix: 'financingCalculator.appointmentRequestModal.personalDataSection' })
  const { t: tCommon } = useTranslation('common', { keyPrefix: 'finance.values' })

  const handlePhoneChange = useCallback((value: string, valid: boolean) => {
    setPhoneNumber(value)
    setPhoneNumberValid(valid ? ValidationState.VALID : ValidationState.INVALID)
  }, [ setPhoneNumber, setPhoneNumberValid ])

  return (
    <div className='PersonalDataSection' data-testid='personal-data-section'>
      <Section title={t('title')}>
        <div>
          <div className='RadioButtonsContainer grid-flex'>
            <RadioButton
              id='man'
              data-testid={'radio-button-man'}
              label={t('man')}
              name='salutation'
              defaultChecked={salutation === Salutation.MR}
              onChange={() => setSalutation(Salutation.MR)}
            />
            <RadioButton
              id='woman'
              data-testid={'radio-button-woman'}
              label={t('woman')}
              name='salutation'
              defaultChecked={salutation === Salutation.MRS}
              onChange={() => setSalutation(Salutation.MRS)}
            />
          </div>
          <SelectField
            className='SelectField padding-top-m'
            data-testid='titleType-select-field'
            label={t('titleType.label')}
            value={title}
            onChange={(e: ChangeEvent<HTMLSelectElement>) => setTitle(e.target.value as TitleType)}
          >
            {Object.keys(TitleTypeValues)
              .map(k =>
                <option key={k} value={TitleTypeValues[ k as keyof typeof TitleTypeValues ]}>
                  {t(`titleType.${k}`)}
                </option>
              )}
          </SelectField>
          <div className='grid'>
            <div className='grid-item desk-one-half one-whole padding-top-m desk-padding-right-xs'>
              <TextInput
                id='forename'
                data-testid='forename'
                name='forename'
                label={t('forename')}
                placeholder=''
                defaultValue={forename}
                valid={!submitted || forenameValid}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setForename(e.target.value)}
                required
              />
            </div>
            <div className='grid-item desk-one-half one-whole padding-top-m desk-padding-left-xs'>
              <TextInput
                id='surname'
                data-testid='surname'
                name='surname'
                label={t('surname')}
                placeholder=''
                defaultValue={surname}
                valid={!submitted || surnameValid}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setSurname(e.target.value)}
                required
              />
            </div>
            <div className='grid-item one-whole padding-top-m'>
              <TextInput
                id='email'
                data-testid='email'
                type='email'
                name='email'
                label={t('email')}
                placeholder=''
                defaultValue={email}
                valid={!submitted || emailValid}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
              />
            </div>
            <div className='grid-item one-whole padding-top-m'>
              <div className='phoneNumberInput'>
                <ValidatedPhoneNumberInput
                  id='phoneNumber'
                  label={`${t('phoneNumber')}`}
                  value={phoneNumber}
                  valid={!submitted || phoneNumberValid == ValidationState.VALID}
                  onChange={handlePhoneChange}
                  allowAutoComplete={false}
                  onBlur={() => {
                    setPhoneNumberTouched(true)
                    setPhoneNumberValid(ValidationState.PENDING)
                  }}
                />
              </div>
            </div>
          </div>
          <SelectField
            className='SelectField padding-top-m'
            data-testid='incomeRange-select-field'
            label={t('incomeRange')}
            value={incomeRange}
            onChange={(e: ChangeEvent<HTMLSelectElement>) => setIncomeRange(e.target.value as IncomeRangeType)}
            isErroneous={submitted && !incomeRangeValid}
          >
            <option hidden value=''>{t('placeholder')}</option>
            {Object.keys(IncomeRange)
              .map(k =>
                <option key={k} value={IncomeRange[ k as keyof typeof IncomeRange ]}>
                  {tCommon(k)}
                </option>
              )}
          </SelectField>
        </div>
      </Section>
    </div>
  )
}

export default PersonalDataSection
