import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import './RepaymentSchedule.less'
import { RepaymentScheduleHeader } from './tableElements/RepaymentScheduleHeader'
import { RepaymentScheduleFooter } from './tableElements/RepaymentScheduleFooter'
import { RepaymentScheduleRow } from './tableElements/RepaymentScheduleRow'
import { YearlyRepayment } from '../model/FinancingResults'
import HorizontalScrollContainerWithShadow from '../../../common/scrollContainerWithShadow/HorizontalScrollContainerWithShadow'
import AccordionWrapper from './AccordionWrapper'

interface FinancingCostProps {
  yearlyRepayments: YearlyRepayment[],
  yearlyInstalmentSum: number
  yearlyInterestSum: number
  yearlyAmortizationSum: number
  yearlyOverpaymentSum: number
  yearlyRemainingDebtSum: number
  fixedRateEndYear: number,
  isAccordionVisible?: boolean
}

const RepaymentSchedule: React.FC<FinancingCostProps> = (
  {
    yearlyRepayments,
    yearlyInstalmentSum,
    yearlyInterestSum,
    yearlyAmortizationSum,
    yearlyOverpaymentSum,
    yearlyRemainingDebtSum,
    fixedRateEndYear,
    isAccordionVisible = true
  },
) => {
  const { t } = useTranslation('odf', { keyPrefix: 'financingFunnel.resultPage.loanDevelopmentSection.repaymentSchedule' })

  const repaymentsScheduleRows = useMemo(() => yearlyRepayments.map((repayment, index) =>
      (<RepaymentScheduleRow yearlyRepayment={repayment} key={index} displaySeparator={index === fixedRateEndYear}/>)),
    [ yearlyRepayments, fixedRateEndYear ])

  return (
    <div className='repayment-schedule grid-item float-none' data-testid='repayment-schedule'>
      <AccordionWrapper accordionTitle={t('title')} isAccordionVisible={isAccordionVisible}>
        <HorizontalScrollContainerWithShadow>
          <RepaymentScheduleHeader t={t}/>
          {repaymentsScheduleRows}
          <RepaymentScheduleFooter
            yearlyInstalmentSum={yearlyInstalmentSum}
            yearlyInterestSum={yearlyInterestSum}
            yearlyAmortizationSum={yearlyAmortizationSum}
            yearlyOverpaymentSum={yearlyOverpaymentSum}
            yearlyRemainingDebtSum={yearlyRemainingDebtSum}
            t={t}
          />
        </HorizontalScrollContainerWithShadow>
      </AccordionWrapper>
    </div>
  )
}

export default RepaymentSchedule
