import { useEffect, useState } from 'react'
import { fetchRandomProviders } from '../service'
import { MortgageProviderResult } from '../model'

export const useMortgageRandomProviders = (enabled: boolean): MortgageProviderResult => {

  const [ mortgageProviders, setMortgageProviders ] = useState<MortgageProviderResult>({ providers: [] })

  useEffect(() => {
    if (enabled) {
      fetchRandomProviders().then((result) => {
        setMortgageProviders(result)
      }).catch(() => {
        setMortgageProviders({ providers: [] })
      })
    }
  }, [ setMortgageProviders, enabled ])

  return mortgageProviders
}
