import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { FinancingTypeValue } from '../../../api/api/types/FinancingType'
import { ApplicationState } from '../../../redux/store'
import { RoadPage } from '../FinancingRequestRoad'
import { withPageViewReporting } from '../hoc/withTrackPageView'
import { LeadEngineState } from '../state/reducer'
import { onFinancingRequestSubmit } from '../state/thunks/app'
import { ButtonGroup } from './ButtonGroup'
import { StepWrapper } from './StepWrapper'
import { MenuBar } from '../MenuBar'
import {
  BuildingProjectStateExtendedType, ProjectStateExtended,
  PurchaseProjectStateExtendedType
} from '../../../api/api/types/ProjectStateExtended'
import { useNavigate } from 'react-router-dom'

const BuildingProjectValues: BuildingProjectStateExtendedType[] = [
  'PROPERTY_FOUND',
  'NOTARY_APPOINTMENT_PLANNED',
  'ON_BUILDER_SEARCH'
]

const PurchaseProjectValues: PurchaseProjectStateExtendedType[] = [
  'ON_OBJECT_SEARCH',
  'OBJECT_INTERESTED',
  'OBJECT_VIEWING',
  'PURCHASING_NEGOTIATIONS',
  'AWARDED',
  'NOTARY_APPOINTMENT_PLANNED'
]

const ProjectStatePage: React.FC<{ nextPage: string }> = ({ nextPage }) => {
  const {
    financing: {
      financingType,
      projectState
    }
  } = useSelector<ApplicationState, LeadEngineState>(state => state.leadEngine)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation('leadEngine')

  const values: ProjectStateExtended[] = financingType === FinancingTypeValue.PurchaseFinancing ? PurchaseProjectValues : BuildingProjectValues

  const handleOnClick = (projectState: ProjectStateExtended) =>
    dispatch(onFinancingRequestSubmit(nextPage, navigate)({ projectState }))

  return (<>
    <MenuBar page={RoadPage.PROJECT_STATE} hideClose/>
    <StepWrapper title={t('projectState.title')}>
      <ButtonGroup<ProjectStateExtended>
        values={values}
        onClick={handleOnClick}
        value={projectState}
      />
    </StepWrapper>
  </>)
}

export default withPageViewReporting(ProjectStatePage, 'projectstate')
