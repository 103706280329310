import React from 'react'
import Carousel from './carousel/Carousel'
import './MortgageProvidersTeaserWidget.less'
import { MortgageProvider } from './model'
import CarouselCard from './carouselCard/CarouselCard'
import { useTranslation } from 'react-i18next'
import { useMortgageProviders } from './hook/useMortgageProviders'
import { useFeature } from '@optimizely/react-sdk'
import { Feature } from '../../../config/optimizely'
import { useMortgageRandomProviders } from './hook/useMortgageRandomProviders'

const MortgageProvidersTeaserWidget: React.FC = () => {
  const { t } = useTranslation('widgets')

  const [ useRandomProviders, /*unused*/, optimizelyReady, optimizelyTimedOut ] = useFeature(Feature.USE_RANDOM_PROVIDERS_ENDPOINT)

  const { providers: offerProviders } = useMortgageProviders('1276003001046', (!useRandomProviders && optimizelyReady) || optimizelyTimedOut)
  const { providers: randomProviders } = useMortgageRandomProviders((useRandomProviders && optimizelyReady))

  const providers = [ ...offerProviders, ...randomProviders ]

  return (
    <div className='MortgageProvidersTeaserWidget'>
      <Carousel>
        {providers.map((provider: MortgageProvider) =>
          <div key={provider.id}>
            <CarouselCard provider={provider}/>
          </div>)
        }
      </Carousel>
      <div className='BottomLink'>
        <span className='with-icon'>
					<span className='is24-icon-chevron-right'></span>
					<a href='/baufinanzierung/'>{t('mortgageProvidersTeaser.link')}</a>
				</span>
      </div>
    </div>
  )
}

export default MortgageProvidersTeaserWidget
