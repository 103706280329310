export enum FinancingResultError {
  LOAN_AMOUNT_TO_LOW = 'LOAN_AMOUNT_TO_LOW',
  COULD_NOT_CALCULATE = 'COULD_NOT_CALCULATE',
  AGE_GROUP_NOT_SUPPORTED = 'AGE_GROUP_NOT_SUPPORTED'
}

export enum FixedRateEndYear {
  FIVE_YEARS = 5,
  TEN_YEARS = 10,
  FIFTEEN_YEARS = 15,
  TWENTY_YEARS = 20,
  THIRTY_YEARS = 30,
  FORTY_YEARS = 40,
}

export const DEFAULT_FIXED_RATE_END_YEAR = 10
export const DEFAULT_AMORTIZATION_RATE = 2

export const fixedRateEndYearValues = () => {
  return Object.keys(FixedRateEndYear)
    .filter(value => !isNaN(Number(value)))
    .map(key => Number(key));
}

export type YearlyRepayment = {
  year: number,
  yearlyInstallment: number,
  yearlyInterest: number,
  yearlyAmortization: number,
  yearlyOverpayment: number,
  yearlyRemainingDebt: number,
  monthlyRepayments?: MonthlyRepayment[]
}

export type MonthlyRepayment = {
  month: number,
  monthlyInstallment: number,
  monthlyInterest: number,
  monthlyAmortization: number,
  monthlyOverpayment: number,
  monthlyRemainingDebt: number
}

export enum RepaymentType {
  YEARLY = 'YEARLY',
  ONCE = 'ONCE'
}

export type FinancingResult = {
  monthlyRemainingDebtAmounts: number[]
  totalTimeMonths: number
  monthlyInstalment: number
  averageInterestRate: number
  effectiveInterestRate: number
  loanAmount: number
  yearlyRepayments: YearlyRepayment[]
  monthlyInstalmentSum: number
  interestSum: number
  amortizationSum: number
  overpaymentSum: number
  remainingDebtSum: number
  fixedRateEndYear: number
}

export type UserInput = {
  amortizationRate: number
  fixedRateEndYear: number
  repaymentAmount: number
  repaymentType: RepaymentType
  repaymentYearOrMonth: number
}

export const userInputDefaults: Readonly<UserInput> = {
  amortizationRate: DEFAULT_AMORTIZATION_RATE,
  fixedRateEndYear: DEFAULT_FIXED_RATE_END_YEAR,
  repaymentAmount: 0,
  repaymentType: RepaymentType.YEARLY,
  repaymentYearOrMonth: 1,
}

export type SummaryOnFixedRateEnd = {
  totalPaid: number;
  totalInterestPaid: number;
  totalAmortizationPaid: number;
  remainingLoanAmount: number;
}

export type SummaryOnDebtEnd = {
  totalOverpayment: number;
  totalInterestAmount: number;
  totalAmortizationAmount: number;
  totalAmount: number;
  numberOfInstalments: number;
}

export type MonthlyScheduleEntry = {
  year: number;
  month: number;
  interestAmount: number;
  amortizationAmount: number;
  instalment: number;
  overpaymentAmount: number;
  remainingLoanAmount: number;
  withinFixedInterestRate: boolean;
  usedInterestRate: number;
}

export type CalculateAmortizationResponse = {
  summaryOnFixedRateEnd: SummaryOnFixedRateEnd;
  summaryOnDebtEnd: SummaryOnDebtEnd;
  repaymentSchedule: MonthlyScheduleEntry[];
  averageInterestRate: number;
  effectiveInterestRate: number;
}
