import { Employment } from '../../../../api/api/types/Employment'
import { ContactChannel } from '../../../../api/api/types/ContactChannel.ds'
import { BuildingFinancingAO, PurchaseFinancingAO } from '../../../../api/api/types/InitialFinancingAOType.ds'
import { FollowupFinancingAO } from '../../../../api/api/types/FollowupFinancingAO.ds'
import { PurchaseFinancingType, PurchaseFinancingTypeValues } from '../../../../api/api/types/PurchaseFinancingType'
import {
  BuildingProjectState,
  BuildingProjectStateType, PurchaseProjectState,
  PurchaseProjectStateType
} from '../../../../api/api/types/ProjectState'
import { AppointmentRequestAO } from '../../../../api/api/types/AppointmentRequestAO'
import { Region } from '../../../common/input/regionSelection/RegionSelection'
import { AdditionalCostsInPercents } from '../model'
import { TitleType } from '../../../../api/api/types/TitleType'
import { FinancingUseType } from '../../../../api/api/types/FinancingUseType.ds'
import { IncomeRangeType } from '../../../../api/api/types/IncomeRange.ds'
import { AppointmentTimeRangeType } from '../../../../api/api/types/AppointmentTimeRanges'
import { FixedNominalInterestRateType } from '../../../../api/api/types/FixedNominalInterestRate'
import { FinancingType } from '../../../../api/api/types/FinancingType'
import { FinancingStartType } from '../../../../api/api/types/FinancingStartType'
import { SalutationType } from '../../../../api/api/types/Salutation.ds'
import { ProductTypeAO } from '../../../../api/api/types/ReferredOffersAO'

export type AppointmentRequestDto = {
  additionalCostsSum: number
  otherLoans: number
  primaryAppointmentDate?: Date
  houseNumber?: string
  regionExpose?: Region
  purchasePrice: number
  exposeId?: number
  remainingDebt: number
  title: TitleType
  ownCapital: number
  financingUseType: FinancingUseType
  forename?: string
  incomeRange?: IncomeRangeType
  surname?: string
  secondaryAppointmentTime: AppointmentTimeRangeType
  providerId?: number
  email?: string
  fixedRateEndYear: FixedNominalInterestRateType
  dateOfBirth?: string
  propertyValue: number
  message?: string
  financingType: FinancingType
  purchaseFinancing?: PurchaseFinancingType
  phoneNumber?: string
  primaryAppointmentTime: AppointmentTimeRangeType
  numberOfBorrowers: number
  financingStart?: FinancingStartType
  amortizationRate: number
  salutation: SalutationType
  region?: Region
  additionalCostsInPercents: AdditionalCostsInPercents
  secondaryAppointmentDate?: Date
  productType?: ProductTypeAO
}

export const mapToBuildingProjectState = (purchaseFinancing: PurchaseFinancingType): BuildingProjectStateType => {
  switch (purchaseFinancing) {
    case PurchaseFinancingTypeValues.ON_OBJECT_SEARCH:
      return BuildingProjectState.ON_BUILDER_SEARCH
    case PurchaseFinancingTypeValues.NOTARY_APPOINTMENT_PLANNED:
      return BuildingProjectState.NOTARY_APPOINTMENT_PLANNED
    case PurchaseFinancingTypeValues.OBJECT_FOUND:
      return BuildingProjectState.PROPERTY_FOUND
    default:
      return BuildingProjectState.NA
  }
}
const mapToPurchaseProjectState = (purchaseFinancing: PurchaseFinancingType): PurchaseProjectStateType => {
  switch (purchaseFinancing) {
    case PurchaseFinancingTypeValues.ON_OBJECT_SEARCH:
      return PurchaseProjectState.ON_OBJECT_SEARCH
    case PurchaseFinancingTypeValues.NOTARY_APPOINTMENT_PLANNED:
      return PurchaseProjectState.NOTARY_APPOINTMENT_PLANNED
    case PurchaseFinancingTypeValues.PURCHASING_NEGOTIATIONS:
      return PurchaseProjectState.PURCHASING_NEGOTIATIONS
    case PurchaseFinancingTypeValues.OBJECT_FOUND:
      return PurchaseProjectState.OBJECT_FOUND
    default:
      return PurchaseProjectState.NA
  }
}
const mapToFinancingAO = (appointmentRequestDto: AppointmentRequestDto): PurchaseFinancingAO | BuildingFinancingAO | FollowupFinancingAO => {
  if (appointmentRequestDto.financingType === 'PurchaseFinancing') {
    const purchaseFinancingAO: PurchaseFinancingAO = {
      type: appointmentRequestDto.financingType,
      financingStart: appointmentRequestDto.financingStart,
      purchasePrice: appointmentRequestDto.purchasePrice,
      ownFunds: appointmentRequestDto.ownCapital,
      additionalCosts: appointmentRequestDto.additionalCostsSum,
      additionalCostsPercentages: {
        notaryCostsPercentage: appointmentRequestDto.additionalCostsInPercents?.notaryCosts,
        entryLandRegisterPercentage: appointmentRequestDto.additionalCostsInPercents?.landRegistration,
        landTransferPercentage: appointmentRequestDto.additionalCostsInPercents?.landTransfer,
        brokerCommissionPercentage: appointmentRequestDto.additionalCostsInPercents?.brokerCommission
      },
      projectState: mapToPurchaseProjectState(appointmentRequestDto.purchaseFinancing!)
    }
    return purchaseFinancingAO
  } else if (appointmentRequestDto.financingType === 'BuildingFinancing') {
    const buildingFinancingAO: BuildingFinancingAO = {
      type: appointmentRequestDto.financingType,
      financingStart: appointmentRequestDto.financingStart,
      purchasePrice: appointmentRequestDto.purchasePrice,
      ownFunds: appointmentRequestDto.ownCapital,
      projectState: mapToBuildingProjectState(appointmentRequestDto.purchaseFinancing!)
    }
    return buildingFinancingAO
  } else {
    const followupFinancingAO: FollowupFinancingAO = {
      type: appointmentRequestDto.financingType,
      financingStart: appointmentRequestDto.financingStart,
      remainingDebt: appointmentRequestDto.remainingDebt,
      propertyValue: appointmentRequestDto.propertyValue,
    }
    return followupFinancingAO;
  }
}
export const createAppointmentRequest = (appointmentRequestDto: AppointmentRequestDto): AppointmentRequestAO => {
  return {
    contactRequest: {
      financingTerms: {
        amortizationRate: appointmentRequestDto.amortizationRate,
        geoCode: appointmentRequestDto.regionExpose?.geoCode,
        employment: Employment.EMPLOYED,
        fixedNominalInterestRate: appointmentRequestDto.fixedRateEndYear!,
        financing: mapToFinancingAO(appointmentRequestDto)
      },
      useType: appointmentRequestDto.financingUseType,
      salutation: appointmentRequestDto.salutation,
      surname: appointmentRequestDto.surname ?? '',
      forename: appointmentRequestDto.forename ?? '',
      email: appointmentRequestDto.email ?? '',
      phoneNumber: appointmentRequestDto.phoneNumber ?? '',
      message: appointmentRequestDto.message,
      contactChannel: ContactChannel.TELEPHONE,
      primaryAppointmentDate: appointmentRequestDto.primaryAppointmentDate?.getTime(),
      primaryAppointmentTime: appointmentRequestDto.primaryAppointmentTime,
      secondaryAppointmentDate: appointmentRequestDto.secondaryAppointmentDate?.getTime(),
      secondaryAppointmentTime: appointmentRequestDto.secondaryAppointmentTime,
      kfwPromotion: false,
      dpaAccepted: false,
      title: appointmentRequestDto.title!,
      contactAddress: {
        street: appointmentRequestDto.region!.street!,
        streetNumber: appointmentRequestDto.houseNumber!,
        postalCode: appointmentRequestDto.region!.postalCode!,
        location: appointmentRequestDto.region!.city!
      },
      exposeId: appointmentRequestDto.exposeId,
      netIncomeRange: appointmentRequestDto.incomeRange,
      dateOfBirth: appointmentRequestDto.dateOfBirth,
      numberOfBorrowers: appointmentRequestDto.numberOfBorrowers,
      currentCredits: appointmentRequestDto.otherLoans
    },
    referredOffers: [
      {
        providerId: appointmentRequestDto.providerId ?? 0,
        product: appointmentRequestDto.productType ?? ProductTypeAO.DEFAULT
      }
    ]
  }
}
