import React from 'react'
import { TFunction } from 'i18next'
import TableRow from './TableRow'

type RepaymentScheduleHeaderProps = {
  t: TFunction
}

export const RepaymentScheduleHeader: React.FC<RepaymentScheduleHeaderProps> = ({ t }) => {

  return (
    <TableRow
      expandable={false}
      className={'teal-border-bottom'}
      rowContent={
        <div>
          <div className='cell font-bold'> {t('period')}</div>
          <div className='cell font-bold'>{t('instalment')}</div>
          <div className='cell font-bold'> {t('interest')}</div>
          <div className='cell font-bold'>{t('amortization')}</div>
          <div className='cell font-bold'> {t('overpayment')}</div>
          <div className='cell font-bold'> {t('remainingDebt')}</div>
        </div>
      }
    />
  )
}


