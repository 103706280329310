import React, { useEffect } from 'react'

import './is24header.less'
import { MY_SCOUT_START } from '../../../config/contextPaths'
import { useTranslation } from 'react-i18next'
import { BackButton } from '../backButton/BackButton'
import { FOOTER_NAME, getHeaderName, loadHeaderFooter } from './loadHeaderFooter'

export type HeaderType = 'default' | 'light'

type Is24TemplateProps = {
  headerType?: HeaderType,
  backToOverview?: boolean,
  withFooter?: boolean,
  limitWidth?: boolean,
  children?: React.ReactNode,
}

export const Is24Template: React.FC<Is24TemplateProps> = (
  {
    headerType = 'default',
    children,
    backToOverview = false,
    withFooter = true,
    limitWidth = true,
  }
) => {

  const { t } = useTranslation('common', { keyPrefix: 'backButton' })

  const headerName = getHeaderName(headerType)
  useEffect(() => {
    loadHeaderFooter(headerName)
  }, [headerName])


  return (
    <div className='viewport'>
      <div
        className='page-wrapper page-wrapper--full-width top-navigation-slide-in-container corporate-powered-by-container'>
        <div className={`inject ${headerName}`}></div>

        <div className='content-wrapper content-wrapper--background'>
          <div className='grid grid-flex grid-fill-rows gutter desk-padding-top-l padding-bottom-l'>
            <div className={`grid-item ${limitWidth ? 'one-whole' : ''}`}>
              {backToOverview && <BackButton href={MY_SCOUT_START} label={t('toOverview')}/>}
              {children}
            </div>
          </div>
        </div>
      </div>
      {withFooter && <div className={`inject ${FOOTER_NAME}`}></div>}
    </div>
  )
}
