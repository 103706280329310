import { useTranslation } from 'react-i18next'
import { RadioButton } from 'is24-corecss'
import React from 'react'
import { formatNumber } from '../../../../services/NumberUtils'
import { NumberInput } from '../../../common/input/numericInput'
import { CostsInput } from './index'

export const MoreInfoRadioOptions = {
  apartment: 'apartment',
  house: 'house'
}

type MoreInfoValues = {
  appreciation: number,
  operatingCosts: number,
  opportunityInterestRate: number,
  years: number,
  propertyValue: number,
  maintenanceCostHouse: number,
  floorArea: number,
  objectType: string
}

type MoreInfoProps = {
  onChange: (name: keyof MoreInfoValues, value: number | string) => void,
  floorValue: number,
} & MoreInfoValues


const MoreInfo: React.FC<MoreInfoProps> =
  ({
     onChange,
     appreciation,
     operatingCosts,
     opportunityInterestRate,
     years,
     propertyValue,
     maintenanceCostHouse,
     floorArea,
     floorValue,
     objectType
   }) => {
    const { t } = useTranslation('widgets')

    const handleChange = (key: keyof MoreInfoValues) =>
      (v: number | string) => onChange(key, v)

    return (
      <>
        <div className='padding-top-l top-divider bottom-divider'>
          <p>{t('rentVsBuy.secondary.furtherInfo.title')}</p>

          <CostsInput
            label={<>{t('rentVsBuy.secondary.furtherInfo.appreciation')} <b
              className='padding-horizontal-xs'>{years}</b> {t('rentVsBuy.years', {count: years})}</>}
            id='appreciation'
            onChange={handleChange('appreciation')}
            input={appreciation}
          />
          <CostsInput
            label={t('rentVsBuy.secondary.furtherInfo.operatingCosts')}
            id='operatingCosts'
            onChange={handleChange('operatingCosts')}
            input={operatingCosts}
          />
          <CostsInput
            isPercentage={true}
            label={t('rentVsBuy.secondary.furtherInfo.opportunityInterestRate')}
            id='opportunityInterestRate'
            onChange={handleChange('opportunityInterestRate')}
            input={opportunityInterestRate}
          />
        </div>
        <div className='padding-top-l'>
          <p>{t('rentVsBuy.secondary.moreInfo.title')}</p>

          <div className='grid grid-flex margin-vertical-l'>
            <div className='three-tenths'>
              {t('rentVsBuy.secondary.moreInfo.objectType')}
            </div>
            <div>
              <RadioButton
                id={MoreInfoRadioOptions.house}
                label={t('rentVsBuy.secondary.moreInfo.house')}
                name='objectType'
                defaultChecked={objectType === MoreInfoRadioOptions.house}
                onChange={() => handleChange('objectType')(MoreInfoRadioOptions.house)}
              />
              <RadioButton
                id={MoreInfoRadioOptions.apartment}
                label={t('rentVsBuy.secondary.moreInfo.apartment')}
                name='objectType'
                defaultChecked={objectType === MoreInfoRadioOptions.apartment}
                onChange={() => handleChange('objectType')(MoreInfoRadioOptions.apartment)}
              />
            </div>
          </div>
          <CostsInput
            label={<>{t('rentVsBuy.secondary.moreInfo.estimatedValue')} <b
              className='padding-horizontal-xs'>{years}</b> {t('rentVsBuy.years', {count: years})}</>}
            id='propertyValue'
            onChange={handleChange('propertyValue')}
            input={propertyValue}
          />
          {objectType === MoreInfoRadioOptions.house ?
            <CostsInput
              label={t('rentVsBuy.secondary.moreInfo.maintenanceCosts')}
              id='maintenanceCostHouse'
              onChange={handleChange('maintenanceCostHouse')}
              input={maintenanceCostHouse}
            />
            :
            <>
              <div className='margin-vertical-l grid grid-flex grid-align-center grid-justify-space-between'>
                <div className='grid grid-flex six-tenth palm-five-tenths'>
                  {t('rentVsBuy.secondary.moreInfo.floorArea')}
                </div>
                <div className='palm-two-fifths rent-vs-buy-input'>
                  <NumberInput
                    id='floorArea'
                    placeholder='0'
                    label=''
                    value={floorArea}
                    onChange={handleChange('floorArea')}
                    unitRight='sqm'
                    className='align-right'
                  />
                </div>
                <div className='one-tenth palm-one-fifth'/>
              </div>
              <div className='eight-tenth palm-one-whole grid grid-flex grid-justify-space-between'>
                <div className='palm-one-third desk-two-sixth'>
                  {t('rentVsBuy.secondary.moreInfo.houseBenefit')}
                </div>
                <div className='palm-one-third desk-two-sixth'>{t('rentVsBuy.secondary.moreInfo.monthly')}</div>
                <div className='palm-one-third desk-two-eighth grid grid-flex grid-align-center'>
                  <div className='margin-right-s big-text'>
                    {formatNumber(floorArea * floorValue)} €
                  </div>
                  <div>{`${t('rentVsBuy.secondary.moreInfo.near')} ${formatNumber(floorValue, 2)} € / m²`}</div>
                </div>
              </div>
            </>
          }
        </div>
      </>
    )
  }

export default MoreInfo
