import trackingService from '../../../../services/TrackingService/TrackingService'

export const reportAppointmentRequestCreatedEvent = (contactRequestId: number) =>
  trackingService.reportEvent(
    'finanzierungsrechner',
    'finance',
    'finance_calculator',
    'lead',
    {
      evt_lafid: contactRequestId,
      event_name: 'lead_submit',
      event_product: 'finance',
      event_parameter_1: 'finance_calculator'
    }
  )
