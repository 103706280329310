import { ButtonGroup } from './ButtonGroup'
import { StepWrapper } from './StepWrapper'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { RoadPage } from '../FinancingRequestRoad'
import { BorrowerRelationshipType } from '../../../api/api/types/BorrowerRelationship.ds'
import { withPageViewReporting } from '../hoc/withTrackPageView'
import { onFinancingRequestSubmit } from '../state/thunks/app'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../../redux/store'
import { LeadEngineState } from '../state/reducer'
import { MenuBar } from '../MenuBar'
import { useNavigate } from 'react-router-dom'


const BorrowerRelationPage: React.FC<{ nextPage: string }> = ({ nextPage }) => {
  const { t } = useTranslation('leadEngine')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { financing: { borrowerRelationship } } = useSelector<ApplicationState, LeadEngineState>(state => state.leadEngine)

  const handleNextPage = (borrowerRelationship: BorrowerRelationshipType) => dispatch(onFinancingRequestSubmit(nextPage, navigate)({ borrowerRelationship }))

  const values: BorrowerRelationshipType[] = [
    'MARRIED',
    'LIVING_TOGETHER',
    'OTHER',
  ]

  return (<>
    <MenuBar page={RoadPage.BORROWER_RELATION}/>
    <StepWrapper title={t('borrowerRelationship.title')}>
      <ButtonGroup<BorrowerRelationshipType>
          values={values}
          onClick={handleNextPage}
          value={borrowerRelationship}/>
    </StepWrapper>
  </>)
}

export default withPageViewReporting(BorrowerRelationPage, 'ext.borrower_relation')
