import { config } from './components'
import { RentVsBuyProps } from './RentVsBuy'

export const getPropertyCosts = (price: number) => Math.round((price * 15) / 100)
export const getMaintenanceCostHouse = (price: number) => price / 100 / 12
export const getMaintenanceCostsApartment = (floorArea: number) => floorArea * config.floorValue;
export const getAdditionalCosts = ({
                              transferTaxValue,
                              brokerageCommissionValue,
                              notaryFeesValue,
                              landRegisterValue,
                              misc
                            }: RentVsBuyProps) =>
  transferTaxValue + brokerageCommissionValue + notaryFeesValue + landRegisterValue + misc

export const getAdditionalCostsValue = (value: number, price: number) => price / 100 * value

type Action = { payload: Partial<RentVsBuyProps> }

export const RentVsBuyReducer = (state: RentVsBuyProps, action: Action): RentVsBuyProps => {
  const newState = { ...state, ...action.payload }

  if(action.payload.price) {
    newState.propertyValue = getPropertyCosts(newState.price) + newState.price
    newState.maintenanceCostHouse = getMaintenanceCostHouse(newState.price)
  }

  const newEquityMax = newState.price - config.priceEquityMinDifference >= 0 ? newState.price - config.priceEquityMinDifference : 0
  newState.equityMax = newEquityMax
  newState.equity = newState.equity > newState.equityMax ? newEquityMax : newState.equity

  newState.maintenanceCostApartment = getMaintenanceCostsApartment(newState.floorArea)

  newState.notaryFeesValue = getAdditionalCostsValue(newState.notaryFees, newState.price)
  newState.landRegisterValue = getAdditionalCostsValue(newState.landRegister, newState.price)
  newState.brokerageCommissionValue = getAdditionalCostsValue(newState.brokerageCommission, newState.price)
  newState.transferTaxValue = getAdditionalCostsValue(newState.transferTax, newState.price)
  newState.additionalCosts = getAdditionalCosts(newState)

  return newState
}
