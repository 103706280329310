import React, { ReactChild } from 'react'
import './FinancingCostRow.less'

export type FinancingCostRowProps = {
  label: string
  value: ReactChild
  borderBottom?: boolean
}

const FinancingCostRow: React.FC<FinancingCostRowProps> = ({ label, value, borderBottom = true }) => {
  return (
    <div className={`FinancingCostRow ${borderBottom ? 'border-bottom' : ''}`}>
      <div className='Label'>{label}</div>
      <div className='Value'>{value}</div>
    </div>
  )
}

export default FinancingCostRow
