export const AppointmentTimeRangeTypes = {
  'TEN_UNTIL_ELEVEN': 'TEN_UNTIL_ELEVEN',
  'ELEVEN_UNTIL_TWELVE': 'ELEVEN_UNTIL_TWELVE',
  'TWELVE_UNTIL_THIRTEEN': 'TWELVE_UNTIL_THIRTEEN',
  'THIRTEEN_UNTIL_FOURTEEN': 'THIRTEEN_UNTIL_FOURTEEN',
  'FOURTEEN_UNTIL_FIFTEEN': 'FOURTEEN_UNTIL_FIFTEEN',
  'FIFTEEN_UNTIL_SIXTEEN': 'FIFTEEN_UNTIL_SIXTEEN',
  'SIXTEEN_UNTIL_SEVENTEEN': 'SIXTEEN_UNTIL_SEVENTEEN',
  'SEVENTEEN_UNTIL_EIGHTEEN': 'SEVENTEEN_UNTIL_EIGHTEEN',
  'EIGHTEEN_UNTIL_NINETEEN': 'EIGHTEEN_UNTIL_NINETEEN',
  'NINETEEN_UNTIL_TWENTY': 'NINETEEN_UNTIL_TWENTY'
} as const

export type AppointmentTimeRangeType = keyof typeof AppointmentTimeRangeTypes
