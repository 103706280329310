export const FollowUpFinancingStartTypeValues = {
  IN_LESS_THAN_ONE_YEAR: 'IN_LESS_THAN_ONE_YEAR',
  IN_ONE_YEAR: 'IN_ONE_YEAR',
  IN_TWO_YEARS: 'IN_TWO_YEARS',
  IN_THREE_YEARS: 'IN_THREE_YEARS',
  IN_FOUR_YEARS: 'IN_FOUR_YEARS',
  IN_FIVE_YEARS: 'IN_FIVE_YEARS'
} as const

export type FollowUpFinancingStartType = keyof typeof FollowUpFinancingStartTypeValues
