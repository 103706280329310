import React from 'react'
import ReactApexChart from 'react-apexcharts'
import './CircleWithPercent.less'

const DIAMETER = 150

export type CircleWithPercentProps = {
  percent: number
}

export const CircleWithPercent: React.FC<CircleWithPercentProps> = ({ percent }) => {
  return (
    <div className='CircleWithPercent'>
      <ReactApexChart
        options={
          {
            chart: {
              id: 'circle-with-percent',
              animations: {
                enabled: false
              },
              height: DIAMETER,
              type: 'radialBar',
            },
            plotOptions: {
              radialBar: {
                hollow: {
                  margin: 0,
                  size: '55%'
                },
                track: {
                  strokeWidth: '6px',
                  background: '#E0E0E0',
                  show: true
                },
                dataLabels: {
                  name: {
                    show: false
                  },
                  value: {
                    fontSize: '20',
                    fontFamily: 'Make It Sans IS24 Web',
                    offsetY: 8,
                    formatter: (val: number): string => `${val} %`
                  }
                }
              },
            },
            legend: {
              show: false,
            },
            colors: [ '#00D9B1' ]
          }}
        series={[ percent ]}
        type='radialBar'
        height={DIAMETER}
        width={DIAMETER}
      />
    </div>
  )
}
