import { useTranslation } from 'react-i18next'
import React from 'react'
import { Loader } from 'is24-corecss'
import { formatNumber } from '../../../../services/NumberUtils'

const BASE_URL = 'https://www.immobilienscout24.de/Suche/controller/executeSearch/region'


export type ChartProps = {
  buyCosts: number
  rentCosts: number
  geoCode: string
  purchasePrice: number
  realEstateType: string //maybe change to 'House' | 'Apartment' later on
  isValidLocation: boolean
  loading: boolean
  onChange: (name: string, value: boolean) => void
}

const Chart: React.FC<ChartProps> =
  ({
     rentCosts,
     buyCosts,
     geoCode,
     purchasePrice,
     realEstateType,
     onChange,
     isValidLocation,
     loading
   }) => {
    const { t } = useTranslation('widgets')

    const retrieveHeight = (cost: number) => {
      const sum = buyCosts + rentCosts
      const value = cost * 100 / sum
      const result = Math.min(value, 100)
      return Math.round(result * 100) / 100
    }

    const buyHeight = retrieveHeight(buyCosts)
    const rentHeight = retrieveHeight(rentCosts)

    const checkRegion = (e: any) => {
      if (!isValidLocation || (isValidLocation && geoCode === '')) {
        e.preventDefault();
        onChange('isValidLocation', false)
      }
    }

    return (
      <div className='grid-flex grid-justify-space-around grid-align-end margin-bottom-l chart'>
        <a
          onClick={(e) => checkRegion(e)}
          href={`${BASE_URL}?realestatetype=${realEstateType}buy&price=0-${purchasePrice}&geocodes=${geoCode}`}
          target='_blank'
          rel='noreferrer'
          className={`two-fifths padding-m chart-bar-container ${rentHeight < buyHeight ? 'lower' : 'higher'}`}
          style={{ height: `${buyHeight}%` }}>
          <div className='grid-flex grid-align-center chart-bar'>
          <span>
            {t('rentVsBuy.chart.purchaseCosts')}
          </span>
            <span>
            {loading ? <Loader size='small'/> : <b>{formatNumber(buyCosts)} €</b>}
          </span>
            <span>
            {t('rentVsBuy.chart.monthly')}
          </span>
          </div>
          <div className='grid-flex grid-justify-center'>
            <i className='is24-icon-house-with-loupe font-l'/>
            <p className='bar-link'>{t('rentVsBuy.chart.saleProperties')}</p>
          </div>
        </a>
        <a
          onClick={(e) => checkRegion(e)}
          href={`${BASE_URL}?realestatetype=${realEstateType}rent&price=0-${purchasePrice}&geocodes=${geoCode}`}
          target='_blank'
          rel='noreferrer'
          className={`two-fifths padding-m chart-bar-container ${buyHeight < rentHeight ? 'lower' : 'higher'}`}
          style={{ height: `${rentHeight}%` }}>
          <div className='grid-flex grid-align-center chart-bar'>
            <span>{t('rentVsBuy.chart.rentalCosts')}</span>
            <span>
              <b>{formatNumber(rentCosts)} €</b>
            </span>
            <span>{t('rentVsBuy.chart.monthly')}</span>
          </div>
          <div className='grid-flex grid-justify-center'>
            <i className='is24-icon-house-with-loupe font-l'/>
            <p className='bar-link'>
              {t('rentVsBuy.chart.rentalProperties')}
            </p>
          </div>
        </a>
      </div>
    )
  }

export default Chart
