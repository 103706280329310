import { Button, Modal } from 'is24-corecss'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

export const ModalDisclaimer = ({open, onClose}: {open: boolean, onClose: Function}) => {
  const { t } = useTranslation('leadEngine')

  return (
    <Modal visible={open} onClose={() => onClose()} title={t('modalDisclaimer.title')}>
      <>
        <span>
          <Trans t={t} i18nKey='modalDisclaimer.text1'>
            <a href="//www.immobilienscout24.de/baufinanzierung/?cmp_id=10-04209&cmp_name=finance_profile&cmp_position=finance_calculator&cmp_creative=finance_consent_link" target="_blank" rel="noreferrer">link</a>
          </Trans>
          <Trans t={t} i18nKey='modalDisclaimer.text2'>
            <a href="//www.immobilienscout24.de/agb/datenschutz.html" target="_blank" rel="noreferrer">link</a>
          </Trans>
        </span>
        <br />
        <br />

        <p>{t('modalDisclaimer.text3')}</p>

        <div className="align-center padding-vertical">
          <Button onClick={() => onClose()} appearance="secondary">
            {t('modalDisclaimer.close')}
          </Button>
        </div>
      </>
    </Modal>
  )
}
