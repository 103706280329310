import { getRequest } from '../request'
import { API_V1_URL } from '../contextPaths'

/**
 * Method: GET
 * Path: /baufinanzierung-api/restapi/api/financing/construction/v1.0/childbenefits
 * QueryParams:
 *  - numberOfChildren=2
 */
export const getChildBenefits = (numberOfChildren: number): Promise<{ childBenefits: number }> => {
  return getRequest({ url: `${API_V1_URL}/childbenefits?numberOfChildren=${numberOfChildren || 0}` })
}

/**
 * Method: GET
 * Path: /baufinanzierung-api/restapi/api/financing/construction/v1.0/livingcosts
 * QueryParams:
 *  - householdsize=4
 *  - totalincome=508
 */
export const getLivingCosts= (householdSize: number, totalIncome: number): Promise<{livingCosts: number}> =>{
  return getRequest({url: `${API_V1_URL}/livingcosts?householdsize=${householdSize}&totalincome=${totalIncome}`})
}
