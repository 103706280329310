import React, { ReactElement } from 'react'
import { Tooltip, TooltipContainer, TooltipTarget } from 'is24-corecss'

import './CustomTooltip.less'

export type CustomTooltipProps = {
  title?: string,
  text: string | ReactElement,
  arrowPosition?: 'left' | 'center' | 'right',
  bodyPosition?: 'left' | 'right' | 'top' | 'bottom'
  minWidth?: number
  fontSize?: 'font-m' | 'font-l' | 'font-xl'
}

export const CustomTooltip: React.FC<CustomTooltipProps> = (
  {
    title,
    text,
    bodyPosition,
    arrowPosition,
    minWidth = 300,
    fontSize = 'font-m'
  }) => (
  <TooltipContainer>
    <TooltipTarget>
      <i className={`is24-icon-info ${fontSize}`}/>
    </TooltipTarget>
    <div role='tooltip'>
      <Tooltip style={{ minWidth: minWidth }}
               arrowPosition={arrowPosition}
               bodyPosition={bodyPosition}>
        {title && (<><strong>{title}</strong><br/></>)}
        <span>{text}</span>
      </Tooltip>
    </div>
  </TooltipContainer>
)

export default CustomTooltip
