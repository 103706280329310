import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../../redux/store'
import { LeadEngineState } from '../state/reducer'
import { BorrowerDataForm } from './BorrowerDataForm'
import { onBorrowersDataSubmit } from '../state/thunks/app'
import { Borrower } from '../models/Financing'
import { withPageViewReporting } from '../hoc/withTrackPageView'
import { RoadPage } from '../FinancingRequestRoad'
import { useNavigate } from 'react-router-dom'

type SecondaryBorrowerFormType = {
  nextPage: string
}

const SecondaryBorrowerForm: React.FC<SecondaryBorrowerFormType> = ({ nextPage }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {
    borrowers: {
      secondary: secondaryBorrower
    }
  } = useSelector<ApplicationState, LeadEngineState>(state => state.leadEngine)

  const onNextPage = (data: Borrower) =>
    dispatch(onBorrowersDataSubmit(nextPage, { secondary: data as Partial<Borrower> }, navigate))

  return <BorrowerDataForm borrowerNumber='2'
                           borrower={secondaryBorrower || {}}
                           onSubmit={onNextPage}
                           page={RoadPage.BORROWERS_DATA_TWO}
  />
}

export default withPageViewReporting(SecondaryBorrowerForm, 'ext.borrower2_personaldata')
