import React, { ChangeEvent, CSSProperties, RefObject, useRef, useState } from 'react'
import { TextInput } from 'is24-corecss'

import './DateInput.less'

type DateInputType = {
  defaultValue?: Date | string
  minDate?: number
  maxDate?: number
  onChange: (date?: Date) => void
  onBlur?: (e: ChangeEvent<HTMLInputElement>) => void
  label?: string
  labelDay?: string
  labelMonth?: string
  labelYear?: string
  placeholderDay?: string
  placeholderMonth?: string
  placeholderYear?: string
  showError?: boolean
  disabled?: boolean
  id?: string
  style?: CSSProperties
  className?: string
  inputRef?: RefObject<HTMLFieldSetElement>
}

export const DateInput: React.FC<DateInputType> = ({
 defaultValue, minDate = Date.parse('1900-01-01'), maxDate  = Date.now(),
 onBlur, onChange, showError = true,
 label, labelDay = '', labelMonth = '', labelYear = '',
 placeholderDay = '', placeholderMonth = '', placeholderYear = '',
 disabled = false, inputRef, className = '', id = '', style = {}
}) => {
  const [ touched, setTouched ] = useState<string[]>([])
  const [ dateInputState, setDateInputState ] = useState<{ day?: number, month?: number, year?: number }>(dateFromString(defaultValue))
  const { day = '', month = '', year = ''} = dateInputState

  const dayRef = useRef<HTMLInputElement>(null)
  const monthRef = useRef<HTMLInputElement>(null)
  const yearRef = useRef<HTMLInputElement>(null)

  const MAX_DAY = 31
  const MAX_MONTH = 12

  const hasYear4Digits = (year: string) => year.length === 4

  const isDateValid = (newState?: { day?: number, month?: number, year?: number }): boolean => {
    const { day, month, year } = newState || dateInputState

    if (!year || !month || !day) {
      return false
    }

    const leapYear = (): boolean =>  ((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0)
    const isDateWithin = (value: number, min: number, max: number): boolean => value >= min && value <= max
    const isValidLeapDay = () =>
      Number(month) === 2 ?
        (leapYear() ? Number(day) <= 29 : Number(day) <= 28) : true

    const date = Date.parse(`${month}/${day}/${year}`)

    return !isNaN(date) &&
          isValidLeapDay() &&
          isDateWithin(date, minDate, maxDate) &&
          hasYear4Digits(year.toString())
  }

  const dayInputAction = (value: string) => {
    if(value.length === 2 || +value > 3) { monthRef.current?.focus() }
  }

  const dayInputValidator = (value: string): boolean => {
    const n = +value
    return !isNaN(n) && n > 0 && n <= MAX_DAY
  }

  const monthInputAction = (value: string) => {
    if(value.length === 2 || +value >= 2) { yearRef.current?.focus() }
  }

  const monthInputValidator = (value: string): boolean => {
    const n = +value
    return !isNaN(n) && n > 0 && n <= MAX_MONTH
  }

  const yearInputAction = (value: string) => {
    const minYearForAction = new Date(minDate).getFullYear()
    if(hasYear4Digits(value) && +value > minYearForAction) { yearRef.current?.blur() }
  }

  const yearInputValidator = (value: string): boolean => {
    const n = +value
    const startsWith = value.startsWith('1') || value.startsWith('2')
    return startsWith && !isNaN(n)
  }

  const handleChange = (inputAction: (value: string) => void, validator: (value: string) => boolean) =>
      (e: ChangeEvent<HTMLInputElement>): void => {
      const { name, value } = e.target

      if(value === '' || validator(value)) {
        const newState = { ...dateInputState, [ name ]: value }

        if(isDateValid(newState)) {
          onChange(dateFromState({ ...newState }))
        } else {
          onChange()
        }

        inputAction(value)
        setDateInputState(newState)
      }
  }

  const handleBlur = (name: string) => () => {
    const newTouched: string[] = [ ...new Set([ ...touched, name ]) ]
    setTouched(newTouched)
    // @ts-ignore
    newTouched.length === 3 && onBlur && onBlur({ target: { id } })
  }

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => e.target.select()

  const fieldId = (name: string): string => id ? `${id}-${name}` : name

  const valid = !showError || (showError && isDateValid())

  return <div className={`date-input grid grid-flex ${className}`} style={style}>
    {label && <label className='font-bold'>{label}</label>}
    <fieldset id={id} ref={inputRef}>
      <div className='date-input-field grid-item three-tenth padding-right-s'>
        <TextInput
          aria-label="day"
          aria-invalid={!valid}
          id={fieldId('day')}
          name='day'
          type='number'
          className='align-right'
          maxLength={2}
          ref={dayRef}
          label={labelDay}
          value={day}
          placeholder={placeholderDay}
          valid={valid}
          onChange={handleChange(dayInputAction, dayInputValidator)}
          onFocus={handleFocus}
          onBlur={handleBlur('day')}
          disabled={disabled}
        />
      </div>
      <div className='date-input-field grid-item three-tenth padding-right-s'>
        <TextInput
          aria-label="month"
          aria-invalid={!valid}
          id={fieldId('month')}
          name='month'
          type='number'
          className='align-right'
          maxLength={2}
          ref={monthRef}
          label={labelMonth}
          value={month}
          placeholder={placeholderMonth}
          valid={valid}
          onChange={handleChange(monthInputAction, monthInputValidator)}
          onFocus={handleFocus}
          onBlur={handleBlur('month')}
          disabled={disabled}
        />
      </div>
      <div className='date-input-field grid-item four-tenth'>
        <TextInput
          aria-label="year"
          aria-invalid={!valid}
          id={fieldId('year')}
          name='year'
          type='number'
          className='align-right'
          maxLength={4}
          ref={yearRef}
          value={year}
          label={labelYear}
          placeholder={placeholderYear}
          valid={valid}
          onChange={handleChange(yearInputAction, yearInputValidator)}
          onFocus={handleFocus}
          onBlur={handleBlur('year')}
          disabled={disabled}
        />
      </div>
    </fieldset>
  </div>
}

const dateFromString = (date?: Date | string) => {
  const dateObj = typeof date === 'string' ? !isNaN(Date.parse(date)) ? new Date(date) : undefined : date

  const day = dateObj?.getDate()
  const month = dateObj ? dateObj.getMonth() + 1 : undefined
  const year = dateObj?.getFullYear()
  return { day, month, year }
}

const dateFromState = (obj: any): Date => {
  const { day, month, year } = obj
  const newDate = Date.parse(`${month}/${day}/${year}`)
  return new Date(newDate)
}
