import { RANDOM_PROVIDERS_URL } from '../contextPaths'
import { getRequest } from '../request'

export type RandomProviderEntry = {
  providerId: number
  name: string
  image: string
  numberOfRatings: number
  averageRating: number
}

export type RandomProvidersResult = RandomProviderEntry[]

export const getRandomProviders = (): Promise<RandomProvidersResult> =>
  getRequest({ url: RANDOM_PROVIDERS_URL })
