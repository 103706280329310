import { HEADER_FOOTER_CONTENT_URL } from '../../../api/contextPaths'
import { HeaderType } from './Is24Template'

export const getHeaderName = (headerType: HeaderType) => headerType === 'default' ? 'is24-de-header-default' : 'is24-de-header-light'
export const FOOTER_NAME = 'is24-de-footer-small-default'

export const loadHeaderFooter = (headerName: string) => {
  loadScript(`${HEADER_FOOTER_CONTENT_URL}/${headerName}.js`)
  loadScript(`${HEADER_FOOTER_CONTENT_URL}/${FOOTER_NAME}.js`)
}

const loadScript = (url: string) => {
  const headerScript = document.createElement('script')

  headerScript.src = url
  headerScript.async = true

  document.body.appendChild(headerScript)
}
