import React, { ReactElement, ReactNode } from 'react';
import CustomTooltip, { CustomTooltipProps } from '../../../common/customTooltip/CustomTooltip';

export type FormRowProps = {
  title: string,
  titleComponent?: ReactNode,
  tooltip?: string | ReactElement,
  tooltipPosition?: CustomTooltipProps['bodyPosition']
  className?: string
  minWidthTooltip?: number,
  children?: ReactNode
}

const FormRow: React.FC<FormRowProps> = ({
                                           title,
                                           titleComponent,
                                           tooltip,
                                           tooltipPosition = 'top',
                                           className = '',
                                           children,
                                           minWidthTooltip = 20
                                         }) => {
  return <div className={`grid-item one-whole padding-horizontal-s ${className}`}>
    <div className='grid-item three-fifth padding-top grid-flex flex-nowrap'>
      {title}{titleComponent}
      {tooltip &&
        <CustomTooltip title={title} text={tooltip} bodyPosition={tooltipPosition} minWidth={minWidthTooltip}/>}
    </div>
    <div className='grid-item two-fifth'>
      {children}
    </div>
  </div>
}

export default FormRow
