import React, { MouseEvent, useCallback, useEffect, useRef, useState } from 'react'
import { ShortlistEntryAO } from '../../../api/api/types/ShortlistEntryAO'
import './list/ShortlistObject.less'
import './list/Shortlist.less'
import Shortlist from './list/Shortlist'
import { getShortlistEntries, toShortlistEntryAO } from './ShortlistService'
import './ShortlistDropdown.less'
import { useTranslation } from 'react-i18next'
import useOnClickedOutside from '../../../hooks/useOnClickedOutside'
import { redirectToSso } from '../../../services/SsoService'
import { isDefined } from '../../../utils/utils'
import { fetchExposeEntity } from '../../../api/api/Expose'

type Props = {
  loggedIn: boolean
  exposeId?: number,
  exposeFromShortlist?: boolean
  onSelectionChanged: (selectedEntry: ExposeEntry | undefined) => void
  loadShortlist?: () => Promise<ShortlistEntryAO[]>
  onLoadingEntries?: () => void
  onEntriesLoaded?: (entries: ShortlistEntryAO[]) => void
  ssoHandler?: () => void
  showDeselectButton?: boolean
  optionalPlaceholder?: boolean
}

export type ExposeEntry = ShortlistEntryAO & {
  externalExpose?: boolean
}

const ShortlistDropdown: React.FC<Props> = ({
  loggedIn,
  exposeId,
  exposeFromShortlist,
  onSelectionChanged,
  loadShortlist = getShortlistEntries,
  onLoadingEntries,
  onEntriesLoaded,
  ssoHandler = () => redirectToSso('shortlistDropdown'),
  showDeselectButton= false,
  optionalPlaceholder = false
}) => {
  const { t } = useTranslation()
  const [expanded, setExpanded] = useState(false)
  const [loading, setLoading] = useState(false)
  const [shortlistEntries, setShortlistEntries] = useState([] as ShortlistEntryAO[])
  const [exposeSelectedOutsideOfShortlist, setExposeSelectedOutsideOfShortlist] = useState<ExposeEntry | undefined>(undefined)
  const [combinedEntries, setCombinedEntries] = useState([] as ExposeEntry[])
  const [selectedEntry, setSelectedEntry] = useState<ExposeEntry | undefined>(undefined)
  const dropdownRef = useRef(null)
  const onClickedOutside = useCallback(() => setExpanded(false), [setExpanded])
  useOnClickedOutside(dropdownRef, onClickedOutside)

  useEffect(() => {
    onLoadingEntries?.();
    setLoading(true)
    loadShortlist().then(entries => {
      setShortlistEntries(entries.filter(entry => entry.publicationState === 'active'))
      onEntriesLoaded?.(entries)
    }).catch(error => {
      console.error(error)
      setShortlistEntries([])
      onEntriesLoaded?.([])
    }).finally(() => setLoading(false))
  }, [loadShortlist, onLoadingEntries, onEntriesLoaded])

  useEffect(() => {
    if (combinedEntries.length !== 0) {
      setSelectedEntry(combinedEntries.find(entry => entry.exposeId === exposeId))
    }
  }, [combinedEntries, exposeId])

  useEffect(() => {
    if (isDefined(exposeId) && exposeFromShortlist === false) {
      fetchExposeEntity(exposeId)
        .then(expose => {
          if (expose) {
            const shortlistEntry = toShortlistEntryAO(expose) as ShortlistEntryAO
            setExposeSelectedOutsideOfShortlist({
              ...shortlistEntry,
              externalExpose: true
            })
          }
        })
        .catch(console.error)
    }
  }, [ exposeId, exposeFromShortlist, setExposeSelectedOutsideOfShortlist ])

  useEffect(() => {
    if (exposeSelectedOutsideOfShortlist) {
      setCombinedEntries([ exposeSelectedOutsideOfShortlist, ...shortlistEntries ])
    } else {
      setCombinedEntries(shortlistEntries)
    }
  }, [exposeSelectedOutsideOfShortlist, shortlistEntries, setCombinedEntries ])

  const onSelect = (selected: ShortlistEntryAO | undefined) => {
    setSelectedEntry(selected)
    setExpanded(false)
    onSelectionChanged(selected)
  }
  const onUnselect = (event: MouseEvent<HTMLElement, Event>) => {
    event.stopPropagation()
    event.nativeEvent.stopPropagation()
    onSelect(undefined);
  }

  return (
    <div ref={dropdownRef} className="expose-shortlist-view">
      <div className="toggler" role="button" tabIndex={0} onClick={() => setExpanded(!expanded)}>
        { selectedEntry ?
          <>
            <div className='selected-entry-wrapper'>
              <div className='selected-entry'>
                {selectedEntry.address}&nbsp;
              </div>
              {showDeselectButton && <i onClick={onUnselect} role='button' tabIndex={0} className='unselect font-m  is24-icon-closing'/>}
            </div>
          </>
          :
          <div className='one-whole'>
            <div
              className='placeholder'>{optionalPlaceholder ? t('shortlist.optionalPlaceholder') : t('shortlist.placeholder')}</div>
          </div>
        }
        <i className={'chevron ' + (expanded ? 'is24-icon-chevron-up': 'is24-icon-chevron-down')}/>
      </div>

      {expanded && <Shortlist shortlistEntries={combinedEntries} loggedIn={loggedIn} isLoading={loading} onSelect={onSelect} redirectToSso={ssoHandler}/>
      }
    </div>
  )
}

export default ShortlistDropdown
