import React from 'react'

import { BorrowerIncomeAO } from '../../../api/api/types/BorrowerIncomeAO.ds'
import IncomeInputRow from './IncomeInputRow'
import IncomeSalaryStepperRow from './IncomeSalaryStepperRow'

export type IncomeFields = Partial<BorrowerIncomeAO>

type IncomeType = {
  primaryIncome?: BorrowerIncomeAO,
  secondaryIncome?: BorrowerIncomeAO,
  showPrimarySalary?: boolean,
  showSecondarySalary?: boolean,
  primaryOnChange: (income: IncomeFields) => void,
  secondaryOnChange?: (income: IncomeFields) => void,
  numberOfBorrowers: number,
  showTooltips?: boolean,
  maxValue?: number,
  odfLabel?: boolean
}

const IncomeForm: React.FC<IncomeType> = ({
  primaryIncome= {},
  secondaryIncome= {},
  showPrimarySalary = false,
  showSecondarySalary = false,
  primaryOnChange,
  secondaryOnChange,
  numberOfBorrowers,
  showTooltips = true,
  maxValue,
  odfLabel = false
}) => {
  const handleOnChangePrimary = (id: keyof IncomeFields) => (value: number) => {
    primaryOnChange({ ...primaryIncome, [id]: value })
  }

  const handleOnChangeSecondary = (id: keyof IncomeFields) => (value: number) => {
    secondaryOnChange?.({ ...secondaryIncome, [id]: value })
  }

  return <>
    <IncomeInputRow
      id='salary'
      primaryValue={primaryIncome.salary}
      secondaryValue={secondaryIncome.salary}
      primaryOnChange={handleOnChangePrimary}
      secondaryOnChange={handleOnChangeSecondary}
      numberOfBorrowers={numberOfBorrowers}
      showTooltip={showTooltips}
      maxValue={maxValue}
      odfLabel={odfLabel}
    />
    <IncomeSalaryStepperRow
      showPrimarySalary={showPrimarySalary}
      showSecondarySalary={showSecondarySalary}
      primaryValue={primaryIncome.numberOfSalaries}
      secondaryValue={secondaryIncome.numberOfSalaries}
      primaryOnChange={handleOnChangePrimary}
      secondaryOnChange={handleOnChangeSecondary}
      numberOfBorrowers={numberOfBorrowers}
      showTooltip={showTooltips}
    />
    <IncomeInputRow
      id='childBenefits'
      primaryValue={primaryIncome.childBenefits}
      secondaryValue={secondaryIncome.childBenefits}
      primaryOnChange={handleOnChangePrimary}
      secondaryOnChange={handleOnChangeSecondary}
      numberOfBorrowers={numberOfBorrowers}
      showTooltip={showTooltips}
      maxValue={maxValue}
    />
    <IncomeInputRow
      id='alimony'
      primaryValue={primaryIncome.alimony}
      secondaryValue={secondaryIncome.alimony}
      primaryOnChange={handleOnChangePrimary}
      secondaryOnChange={handleOnChangeSecondary}
      numberOfBorrowers={numberOfBorrowers}
      showTooltip={showTooltips}
      maxValue={maxValue}
    />
    <IncomeInputRow
      id='rental'
      primaryValue={primaryIncome.rental}
      secondaryValue={secondaryIncome.rental}
      primaryOnChange={handleOnChangePrimary}
      secondaryOnChange={handleOnChangeSecondary}
      numberOfBorrowers={numberOfBorrowers}
      showTooltip={showTooltips}
      maxValue={maxValue}
    />
    <IncomeInputRow
      id='pension'
      primaryValue={primaryIncome.pension}
      secondaryValue={secondaryIncome.pension}
      primaryOnChange={handleOnChangePrimary}
      secondaryOnChange={handleOnChangeSecondary}
      numberOfBorrowers={numberOfBorrowers}
      showTooltip={showTooltips}
      maxValue={maxValue}
    />
    <IncomeInputRow
      id='other'
      primaryValue={primaryIncome.other}
      secondaryValue={secondaryIncome.other}
      primaryOnChange={handleOnChangePrimary}
      secondaryOnChange={handleOnChangeSecondary}
      numberOfBorrowers={numberOfBorrowers}
      showTooltip={showTooltips}
      maxValue={maxValue}
    />
  </>
}


export const getIncomeTotal = (incomeList: IncomeFields[]) => {
    let total = 0

    incomeList.forEach((income) => {
      for(const key in income) {
        if(key !== 'numberOfSalaries'&& key !== 'total') {
          total += income[ key as keyof IncomeFields] || 0
        }
      }
    })

    return total
}

export default IncomeForm
