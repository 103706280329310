import { BANK_DATA_API } from '../contextPaths'
import { getRequest } from '../request'

export type BankDataObject = {
  'BLZ': string,
  'Name': string,
  'BIC': string
}

export const getBankData = async (searchString: string): Promise<BankDataObject[]> => {
  const url = `${BANK_DATA_API}?term=${searchString}`
  return getRequest({ url })
}
