import React, { useCallback, useEffect, useReducer, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { logError } from '../../../api/api/Logger'
import { getMonthlyRate } from '../../../api/api/MonthlyRate'
import { getMonthlyBuyCost, getMonthlyRentCost } from '../../../services/CalculationService'
import { AdditionalCosts, config, initialState, MoreInfo, StickySection, Slides } from './components'
import { RegionSelection } from '../../common/input/regionSelection/RegionSelection'
import { MoreInfoRadioOptions } from './components/MoreInfo'
import { RentVsBuyReducer, getAdditionalCosts } from './utils';
import './RentVsBuy.less'
import { mapToFixedInterestValue } from '../../../api/utils'

type RentVsBuyType = {
  headline?: string | null,
}

export type RentVsBuyProps = typeof initialState

const RentVsBuy: React.FC<RentVsBuyType> = ({ headline }) => {
  const { t } = useTranslation('widgets')
  const [ showMore, setShowMore ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const [ monthlyRate, setMonthlyRate ] = useState(0)
  const [ interestRate, setInterestRate ] = useState(0)
  const [ state, dispatch ] = useReducer(RentVsBuyReducer, initialState)

  const updateStateValue = (obj: Partial<RentVsBuyProps>) =>
    dispatch({ payload: obj })

  const onChange = (name: string, value?: string | number | boolean) =>
    updateStateValue({ [ name ]: value })

  useEffect(() => {
    updateStateValue(initialState)
  }, [])

  const loadMonthlyRate = useCallback(async () => {
    setLoading(true)

    try {
      const { monthlyRate, averageInterestRate } = await getMonthlyRate({
        geocode: state.geoCode,
        fixedNominalInterestRate: mapToFixedInterestValue(state.fixedInterestRate),
        purchasePrice: state.price,
        ownFunds: state.equity,
        amortizationRate: state.amortizationRate,
        additionalCosts: state.additionalCosts
      })

      setInterestRate(averageInterestRate)
      setMonthlyRate(monthlyRate)
      setLoading(false)
    } catch (e: any) {
      logError('RentVsBuy getMonthlyRate', e)
      setLoading(false)
    }
  }, [ state ])


  useEffect(() => {
    const timeout = setTimeout(loadMonthlyRate, 400)

    return () => clearTimeout(timeout)
  }, [ state.years, state.price, state.equity, loadMonthlyRate, state.additionalCosts ])

  const getBuyCosts = () => {
    let maintenanceCost
    if (state.objectType === MoreInfoRadioOptions.house) {
      maintenanceCost = state.maintenanceCostHouse
    } else {
      maintenanceCost = state.maintenanceCostApartment
    }

    return getMonthlyBuyCost(
      state.price,
      state.equity,
      getAdditionalCosts(state),
      interestRate,
      monthlyRate,
      state.years,
      maintenanceCost,
      state.amortizationRate,
      state.propertyValue,
      state.opportunityInterestRate)
  }

  const buyCosts = getBuyCosts()
  const rentCosts = getMonthlyRentCost(state.rent, state.appreciation, state.years, state.operatingCosts)
  return (
    <div
      className='nine-tenths palm-one-whole grid grid-flex grid-justify-center grid-align-center margin-vertical-l margin-horizontal-l rent-vs-buy'
    >
      <div
        className='seven-tenths palm-one-whole grid grid-flex grid-justify-center grid-align-center align-center title'
      >
        <h1>{headline || t('rentVsBuy.title')}</h1>
        <p className='big-text'>{t('rentVsBuy.subtitle')}</p>
      </div>
      <div className='grid grid-flex palm-one-whole'>
        <div className='two-thirds palm-one-whole lap-one-whole desk-padding-horizontal-xxl palm-padding-right-xl'>
          <div className='margin-vertical-l'>
            <div className='padding-vertical-m'>
              <strong>{t('rentVsBuy.main.location')}</strong>
            </div>
            <RegionSelection
              valid={state.isValidLocation}
              value={state.locationLabel}
              onRegionSelect={({ geoCode }) => {
                onChange('geoCode', geoCode)
                onChange('isValidLocation', true)
              }}
            />
          </div>
          <Slides {...state} onChange={onChange}/>
          <button
            className='link-text margin-top padding-left padding-bottom-l show-more-btn'
            onClick={() => setShowMore(!showMore)}
          >
            <i className={`arrow ${showMore ? 'up' : 'down'}`}/>
            {t('rentVsBuy.main.showMore')}
          </button>
          <div className='one-whole palm-padding-top-m bottom-divider'/>
          {showMore &&
            <>
              <AdditionalCosts {...state} onChange={onChange}/>
              <MoreInfo {...state} floorValue={config.floorValue} onChange={onChange}/>
            </>
          }
        </div>
        <StickySection
          purchasePrice={state.price}
          realEstateType={state.objectType}
          interestRate={interestRate}
          buyCosts={buyCosts}
          rentCosts={rentCosts}
          geoCode={state.geoCode}
          loading={loading}
          isValidLocation={state.isValidLocation}
          onChange={onChange}
        />
      </div>
    </div>
  )
}

export default RentVsBuy
