import React from 'react'
import { useTranslation } from 'react-i18next'
import { FormLabel } from '../../input/FormLabel'
import { Stepper } from 'is24-corecss'

type ExpensesVehiclesStepperRowType = {
  primaryValue?: number,
  secondaryValue?: number,
  primaryOnChange: Function,
  secondaryOnChange: Function,
  numberOfBorrowers: number,
  showVehiclesExpenses? : boolean
}

const ExpensesVehiclesStepperRow: React.FC<ExpensesVehiclesStepperRowType> = ({
  primaryValue = 0,
  secondaryValue = 0,
  primaryOnChange,
  secondaryOnChange,
  numberOfBorrowers,
  showVehiclesExpenses = true
}) => {
  const { t } = useTranslation('leadEngine')
  const id = 'numberOfVehicles'
  const labelFor = (name: string): string => t(`expensesForm.value.${name}.label`)
  const tooltipFor = (name: string): string => t(`expensesForm.value.${name}.tooltip`)

  return <>
    {showVehiclesExpenses && (
      <FormLabel label={labelFor(id)} tooltip={tooltipFor(id)}/>
    )}
    <div className="grid-flex gutter-l flex-nowrap">
      <div className='one-whole grid-item'>
        {showVehiclesExpenses && (
        <Stepper
          label=''
          step={1}
          defaultValue={`${primaryValue}`}
          name={id}
          id={`primary-${id}`}
          onChange={primaryOnChange(id)}
        />
        )}
      </div>

      {numberOfBorrowers > 1 && (
        <div className='one-whole grid-item'>
          {showVehiclesExpenses && (
          <Stepper
            label=''
            step={1}
            defaultValue={`${secondaryValue}`}
            name={id}
            id={`secondary-${id}`}
            onChange={secondaryOnChange(id)}
          />
          )}
        </div>
      )}
    </div>
  </>
}

export default ExpensesVehiclesStepperRow
