import React, { useCallback, useEffect, useState } from 'react';

export function useScrollWithShadow(scrollContainerRef?: React.RefObject<HTMLElement>) {
  const [ scrollLeft, setScrollLeft ] = useState( 0)
  const [ scrollWidth, setScrollWidth ] = useState( 0)
  const [ clientWidth, setClientWidth ] = useState( 0)

  const handleResize = useCallback(() => {
    const scrollContainer = scrollContainerRef?.current
    if (scrollContainer) {
      setScrollLeft(scrollContainer.scrollLeft)
      setScrollWidth(scrollContainer.scrollWidth)
      setClientWidth(scrollContainer.clientWidth)
    }
  }, [ scrollContainerRef ])

  useEffect(() => {
    if (scrollContainerRef?.current) {
      window.addEventListener('resize', handleResize)
    }
    return () => window.removeEventListener('resize', handleResize)
  }, [ scrollContainerRef, handleResize ])

  useEffect(() => {
    const scrollContainer = scrollContainerRef?.current
    if (scrollContainer && scrollWidth === 0 && clientWidth === 0) {
      setScrollLeft(scrollContainer.scrollLeft)
      setScrollWidth(scrollContainer.scrollWidth)
      setClientWidth(scrollContainer.clientWidth)
    }
  }, [ scrollContainerRef, scrollWidth, clientWidth ])

  const onScrollHandler = (event: any) => {
    setScrollLeft(event.target.scrollLeft)
    setScrollWidth(event.target.scrollWidth)
    setClientWidth(event.target.clientWidth)
  };

  const isRight = clientWidth - 2 <= scrollWidth - scrollLeft && scrollWidth > clientWidth
  const isLeft = scrollLeft === 0 && scrollWidth > clientWidth
  const isBetween = scrollLeft > 0 && clientWidth < scrollWidth - scrollLeft && scrollWidth > clientWidth

  let boxShadow = 'shadow-none'
  const left = 'shadow-left'
  const right = 'shadow-right'
  const between = 'shadow-both'

  if (isLeft) {
    boxShadow = right
  } else if (isBetween) {
    boxShadow = between
  } else if (isRight) {
    boxShadow = left
  }


  return { boxShadow, onScrollHandler }
}
