import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import checkmark from '../../../assets/images/lead-engine/loading-with-animation/checkmark.svg'
import './LoadingWithAnimation.less'
import { CircleWithPercent } from './CircleWithPercent'

const ITEM_DELAYS = [ 400, 200, 50, 500, 50, 300, 200 ]
const ITEMS = ITEM_DELAYS.map((n, index) => index)
const ITEMS_COUNT = ITEMS.length

export const LoadingWithAnimation: React.FC = () => {
  const { t } = useTranslation('leadEngine', { keyPrefix: 'loadingWithAnimation' })

  const [ currentItem, setCurrentItem ] = useState(0)

  useEffect(() => {
    if (currentItem < ITEMS_COUNT) {
      const timeout = setTimeout(() => {
        setCurrentItem((prevState) => prevState + 1)
      }, ITEM_DELAYS[ currentItem ] ?? ITEM_DELAYS[ 0 ])
      return () => clearTimeout(timeout)
    }
  }, [ currentItem ])

  return (
    <div className='LoadingWithAnimation'>
      <CircleWithPercent percent={Math.round(currentItem * 100 / ITEMS_COUNT)}/>
      <h3>{t('title')}</h3>
      <div className='description'>{t('description')}</div>
      <div className='items padding-top-s'>
        {ITEMS.map(i =>
          <div key={i} className={`item ${i < currentItem ? 'show' : ''}`}>
            <img alt='Checkmark' src={checkmark}/>
            {t('item.' + i)}
          </div>
        )}
      </div>
    </div>
  )
}
