import React, { ChangeEvent, forwardRef, ForwardRefRenderFunction, RefObject, useEffect, useRef, useState } from 'react'
import { TextInput } from 'is24-corecss'
import { NumericInputType } from './common'
import { formatNumber } from '../../../../services/NumberUtils'

const NumberInput: ForwardRefRenderFunction<HTMLInputElement, NumericInputType> = (
  {
    id,
    value = 0,
    onChange,
    onFocus,
    onBlur,
    placeholder = '',
    valid = true,
    errorMessage,
    label = '',
    className = '',
    disabled = false,
    readOnly = false,
    forceSelect = true,
    unitRight = 'eur',
    ariaLabel = 'currency',
    min = 0
  }
  , ref) => {
  const defaultInputRef = useRef<HTMLInputElement | null>(null)
  const [ formattedValue, setFormattedValue ] = useState(formatNumber(value))
  const inputRef = ref ? ref as RefObject<HTMLInputElement> : defaultInputRef

  useEffect(() => {
    setFormattedValue(formatNumber(value >= min ? value : min))
  }, [ value, min ])

  const removeChars = (value: string) => value.replace(/[^0-9]/g, '')

  const handleOnBlur = () => {
    const newValue = +removeChars(formattedValue)
    setFormattedValue(formatNumber(newValue >= min ? newValue : min))
    onBlur?.()
  }

  const handleOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!readOnly) {
      const { target } = e
      setFormattedValue(removeChars(formattedValue))
      forceSelect && setTimeout(() => target.select())
    }

    onFocus?.()
  }

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormattedValue(e.target.value)
    const newValue = +removeChars(e.target.value)
    onChange?.(newValue)
  }

  return (
    <TextInput
      aria-label={ariaLabel}
      id={id}
      ref={inputRef}
      placeholder={placeholder}
      label={label}
      value={formattedValue}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
      onFocus={handleOnFocus}
      readOnly={readOnly}
      affixRight='unit'
      unitRight={unitRight}
      className={`currency-input ${className}`}
      type='tel'
      data-testid={id}
      disabled={disabled}
      valid={valid}
      errorMessage={errorMessage}
    />
  )
}

export default forwardRef(NumberInput)
