import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { logError } from '../../../api/api/Logger'

import { BorrowerExpensesAO } from '../../../api/api/types/BorrowerExpensesAO.ds'
import { getIncomeTotal } from '../../common/incomeForm/IncomeForm'
import { RoadPage } from '../FinancingRequestRoad'
import { StepWrapper } from '../pages/StepWrapper'
import { Button, StatusMessage } from 'is24-corecss'
import { getLivingCosts } from '../../../api/api/Financing'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../../redux/store'
import { BorrowersType, LeadEngineState } from '../state/reducer'
import { onBorrowersDataSubmit, recordExtendedContactDetails } from '../state/thunks/app'
import { withPageViewReporting } from '../hoc/withTrackPageView'
import { isOnObjectSearch } from '../models/Financing'
import { formatNumber } from '../../../services/NumberUtils'
import ExpenseForm from '../../common/expenseForm/ExpenseForm'
import { MenuBar } from '../MenuBar'
import { ProjectStateExtended } from '../../../api/api/types/ProjectStateExtended'
import { useNavigate } from 'react-router-dom'

export type ExpensesFormType = {
  nextPage: (projectState?: ProjectStateExtended) => string
}

const Expense: React.FC<ExpensesFormType> = ({ nextPage }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {
    borrowers: { primary: primaryBorrower, secondary: secondaryBorrower },
    financing: { numberOfBorrowers = 1, numberOfChildren = 0, projectState }
  } = useSelector<ApplicationState, LeadEngineState>(state => state.leadEngine)

  const [ primaryBorrowerExpenses, setPrimaryBorrowerExpenses ] = useState<BorrowerExpensesAO>(primaryBorrower?.expenses || {})
  const [ secondaryBorrowerExpenses, setSecondaryBorrowerExpenses ] = useState<BorrowerExpensesAO>(secondaryBorrower?.expenses || {})
  const [ showError, setShowError ] = useState<boolean>(false)
  const total = calculateTotalExpenses()
  const { t } = useTranslation('leadEngine')

  useEffect(() => {
    const fetchLivingCosts = async (): Promise<void> => {
      const householdSize = 1 + numberOfChildren + (secondaryBorrower ? 1 : 0)
      const totalIncome = getIncomeTotal([ primaryBorrower.income || {}, secondaryBorrower?.income || {} ])
      const { livingCosts } = await getLivingCosts(householdSize, totalIncome)
      setPrimaryBorrowerExpenses(state => ({ ...state, livingCost: livingCosts }))
    }

    fetchLivingCosts().catch(e => logError('Expense fetchLivingCosts', e))
  }, [ numberOfChildren, primaryBorrower.income, secondaryBorrower ])

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()

    if (total > 0) {
      const data: BorrowersType = {
        primary: { expenses: primaryBorrowerExpenses },
      }

      if (numberOfBorrowers > 1) {
        data.secondary = { expenses: secondaryBorrowerExpenses }
      }

      if (isOnObjectSearch(projectState)) {
        dispatch(recordExtendedContactDetails(nextPage(projectState) , { borrowers: data }, navigate))
      } else {
        dispatch(onBorrowersDataSubmit(nextPage(projectState), data, navigate))
      }
    } else {
      setShowError(true)
    }
  }

  function calculateTotalExpenses() {
    const getSum = (expenses: BorrowerExpensesAO): number => {
      const { livingCost = 0, rent = 0, buildingSaving = 0, saving = 0, healthInsurance = 0, pensionInsurance = 0, alimony = 0, other = 0, creditRate = 0 } = expenses
      return livingCost + rent + buildingSaving + saving + healthInsurance + pensionInsurance + alimony + other + creditRate
    }

    return [ primaryBorrowerExpenses, secondaryBorrowerExpenses ].reduce((curr, expense) => (curr + (expense ? getSum(expense) : 0)), 0)
  }

  const getSubmitButton = () => {
    if (isOnObjectSearch(projectState)) {
      return <Button appearance="primary" type="submit">{t('common:button.send')}</Button>
    } else {
      return <Button appearance="secondary" type="submit">{t('common:button.acquire')}</Button>
    }
  }

  return <>
    <MenuBar page={RoadPage.EXPENSES}/>
    <StepWrapper title={t('expensesForm.title', { count: numberOfBorrowers })}>
      <div className={`${numberOfBorrowers === 1 ? 'one-half' : ''} horizontal-center palm-one-whole`}>
        {showError && (
          <div className='margin-bottom'>
            <StatusMessage headline={t('expensesForm.error.headline')}
                           message={t('expensesForm.error.text')}
                           statusType='error'/>
          </div>
        )}

        <form noValidate={true} onSubmit={handleSubmit} name='expenses-search'>
          {numberOfBorrowers > 1 &&
          <div className="grid-flex margin-bottom flex-nowrap">
            <div className='one-whole padding-right'>
              <strong>{primaryBorrower.forename} {primaryBorrower.surname?.substr(0, 1)}.</strong>
            </div>

            <div className='one-whole padding-left'>
              <strong>{secondaryBorrower?.forename} {secondaryBorrower?.surname?.substr(0, 1)}.</strong>
            </div>
          </div>
          }

          <div className='grid-flex'>
            <ExpenseForm
              primaryExpenses={primaryBorrowerExpenses}
              secondaryExpenses={secondaryBorrowerExpenses}
              numberOfBorrowers={numberOfBorrowers}
              primaryOnChange={setPrimaryBorrowerExpenses}
              secondaryOnChange={setSecondaryBorrowerExpenses}
            />
          </div>

          <div className='grid-flex grid-align-center border-top padding-top margin-top flex-nowrap'>
            <div className='one-whole align-right padding-right-l'>
              <span className='font-lightgray'>{t('expensesForm.total')}</span> <br/>
              <strong>{formatNumber(total)}&nbsp;€</strong>
            </div>
            <div>
              {getSubmitButton()}
            </div>
          </div>
        </form>
      </div>
    </StepWrapper>
  </>
}

export default withPageViewReporting(Expense, 'ext.spending')
