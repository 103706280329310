import stars from '../../../assets/images/stars.svg'
import React, { useState } from 'react'
import './StaticStarRating.less'
import InfoModal from './InfoModal'
import { formatNumber } from '../../../services/NumberUtils'

const currentRating = 4.4

const StaticStarRating: React.FC<{ vertical?: boolean}> = ({ vertical }) => {
  const [ modalOpen, setModalOpen ] = useState(false)
  return (
    <>
      <div className={`StaticStarRating ${vertical ? 'vertical' : ''}`}
           onClick={() => setModalOpen(true)}
           role='button' tabIndex={0}>
        <div className='RatingText'>
          {formatNumber(currentRating, 1)} <span className='font-lightgray'>/ 5</span>
        </div>
        <img className='RatingStars' alt='rating' src={stars}/>
      </div>
      {modalOpen && <InfoModal onClose={() => setModalOpen(false)}/>}
    </>
  )
}

export default StaticStarRating
