import React, { useState } from 'react'
import { Slider } from 'is24-corecss'
import { NumberInput } from '../input/numericInput'

type InputWithSliderType = {
  value?: number,
  onChange: (val: number) => void,
  title: string,
  id: string,
  tooltipText?: string
  max: number,
  min: number,
  step: number,
  titleClassName?: string,
  currencyClassName?:  string,
}

export const InputWithSliderBelow: React.FC<InputWithSliderType> = ({
                                                                      value = 0,
                                                                      onChange,
                                                                      title,
                                                                      id,
                                                                      max,
                                                                      min,
                                                                      step,
                                                                      titleClassName = 'one-half',
                                                                      currencyClassName = 'one-half'
                                                                    }) => {
  const [ inputValue, setInputValue ] = useState(value);

  return (
    <>
      <div className='grid-flex grid-align-center grid-justify-space-between margin-bottom-l'>
        <div className={titleClassName}>
          <strong>{title}</strong>
        </div>
        <div className={currencyClassName}>
          <NumberInput
            id={id}
            value={value}
            onChange={(val) => setInputValue(val)}
            onBlur={() => onChange(inputValue)}
          />
        </div>
      </div>
      <div>
        <Slider
          id={`${id}-slider`}
          title={title}
          maxValue={value}
          max={max}
          min={min}
          step={step}
          onChange={onChange}
        />
      </div>
    </>
  )
}
