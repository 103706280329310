import { API_CONTACT_REQUEST_URL } from '../contextPaths'
import { ContentType, getRequest, postRequest, putRequest } from '../request'
import { ContactRequestAO } from './types/ContactRequestAO.ds'
import { ExtendedContactRequestAO } from './types/ExtendedContactRequestAO.ds'
import { MortgageProviderAO } from './types/MortgageProviderAO.ds'


export const REQUEST_SOURCE = {
  LEADENGINE: 'leadengine',
  MORTGAGEOFFICER: 'mortgageofficer',
  PREAPPROVAL: 'preapproval'
}

export type ResourceCreatedMessageAO = {
  id: number,
  updateToken: string,
  advisoryLink: { link: string, expiryDate: number },
}

export type AdvisoryBookingLinkAO = {
  link: string,
  expiryDate: number
}

type HeadersType = { [ key: string ]: string | boolean }
type QueryType = { [key: string]: string | boolean };
export const postFinancingRequest = async (contactRequest: ContactRequestAO, requestSource?: string, headers?: HeadersType, query?: QueryType): Promise<ResourceCreatedMessageAO> =>
  postRequest({
    url: `${API_CONTACT_REQUEST_URL}/financingrequest`,
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      'X-IS24-Origin': requestSource ? `IS24:${requestSource}` : '',
      ...headers
    },
    query,
    body: { contactRequest: contactRequest,}
  }).then(resp => resp.message[ 0 ])

export const putExtendedFinancingRequest = async (extendedContactRequest: ExtendedContactRequestAO, contactRequestId: number, updateToken: string, advisoryLinkFeatureSwitch = false): Promise<AdvisoryBookingLinkAO> =>
 putRequest({
    url: `${API_CONTACT_REQUEST_URL}/${contactRequestId}?returnLink=${advisoryLinkFeatureSwitch}`,
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'x-is24-token': updateToken
    },
    body: extendedContactRequest,
  })

export type ContactedProvidersByUpdateTokenResponse = {
  provider: MortgageProviderAO[],
  totalNumberOfProviders: number
}

export const getContactedProvidersByUpdateToken = (id: number, token: string | null): Promise<ContactedProvidersByUpdateTokenResponse> =>
  getRequest({ url: `${API_CONTACT_REQUEST_URL}/${id}/providers?token=${token}` })


export const sendContactRequestRedistribution = (contactRequestId: number, token: string): Promise<Response> =>
  fetch(`${API_CONTACT_REQUEST_URL}/${contactRequestId}/redistribute?token=${token}`, {
    method: 'PUT',
    headers: { Accept: ContentType.APPLICATION_JSON_UTF8, 'Content-Type': 'application/json; charset=utf-8' }
  })

export const validateContactRequest = async (contactRequest: ContactRequestAO) =>
  postFinancingRequest(contactRequest, 'test request', { 'X-IS24-API-test': true })




