import { getRequest } from '../api/request'
import { API_USER_REQUEST_URL } from '../api/contextPaths'

export type Address = {
  street?: string,
  houseNumber?: string,
  postcode?: string,
  city?: string
}

export type UserContactDetails = {
  salutation?: string
  email?: string,
  firstname?: string,
  lastname?: string,
  phoneNumber?: string,
  cellPhoneNumber?: string,
  address?: Address
}

export type UserResponse = {
  userName?: string,
  userContactDetails?: UserContactDetails,
}

export const getUser = (): Promise<UserResponse> => getRequest({
  url: API_USER_REQUEST_URL,
  credentials: 'include'
})
