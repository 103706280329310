import Highcharts, { Options } from 'highcharts'

export const config = {
  chart: {
    type: 'line',
    renderTo: '',
    backgroundColor: 'rgba(0,0,0,0)',
    style: {
      fontSize: '12px',
      fontFamily: '\'Open Sans\', Verdana'
    },
    spacingLeft: 0,
    spacingRight: 15,
  },
  title: {
    text: ''
  },
  xAxis: {
    maxPadding: 0.01,
    minPadding: 0.01,
    lineWidth: 1,
    tickWidth: 1,
    gridLineWidth: 1,
    gridLineDashStyle: 'Dot',
    gridLineColor: '#BFBFBF',
    showLastLabel: true,
    type: 'datetime',
    labels: {
      y: 20,
      formatter: function (): string {
        return Highcharts.dateFormat('%d. %b', this.value as number - (24 * 60 * 60 * 1000));
      }
    }
  },
  yAxis: {
    maxPadding: 0,
    minPadding: 0,
    tickInterval: 0.5,
    lineWidth: 0,
    tickWidth: 1,
    labels: {
      formatter: function (): string {
        return Highcharts.numberFormat(this.value as number, 2) + ' %';
      }
    },
    title: {
      text: null
    }
  },
  tooltip: {
    shared: false,
    formatter: function (): string {
      return '<b>' + this.series.name + '</b><br/>' + Highcharts.dateFormat('%d.%m.%Y', this.x - (24 * 60 * 60 * 1000)) + ': ' + Highcharts.numberFormat(this.y, 2) + ' %';
    }
  },
  credits: {
    enabled: false
  },
  plotOptions: {
    series: {
      marker: {
        radius: 2,
        enabled: false,
        symbol: 'circle'
      }
    }
  },
  legend: {
    verticalAlign: 'bottom',
    borderWidth: 0,
    symbolWidth: 10,
    labelFormatter: function (): string {
      return this.name.replace('Zinsbindung ', '');
    },
    itemStyle: {
      fontSize: '11px',
      fontWeight: 'normal',
      fontFamily: '\'Open Sans\', Verdana'
    }
  },
  exporting: {
    enabled: false
  },
  series: []
} as Options;

export const options = {
  lang: {
    decimalPoint: ',',
    loading: 'Lade...',
    months: [ 'Januar',
      'Februar',
      'M&auml;rz',
      'April',
      'Mai',
      'Juni',
      'Juli',
      'August',
      'September',
      'Oktober',
      'November',
      'Dezember' ],
    shortMonths: [ 'Jan', 'Feb', 'Mrz', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov',
      'Dez' ],
    weekdays: [ 'Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag',
      'Samstag' ],
    resetZoom: 'Zoom zurücksetzen',
    resetZoomTitle: 'Zoom auf Verhältnis 1:1 zurücksetzen',
    thousandsSep: '.'
  }
} as Options;
