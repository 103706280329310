import React, { ReactChild } from 'react'
import './LoanSettings.less'
import { Stepper } from 'is24-corecss'

export type PercentageStepperProps = {
  id: string
  label: ReactChild
  min: number
  max: number
  defaultValue: string | undefined
  onValueChange: (val: number) => void
  inputSanitization: (rawValue: string) => string
  step?: number
}

export const PercentageStepper: React.FC<PercentageStepperProps> = (
  {
    id,
    label,
    min,
    max,
    defaultValue,
    onValueChange,
    inputSanitization,
    step = 0.5
  }) => (
  <>
    {defaultValue &&
      <Stepper
        id={id}
        label={label}
        min={min}
        max={max}
        defaultValue={defaultValue}
        step={step}
        onBlur={(event: any) => {
          event.target.value = inputSanitization(event.target.value)
          onValueChange(Number(event.target?.value))
        }}
        onChange={(event: any) => {
          event.nativeEvent.constructor.name !== 'InputEvent' && onValueChange(Number(event.target?.value))
        }}
      />}
  </>
)

