import React from 'react';
import { useTranslation } from 'react-i18next'

const PersonalDataChips = () => {
  const {t} = useTranslation('leadEngine')

  const style = {
    border: '1px solid #00D9B1',
    padding: '0.5rem 1.2rem',
    borderRadius: 4,
    fontWeight: 600,
    backgroundColor: 'rgba(0, 217, 177, 0.05',
    margin: '2rem 2rem 0 0'
  }
  const icon = 'is24-icon-positive-check button-icon-margin-left'

  return (
    <div className='grid grid-flex grid-justify-center'>
      <div style={style}>
        {t('financingRequest.personalData.monthlyRate')}
        <span className={icon} />
      </div>
      <div style={style}>
        {t('financingRequest.personalData.interestRate')}
        <span className={icon} />
      </div>
      <div style={style}>
        {t('financingRequest.personalData.repaymentTerm')}
        <span className={icon} />
      </div>
      <div style={style}>
        {t('financingRequest.personalData.residualDebt')}
        <span className={icon} />
      </div>
      <div style={style}>
        {t('financingRequest.personalData.providersComparison')}
        <span className={icon} />
      </div>
      <div style={style}>
        {t('financingRequest.personalData.offerVariants')}
        <span className={icon} />
      </div>
      <div style={style}>
        {t('financingRequest.personalData.financingAdvice')}
        <span className={icon} />
      </div>
    </div>
  );
};

export default PersonalDataChips;
