import React from 'react'
import { Button, ProgressBar } from 'is24-corecss'
import {
  RoadPage,
  showFollowUpFinancingSettingsPage,
  showLocationPage,
  showPurchasePricePage
} from './FinancingRequestRoad'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../../redux/store'
import { LeadEngineState } from './state/reducer'
import { isFollowupFinancing, isOnObjectSearch } from './models/Financing'
import { goToFinancingStartPage } from './routing'
import { useTranslation } from 'react-i18next'
import './MenuBar.less'
import useIncomePageWithInputField from './hook/useIncomePageWithInputField'
import { useNavigate } from 'react-router-dom'

export interface MenuBarProps {
  page: string,
  hideClose?: boolean,
  hideBack?: boolean
}

export const MenuBar: React.FC<MenuBarProps> = ({ page, hideClose = false, hideBack = false }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { financing, property } = useSelector<ApplicationState, LeadEngineState>(state => state.leadEngine)
  const shouldUseIncomePageWithInputField = useIncomePageWithInputField()

  const showEnergeticModernizationPage = !property.hasSufficientEnergyEfficiency

  const simpleFlow = isFollowupFinancing(financing.financingType) ? [
    RoadPage.FINANCING_START,
    showLocationPage(property) ? RoadPage.LOCATION : '',
    showFollowUpFinancingSettingsPage(financing) ? RoadPage.FOLLOW_UP_FINANCING_SETTINGS : '',
    RoadPage.FINANCING_USE_TYPE,
    RoadPage.EMPLOYMENT,
    shouldUseIncomePageWithInputField ? RoadPage.INCOME_SINGLE_VALUE : RoadPage.INCOME_RANGE,
  ].filter(Boolean) : [
    RoadPage.FINANCING_START,
    RoadPage.PROJECT_STATE,
    showLocationPage(property) ? RoadPage.LOCATION : '',
    showPurchasePricePage(financing) ? RoadPage.PURCHASE_PRICE : '',
    showEnergeticModernizationPage ? RoadPage.ENERGETIC_MODERNIZATION : '',
    RoadPage.FINANCING_USE_TYPE,
    RoadPage.EMPLOYMENT,
    shouldUseIncomePageWithInputField ? RoadPage.INCOME_SINGLE_VALUE : RoadPage.INCOME_RANGE
  ].filter(Boolean)

  const extendedFlow = financing.numberOfBorrowers === 1 ?
    [
      RoadPage.NUMBER_OF_BORROWERS,
      RoadPage.BORROWERS_DATA,
      RoadPage.NUMBER_OF_CHILDREN,
      RoadPage.INCOME_DETAILS,
      RoadPage.EXPENSES
    ] : [
      RoadPage.NUMBER_OF_BORROWERS,
      RoadPage.BORROWER_RELATION,
      RoadPage.BORROWERS_DATA,
      RoadPage.BORROWERS_DATA_TWO,
      RoadPage.NUMBER_OF_CHILDREN,
      RoadPage.INCOME_DETAILS,
      RoadPage.EXPENSES
    ]

  if (!isOnObjectSearch(financing.projectState)) {
    extendedFlow.push(
      RoadPage.PROPERTY_ADDRESS,
      RoadPage.PROPERTY_TYPE,
      RoadPage.PROPERTY_SUBTYPE,
      RoadPage.PROPERTY_DETAILS
    )
  }

  const findPageStep = (page: string): number => {
    const calculateStepPercent = (arr: string[]): number => Math.round(((arr.indexOf(page) + 1) / arr.length) * 95)
    return simpleFlow.includes(page) ? calculateStepPercent(simpleFlow) : calculateStepPercent(extendedFlow)
  }

  const onBack = () => navigate(-1)
  const onClose = () => goToFinancingStartPage(navigate)

  return <div className='menu-bar'>
    <div className='menu-progress-bar padding-bottom-s'>
      <ProgressBar size='large' progress={findPageStep(page)}/>
    </div>
    <div className='menu-button-group'>
      <div className='menu-back-button'>
        {!hideBack &&
          <Button
            aria-label={t('common:button.back')}
            iconStandalone={true}
            onClick={onBack}
            className='font-lightgray'
          >
            <span className='is24-icon-chevron-left font-s palm-font-xs'/>
            <span className='font-s palm-font-xs'>Zurück</span>
          </Button>
        }
      </div>
      <Button
        hidden={hideClose}
        aria-label={t('common:button.close')}
        iconStandalone={true}
        onClick={onClose}
        className='font-lightgray'
      >
        <span className='is24-icon-closing font-s palm-font-xs'/>
      </Button>
    </div>
  </div>
}
