
export const MIN_AMORTIZATION_RATE_PERCENTAGE = 1
export const MIN_INTEREST_RATE_PERCENTAGE = 0.5
export const MAXIMUM_PERCENTAGE = 10

const rangeValidation = (min: number, max: number) => (value: number): number => Math.min(Math.max(value, min), max)
export const validateAmortizationRate = rangeValidation(MIN_AMORTIZATION_RATE_PERCENTAGE, MAXIMUM_PERCENTAGE)
export const validateInterestRate = rangeValidation(MIN_INTEREST_RATE_PERCENTAGE, MAXIMUM_PERCENTAGE)

const roundToFullHalves = (valueAsNumber: number) => `${Math.round(valueAsNumber * 2) / 2}`

export const sanitizeInput = (min: number, max: number) => (rawValue: string): string => {
  if (rawValue || rawValue === '') {
    rawValue = rawValue.replace(/[^0-9.]/, '');
    const valueAsNumber = Number(rawValue)
    if (isNaN(valueAsNumber) || (valueAsNumber < min)) {
      return `${min}`
    }
    if (valueAsNumber > max) {
      return `${max}`
    }
    if (rawValue.indexOf('.') > 0) {
      return roundToFullHalves(valueAsNumber)
    }
  }
  return rawValue;
}

export const sanitizeAmortizationRateInput = sanitizeInput(MIN_AMORTIZATION_RATE_PERCENTAGE, MAXIMUM_PERCENTAGE)
export const sanitizeInterestRateInput = sanitizeInput(MIN_INTEREST_RATE_PERCENTAGE, MAXIMUM_PERCENTAGE)
