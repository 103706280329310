import React from 'react'

export type SectionProps = {
  title: string
  children: React.ReactChild
  paddingBottom?: boolean
}

const Section: React.FC<SectionProps> = ({ title, children, paddingBottom = true }) => {
  return (
    <div className='Section'>
      <div className='font-bold'>{title}</div>
      <div className={`padding-top-m ${paddingBottom ? 'padding-bottom-xl' : ''}`}>{children}</div>
    </div>
  )
}

export default Section
