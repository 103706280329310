import { loadMarketDataRanges, MarketDataResourceParams } from './MarketDataResource'
import { Employment } from '../api/api/types/Employment'
import { logError } from '../api/api/Logger'

export const isObject = (v: object) => typeof v === 'object';

export const getDataFromFirstHierarchy = (marketData: {[key: string]: object}) => {
  try {
    const getFirstEntry = (data: object) => isObject(data) ? Object(data)[ Object.keys(data)[ 0 ] ] : undefined
    const getLastObject = (obj: object): object => {
      const firstEntry = getFirstEntry(obj);
      return isObject(firstEntry) && !isObject(getFirstEntry(firstEntry)) ? obj : getLastObject(firstEntry)
    }

    const newHierarchy: {[key: string]: object} = {}
    for (const key in marketData) {
      const lastObject = getLastObject(marketData[key]);
      if(lastObject) {
        newHierarchy[`${key}`] = lastObject
      }
    }

    return newHierarchy
  } catch (e: any) {
    logError('MarketDataService getDataFromFirstHierarchy', e)
  }
}

export async function getMarketDataRanges() {
  const params: MarketDataResourceParams = {
    timeFrameUnit: 'WEEK',
    geoCode: '1276',
    loanAmount: 100000,
    loanToValueRatio: 80,
    amortizationRate: 2.5,
    employment: Employment.EMPLOYED,
  };

  const response = await loadMarketDataRanges(params)
  const marketData = await response.json()
  return getDataFromFirstHierarchy(marketData)
}

