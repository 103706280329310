import { useTranslation } from 'react-i18next'
import React from 'react'
import { formatNumber } from '../../../../services/NumberUtils'
import { CostsInput, DropdownTile } from './index'

const config = {
  amortizationValues: [
    1.0,
    1.5,
    2.0,
    2.5,
    3.0,
    3.5,
    4.0,
    4.5,
    5.0,
    5.5,
    6.0,
    6.5,
    7.0,
    7.5,
  ],
  fixedInterestRateValues: [
    5,
    10,
    15,
    20,
    30,
    40,
  ],
}

type AdditionalCostsValues = {
  transferTax: number,
  brokerageCommission: number,
  notaryFees: number,
  landRegister: number,
  misc: number,
  amortizationRate: number,
  fixedInterestRate: number,
}

type AdditionalCostsProps = {
  transferTaxValue: number,
  brokerageCommissionValue: number,
  notaryFeesValue: number,
  landRegisterValue: number,
  onChange: (key: keyof AdditionalCostsValues, value: number) => void,
} & AdditionalCostsValues

export const AdditionalCosts: React.FC<AdditionalCostsProps> =
  ({
     transferTax,
     transferTaxValue,
     brokerageCommission,
     brokerageCommissionValue,
     notaryFeesValue,
     notaryFees,
     landRegister,
     landRegisterValue,
     misc,
     amortizationRate,
     fixedInterestRate,
     onChange,
   }) => {
    const { t } = useTranslation('widgets')

    const handleChange = (key: keyof AdditionalCostsValues) =>
      (v: number) => onChange(key, v)

    return (
      <>
        <DropdownTile
          label={t('rentVsBuy.secondary.amortization')}
          value={amortizationRate}
          id={'amortizationRate'}
          listItems={config.amortizationValues}
          labelFormatter={(value: any) => `${formatNumber(value, 1)} %`}
          onChange={(value: string) => handleChange('amortizationRate')(parseFloat(value))}

        />
        <DropdownTile<number>
          label={t('rentVsBuy.secondary.interestRate')}
          value={fixedInterestRate}
          id={'fixedInterestRate'}
          listItems={config.fixedInterestRateValues}
          labelFormatter={(value: number) => `${value} Jahre`}
          onChange={(value: string) => handleChange('fixedInterestRate')(parseFloat(value))}

        />
        <div className='padding-top-s top-divider'>
          <p className='big-text'>{t('rentVsBuy.secondary.purchaseCosts.title')}</p>
          <p className='grey-text'>{t('rentVsBuy.secondary.purchaseCosts.subtitle')}</p>
          <CostsInput
            isPercentage={true}
            onChange={handleChange('transferTax')}
            input={transferTax}
            value={transferTaxValue}
            id='transferTax'
            label={t('rentVsBuy.secondary.purchaseCosts.transferTax')}/>
          <CostsInput
            onChange={handleChange('brokerageCommission')}
            input={brokerageCommission}
            value={brokerageCommissionValue}
            isPercentage={true}
            id={'brokerageCommission'}
            label={t('rentVsBuy.secondary.purchaseCosts.brokerageCommission')}/>
          <CostsInput
            onChange={handleChange('notaryFees')}
            input={notaryFees}
            value={notaryFeesValue}
            isPercentage={true}
            id={'notaryFees'}
            label={t('rentVsBuy.secondary.purchaseCosts.notaryFees')}/>
          <CostsInput
            onChange={handleChange('landRegister')}
            input={landRegister}
            value={landRegisterValue}
            isPercentage={true}
            id={'landRegister'}
            label={t('rentVsBuy.secondary.purchaseCosts.landRegister')}/>
          <CostsInput
            onChange={handleChange('misc')}
            input={misc}
            id={'misc'}
            label={t('rentVsBuy.secondary.purchaseCosts.misc')}/>
        </div>
      </>
    )
  }

export default AdditionalCosts
