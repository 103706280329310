import React, { useMemo, useState } from 'react'
import { toCurrency } from '../../../../../services/NumberUtils'
import { YearlyRepayment } from '../../model/FinancingResults'
import TableRow from './TableRow'
import { useTranslation } from 'react-i18next'
import { Separator } from './Separator'

type RepaymentScheduleRowProps = {
  yearlyRepayment: YearlyRepayment,
  displaySeparator?: boolean
}

export const currentMonth = new Date().getMonth()

export const RepaymentScheduleRow: React.FC<RepaymentScheduleRowProps> = (
  { yearlyRepayment, displaySeparator }) => {

  const [ showOnMonth, setShowOnMonth ] = useState(false);
  const showOnMonthOnChange = () => setShowOnMonth(!showOnMonth)
  const { t } = useTranslation('odf')

  const monthlyRepaymentRows = useMemo(() => (
      <div>
        {yearlyRepayment.monthlyRepayments?.map((monthlyRepayment, index) => (
          <div key={index}>
            <div className='row sub-row-color grey-border-bottom'>
              <div className='plug'/>
              <div>
                <div className='cell font-bold'>{t(`commons.monthsShort.${monthlyRepayment.month - 1}`)}</div>
                <div className='cell'>{toCurrency(monthlyRepayment.monthlyInstallment)}</div>
                <div className='cell'>{toCurrency(monthlyRepayment.monthlyInterest)}</div>
                <div className='cell'>{toCurrency(monthlyRepayment.monthlyAmortization)}</div>
                <div className='cell'>{toCurrency(monthlyRepayment.monthlyOverpayment)}</div>
                <div className='cell'>{toCurrency(monthlyRepayment.monthlyRemainingDebt)}</div>
              </div>
            </div>
            {displaySeparator && showOnMonth && index == currentMonth ?
              <Separator showOnMonth={true}/> : null}
          </div>
        ))}
      </div>),
  [yearlyRepayment.monthlyRepayments, displaySeparator, showOnMonth, t ])

  return (
    <div>
      <TableRow
        expandable={true}
        className='row-color grey-border-bottom cursor-pointer'
        showOnMonthOnChange={showOnMonthOnChange}
        rowContent={
          <div>
            <div className='cell font-bold'>{yearlyRepayment.year}</div>
            <div className='cell'>{toCurrency(yearlyRepayment.yearlyInstallment)}</div>
            <div className='cell'>{toCurrency(yearlyRepayment.yearlyInterest)}</div>
            <div className='cell'>{toCurrency(yearlyRepayment.yearlyAmortization)}</div>
            <div className='cell'>{toCurrency(yearlyRepayment.yearlyOverpayment)}</div>
            <div className='cell'>{toCurrency(yearlyRepayment.yearlyRemainingDebt)}</div>
          </div>
        }
        subContent={monthlyRepaymentRows}
      />
      {displaySeparator && !showOnMonth ?
        <Separator showOnMonth={false} monthNumber={currentMonth} year={yearlyRepayment.year}/> : null}
    </div>
  )
}

