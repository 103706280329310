import React from 'react'

import { Button } from 'is24-corecss'
import { useTranslation } from 'react-i18next'
import chevronRight from '../../../assets/images/lead-engine/chevron-right.svg'
import checkmark from '../../../assets/images/lead-engine/checkmark.svg'

type ButtonGroupProps<T> = {
  values: T[],
  onClick: (v: T) => void,
  value?: T,
}

export const ButtonGroup = <T extends string>({ values, onClick, value}: ButtonGroupProps<T>): React.ReactElement => {
  const {t} = useTranslation()

  const selectedProps: { [ key: string ]: string } = {
    className: 'selected',
    appearance: 'secondary'
  }

  return (
    <div className="selection-group">
      {values.map((v, i) =>
        <span key={i} className='absolute-reference'>
          <Button
                  {...(v === value ? selectedProps : {})}
                  onClick={() => onClick(v)}
          >
            {t(`common:finance.values.${v}`)}
          </Button>
          {v === value ? <img alt='Checkmark' className='selection-group-checkmark' src={checkmark}/> :
            <img alt='Chevron right' className='selection-group-chevron' src={chevronRight}/>}
        </span>
      )}
    </div>
  )
}
