import React from 'react'
import './Summary.less'
import { useTranslation } from 'react-i18next'
import { formatNumber, toCurrency } from '../../../../services/NumberUtils'
import Tile from './Tile'
import { StatusMessage } from 'is24-corecss'
import { FinancingResultError } from '../../../financing/resultPage/model/FinancingResults'
import { MINIMAL_LOAN_AMOUNT } from '../../../financing/hook/useLoanAmount'

export type SummaryProps = {
  monthlyInstalment: number,
  averageInterestRate: number,
  effectiveInterestRate: number,
  netLoan: number,
  geoCode?: string,
  postalCode?: string
  error?: FinancingResultError
}

const Summary: React.FC<SummaryProps> = (
  {
    monthlyInstalment,
    averageInterestRate,
    effectiveInterestRate,
    netLoan,
    geoCode,
    postalCode,
    error
  }) => {

  const { t } = useTranslation('widgets', { keyPrefix: 'financingCalculator.summary' })
  const netLoanNotValid = netLoan < MINIMAL_LOAN_AMOUNT
  const geoCodeOrPostalCodeNotValid = !(geoCode || postalCode)
  const getHeadLine = (content: string) => {
    return <span className='font-h5 font-bold'>{content}</span>
  }

  // TODO provide proper translations
  const getStatusMessage = () => {
    if (netLoanNotValid) return <StatusMessage
      headline={getHeadLine(t('netLoanMessageHeader'))} message={t('netLoanMessage')} statusType='info'/>
    if (geoCodeOrPostalCodeNotValid) return <StatusMessage
      headline={getHeadLine(t('regionMessageHeader'))} message={t('regionMessage')} statusType='info'/>
    if (error) return <StatusMessage
      headline={getHeadLine(t('errorMessageHeader'))} message={t('errorMessage')} statusType='info'/>
  }

  return (
    <div className='Summary' data-testid='Summary'>
      <div className='Container'>
        <div className='Title'>{t('title')}</div>
        {netLoanNotValid || geoCodeOrPostalCodeNotValid || error ?
          getStatusMessage()
          :
          <div>
            <div className='TilesContainer'>
              <Tile value={toCurrency(monthlyInstalment)} label={t('monthlyInstalment')}/>
              <Tile value={`${formatNumber(averageInterestRate, 2)} %`} label={t('averageInterestRate')}/>
              <Tile value={`${formatNumber(effectiveInterestRate, 2)} %`} label={t('effectiveInterestRate')}/>
            </div>
          </div>
        }
      </div>
    </div>
  )
}

export default Summary
