import React from 'react'
import { useTranslation } from 'react-i18next'
import { FormLabel } from '../input/FormLabel'
import { NumberInput } from '../input/numericInput'
import { ExpenseFields } from './ExpenseForm'

interface Props {
  id: keyof ExpenseFields,
  primaryValue?: number,
  secondaryValue?: number,
  primaryOnChange: (id: keyof ExpenseFields) => (value: number) => void,
  secondaryOnChange: (id: keyof ExpenseFields) => (value: number) => void,
  numberOfBorrowers: number,
  showLabelTooltip?: boolean
  maxValue?: number
}

const DEFAULT_MAX_VALUE = 99_999_999;
export const checkIfValid = (value?: number, maxValue?: number) => {
  return value === undefined || (value >= 0 && value <= (maxValue ?? DEFAULT_MAX_VALUE))
}

const ExpensesInputRow: React.FC<Props> = ({
  id,
  primaryValue,
  secondaryValue,
  primaryOnChange,
  secondaryOnChange,
  numberOfBorrowers,
  showLabelTooltip = true,
  maxValue
}) => {
  const { t } = useTranslation('leadEngine')
  const label = t(`expensesForm.value.${id}.label`)
  const tooltipFor = (name: string): string => showLabelTooltip ? t(`expensesForm.value.${name}.tooltip`) : ''
  const primaryValid = checkIfValid(primaryValue, maxValue);
  const secondaryValid = checkIfValid(secondaryValue, maxValue);


  return <>
    <FormLabel label={label} tooltip={tooltipFor(id)}/>
    <div className='grid-flex gutter-l flex-nowrap'>
      <div className='one-whole grid-item'>
        <NumberInput
          id={`primary-${id}`}
          value={primaryValue}
          onChange={(value) => primaryOnChange(id)(value)}
          errorMessage={t(maxValue ? 'expensesForm.error.inputValueAlternative' : 'expensesForm.error.inputValue')}
          valid={primaryValid} forceSelect={false}/>
      </div>
      {numberOfBorrowers > 1 && (
        <div className='one-whole grid-item'>
          <NumberInput
            id={`secondary-${id}`}
            value={secondaryValue}
            onChange={(value) => secondaryOnChange(id)(value)}
            errorMessage={t(maxValue ? 'expensesForm.error.inputValueAlternative' : 'expensesForm.error.inputValue')}
            valid={secondaryValid} forceSelect={false}/>
        </div>
      )}
    </div>
  </>
}

export default ExpensesInputRow
