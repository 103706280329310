import { useEffect, useState } from 'react'
import { FinancingResult, FinancingResultError } from '../resultPage/model/FinancingResults'
import { calculateAmortization } from '../services/FinancingService'
import { mapToMonthlyRemainingDebtAmounts, mapToYearlyRepayments } from '../resultPage/model/mappings'
import { OnErrorCallback } from './useFinancingProbability'
import { MINIMAL_LOAN_AMOUNT } from './useLoanAmount'
import { FinancingType, formatFinancingType } from '../../../api/api/types/FinancingType'
import { Employment } from '../../../api/api/types/Employment'
import { mapToFixedInterestValue } from '../../../api/utils'

let latestResultVersion = 0
const EMPLOYMENT_TYPE = Employment.EMPLOYED

export type AmortizationFinancingCalculationInput = {
  loanAmount: number
  propertyCost: number
  additionalCosts: number
  ownCapital: number
  amortizationRate: number
  fixedRateEndYear: number
  financingType: FinancingType
  postalCode?: string
  geoCode?: string
  propertyValue?: number
  remainingDebt?: number
}

export const useAmortizationFinancingCalculation = ({
  loanAmount,
  propertyCost,
  additionalCosts,
  ownCapital,
  amortizationRate,
  fixedRateEndYear,
  financingType,
  postalCode,
  geoCode,
  propertyValue,
  remainingDebt
}: AmortizationFinancingCalculationInput,
  onError: OnErrorCallback
): FinancingResult | undefined => {

  const [ result, setResult ] = useState<FinancingResult | undefined>(undefined)

  useEffect(() => {
    if (!!propertyCost && (!!geoCode || !!postalCode) && loanAmount > MINIMAL_LOAN_AMOUNT) {
      const currentResultVersion = ++latestResultVersion
      calculateAmortization({
        purchasePrice: propertyCost,
        additionalCosts,
        ownFunds: ownCapital,
        amortizationRate,
        geoCode,
        postalCode,
        employmentType: EMPLOYMENT_TYPE,
        fixedRatePeriod: mapToFixedInterestValue(fixedRateEndYear),
        financingType: formatFinancingType(financingType),
        propertyValue,
        remainingDebt
      }).then(calculated => {
          if (currentResultVersion === latestResultVersion) {
            setResult({
              totalTimeMonths: calculated.summaryOnDebtEnd?.numberOfInstalments,
              monthlyInstalment: calculated.repaymentSchedule?.length && calculated.repaymentSchedule[ 0 ].instalment,
              averageInterestRate: calculated.averageInterestRate,
              effectiveInterestRate: calculated.effectiveInterestRate,
              loanAmount,
              yearlyRepayments: mapToYearlyRepayments(calculated.repaymentSchedule),
              monthlyRemainingDebtAmounts: mapToMonthlyRemainingDebtAmounts(calculated.repaymentSchedule),
              monthlyInstalmentSum: calculated.summaryOnFixedRateEnd?.totalPaid,
              interestSum: calculated.summaryOnFixedRateEnd?.totalInterestPaid,
              amortizationSum: calculated.summaryOnFixedRateEnd?.totalAmortizationPaid,
              overpaymentSum: calculated.summaryOnDebtEnd.totalOverpayment,
              remainingDebtSum: calculated.summaryOnFixedRateEnd?.remainingLoanAmount,
              fixedRateEndYear: fixedRateEndYear // to avoid re-rendering of the whole page on input change we copy this to result object
            })
          }
          onError(undefined)
        }
      ).catch(error => {
        console.error(error)
        onError(FinancingResultError.COULD_NOT_CALCULATE)
      })
    }
  }, [ setResult, loanAmount, propertyCost, additionalCosts, ownCapital, amortizationRate, geoCode, postalCode, fixedRateEndYear, onError, financingType, propertyValue, remainingDebt ])

  return result
}
