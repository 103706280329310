import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { queryParams } from '../../../utils/utils'
import { onLeadEngineInitialization } from '../state/thunks/app'
import { ApplicationState } from '../../../redux/store'
import { LeadEngineState } from '../state/reducer'
import { useNavigate, useLocation } from 'react-router-dom'
import { FinancingUrlParams } from '../models/Financing'
import { PropertyUrlParams } from '../models/Property'
import { withIs24Template } from '../../common/snippets/withIs24Template'
// we need to ignore eslint here because of storybook's strange error
//@eslint-ignore
import { NavigateFunction } from 'react-router/dist/lib/hooks'

export type LeadEngineUrlParams = Partial<{
  openform: string,
  test: string
}> & FinancingUrlParams
  & PropertyUrlParams

function removePIIFromUrl(searchParams: string, navigate: NavigateFunction) {
  try {
    const queryParams = new URLSearchParams(searchParams)
    queryParams.delete('email')
    queryParams.delete('firstname')
    queryParams.delete('lastname')
    queryParams.delete('phonenumber')
    navigate({
      search: queryParams.toString(),
    }, { replace: true })
  } catch (error) {
    console.log(error)
  }
}

export const withInitialization = (WrappedComponent: React.ComponentType, asWidget = false) => {
  const HOC: React.FC = (props) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { initialized } = useSelector<ApplicationState, LeadEngineState>(state => state.leadEngine)
    const searchParams = useLocation().search

    const params: LeadEngineUrlParams = useMemo(() =>
        queryParams<LeadEngineUrlParams>(searchParams)
      , [ searchParams ])

    useEffect(() => {
      if (!initialized) {
        dispatch(onLeadEngineInitialization(params, navigate, asWidget))
      }
      removePIIFromUrl(searchParams, navigate)
    }, [ initialized, dispatch, params, navigate, searchParams ])

    return <WrappedComponent {...props} />
  }

  return asWidget ? HOC : withIs24Template(HOC, false)
}
