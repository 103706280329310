import { BorrowerExpensesAO } from '../../../api/api/types/BorrowerExpensesAO.ds'
import React from 'react'
import ExpensesInputRow  from './ExpensesInputRow'
import ExpensesVehiclesStepperRow from './expensesVehiclesStepperRow/ExpensesVehiclesStepperRow'

export type ExpenseFields = Partial<BorrowerExpensesAO>

type ExpenseType = {
  primaryExpenses: BorrowerExpensesAO,
  secondaryExpenses?: BorrowerExpensesAO,
  primaryOnChange: (income: ExpenseFields) => void,
  secondaryOnChange?: (income: ExpenseFields) => void,
  numberOfBorrowers: number
  showVehiclesExpenses?: boolean,
  showLabelTooltip?: boolean
  maxValue?: number
}
const ExpenseForm: React.FC<ExpenseType> = ({
  primaryExpenses,
  secondaryExpenses = {},
  primaryOnChange,
  secondaryOnChange,
  numberOfBorrowers,
  showVehiclesExpenses = true,
  showLabelTooltip = true,
  maxValue
}) => {
  const handleOnChangePrimary = (id: keyof ExpenseFields) => (value: number) => {
    primaryOnChange({ ...primaryExpenses, [ id ]: value })
  }

  const handleOnChangeSecondary = (id: keyof ExpenseFields) => (value: number) => {
    secondaryOnChange?.({ ...secondaryExpenses, [ id ]: value })
  }

  return <>
    <ExpensesInputRow
      id="livingCost"
      primaryValue={primaryExpenses.livingCost}
      secondaryValue={secondaryExpenses.livingCost}
      primaryOnChange={handleOnChangePrimary}
      secondaryOnChange={handleOnChangeSecondary}
      numberOfBorrowers={numberOfBorrowers}
      showLabelTooltip={showLabelTooltip}
      maxValue={maxValue}
    />
    <ExpensesInputRow
      id="rent"
      primaryValue={primaryExpenses.rent}
      secondaryValue={secondaryExpenses.rent}
      primaryOnChange={handleOnChangePrimary}
      secondaryOnChange={handleOnChangeSecondary}
      numberOfBorrowers={numberOfBorrowers}
      showLabelTooltip={showLabelTooltip}
      maxValue={maxValue}
    />
    <ExpensesInputRow
      id="buildingSaving"
      primaryValue={primaryExpenses.buildingSaving}
      secondaryValue={secondaryExpenses.buildingSaving}
      primaryOnChange={handleOnChangePrimary}
      secondaryOnChange={handleOnChangeSecondary}
      numberOfBorrowers={numberOfBorrowers}
      showLabelTooltip={showLabelTooltip}
      maxValue={maxValue}
    />
    <ExpensesInputRow
      id="saving"
      primaryValue={primaryExpenses.saving}
      secondaryValue={secondaryExpenses.saving}
      primaryOnChange={handleOnChangePrimary}
      secondaryOnChange={handleOnChangeSecondary}
      numberOfBorrowers={numberOfBorrowers}
      showLabelTooltip={showLabelTooltip}
      maxValue={maxValue}
    />
    <ExpensesInputRow
      id="alimony"
      primaryValue={primaryExpenses.alimony}
      secondaryValue={secondaryExpenses.alimony}
      primaryOnChange={handleOnChangePrimary}
      secondaryOnChange={handleOnChangeSecondary}
      numberOfBorrowers={numberOfBorrowers}
      showLabelTooltip={showLabelTooltip}
      maxValue={maxValue}
    />
    <ExpensesInputRow
      id="healthInsurance"
      primaryValue={primaryExpenses.healthInsurance}
      secondaryValue={secondaryExpenses.healthInsurance}
      primaryOnChange={handleOnChangePrimary}
      secondaryOnChange={handleOnChangeSecondary}
      numberOfBorrowers={numberOfBorrowers}
      showLabelTooltip={showLabelTooltip}
      maxValue={maxValue}
    />
    <ExpensesInputRow
      id="pensionInsurance"
      primaryValue={primaryExpenses.pensionInsurance}
      secondaryValue={secondaryExpenses.pensionInsurance}
      primaryOnChange={handleOnChangePrimary}
      secondaryOnChange={handleOnChangeSecondary}
      numberOfBorrowers={numberOfBorrowers}
      showLabelTooltip={showLabelTooltip}
      maxValue={maxValue}
    />
    <ExpensesInputRow
      id="creditRate"
      primaryValue={primaryExpenses.creditRate}
      secondaryValue={secondaryExpenses.creditRate}
      primaryOnChange={handleOnChangePrimary}
      secondaryOnChange={handleOnChangeSecondary}
      numberOfBorrowers={numberOfBorrowers}
      showLabelTooltip={showLabelTooltip}
      maxValue={maxValue}
    />
    <ExpensesVehiclesStepperRow
      primaryValue={primaryExpenses.numberOfVehicles}
      secondaryValue={secondaryExpenses.numberOfVehicles}
      primaryOnChange={handleOnChangePrimary}
      secondaryOnChange={handleOnChangeSecondary}
      numberOfBorrowers={numberOfBorrowers}
      showVehiclesExpenses={showVehiclesExpenses}
    />
    <ExpensesInputRow
      id="other"
      primaryValue={primaryExpenses.other}
      secondaryValue={secondaryExpenses.other}
      primaryOnChange={handleOnChangePrimary}
      secondaryOnChange={handleOnChangeSecondary}
      numberOfBorrowers={numberOfBorrowers}
      showLabelTooltip={showLabelTooltip}
      maxValue={maxValue}
    />
  </>
}

export const getExpensesTotal = (expenseList: ExpenseFields[]) => {
  let total = 0

  expenseList.forEach((expense) => {
    for ( const key in expense ) {
      if (key !== 'numberOfVehicles' && key !== 'total') {
        total += expense[ key as keyof ExpenseFields ] || 0
      }
    }
  })
  return total
}


export default ExpenseForm
