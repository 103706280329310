import { FixedNominalInterestRate, FixedNominalInterestRateType } from './api/types/FixedNominalInterestRate'

export const getISODateString = (date?: Date): string | undefined => {
  return date?.toISOString().split('T')[ 0 ].trim()
}

export const mapToFixedInterestValue = (years: number): FixedNominalInterestRateType => {
  switch (years) {
    case 5:
      return FixedNominalInterestRate.FIVE_YEARS
    case 10:
      return FixedNominalInterestRate.TEN_YEARS
    case 15:
      return FixedNominalInterestRate.FIFTEEN_YEARS
    case 20:
      return FixedNominalInterestRate.TWENTY_YEARS
    case 30:
      return FixedNominalInterestRate.THIRTY_YEARS
    case 40:
      return FixedNominalInterestRate.FORTY_YEARS
    default:
      return FixedNominalInterestRate.TEN_YEARS
  }
}
