import { StepWrapper } from './StepWrapper'
import React, { FormEvent, RefObject, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RoadPage } from '../FinancingRequestRoad'
import { Address, AddressFields } from '../address/AddressComponent'
import { Button } from 'is24-corecss'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../../redux/store'
import { LeadEngineState } from '../state/reducer'
import {  onUpdatePropertyData } from '../state/thunks/app'
import { withPageViewReporting } from '../hoc/withTrackPageView'
import { goToRoadPage } from '../routing'
import { MenuBar } from '../MenuBar'
import { useNavigate } from 'react-router-dom'


const PropertyAddressPage: React.FC<{ nextPage: string }> = ({ nextPage }) => {
  const { t } = useTranslation('leadEngine')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { property } = useSelector<ApplicationState, LeadEngineState>(state => state.leadEngine)

  const streetRef = useRef<HTMLInputElement>(null)
  const streetNumberRef = useRef<HTMLInputElement>(null)
  const postalCodeRef = useRef<HTMLInputElement>(null)
  const locationRef = useRef<HTMLInputElement>(null)
  const refsCollection: RefObject<any>[] = [ streetRef, locationRef,streetNumberRef, postalCodeRef ]

  const { postalCode = '', street = '', location = '', streetNumber = '' } = property
  const [ address, setAddress ] = useState<AddressFields>({
    postalCode,
    streetNumber,
    street,
    location
  })

  const [ touched, setTouched ] = useState<string[]>([])

  const isRefValid = (ref: RefObject<HTMLInputElement>): boolean => {
    return !ref.current || ref.current.validity.valid
  }

  const isRefUntouched = (ref: RefObject<HTMLInputElement>): boolean => {
    return ref.current === null || !touched.includes(ref.current.name)
  }

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()

    const isFormValid = refsCollection.filter(ref => ref.current).filter(ref => !isRefValid(ref)).length === 0

    if (isFormValid) {
      dispatch(onUpdatePropertyData(address))
      goToRoadPage(nextPage, navigate)
    } else {
      setTouched(refsCollection.map(ref => ref.current?.id))
    }
  }

  return (<>
    <MenuBar page={RoadPage.PROPERTY_ADDRESS}/>
    <StepWrapper title={t('propertyAddress.title')}>
      <form noValidate={true} onSubmit={handleSubmit}>
        <Address postalCodeRef={postalCodeRef}
                 locationRef={locationRef}
                 streetRef={streetRef}
                 streetNumberRef={streetNumberRef}
                 value={address}
                 validation={{
                   postalCode: isRefUntouched(postalCodeRef) || isRefValid(postalCodeRef),
                   location: isRefUntouched(locationRef) || isRefValid(locationRef),
                   street: isRefUntouched(streetRef) || isRefValid(streetRef),
                   streetNumber: isRefUntouched(streetNumberRef) || isRefValid(streetNumberRef),
                 }}
                 onChange={setAddress}
        />

        <div className='align-right border-top padding-top margin-top'>
          <Button appearance="secondary" type="submit">{t('common:button.acquire')}</Button>
        </div>
      </form>
    </StepWrapper>
  </>)
}

export default withPageViewReporting(PropertyAddressPage, 'ext.object_address')
