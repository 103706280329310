import React, { useEffect, useState } from 'react';
import baufilogo from '../../../assets/images/baufilogo.png';
import { Button } from 'is24-corecss';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../redux/store';
import { LeadEngineState } from '../state/reducer';
import optimizelyClient, { OptimizelyEvent } from '../../../config/optimizely'

type Props = {
  onAdvisoryAvailable: (available: boolean) => void
};

export const AdvisoryRecommendationOld: React.FC<Props> = ({ onAdvisoryAvailable }) => {

  const calculateTimeDifference = (expiryDate: number) => {
    return expiryDate - new Date().getTime();
  }

  const { t } = useTranslation('leadEngine');
  const { advisoryLink } = useSelector<ApplicationState, LeadEngineState>(state => state.leadEngine);
  const [ timeDifference, setTimeDifference ] = useState(calculateTimeDifference(advisoryLink?.expiryDate ?? Date.now()));
  const handleClick = (): void => {
    optimizelyClient.track(OptimizelyEvent.BOOKING_APPLICATION_WIDGET)
  }
  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeDifference(calculateTimeDifference(advisoryLink?.expiryDate ?? Date.now()));
    }, 1000);
    return () => {
      clearTimeout(timer);
    }
  });

  useEffect(() => {
    const isAdvisoryAvailable = (): boolean => !!advisoryLink && !!advisoryLink.link;
    onAdvisoryAvailable(isAdvisoryAvailable() && timeDifference > 0);
  }, [ timeDifference, onAdvisoryAvailable, advisoryLink ])

  return (timeDifference > 0 ?
    <section data-testid='advisory-recommendation' className='card margin-bottom align-center'
             style={{ marginLeft: 'auto', marginRight: 'auto' }}>
      <div className='card-header margin-bottom-m'>
        <img alt={'baufilogo'} src={baufilogo}/>
      </div>
      <div className='card-body padding-bottom-l' style={{ height: 'auto' }}>
        <p className='padding-bottom-s font-bold'
           style={{ fontSize: '16px' }}>{t('advisorySuccess.cardHeadline')}</p>
        <p>{t('advisorySuccess.cardBody')}</p>
      </div>
      <a href={advisoryLink?.link}>
        <div className='card-footer align-center'>
          <Button onClick={handleClick} data-testid='advisory-button'>{t('advisorySuccess.cardButton')}</Button>
        </div>
      </a>
    </section> : null);
};
