import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'is24-corecss'
import { formatNumber, toCurrency } from '../../../../services/NumberUtils'
import './AdditionalCosts.less'
import AdditionalCostsDetailsModal from './additionalCostsDetailsModal/AdditionalCostsDetailsModal'
import { calculateAmount, sumPercents } from '../service'
import { AdditionalCostsInPercents } from '../model'

export type AdditionalCostsProps = {
  purchasePrice: number,
  additionalCostsInPercents: AdditionalCostsInPercents,
  updateAdditionalCostsInPercents: (value: AdditionalCostsInPercents) => void
}

const AdditionalCosts: React.FC<AdditionalCostsProps> = ({
  purchasePrice,
  additionalCostsInPercents,
  updateAdditionalCostsInPercents
}) => {
  const { t } = useTranslation('widgets', { keyPrefix: 'financingCalculator.additionalCosts' })
  const [ modalVisible, setModalVisible ] = useState(false)

  const percentsSum = useMemo(() => sumPercents(additionalCostsInPercents), [ additionalCostsInPercents ])

  const additionalCostsSum =  calculateAmount(percentsSum, purchasePrice)

  return (
    <div className='FinancingCalculatorAdditionalCosts padding-top-l'>
      <div className='grid grid-flex'>
        <div className='grid-item one-half'>
          <div className='font-bold'>{t('title')}</div>
          <Button className='details-padding' textStyle={true} onClick={() => setModalVisible(true)}>
            {t('details')}
          </Button>
        </div>
        <div className='grid-item one-half align-right'>
          <div className='cost-and-percent-padding font-bold'>
            <span className='padding-right-m'>{toCurrency(additionalCostsSum)}</span> {formatNumber(percentsSum, 0, 2)} %
          </div>
        </div>
      </div>
      {modalVisible && <AdditionalCostsDetailsModal
        purchasePrice={purchasePrice}
        additionalCostsInPercents={additionalCostsInPercents}
        updateAdditionalCostsInPercents={updateAdditionalCostsInPercents}
        onClose={() => setModalVisible(false)}
      />}
    </div>
  )
}

export default AdditionalCosts
