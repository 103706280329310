import React, { ReactNode } from 'react'
import ClaraAvatar from '../../common/clara/ClaraAvatar'
import './stepWrapper.less'
import { useNavigate } from 'react-router-dom'

interface Props {
  title: ReactNode,
  text?:string | React.ReactNode,
  children: React.ReactNode,
  backIcon?: boolean,
  titleFontSize?: string,
}

export const StepWrapper = ({ title, text, children, backIcon, titleFontSize }: Props) => {
  const navigate = useNavigate()

  return (
    <>
      <div className='StepWrapper align-center padding-top-l'>
        <ClaraAvatar/>
        {backIcon ? (
            <span className='align-center padding-top-l'>
              <i role='button'
                 tabIndex={0}
                 className='is24-icon-chevron-left margin-right-l backIcon'
                 onClick={() => navigate(-1)} />
              <span className="title padding-top-l palm-padding-top-m" style={{ fontSize: titleFontSize }}>
                {title}
              </span>
            </span>
        ) : (
          <div className="title padding-top-l palm-padding-top-m" style={{ fontSize: titleFontSize }}>{title}</div>
        )}
        <div className='text margin-bottom-l'>{text}</div>
      </div>
      {children}
    </>
  )
}
