import { AdditionalCostsObjLocal, FinancingData, FinancingDataLocal } from './types'
import { AdditionalCostsInPercents } from '../../model'
import { formatFinancingType } from '../../../../../api/api/types/FinancingType'
import { mapToFixedInterestValue } from '../../../../../api/utils'


const calculateAdditionalCostSum = (purchasePrice: number, percentage: number): number =>
  Math.round(percentage * purchasePrice / 100)

const mapToAdditionalCostObjLocal = (
  additionalCostsPercentages: AdditionalCostsInPercents,
  purchasePrice: number
): AdditionalCostsObjLocal => {
  const { brokerCommission, landRegistration, landTransfer, notaryCosts } = additionalCostsPercentages
  const brokerCommissionSum = calculateAdditionalCostSum(purchasePrice, brokerCommission)
  const landRegistrationSum = calculateAdditionalCostSum(purchasePrice, landRegistration)
  const landTransferSum = calculateAdditionalCostSum(purchasePrice, landTransfer)
  const notaryCostsSum = calculateAdditionalCostSum(purchasePrice, notaryCosts)

  const sum = brokerCommissionSum + landRegistrationSum + landTransferSum + notaryCostsSum
  const percent = (100 * sum) / purchasePrice

  return {
    brokerCommission: { percent: brokerCommission, sum: brokerCommissionSum },
    landRegistration: { percent: landRegistration, sum: landRegistrationSum },
    landTransfer: { name: '', percent: landTransfer, sum: landTransferSum },
    notaryCosts: { percent: notaryCosts, sum: notaryCostsSum },
    percent,
    sum
  }
}

export const mapToFinancingDataLocal = (financingData: FinancingData): FinancingDataLocal => {
  const additionalCostsObjLocal: AdditionalCostsObjLocal =
    mapToAdditionalCostObjLocal(financingData.additionalCostsInPercents, financingData.purchasePrice)

  return {
    additionalCosts: additionalCostsObjLocal.sum,
    additionalCostsObj: additionalCostsObjLocal,
    amortizationRate: financingData.amortizationRate,
    financingType: formatFinancingType(financingData.financingType),
    fixedNominalInterestRate: mapToFixedInterestValue(financingData.fixedRateEndYear),
    geoCode: financingData.region?.geoCode || '',
    postalCode: financingData.region?.postalCode || '',
    ownFunds: financingData.ownCapital,
    propertyValue: financingData.propertyValue,
    purchasePrice: financingData.purchasePrice,
    remainingDebt: financingData.remainingDebt,
    searchLabel: financingData.region?.locationLabel || '',
    cleared: true
  }
}
