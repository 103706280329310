import React, { useState } from 'react';
import { InputWithSlider } from '../../common/inputWithSlider/InputWithSlider'
import { useTranslation } from 'react-i18next'
import { Button } from 'is24-corecss'

type MortgageCalculatorType = {
  initialPurchasePrice?: string,
  initialEquityCapital?: string,
  targetLink: string,
  headline?: string,
  buttonText?: string,
  buttonColor?: string,
}

const MortgageCalculator = ({
  initialPurchasePrice = '250000',
  initialEquityCapital = '50000',
  targetLink,
  headline,
  buttonColor,
  buttonText,
}: MortgageCalculatorType ) => {
  const { t } = useTranslation('widgets')

  const convertedInitialPurchasePrice = Number(initialPurchasePrice)
  const convertedInitialEquityCapital = Number(initialEquityCapital)

  const [purchasePrice, onPurchasePriceChange] = useState(convertedInitialPurchasePrice)
  const [ownFunds, onOwnFundsChange] = useState(convertedInitialEquityCapital)

  const campaign = 'cmp_id=10-02404&cmp_name=finance_calculator&cmp_position=finance_content&cmp_creative=calculator'
  const redirect = `${targetLink}?${campaign}&purchasePrice=${purchasePrice}&ownFunds=${ownFunds}`

  return (
    <div className='margin-vertical-l'>
      <h3>{headline ?? t('mortgageCalculator.title')}</h3>
      <div className="margin-top-l">
        <InputWithSlider
          id='purchasePrice'
          onChange={onPurchasePriceChange}
          value={purchasePrice}
          title={t('mortgageCalculator.purchasePrice.title')}
          tooltipText={t('mortgageCalculator.purchasePrice.tooltipText')}
          min={100000}
        />
      </div>
      <InputWithSlider
        id='ownFunds'
        title={t('mortgageCalculator.ownFunds.title')}
        value={ownFunds}
        min={0}
        max={500000}
        tooltipText={t('mortgageCalculator.ownFunds.tooltipText')}
        onChange={onOwnFundsChange}
      />
      <Button className={`palm-one-whole ${buttonColor ?? ''}`} renderContainer={(props: Object) =>
        <a {...props} href={redirect}>
          {buttonText ?? t('mortgageCalculator.calculatorCTA')}
        </a>
      }
      />
    </div>
  );
}

export default MortgageCalculator
