import { MonthlyRepayment, MonthlyScheduleEntry, YearlyRepayment } from './FinancingResults'

const mapToMonthlyRepayments = (entries: MonthlyScheduleEntry[]): MonthlyRepayment[] =>
  entries.map(entry => ({
    month: entry.month,
    monthlyInstallment: entry.instalment,
    monthlyInterest: entry.interestAmount,
    monthlyAmortization: entry.amortizationAmount,
    monthlyOverpayment: entry.overpaymentAmount,
    monthlyRemainingDebt: entry.remainingLoanAmount
  }))

const sum = (entries: MonthlyScheduleEntry[], extractFn: (entry: MonthlyScheduleEntry) => number) =>
  entries.reduce((acc, entry) => acc + extractFn(entry), 0)

export const mapToYearlyRepayments = (entries: MonthlyScheduleEntry[]): YearlyRepayment[] => {
  const uniqueYears: number[] = [ ...new Set(entries.map(e => e.year)) ]
  return uniqueYears.map(year => {
    const entriesPerYear = entries.filter(e => e.year === year)
    return {
      year,
      yearlyInstallment: sum(entriesPerYear, e => e.instalment),
      yearlyInterest: sum(entriesPerYear, e => e.interestAmount),
      yearlyAmortization: sum(entriesPerYear, e => e.amortizationAmount),
      yearlyOverpayment: sum(entriesPerYear, e => e.overpaymentAmount),
      yearlyRemainingDebt: entriesPerYear[ entriesPerYear.length - 1 ].remainingLoanAmount,
      monthlyRepayments: mapToMonthlyRepayments(entriesPerYear)
    }
  })
}

export const mapToMonthlyRemainingDebtAmounts = (entries: MonthlyScheduleEntry[]): number[] =>
  entries.map(entry => entry.remainingLoanAmount)

