import { ButtonGroup } from './ButtonGroup'
import { StepWrapper } from './StepWrapper'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { RoadPage } from '../FinancingRequestRoad'
import { PropertyType } from '../../../api/api/types/PropertyType'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../../redux/store'
import { LeadEngineState } from '../state/reducer'
import { onUpdatePropertyData } from '../state/thunks/app'
import { withPageViewReporting } from '../hoc/withTrackPageView'
import { goToRoadPage } from '../routing'
import { MenuBar } from '../MenuBar'
import { useNavigate } from 'react-router-dom'

type PropertyTypePageType = {
  nextPage: (type: PropertyType) => string
}

const PropertyValues: PropertyType[] = [
  'APARTMENT_BUY',
  'HOUSE_BUY',
  'LIVING_BUY_SITE',
]

const PropertyTypePage: React.FC<PropertyTypePageType> = ({ nextPage }) => {
  const { t } = useTranslation('leadEngine')
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { property: { type } } = useSelector<ApplicationState, LeadEngineState>(state => state.leadEngine)

  const handleNextPage = (type: PropertyType) => {
    dispatch(onUpdatePropertyData({ type }))
    goToRoadPage(nextPage(type), navigate)
  }

  return <>
    <MenuBar page={RoadPage.PROPERTY_TYPE}/>
    <StepWrapper title={t('propertyType.title')}>
      <ButtonGroup<PropertyType>
        values={PropertyValues}
        onClick={handleNextPage}
        value={type}
      />
    </StepWrapper>
  </>
}

export default withPageViewReporting(PropertyTypePage, 'ext.object_type')
