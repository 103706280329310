export const PurchaseProjectStateExtended = {
  ON_OBJECT_SEARCH: 'ON_OBJECT_SEARCH',
  OBJECT_INTERESTED: 'OBJECT_INTERESTED',
  OBJECT_VIEWING: 'OBJECT_VIEWING',
  PURCHASING_NEGOTIATIONS: 'PURCHASING_NEGOTIATIONS',
  AWARDED: 'AWARDED',
  NOTARY_APPOINTMENT_PLANNED: 'NOTARY_APPOINTMENT_PLANNED',
  NA: 'NA',
} as const
export type PurchaseProjectStateExtendedType = keyof typeof PurchaseProjectStateExtended

export const BuildingProjectStateExtended = {
  PROPERTY_FOUND: 'PROPERTY_FOUND',
  NOTARY_APPOINTMENT_PLANNED: 'NOTARY_APPOINTMENT_PLANNED',
  ON_BUILDER_SEARCH: 'ON_BUILDER_SEARCH',
  NA: 'NA',
} as const
export type BuildingProjectStateExtendedType = keyof typeof BuildingProjectStateExtended

export type ProjectStateExtended = PurchaseProjectStateExtendedType | BuildingProjectStateExtendedType


