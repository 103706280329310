import React from 'react'
import CustomTooltip from '../../../common/customTooltip/CustomTooltip'

export type LabelWithTooltipProps = {
  title: string,
  tooltipText: string,
  tooltipTitle?: string
}

const LabelWithTooltip: React.FC<LabelWithTooltipProps> = ({ title, tooltipText, tooltipTitle }) => (
  <div className='LabelWithTooltip'>
    <span className='font-bold font-s'>{title}</span>
    <CustomTooltip title={tooltipTitle ? tooltipTitle : title} text={tooltipText} bodyPosition='top'
                   arrowPosition='left' minWidth={200}
                   fontSize='font-l'/>
  </div>
)

export default LabelWithTooltip

