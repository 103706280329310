import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../../redux/store'
import { LeadEngineState } from '../state/reducer'
import { BorrowerDataForm } from './BorrowerDataForm'
import { onBorrowersDataSubmit } from '../state/thunks/app'
import { Borrower, PrimaryBorrower } from '../models/Financing'
import { withPageViewReporting } from '../hoc/withTrackPageView'
import { RoadPage } from '../FinancingRequestRoad'
import { useNavigate } from 'react-router-dom'

type PrimaryBorrowerFormType = {
  nextPage: (count: number) => string
}

const PrimaryBorrowerForm: React.FC<PrimaryBorrowerFormType> = ({ nextPage }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {
    borrowers: {
      primary: primaryBorrower
    },
    financing: {
        numberOfBorrowers = 1
    }
  } = useSelector<ApplicationState, LeadEngineState>(state => state.leadEngine)

  const onNextPage = (data: Borrower) =>
    dispatch(onBorrowersDataSubmit(nextPage(numberOfBorrowers), { primary: data as Partial<PrimaryBorrower> }, navigate))

  return <BorrowerDataForm borrowerNumber='1' borrower={primaryBorrower} onSubmit={onNextPage} page={RoadPage.BORROWERS_DATA}/>
}

export default withPageViewReporting(PrimaryBorrowerForm, 'ext.borrower1_personaldata')
