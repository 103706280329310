import { BaufiExceptionType } from '../exceptions'

export const ContactRequestError = {
  SURNAME_BLACKLISTED: 'SURNAME_BLACKLISTED',
  FORENAME_BLACKLISTED: 'FORENAME_BLACKLISTED',
  EMAIL_BLACKLISTED: 'EMAIL_BLACKLISTED',
  RECENTLY_CREATED_REQUEST: 'RECENTLY_CREATED_REQUEST',
  INVALID_POSTALCODE: 'INVALID_POSTALCODE',
  TELEPHONE_IS_EMPTY: 'TELEPHONE_IS_EMPTY',
  TELEPHONE_IS_NO_GERMAN_NUMBER: 'TELEPHONE_IS_NO_GERMAN_NUMBER',
  UNKNOWN: 'UNKNOWN'
} as const
export type ContactRequestErrorType = keyof typeof ContactRequestError

export const convertToContactRequestErrors = (e: BaufiExceptionType) => {
  if (e.message) {
    return e.message.map(error => {
      if (error.message.includes('FORENAME is blacklisted')) {
        return ContactRequestError.FORENAME_BLACKLISTED
      } else if (error.message.includes('SURNAME is blacklisted')) {
        return ContactRequestError.SURNAME_BLACKLISTED
      } else if (error.message.includes('EMAIL is blacklisted')) {
        return ContactRequestError.EMAIL_BLACKLISTED
      } else if (error.message.includes('we recently received a financing request from that requester')) {
        return ContactRequestError.RECENTLY_CREATED_REQUEST
      } else if (error.message.includes('contactRequest.phoneNumber reason=must not be empty')) {
        return ContactRequestError.TELEPHONE_IS_EMPTY
      } else if (error.message.includes('contactRequest.contactAddress.postalCode')) {
        return ContactRequestError.INVALID_POSTALCODE
      } else if (error.message.includes('The given phoneNumber is no valid German phone/mobile number')) {
        return ContactRequestError.TELEPHONE_IS_NO_GERMAN_NUMBER
      } else {
        return ContactRequestError.UNKNOWN
      }
    })
  } else {
    return [ ContactRequestError.UNKNOWN ]
  }
}
