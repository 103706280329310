import {
  MORTGAGE_PROFILE,
  MORTGAGE_PROFILE_FEASIBILITY_TAB_PATH,
  MORTGAGE_PROFILE_OFFERS_TAB_PATH
} from '../../../config/contextPaths'
import { useURLSearchParams } from '../../../hooks/useURLSearchParams'

export const CERTIFICATE_MODE_URL_PARAM = 'zertifikat'

const usePreApprovalResultPageUrl = (): string => {
  const queryParams = useURLSearchParams()

  // TODO remove the line below as soon as the pre-approval link in the CMS fragment "campaigns/is24/expose/verfuegbare-services/postbank.html" gets updated with the "zertifikat" query param
  const isFromExposeServiceBox = queryParams.get('cmp_position') === 'expose_servicebox'
  const certificateMode = queryParams.has(CERTIFICATE_MODE_URL_PARAM) || isFromExposeServiceBox
  const profileTabPath = certificateMode ? MORTGAGE_PROFILE_FEASIBILITY_TAB_PATH : MORTGAGE_PROFILE_OFFERS_TAB_PATH
  return `${MORTGAGE_PROFILE}${profileTabPath}`
}

export default usePreApprovalResultPageUrl
