import React, { useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Button, Font, Loader } from 'is24-corecss'
import { ApplicationState } from '../../../../../../redux/store'
import { LocationType } from '../../../../../mortgageOfficer/state/reducer'
import { LogInState } from '../../../../../../redux/reducers/Login'
import ShortlistDropdown, { ExposeEntry } from '../../../../shortlistDropdown/ShortlistDropdown'
import { ShortlistEntryAO } from '../../../../../../api/api/types/ShortlistEntryAO'
import { Expose, fetchExposeEntity } from '../../../../../../api/api/Expose'
import { getLocationFromExpose } from './ShortlistSelectionUtils'
import './shortlistSelection.less'
import { redirectToSso } from '../../../../../../services/SsoService'
import { shortlistTabPathParam } from './useShortlistTabPreselection'
import { isDefined } from '../../../../../../utils/utils'

interface ShortlistSelectionType {
  exposeId?: number
  exposeFromShortlist?: boolean
  onExposeSelect: (location: LocationType | undefined, expose: Expose | undefined, exposeFromShortlist: boolean | undefined) => void
  optionalPlaceholder? : boolean
}

enum ErrorState {
  NONE = 'NONE', EXPOSE_NOT_FOUND = 'EXPOSE_NOT_FOUND', OTHER_ERROR = 'OTHER_ERROR'
}

const ShortlistSelection: React.FC<ShortlistSelectionType> = (
  {
    exposeId,
    exposeFromShortlist,
    onExposeSelect,
    optionalPlaceholder = false
  }) => {
  const [ errorState, setErrorState ] = useState<ErrorState>(ErrorState.NONE)
  const [ isLoading, setLoading ] = useState<boolean>(true);
  const [ hasEntries, setHasEntries ] = useState<boolean>(false);
  const { loggedIn } = useSelector<ApplicationState, LogInState>(state => state.logIn);
  const { t } = useTranslation('odf');

  const handleLoadEntries = useCallback(() => setLoading(true), [])

  const currentLocation = window.location.href
  const returnUrl = `${currentLocation}?${shortlistTabPathParam}`
  const ssoHandler = () => redirectToSso('financingFlow', returnUrl)
  const hasExternalExpose = isDefined(exposeId) && !exposeFromShortlist

  const handleEntriesLoaded = useCallback((shortlistEntries: ShortlistEntryAO[]) => {
    setHasEntries(shortlistEntries.length !== 0 || hasExternalExpose);
    setLoading(false);
  }, [ hasExternalExpose ])

  const handleSelectionChanged = async (selectedEntry: ExposeEntry | undefined) => {
    setErrorState(ErrorState.NONE)

    if (!selectedEntry) {
      onExposeSelect(undefined, undefined, undefined)
      return
    }

    try {
      const { exposeId } = selectedEntry
      const expose = await fetchExposeEntity(exposeId)
      if (expose) {
        onExposeSelect(getLocationFromExpose(expose), expose, !selectedEntry.externalExpose)
      } else {
        setErrorState(ErrorState.EXPOSE_NOT_FOUND)
      }
    } catch (error) {
      console.error(error)
      setErrorState(ErrorState.OTHER_ERROR)
    }
  }

  return (
    <>
      {(loggedIn || hasExternalExpose) ?
        <div>
          <div className={`center ${isLoading ? '' : 'hidden'}`} data-testid='loading-spinner'>
            <Loader/>
          </div>
          <div className={isLoading ? 'hidden' : ''}>
            <div data-testid='shortlist-dropdown' className={hasEntries ? '' : 'hidden'}>
              <ShortlistDropdown
                exposeId={exposeId}
                exposeFromShortlist={exposeFromShortlist}
                loggedIn={loggedIn || false}
                onLoadingEntries={handleLoadEntries}
                onEntriesLoaded={handleEntriesLoaded}
                onSelectionChanged={handleSelectionChanged}
                ssoHandler={ssoHandler}
                optionalPlaceholder={optionalPlaceholder}
              />
              {errorState !== ErrorState.NONE && <Font color='error'><Trans t={t} i18nKey={`userFunnel.location.shortlist_tab.errorMessage.${errorState}`}><br className='palm-hide'/></Trans></Font>}
            </div>
            <div className={`center ${hasEntries ? 'hidden' : ''}`} data-testid='info-text'>
              <span className='info-text font-xs font-line-xs'>
                {t('userFunnel.location.shortlist_tab.noEntriesDescription')}
              </span>
            </div>
          </div>
        </div>
        :
        <div className='align-center padding-bottom'>
          <div className='margin-bottom-s'>{t('userFunnel.location.shortlist_tab.loginCaption')}</div>
          <Button
            appearance='secondary'
            className='button'
            onClick={ssoHandler}>{t('userFunnel.location.shortlist_tab.loginButton')}
          </Button>
        </div>}
    </>
  )
};

export default ShortlistSelection;
