import React, { useEffect } from 'react'
import { Button } from 'is24-corecss'
import './StickyNextButton.less'

export type StickyNextButtonProps = {
  text: string
  onClick: () => void
  disabled?: boolean
}

const PALM_MAX_WIDTH = 668

const StickyNextButton: React.FC<StickyNextButtonProps> = ({ text, onClick, disabled = false }) => {

  useEffect(() => {
    const footer = document.getElementById('is24-ui-navigation-footer');
    if (footer && window.innerWidth <= PALM_MAX_WIDTH) {
      footer.style.paddingBottom = '60px';
    }
    return () => {
      if (footer) {
        footer.style.paddingBottom = '0';
      }
    }
  }, []);

  return (
    <div className='StickyNextButton'>
      <div className='Button'>
        <Button appearance='primary' onClick={onClick} className='forward-button' disabled={disabled}>
          {text}
        </Button>
      </div>
    </div>
  )
}

export default StickyNextButton
