import React from 'react'
import { Accordion, AccordionItem } from 'is24-corecss'

export type AccordionWrapperProps = {
  accordionTitle: string
  isAccordionVisible?: boolean
  children?: React.ReactNode
}

const AccordionWrapper: React.FC<AccordionWrapperProps> = ({ accordionTitle, isAccordionVisible = true, children }) => {
  return (
    isAccordionVisible ?
      <div>
        <Accordion>
          <AccordionItem defaultExpanded={false} title={accordionTitle}>
            {children}
          </AccordionItem>
        </Accordion>
      </div>
      :
      <>{children}</>
  )
}

export default AccordionWrapper
