import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'is24-corecss'
import '../financingForm.less'
import { withPageViewReporting } from '../../hoc/withTrackPageView'
import { StepWrapper } from '../../pages/StepWrapper'
import React, { useEffect, useState } from 'react'
import { ApplicationState } from '../../../../redux/store'
import { AddressFields } from '../../address/AddressComponent'
import { ModalDisclaimer } from '../../modalDisclaimer/ModalDisclaimer'
import AddressGAC from '../../address/AddressGAC'
import { ContactRequestError } from '../../../../api/api/ContactRequestExceptions'
import ValidatedPhoneNumberInput from '../../../common/phoneNumberInput/ValidatedPhoneNumberInput'
import { PrimaryBorrower } from '../../models/Financing'
import { goToRoadPage } from '../../routing'
import { updateBorrowersData } from '../../state/actions'
import { recordContactDetails } from '../../state/thunks/app'
import './ContactDetails.less'
import StickyNextButton from '../../pages/common/stickyNextButton/StickyNextButton'
import StaticStarRating from '../../staticStarRating/StaticStarRating'
import { useNavigate } from 'react-router-dom'

export type ContactDetailsType = { nextPage: string, errorGoToPage: string }

type Validation = {
  postalCode?: boolean,
  location?: boolean,
  street?: boolean,
  streetNumber?: boolean,
  phone?: boolean,
  availability?: boolean,
}

type Touched = Validation

const ALL_TOUCHED = {
  postalCode: true,
  location: true,
  street: true,
  streetNumber: true,
  phone: true,
  availability: true,
}

const ContactDetails: React.FC<ContactDetailsType> = ({ nextPage, errorGoToPage }) => {
  const { t } = useTranslation('leadEngine', { keyPrefix: 'financingRequest.telephoneNumber' })
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const borrower = useSelector<ApplicationState, Partial<PrimaryBorrower>>(state => state.leadEngine.borrowers.primary)
  const errors = useSelector<ApplicationState, string[]>(state => state.leadEngine.errors)

  const { postalCode = '', street = '', streetNumber = '', location = '' } = borrower

  const [ phone, setPhone ] = useState(borrower.phone)
  const [ phoneValid, setPhoneValid ] = useState(!!phone)
  const [ address, setAddress ] = useState<AddressFields>({ postalCode, streetNumber, street, location })

  const [ touched, setTouched ] = useState<Touched>({})
  const [ validation, setValidation ] = useState<Validation>({})

  const [ isDisclaimerOpen, showDisclaimer ] = useState(false)

  useEffect(() => {
    setValidation({
      phone: phoneValid,
      postalCode: !!address.postalCode && !errors.includes(ContactRequestError.INVALID_POSTALCODE),
      location: !!address.location,
      street: !!address.street,
      streetNumber: !!address.streetNumber,
    })
  }, [ phoneValid, address, errors ])

  const handleBlur = (field: string) => setTouched(touched => ({ ...touched, [ field ]: true }))

  const handlePhoneChange = (value: string | undefined, valid: boolean) => {
    setPhone(value)
    setPhoneValid(valid)
  }

  const handleOpenDisclaimerClick = (e: any) => {
    e.preventDefault()
    showDisclaimer(true)
  }

  const handleSubmit = (): void => {
    setTouched(ALL_TOUCHED)

    const isFormValid = Object.values(validation).every(valid => valid)
    if (!isFormValid) return

    dispatch(updateBorrowersData({
      primary: {
        postalCode: address.postalCode,
        location: address.location,
        street: address.street,
        streetNumber: address.streetNumber,
      }
    }))

    dispatch(recordContactDetails({ phone }, nextPage, errorGoToPage, navigate))

    goToRoadPage(nextPage, navigate)
  }

  const title = <Trans i18nKey={borrower.forename?.trim() ? 'personalizedTitle' : 'title'}
                       values={{ firstName: borrower.forename }} t={t}>
    <br/>
  </Trans>

  return (
    <div className='contact-details'>
      <StepWrapper title={title}>
        <div className='grid-flex grid-justify-center'>
          <StaticStarRating vertical/>
        </div>
        <div className='question-container'>
          <div className='TelephoneNumber grid-flex'>
            <div className='grid-item grid-flex one-whole padding-top-l'>
              <div className='phoneNumberInput'>
                <ValidatedPhoneNumberInput
                  label={`${t('phoneNumber')}`}
                  value={phone}
                  valid={!touched.phone || validation.phone}
                  onChange={handlePhoneChange}
                  onBlur={() => handleBlur('phone')}
                />
              </div>
            </div>
            <div className='grid-item one-whole'>
              <AddressGAC
                value={address}
                validation={{
                  postalCode: !touched.postalCode || validation.postalCode,
                  location: !touched.location || validation.location,
                  street: !touched.street || validation.street,
                  streetNumber: !touched.streetNumber || validation.streetNumber,
                }}
                onChange={setAddress}
                onBlur={() => {
                  // no action
                }}
              />
            </div>
            <div className='grid-item grid-flex one-whole padding-top-l'>
              <p className='font-xs font-lightgray padding-vertical-s'>
                <Trans t={t} i18nKey='disclaimer'>
                  <Button
                    textStyle
                    aria-labelledby='disclaimer'
                    className='disclaimerButton'
                    onClick={handleOpenDisclaimerClick}
                  />
                </Trans>
                <ModalDisclaimer open={isDisclaimerOpen} onClose={() => showDisclaimer(false)}/>
              </p>
            </div>
            <StickyNextButton text={t('CTA')} onClick={handleSubmit}/>
          </div>
        </div>
      </StepWrapper>
    </div>
  )
}

export default withPageViewReporting(ContactDetails, 'telephonenumber')
