import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import '../RepaymentSchedule.less'

type SeparatorProps = {
  showOnMonth: boolean
  monthNumber?: number
  year?: number
}
export const Separator: React.FC<SeparatorProps> = ({ showOnMonth, monthNumber, year }) => {

  const { t } = useTranslation('odf')
  const separatorTranslationPrefix = 'financingFunnel.resultPage.loanDevelopmentSection.repaymentSchedule';

  return (
    <div className='separator grey-border-bottom' data-testid='separator-test-id'>
      {showOnMonth ?
        <div className='font-bold font'>{t(`${separatorTranslationPrefix}.separatorTitleMonth`)}</div>
        :
        <div className='font-bold font'>
          <Trans t={t} i18nKey={`${separatorTranslationPrefix}.separatorTitleYear`}>
            {monthNumber != undefined ? t(`commons.monthsFull.${monthNumber}`) : ''}
            {year ? year.toString() : ''}
          </Trans>
        </div>
      }
      <div>{t(`${separatorTranslationPrefix}.separatorDescription`)}</div>
    </div>
  )
}
