import React from 'react'
import { toCurrency } from '../../../../../services/NumberUtils'
import { TFunction } from 'i18next'
import TableRow from './TableRow'

type RepaymentScheduleFooterProps = {
  yearlyInstalmentSum: number
  yearlyInterestSum: number
  yearlyAmortizationSum: number
  yearlyOverpaymentSum: number
  yearlyRemainingDebtSum: number
  t: TFunction
}

export const RepaymentScheduleFooter: React.FC<RepaymentScheduleFooterProps> = (
  {
    yearlyInstalmentSum,
    yearlyInterestSum,
    yearlyAmortizationSum,
    yearlyOverpaymentSum,
    yearlyRemainingDebtSum,
    t
  }) => {

  return (
    <TableRow
      expandable={false}
      className={'teal-border-top'}
      rowContent={
        <div>
          <div className='cell font-bold'> {t('total')}</div>
          <div className='cell font-bold'>{toCurrency(yearlyInstalmentSum)}</div>
          <div className='cell font-bold'>{toCurrency(yearlyInterestSum)}</div>
          <div className='cell font-bold'>{toCurrency(yearlyAmortizationSum)}</div>
          <div className='cell font-bold'>{toCurrency(yearlyOverpaymentSum)}</div>
          <div className='cell font-bold'>{toCurrency(yearlyRemainingDebtSum)}</div>
        </div>
      }
    />
  )
}


