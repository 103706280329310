import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next'
import { TextInput } from 'is24-corecss'
import { Region, RegionSelection } from '../../common/input/regionSelection/RegionSelection'
import { AddressFields } from './AddressComponent'

type Validation = {
  postalCode?: boolean,
  location?: boolean,
  street?: boolean,
  streetNumber?: boolean,
}

interface Props {
  value: AddressFields,
  validation: Validation,
  onChange: (address: AddressFields) => void
  onBlur: (field: string) => void
}

const AddressGAC = ({ value, validation, onChange, onBlur }: Props) => {
  const { t } = useTranslation('leadEngine')

  const { postalCode = '', street = '', location = '', streetNumber = '' } = value
  const region = (postalCode && location) ? `${street}, ${postalCode} ${location}` : ''

  const handleRegionSelect = (region: Region | undefined) => {
    onChange({
      ...value,
      location: region?.city ?? '',
      postalCode: region?.postalCode ?? '',
      street: region?.street ?? '',
    })
    onBlur('postalCode')
    onBlur('location')
    onBlur('street')
  }

  return (
    <div className='grid-flex'>
      <div className='grid-item one-whole padding-top-l'>
        <RegionSelection
          placeholder=''
          label={t('address.streetAndCity')}
          value={region}
          queryType='street'
          valid={validation.postalCode && validation.location && validation.street}
          onRegionSelect={handleRegionSelect}
          errorMessage={t('locationDialog.errorMessage.address')}
        />
      </div>

      <div className='grid-item one-whole padding-top-l'>
        <TextInput
          id='streetNumber'
          name='streetNumber'
          defaultValue={streetNumber}
          label={t('address.streetNumber')}
          placeholder=''
          valid={validation.streetNumber}
          aria-invalid={!(validation.streetNumber ?? true)}
          onChange={(e: ChangeEvent<HTMLInputElement>) => onChange({ ...value, streetNumber: e.target.value })}
          onBlur={() => onBlur('streetNumber')}
          autoComplete='off'
        />
      </div>
    </div>
  );
};

export default AddressGAC;
