import { ButtonGroup } from './ButtonGroup'
import React from 'react'
import { Button, StatusMessage } from 'is24-corecss'
import { RoadPage } from '../FinancingRequestRoad'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../../redux/store'
import { LeadEngineState } from '../state/reducer'
import { onFinancingRequestSubmit } from '../state/thunks/app'
import { withPageViewReporting } from '../hoc/withTrackPageView'
import { goToRoadPage } from '../routing'
import { useTranslation } from 'react-i18next'
import { MenuBar } from '../MenuBar'
import { StepWrapper } from './StepWrapper'
import { useNavigate } from 'react-router-dom'

const BorrowerValues = [
  'ONE_BORROWER',
  'TWO_BORROWERS'
]

type NumberOfBorrowersPageType = {
  nextPage: (count: number) => string
  skipPage: string
}

const NumberOfBorrowersPage: React.FC<NumberOfBorrowersPageType> = ({ nextPage, skipPage }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation('leadEngine')
  const { financing: { numberOfBorrowers } } = useSelector<ApplicationState, LeadEngineState>(state => state.leadEngine)

  const handleNextPage = (borrower: string) => {
    const numberOfBorrowers = BorrowerValues.indexOf(borrower) + 1
    dispatch(onFinancingRequestSubmit(nextPage(numberOfBorrowers), navigate)({ numberOfBorrowers }))
  }

  const handleSkipPage = () => goToRoadPage(skipPage, navigate)

  return <>
    <MenuBar page={RoadPage.NUMBER_OF_BORROWERS} hideBack={true}/>
    <div className="margin-top">
      <StatusMessage
        headline={t('borrowerData.hint.title')}
        message={t('borrowerData.hint.message')}
        statusType="confirm"
      />
    </div>

    <StepWrapper title={t('borrowerData.content.title')}>
      <ButtonGroup
        values={BorrowerValues}
        onClick={handleNextPage}
        value={BorrowerValues[numberOfBorrowers]}
      />

      <div className="grid-flex grid-align-center margin-top margin-bottom">
        <div className="one-tenth align-right padding-right"><i
          className="is24-icon-info1 font-xl"/></div>
        <div className="nine-tenth">
          {t('borrowerData.content.text')}
        </div>
      </div>
      <div className="align-center">
        <Button textStyle={true} onClick={handleSkipPage}>
          {t('borrowerData.content.button')}
        </Button>
      </div>
    </StepWrapper>
  </>
}

export default withPageViewReporting(NumberOfBorrowersPage, 'ext.borrowers')
