import { StepWrapper } from './StepWrapper'
import React, { ChangeEvent, FormEvent, RefObject, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, TextInput } from 'is24-corecss'
import { PropertyTypeValue } from '../../../api/api/types/PropertyType'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../../redux/store'
import { LeadEngineState } from '../state/reducer'
import { withPageViewReporting } from '../hoc/withTrackPageView'
import { recordExtendedContactDetails } from '../state/thunks/app'
import { Property } from '../models/Property'
import { RoadPage } from '../FinancingRequestRoad'
import { MenuBar } from '../MenuBar'
import { useNavigate } from 'react-router-dom'

type PropertyDetailsPageType = {
  nextPage: string,
  advisorySwitchFeatureEnabled: boolean,
}

const PropertyDetailsPage: React.FC<PropertyDetailsPageType> = ({ nextPage , advisorySwitchFeatureEnabled = false}) => {
  const { t } = useTranslation('leadEngine')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { property } = useSelector<ApplicationState, LeadEngineState>(state => state.leadEngine)

  const livingAreaRef = useRef<HTMLInputElement>(null)
  const siteAreaRef = useRef<HTMLInputElement>(null)
  const constructionYearRef = useRef<HTMLInputElement>(null)
  const refsCollection: RefObject<any>[] = [ livingAreaRef, siteAreaRef, constructionYearRef ]

  const [ touched, setTouched ] = useState<string[]>([])

  const isRefValid = (ref: RefObject<HTMLInputElement>): boolean => {
    return !ref.current || (ref.current.validity.valid && ref.current.value !== '' && !isNaN(parseFloat(ref.current.value)))
  }

  const isRefUntouched = (ref: RefObject<HTMLInputElement>): boolean => {
    return ref.current === null || !touched.includes(ref.current.name)
  }

  const handleBlur = (event: ChangeEvent<HTMLInputElement>): void => {
    const { name } = event.target
    setTouched(state => ([ ...new Set([ ...state, name ]) ]))
  }

  const getRefValue = (ref: RefObject<HTMLInputElement>) => ref.current && parseFloat(ref.current.value)

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()

    const isFormValid = refsCollection.filter(ref => ref.current).filter(ref => !isRefValid(ref)).length === 0
    if (isFormValid) {
      const data: Property = {
        constructionYear: getRefValue(constructionYearRef)!,
        livingArea: getRefValue(livingAreaRef)!,
        siteArea: getRefValue(siteAreaRef)!
      }

      dispatch(recordExtendedContactDetails(nextPage, { property: data }, navigate, advisorySwitchFeatureEnabled))
    } else {
      setTouched([ ...refsCollection ].filter(ref => ref.current).map(ref => ref.current.name))
    }
  }

  const { type } = property

  return (
    <>
      <MenuBar page={RoadPage.PROPERTY_DETAILS}/>
      <StepWrapper title={t(`propertyDetails.title.${property.type}`)}>
        <form onSubmit={handleSubmit} noValidate={true} className='grid-flex gutter-m'>
          {type !== PropertyTypeValue.LIVING_BUY_SITE && (
            <div className='grid-item one-half palm-one-whole'>
              <TextInput
                id='livingArea'
                label=''
                name='livingArea'
                maxLength={5}
                placeholder={t('propertyDetails.placeholder.livingArea')}
                affixRight="unit"
                unitRight='sqm'
                ref={livingAreaRef}
                defaultValue={property.livingArea}
                onBlur={handleBlur}
                required
                valid={isRefUntouched(livingAreaRef) || isRefValid(livingAreaRef)}
              />
            </div>
          )}

          {type !== PropertyTypeValue.APARTMENT_BUY && (
            <div className='grid-item one-half palm-one-whole'>
              <TextInput
                id='siteArea'
                label=''
                name='siteArea'
                maxLength={5}
                placeholder={t('propertyDetails.placeholder.siteArea')}
                affixRight="unit"
                unitRight='sqm'
                ref={siteAreaRef}
                defaultValue={property.siteArea}
                onBlur={handleBlur}
                required
                valid={isRefUntouched(siteAreaRef) || isRefValid(siteAreaRef)}
              />
            </div>
          )}

          {type !== PropertyTypeValue.LIVING_BUY_SITE && (
            <div className='grid-item one-half palm-one-whole'>
              <TextInput
                id='constructionYear'
                label=''
                maxLength={4}
                minLength={4}
                name='constructionYear'
                placeholder={t('propertyDetails.placeholder.constructionYear')}
                defaultValue={property.constructionYear}
                onBlur={handleBlur}
                ref={constructionYearRef}
                required
                valid={isRefUntouched(constructionYearRef) || isRefValid(constructionYearRef)}
              />
            </div>
          )}

          <div className='one-whole align-right border-top padding-top margin-top'>
            <Button appearance="primary" type="submit">{t('common:button.send')}</Button>
          </div>
        </form>
      </StepWrapper>
    </>
  )
}

export default withPageViewReporting(PropertyDetailsPage, 'ext.object_character')
