const VALID_EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const isValidEmail = (email?: string) =>
  !!email &&
  VALID_EMAIL.test(email)

export const isNotEmptyString = (string?: string) => string !== undefined && string.trim() !== ''

const VALID_FIVE_DIGITS_STRING = /^[0-9]{5}$/;
export const isFiveDigitsString = (input?: string | null) => {
  return !!input && VALID_FIVE_DIGITS_STRING.test(input);
}
