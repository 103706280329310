import React, { ChangeEvent, RefObject, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Checkbox, RadioButton, TextInput } from 'is24-corecss'
import '../financingForm.less'
import { StepWrapper } from '../../pages/StepWrapper'
import { withPageViewReporting } from '../../hoc/withTrackPageView'
import FinancingFormStatusMessage from '../FinancingFormStatusMessage'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../../../redux/store'
import { LeadEngineState } from '../../state/reducer'
import { Salutation } from '../../../../api/api/types/Salutation.ds'
import { ContactRequestError } from '../../../../api/api/ContactRequestExceptions'
import { EmploymentSectorType, EmploymentSectorValue } from '../../../../api/api/types/EmploymentSector.ds'
import { PrimaryBorrower } from '../../models/Financing'
import { updateBorrowersData } from '../../state/actions'
import { goToRoadPage } from '../../routing'
import PersonalDataChips from '../PersonalDataChips/PersonalDataChips'
import { withUserData } from '../../hoc/withUserData'
import './PersonalData.less'
import { useFeature } from '@optimizely/react-sdk'
import { Feature } from '../../../../config/optimizely'
import StickyNextButton from '../../pages/common/stickyNextButton/StickyNextButton'
import StaticStarRating from '../../staticStarRating/StaticStarRating'
import { useNavigate } from 'react-router-dom'

export const PERSONALDATA_PAGE_VIEW = 'personaldata'

type PersonalDataType = { nextPage: string }

const normalizeValue = (value: string) => value.trim()

const PersonalData: React.FC<PersonalDataType> = ({ nextPage }) => {
  const { t } = useTranslation('leadEngine')
  const {
    borrowers: { primary: primaryBorrower },
    errors = []
  } = useSelector<ApplicationState, LeadEngineState>(state => state.leadEngine)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [ showLoadingWithAnimation ] = useFeature(Feature.LEAD_ENGINE_ANIMATED_LOADING)

  const salutationRef1 = useRef<HTMLInputElement>(null)
  const salutationRef2 = useRef<HTMLInputElement>(null)
  const forenameRef = useRef<HTMLInputElement>(null)
  const surnameRef = useRef<HTMLInputElement>(null)
  const emailRef = useRef<HTMLInputElement>(null)

  const { salutation = '', forename = '', surname = '', email = '', employmentSector } = primaryBorrower

  const [ touched, setTouched ] = useState<string[]>([])
  const [ employmentSectorType, setEmploymentSectorType ] = useState<EmploymentSectorType | undefined>(employmentSector)

  useEffect(() => {
    if (salutationRef1.current && salutationRef2.current) {
      if (primaryBorrower.salutation === Salutation.MR) {
        salutationRef1.current.checked = true
      } else if (primaryBorrower.salutation === Salutation.MRS) {
        salutationRef2.current.checked = true
      }
    }
  }, [ primaryBorrower.salutation ])

  const isRefValid = (ref: RefObject<HTMLInputElement>): boolean => {
    return !ref.current || ref.current.validity.valid

  }

  const isRefUntouched = (ref: RefObject<HTMLInputElement>): boolean => {
    return ref.current === null || !touched.includes(ref.current.name)
  }

  const isSalutationInvalid = () => {
    return salutationRef1.current !== null && salutationRef2.current !== null && !salutationRef1.current.checked && !salutationRef2.current.checked
  }

  const handleBlur = (event: ChangeEvent<HTMLInputElement>): void => {
    const { name } = event.target
    event.target.value = normalizeValue(event.target.value)
    setTouched(state => ([ ...new Set([ ...state, name ]) ]))
  }

  const refsCollection: RefObject<any>[] = [ forenameRef, surnameRef, emailRef ]

  const getRefValue = (ref: React.RefObject<any>): string => {
    return ref.current ? ref.current.value : ''
  }

  const onBorrowerUpdate = (): void => {
    const isFormValid = refsCollection.filter(ref => !isRefValid(ref)).length === 0

    if (isFormValid) {
      const salutation = salutationRef1.current?.checked ? salutationRef1.current.value : salutationRef2.current?.value
      const personalData = {
        salutation,
        forename: getRefValue(forenameRef),
        surname: getRefValue(surnameRef),
        email: getRefValue(emailRef),
        employmentSector: employmentSectorType
      } as PrimaryBorrower

      dispatch(updateBorrowersData({ primary: personalData }))
      goToRoadPage(nextPage, navigate)
    } else {
      setTouched([ ...refsCollection, salutationRef1, salutationRef2 ].map(ref => ref.current.name))
    }
  }

  return (
    <div className='personal-data'>
      {!showLoadingWithAnimation && <FinancingFormStatusMessage
        headline={t('financingRequest.hint.title')}
        message={t('financingRequest.hint.message')}
        errors={errors}
      />}
      {!showLoadingWithAnimation &&
        <div className='personalDataChips'>
          <PersonalDataChips/>
        </div>
      }
      <StepWrapper title={t('financingRequest.personalData.title')}>
        <div className='section-header'>
          <div className='description'>{t('financingRequest.personalData.description')}</div>
            <StaticStarRating vertical />
        </div>
        <div className='question-container grid-flex padding-top-l'>
          <div className='radio-buttons-container grid-item grid-flex one-whole'>
            <RadioButton
              ref={salutationRef1}
              label={t('common:label.man')}
              id='MR'
              name='salutation'
              value={Salutation.MR}
              onBlur={handleBlur}
              isErroneous={!isRefUntouched(salutationRef1) && isSalutationInvalid()}
              defaultChecked={salutation === Salutation.MR}
            />
            <RadioButton
              ref={salutationRef2}
              label={t('common:label.women')}
              id='MRS'
              name='salutation'
              value={Salutation.MRS}
              onBlur={handleBlur}
              isErroneous={!isRefUntouched(salutationRef2) && isSalutationInvalid()}
              defaultChecked={salutation === Salutation.MRS}
            />
          </div>
          <div className='grid-item one-whole padding-top-m'>
            <TextInput
              id='forename'
              name='forename'
              label={t('common:label.firstname')}
              placeholder=''
              defaultValue={forename}
              ref={forenameRef}
              valid={(isRefUntouched(forenameRef) || isRefValid(forenameRef)) && !errors.includes(ContactRequestError.FORENAME_BLACKLISTED)}
              onBlur={handleBlur}
              required
            />
          </div>

          <div className='grid-item one-whole padding-top-l'>
            <TextInput
              id='surname'
              name='surname'
              label={t('common:label.lastname')}
              placeholder=''
              defaultValue={surname}
              ref={surnameRef}
              valid={(isRefUntouched(surnameRef) || isRefValid(surnameRef)) && !errors.includes(ContactRequestError.SURNAME_BLACKLISTED)}
              onBlur={handleBlur}
              required
            />
          </div>

          <div className='grid-item one-whole padding-top-l'>
            <TextInput
              id='email'
              type='email'
              name='email'
              label={t('common:label.email')}
              placeholder=''
              defaultValue={email}
              ref={emailRef}
              valid={(isRefUntouched(emailRef) || isRefValid(emailRef)) && !errors.includes(ContactRequestError.EMAIL_BLACKLISTED)}
              onBlur={handleBlur}
              required
            />
          </div>
          <div className='grid-item one-whole padding-top-l'>
            <Checkbox
              id='medicalField'
              label={t('financingRequest.workInMedicalField')}
              defaultChecked={employmentSectorType !== undefined}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setEmploymentSectorType(e.target.checked ? EmploymentSectorValue.MEDICAL : undefined)}
            />
          </div>
          <StickyNextButton text={t('common:button.forward')} onClick={onBorrowerUpdate}/>
        </div>
      </StepWrapper>
    </div>
  );
}

const newFormWithUserData = withUserData<PersonalDataType>(PersonalData)
export default withPageViewReporting(newFormWithUserData, PERSONALDATA_PAGE_VIEW)
