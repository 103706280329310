import { ButtonGroup } from './ButtonGroup'
import { StepWrapper } from './StepWrapper'
import React from 'react'
import { IncomeRangeType, IncomeRangeValues } from '../../../api/api/types/IncomeRange.ds'
import { useTranslation } from 'react-i18next'
import { RoadPage } from '../FinancingRequestRoad'
import { withPageViewReporting } from '../hoc/withTrackPageView'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../../redux/store'
import { LeadEngineState } from '../state/reducer'
import { isPermanentlyEmployed } from '../../../api/api/types/Employment'
import { onIncomeRangeSubmit } from '../state/thunks/app'
import { goToRoadPage } from '../routing'
import { MenuBar } from '../MenuBar'
import { useNavigate } from 'react-router-dom'

const IncomeRangePage: React.FC<{ nextPage: string }> = ({ nextPage }) => {
  const { t } = useTranslation('leadEngine')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { borrowers: { primary: { employment } }, financing: { netIncomeRange } } = useSelector<ApplicationState, LeadEngineState>(state => state.leadEngine)

  const title = isPermanentlyEmployed(employment) ? t('netIncomeRange.titlePermanentEmployee') : t('netIncomeRange.titleNotPermanentEmployee')

  const handleOnClick = (incomeRange: IncomeRangeType) => {
    dispatch(onIncomeRangeSubmit(incomeRange))
    goToRoadPage(nextPage, navigate)
  }

  return <>
    <MenuBar page={RoadPage.INCOME_RANGE} hideClose/>
    <StepWrapper title={title}>
      <ButtonGroup<IncomeRangeType>
        values={IncomeRangeValues}
        onClick={handleOnClick}
        value={netIncomeRange}
      />
    </StepWrapper>
  </>
}

export default withPageViewReporting(IncomeRangePage, 'income')
