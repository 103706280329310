import React, { useMemo, useState } from 'react'
import { Button, ModalBackdrop, ModalContainer } from 'is24-corecss'
import { useTranslation } from 'react-i18next'
import AdditionalCostsInputRow from './AdditionalCostsInputRow'
import { formatNumber, toCurrency } from '../../../../../services/NumberUtils'
import './AdditionalCostsDetailsModal.less'
import { calculateAmount, sumPercents } from '../../service'
import { AdditionalCostsInPercents } from '../../model'
import LandTransferTaxTable from './tooltip/LandTransferTaxTable'
import BrokerCommissionTable from './tooltip/BrokerCommissionTable'

export type AdditionalCostsDetailsModalProps = {
  purchasePrice: number
  additionalCostsInPercents: AdditionalCostsInPercents
  updateAdditionalCostsInPercents: (value: AdditionalCostsInPercents) => void
  onClose: () => void
}

const MAX_VALUE = 10_000_000

const AdditionalCostsDetailsModal: React.FC<AdditionalCostsDetailsModalProps> = ({
  purchasePrice,
  additionalCostsInPercents,
  updateAdditionalCostsInPercents,
  onClose
}) => {

  const { t } = useTranslation('widgets', { keyPrefix: 'financingCalculator.additionalCosts.modal' })

  const [ inputValues, setInputValues ] = useState(additionalCostsInPercents)

  const percentsSum = useMemo(() => sumPercents(inputValues), [ inputValues ])

  const additionalCostsSum =  calculateAmount(percentsSum, purchasePrice)

  const isAdditionalCostsValid = additionalCostsSum <= MAX_VALUE

  const onCloseHandler = () => {
    updateAdditionalCostsInPercents(inputValues)
    onClose()
  }

  const handleInputChange = (fieldName: string, value: number) => {
    setInputValues((previousValue) => ({ ...previousValue, [ fieldName ]: value, }))
  }

  return (
    <div>
      <ModalBackdrop visible={true} onClose={onClose}>
        <ModalContainer
          width={580}
          visible={true}
          modalTitle={t('additionalCosts')}
          onClose={onClose}
          ariaLabelCloseButton='close'
          contentWrapperClassName='AdditionalCostsDetailsModal'>

          <div className='border-bottom'>
            <AdditionalCostsInputRow text={t('landTransfer')}
                                     tooltip={<LandTransferTaxTable/>}
                                     tooltipDesktopBodyPosition='bottom'
                                     purchasePrice={purchasePrice}
                                     value={inputValues.landTransfer}
                                     onChange={(value) => handleInputChange('landTransfer', value)}
                                     maxValue={MAX_VALUE}/>
            <AdditionalCostsInputRow text={t('notaryCosts')}
                                     purchasePrice={purchasePrice}
                                     value={inputValues.notaryCosts}
                                     onChange={(value) => handleInputChange('notaryCosts', value)}
                                     maxValue={MAX_VALUE}/>
            <AdditionalCostsInputRow text={t('landRegistration')}
                                     purchasePrice={purchasePrice}
                                     value={inputValues.landRegistration}
                                     onChange={(value) => handleInputChange('landRegistration', value)}
                                     maxValue={MAX_VALUE}/>
            <AdditionalCostsInputRow text={t('brokerCommission')}
                                     tooltip={<BrokerCommissionTable/>}
                                     purchasePrice={purchasePrice}
                                     value={inputValues.brokerCommission}
                                     onChange={(value) => handleInputChange('brokerCommission', value)}
                                     maxValue={MAX_VALUE}/>
          </div>
          <div className='Footer'>
            <div>
              <div>{t('additionalCosts')}</div>
              <div className='Total'>
                <span className='padding-right-m'>{toCurrency(additionalCostsSum)}</span> {formatNumber(percentsSum, 0, 2)} %
              </div>
            </div>
            <div className='font-error absolute-content margin-top-xs'>
              {!isAdditionalCostsValid && t('totalErrorMessage')}
            </div>
            <div className='Buttons'>
              <Button className='CancelButton' textStyle onClick={onClose}>{t('cancelButton')}</Button>
              <Button disabled={!isAdditionalCostsValid} appearance='primary'
                      onClick={onCloseHandler}>{t('applyButton')}</Button>
            </div>
          </div>
        </ModalContainer>
      </ModalBackdrop>
    </div>
  )
}

export default AdditionalCostsDetailsModal
