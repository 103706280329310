import CustomTooltip from '../customTooltip/CustomTooltip'
import { Slider } from 'is24-corecss'
import React, { useState } from 'react'
import { NumberInput } from '../input/numericInput'

type InputWithSliderType = {
  id: string,
  title: string,
  value?: number,
  min?: number,
  max?: number,
  onChange: (val: number) => void,
  tooltipText?: string
}

export const InputWithSlider: React.FC<InputWithSliderType> = ({
                                                                 id,
                                                                 title,
                                                                 value = 0,
                                                                 min = 20000,
                                                                 max = 1000000,
                                                                 onChange,
                                                                 tooltipText
                                                               }) => {
  const [ inputValue, setInputValue ] = useState(value);

  return (
    <div className='grid-flex grid-align-center grid-justify-space-between margin-bottom-l'>
      <div className='grid grid-flex two-tenths palm-one-half'>
        <strong>{title}</strong>
        {tooltipText && <CustomTooltip title={title} text={tooltipText}/>}
      </div>
      <div className='five-tenths padding-horizontal-m palm-order-one-down palm-one-whole'>
        <Slider
          id={`${id}-slider`}
          title={title}
          maxValue={value}
          min={min}
          max={max}
          step={5000}
          onChange={onChange}
        />
      </div>
      <div className='grid-flex grid-justify-end three-tenths palm-one-half'>
        <NumberInput
          id={id}
          value={value}
          onChange={(val) => setInputValue(val >= min ? val : min )}
          onBlur={() => onChange(inputValue)}
          min={min}
        />
      </div>
    </div>
  )
}
