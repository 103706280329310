import { formatNumber, toCurrency } from '../../../../../services/NumberUtils';
import React from 'react';
import { AdditionalCostsPercentages } from '../../../models/Financing';
import { useTranslation } from 'react-i18next'

const tooltipLine = (title: string, percentage = 0, value: number) => <>
  <span className='grid-item two-third'>{title} ({formatNumber(percentage, 2)}%)</span>
  <span className='grid-item one-third align-right'>{toCurrency(percentage * value / 100)}</span>
</>

const AdditionalCostTooltip: React.FC<{ percentages: AdditionalCostsPercentages, purchasePrice: number }> =
  ({ percentages = {}, purchasePrice }) => {
    const { t } = useTranslation('leadEngine', { keyPrefix: 'purchasePrice.additionalCostTooltip' })
    return <>
      {tooltipLine(t('notaryCosts'), percentages.notaryCostsPercentage, purchasePrice)}
      {tooltipLine(t('landTransferTax'), percentages.landTransferPercentage, purchasePrice)}
      {tooltipLine(t('landRegisterEntry'), percentages.entryLandRegisterPercentage, purchasePrice)}
      {tooltipLine(t('brokerCommission'), percentages.brokerCommissionPercentage, purchasePrice)}
    </>
  }

export default AdditionalCostTooltip
