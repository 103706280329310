import React from 'react'
import { useTranslation } from 'react-i18next'
import NumberInput from '../../../common/input/numericInput/NumberInput'
import { AdditionalCostsPercentages, getAdditionalCosts } from '../../models/Financing'
import FormRow from '../common/FormRow'
import AdditionalCostTooltip from './parts/AdditionalCostTooltip'
import Percentage from './parts/Percentage'
import ReadOnlyField from '../common/ReadOnlyField'
import LoanAmountReadOnlyRow from '../common/LoanAmountReadOnlyRow'

export type PurchasePriceFormProps = {
  purchasePrice: number,
  ownFunds: number,
  loanAmount: number,
  loanAmountValid: boolean,
  additionalCostsPercentages: AdditionalCostsPercentages,
  onPurchasePriceChanged: (val: number) => void,
  onOwnFundsChanged: (val: number) => void,
}

export const MAX_AMOUNT = 10_000_000

const PurchasePriceForm: React.FC<PurchasePriceFormProps> = ({
                                                               purchasePrice,
                                                               ownFunds,
                                                               loanAmount,
                                                               loanAmountValid,
                                                               additionalCostsPercentages,
                                                               onPurchasePriceChanged,
                                                               onOwnFundsChanged
                                                             }) => {
  const { t } = useTranslation('leadEngine', { keyPrefix: 'purchasePrice' })
  const additionalCosts = getAdditionalCosts({ additionalCostsPercentages, purchasePrice })

  return (
    <>
      <FormRow title={t('purchasePrice')}
               tooltip={t('purchasePriceTooltip')}
               tooltipPosition='top'
               className='border-bottom'>
        <div className='padding-vertical-s'>
          <NumberInput id='purchasePrice'
                       data-testid='purchasePrice'
                       value={purchasePrice}
                       onChange={onPurchasePriceChanged}
                       onBlur={() => purchasePrice > MAX_AMOUNT && onPurchasePriceChanged(MAX_AMOUNT)}
          />
        </div>
      </FormRow>
      <FormRow title={t('ownFunds')}
               titleComponent={<Percentage partial={ownFunds} total={purchasePrice}/>}
               tooltip={t('ownFundsTooltip')}
               className='border-bottom'>
        <div className='padding-vertical-s'>
          <NumberInput id='ownFunds'
                       data-testid='ownFunds'
                       value={ownFunds}
                       onChange={onOwnFundsChanged}
                       onBlur={() => ownFunds > MAX_AMOUNT && onOwnFundsChanged(MAX_AMOUNT)}
          />
        </div>
      </FormRow>
      <FormRow title={t('additionalCosts')}
               titleComponent={<Percentage partial={additionalCosts} total={purchasePrice}/>}
               tooltip={<AdditionalCostTooltip percentages={additionalCostsPercentages} purchasePrice={purchasePrice}/>}
               className='palm-padding-vertical-s border-bottom' minWidthTooltip={210}>
        <ReadOnlyField value={additionalCosts}/>
      </FormRow>
      <LoanAmountReadOnlyRow loanAmount={loanAmount} loanAmountValid={loanAmountValid}/>
    </>
  )
}

export default PurchasePriceForm

