import React, { ChangeEvent } from 'react'
import Section from '../Section'
import { useTranslation } from 'react-i18next'
import { Region, RegionSelection } from '../../../../common/input/regionSelection/RegionSelection'
import { TextInput } from 'is24-corecss'
import './AddressSection.less'

export type AddressSectionProps = {
  submitted: boolean
  region: Region | undefined
  regionValid: boolean
  houseNumber: string | undefined
  houseNumberValid: boolean
  setRegion: (region: Region) => void
  setHouseNumber: (houseNumber: string) => void
}

const AddressSection: React.FC<AddressSectionProps> = (
  {
    submitted,
    region,
    regionValid,
    houseNumber,
    houseNumberValid,
    setRegion,
    setHouseNumber
  }) => {
  const { t } = useTranslation('widgets', { keyPrefix: 'financingCalculator.appointmentRequestModal.addressSection' })
  return (
    <Section title={t('title')} paddingBottom={false}>
      <div data-testid='address-section' className='AddressSection'>
        <div className='grid-flex'>
          <div className='grid-item three-fourth palm-one-whole desk-padding-right-s lap-padding-top-m palm-padding-top-m'>
            <RegionSelection
              placeholder={t('postalCodeOrCity')}
              label={t('postalCodeOrCity')}
              value={region === undefined ? '' : `${region?.street}, ${region?.postalCode} ${region?.city}`}
              queryType='street'
              onRegionSelect={(e) => setRegion(e)}
              valid={!submitted || regionValid}
            />
          </div>
          <div className='grid-item palm-one-whole one-fourth lap-padding-top-m palm-padding-top-m'>
            <TextInput label={t('houseNumber')} id='houseNumber' className='HouseNumber'
                       name='houseNumber' placeholder={t('houseNumber')} defaultValue={houseNumber}
                       onChange={(e: ChangeEvent<HTMLInputElement>) => setHouseNumber(e.target.value)}
                       valid={!submitted || houseNumberValid}/>
          </div>
        </div>
      </div>
    </Section>

  )
}

export default AddressSection
