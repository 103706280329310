import React, { ChangeEvent, HTMLProps, RefObject, useEffect, useState } from 'react'
import { TextInput } from 'is24-corecss'

import './TextInputSelect.less'

interface TextInputSelectType<T> extends Omit<HTMLProps<HTMLInputElement>, 'onSelect'> {
  entries: T[],
  value?: string,
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void,
  onSelect?: (index: number) => void,
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void,
  entryTransformation?: (entry: T) => string,
  valid?: boolean,
  inputRef?: RefObject<HTMLInputElement>
  errorMessage?: string
  customIconImg?: string
  allowAutoComplete?: boolean
}

export const TextInputSelect = <T extends object>({
                                                    entries = [],
                                                    value = '',
                                                    onSelect,
                                                    onBlur,
                                                    onFocus,
                                                    onChange,
                                                    entryTransformation = entry => String(entry),
                                                    valid, inputRef, name = '', id = '', errorMessage,
                                                    placeholder = '', label = '', required = false,
                                                    customIconImg,
                                                    allowAutoComplete = true
                                                  }: TextInputSelectType<T>): React.ReactElement => {

  const [ index, setIndex ] = useState(0)

  useEffect(() => {
    setIndex(0)
  }, [ entries ])

  const increaseIndex = () => setIndex(Math.min(entries.length - 1, index + 1))
  const decreaseIndex = () => setIndex(Math.max(0, index - 1))

  const handleOnSelect = (index: number) => {
    setIndex(index)
    onSelect?.(index)
  }

  const onEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    event.stopPropagation()
    event.preventDefault()
    event.currentTarget.blur()
    handleOnSelect(index)
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    event.key === 'ArrowUp' && decreaseIndex()
    event.key === 'ArrowDown' && increaseIndex()
    event.key === 'Enter' && onEnter(event)
  }

  return (
    <div className='location-input'>
      <TextInput
        className='absolute-reference'
        autoComplete={allowAutoComplete ? 'on' : 'new-password'}
        ref={inputRef}
        id={id}
        aria-invalid={!valid}
        valid={valid}
        value={value}
        name={name}
        onFocus={onFocus}
        onKeyUp={handleKeyPress}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        label={label}
        required={required}
        errorMessage={errorMessage}
        affixRight={customIconImg ? 'icon' : undefined}
        iconRight={customIconImg ? 'icon-placeholder' : undefined}
      />
      {customIconImg && <img src={customIconImg} className='location-input-icon' alt='icon'/>}
      {entries.length > 0 &&
        <ul className={`${id}-list`}>
          {entries.map((entry, id) =>
            <li key={id}>
              <button
                type='button'
                onClick={() => handleOnSelect(id)}
                className={index === id ? 'selected' : ''}
              >
                {entryTransformation(entry)}
              </button>
            </li>
          )}
        </ul>
      }
    </div>
  )
}

