import React, { useEffect, useState, FocusEvent, ChangeEvent, useCallback } from 'react'
import { TextInput } from 'is24-corecss'
import './DateInputWithPlaceholder.less'

import {
  autoCompleteGermanDateString,
  formatDateString,
  GERMAN_DATE_FORMAT,
  ISO_DATE_FORMAT,
} from '../../../../utils/dateUtils'
import { DateAsISOString } from '../../../../api/api/types/DateAsISOString'

export type DateValidationResult = {
  isValid: boolean,
  message?: string
}

export type DateInputWithPlaceholderProps = {
  id: string
  label: string
  placeholder: string
  initialValue: DateAsISOString | undefined
  onChange: (date: DateAsISOString | undefined) => void
  validateDateString: (dateStringInGermanFormat: string | undefined) => DateValidationResult
  onTouched?: () => void,
  onValidationStatusChanged?: (isValid: boolean) => void,
  validationErrorClass?: string,
}

const DateInputWithPlaceholder: React.FC<DateInputWithPlaceholderProps> = (
  { id,
    label,
    placeholder,
    initialValue,
    onChange,
    validateDateString,
    onTouched,
    onValidationStatusChanged,
    validationErrorClass,
  }) => {
  const [ dateString, setDateString ] = useState<string | undefined>(undefined)
  const [ touched, setTouched ] = useState(false)
  const [ validationResult, setValidationResult ] = useState<DateValidationResult>({ isValid: false })

  useEffect(() => {
    if (touched) {
      onTouched?.()
    }
  }, [ touched, onTouched ])

  useEffect(() => {
      onValidationStatusChanged?.(validationResult.isValid)
  }, [ validationResult.isValid, onValidationStatusChanged ])

  const validateAndFireUpdate = useCallback((value: string) => {
    setTouched(true);
    const result = validateDateString(value);
    setValidationResult(result);
    if (result.isValid) {
      onChange(formatDateString(value, GERMAN_DATE_FORMAT, ISO_DATE_FORMAT))
    } else if (!value) {
      onChange(undefined)
    }
  }, [onChange, validateDateString])

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>): void =>
    setDateString(oldValue => {
      let newValue = event.target.value.replace(/[^0-9.]/, '')
      if (newValue.length > (oldValue?.length ?? 0)) {
        newValue = autoCompleteGermanDateString(newValue);
      }
      event.target.value = newValue
      return newValue
    });

  useEffect(() => {
    if (dateString?.length === 10) {
      validateAndFireUpdate(dateString)
    }
  }, [ dateString, validateAndFireUpdate])

  const handleOnBlur = (event: FocusEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    setDateString(value);
    validateAndFireUpdate(value)
  }

  useEffect(() => {
    const date = initialValue ? formatDateString(initialValue, ISO_DATE_FORMAT, GERMAN_DATE_FORMAT) : undefined;
    setDateString(date);
    setValidationResult(validateDateString(date));
  }, [ initialValue, validateDateString ])

  const dynamicPlaceholder = dateString && (dateString + placeholder.substring(dateString?.length || placeholder.length)) || ''

  return (
    <div>
      <div className='InputContainer'>
        <div className='PlaceholderOverlay'>
          <div>{dynamicPlaceholder}</div>
        </div>
        <TextInput
          id={id}
          placeholder={placeholder}
          label={label}
          defaultValue={dateString}
          onBlur={handleOnBlur}
          onChange={handleOnChange}
          valid={!touched || validationResult.isValid}
        />
      </div>
      <div className={`font-error absolute-content margin-top-xs ${validationErrorClass ?? ''}`}>
        {validationResult.message && touched ? validationResult.message : ''}
      </div>
    </div>
  )
}

export default DateInputWithPlaceholder
