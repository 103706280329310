import React from 'react'
import { withOptimizely } from './components/hoc/withOptimizely'
import BudgetCalculatorWrapper from './components/widgets/budgetCalculator/BudgetCalculatorWrapper'
import MonthlyRateButtonContainer from './components/widgets/monthlyRateButton/MonthlyRateButtonContainer'
import MortgageCalculator from './components/widgets/mortgageCalculator/MortgageCalculator'
import FollowupCalculatorWrapper from './components/widgets/followupCalculator/FollowupCalculatorWrapper'
import RentVsBuy from './components/widgets/rentVsBuy/RentVsBuy'
import './config/i18n'
import './styles/common.less'
import InterestChart from './components/widgets/interestChart/InterestChart'
import PrequalifiedLead from './components/widgets/prequalifiedLead/PrequalifiedLead'
import LeadEngineEntryWidget from './components/widgets/leadEngineEntryWidget/leadEngineEntryWidget'
import { configureStore } from './redux/store'
import FinancingCalculatorWidget from './components/widgets/financingCalculator/FinancingCalculatorWidget'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import MortgageProvidersTeaserWidget from './components/widgets/mortgageProvidersTeaser/MortgageProvidersTeaserWidget'

const WidgetContainer: React.FC<{ domElement: Element }> = ({ domElement }) => {
  const type = domElement.getAttribute('data-type') || ''
  const headline = domElement.getAttribute('data-headline') || ''
  const buttonTarget = domElement.getAttribute('data-button-target') || ''
  const buttonText = domElement.getAttribute('data-button-text') || ''
  const buttonColor = domElement.getAttribute('data-button-style') || ''
  const initialPurchasePrice = domElement.getAttribute('data-initial-purchase-price') || ''
  const initialEquityCapital = domElement.getAttribute('data-initial-equity-capital') || ''
  const initialRemainingDebt = domElement.getAttribute('data-initial-remaining-debt') || ''
  const initialPropertyValue = domElement.getAttribute('data-initial-property-value') || ''
  const showHeader = domElement.getAttribute('data-show-header') || ''
  const width = domElement.getAttribute('data-width') || ''
  const height = domElement.getAttribute('data-height') || ''
  const exposeId = domElement.getAttribute('data-exposeid') || ''
  const purchasePrice = domElement.getAttribute('data-purchase-price') || ''
  const hasBrokerCommission = domElement.getAttribute('data-has-broker-commission') || ''
  const brokerCommission = domElement.getAttribute('data-broker-commission') || ''
  const geoCode = domElement.getAttribute('data-geo-code') || ''
  const postalCode = domElement.getAttribute('data-postcode') || ''
  const cwid = domElement.getAttribute('data-cwid') || ''
  const trackingCmpId = domElement.getAttribute('data-tracking-cmp-id') || ''
  const trackingCmpPosition = domElement.getAttribute('data-tracking-cmp-position') || ''
  const isExclusive = domElement.getAttribute('data-exclusive') || ''
  const financingType = domElement.getAttribute('data-financing-type') || ''

  switch (type) {
    case 'budget':
      return <BudgetCalculatorWrapper headline={headline} targetLink={buttonTarget} />
    case 'mortgage':
      return <MortgageCalculator
        headline={headline}
        targetLink={buttonTarget}
        buttonText={buttonText}
        initialPurchasePrice={initialPurchasePrice}
        buttonColor={buttonColor}
        initialEquityCapital={initialEquityCapital}
      />
    case 'followup':
      return <FollowupCalculatorWrapper
        initialPropertyValue={initialPropertyValue}
        initialRemainingDebt={initialRemainingDebt}
        buttonText={buttonText}
        buttonColor={buttonColor}
        targetLink={buttonTarget}
        headline={headline}
      />
    case 'interest':
      return <InterestChart
        title='Zinsentwicklung'
        width={width ? parseInt(width) : undefined}
        height={height ? parseInt(height) : undefined}
        showHeader={showHeader === 'true'}
      />
    case 'rentvsbuy':
      return <RentVsBuy headline={headline} />
    case 'prequalified-lead':
      return <PrequalifiedLead
        exposeId={exposeId}
        geocode={geoCode}
        postalCode={postalCode}
        purchasePrice={Number(purchasePrice)}
        hasBrokerCommission={!!hasBrokerCommission && hasBrokerCommission.toLowerCase() === 'true'}
        brokerCommission={brokerCommission}
        isExclusive={isExclusive==='true'}
      />
    case 'monthlyrate':
      return <MonthlyRateButtonContainer
        exposeId={exposeId}
        geocode={geoCode}
        cwid={cwid}
        cmpId={trackingCmpId}
        cmpPosition={trackingCmpPosition}
      />
    case 'leadEngineEntry':
      return <BrowserRouter>
        <LeadEngineEntryWidget />
      </BrowserRouter>
    case 'financingCalculator':
      return <Provider store={configureStore()}>
        <BrowserRouter>
          <FinancingCalculatorWidget defaultFinancingTypeMode={financingType}/>
        </BrowserRouter>
      </Provider>
    case 'mortgageProvidersTeaser':
      return <MortgageProvidersTeaserWidget/>
    default:
      return <></>
  }
}

export const WidgetContainerComponent = WidgetContainer
export default withOptimizely(WidgetContainer);
