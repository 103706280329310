import TrackingService from '../../../services/TrackingService/TrackingService'
import { Financing, getAdditionalCosts, getLoanAmount, PrimaryBorrower } from '../models/Financing'
import { StateValues } from '../models/StateValues'
import { Property } from '../models/Property'
import { HashedPIIData, hashPIIData } from '../../../services/TrackingService/HashingService'

function getFinanceReportParams(financing: Financing, borrower: Partial<PrimaryBorrower>, property: Property) {
  const getState = (geoCode = '') => Object.values(StateValues).find(v => v.geoCode === geoCode.substr(0, 7))?.label
  const getHousehold = (b = 0, c = 0) => Math.max(b + c, 1)

  const reformatDate = (date: Date) => {
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    return day + month + year;
  }

  return {
    financingtype: financing.financingType,
    financing_start: financing.financingStart,
    psn_budget: financing.netIncomeRange,
    employment: borrower.employment,
    purchaseprice: financing.purchasePrice,
    ownfunds: financing.ownFunds,
    property_usage: financing.financingUseType,
    project_state: financing.projectState,
    obj_household: getHousehold(financing.numberOfBorrowers, financing.numberOfChildren),
    geo_city: property.city,
    additionalcosts: getAdditionalCosts(financing),
    remainingdebt: financing.remainingDebt,
    propertyvalue: financing.propertyValue,
    geo_label: property.locationLabel,
    geo_code: property.geoCode,
    geo_bln: getState(property.geoCode),
    budget: getLoanAmount(financing),
    psn_gender: borrower.salutation,
    psn_age: borrower.dateOfBirth ? reformatDate(borrower.dateOfBirth) : undefined,
  }
}

const getPageTitle = (page: string) =>  'leadengine.financingrequest.' + page

export function reportPageView(pageTitle: string, financing: Financing, borrower: Partial<PrimaryBorrower>, property: Property) {
  TrackingService.reportPageView(
    getPageTitle(pageTitle),
    getFinanceReportParams(financing, borrower, property)
  )
}

export function reportExtendedLeadEvent(financing: Financing, borrower: Partial<PrimaryBorrower>, property: Property) {
  TrackingService.reportEvent(
    getPageTitle('ext'),
    'extended_lead',
    `${financing.numberOfBorrowers}_borrower`,
    'finance',
    {
      ...getFinanceReportParams(financing, borrower, property),
      event_name: 'lead_extended',
      event_product: 'finance',
      event_parameter_1: 'leadengine',
      event_parameter_2: `${financing.numberOfBorrowers}_borrower`
    }
  )
}

// BAU-6552- A/B Test - remove variation at end of the parameters list

export async function reportFinancingRequest(contactRequestId: number, financing: Financing, borrower: Partial<PrimaryBorrower>, property: Property) {
  const hashedPIIData: HashedPIIData = await hashPIIData({
    email: borrower.email,
    phone: borrower.phone,
    streetName: borrower.street,
    firstNane: borrower.forename,
    lastName: borrower.surname,
    postcode: borrower.postalCode,
    city: borrower.location
  })

  const additionalParams = {
    evt_lafid: contactRequestId,
    ...getFinanceReportParams(financing, borrower, property),
    ...hashedPIIData,
    event_name: 'lead_submit',
    event_product: 'finance',
    event_parameter_1: 'leadengine'
  }

  TrackingService.reportEvent(
    getPageTitle('personaldata'),
    'finance',
    `leadengine`,
    'lead',
    additionalParams
  )
}
