import React, { ChangeEvent } from 'react'
import Section from '../Section'
import { useTranslation } from 'react-i18next'
import { SelectField } from 'is24-corecss'
import { PurchaseFinancingType, PurchaseFinancingTypeValues } from '../../../../../api/api/types/PurchaseFinancingType'
import './FinancingDetailsSection.less'
import { FinancingUseType, FinancingUseTypes } from '../../../../../api/api/types/FinancingUseType.ds'
import { FinancingType, FinancingTypeValue } from '../../../../../api/api/types/FinancingType'
import {
  FollowUpFinancingStartType,
  FollowUpFinancingStartTypeValues
} from '../../../../../api/api/types/FollowUpFinancingStartType'
import { FinancingStartType, FinancingStartTypeValues } from '../../../../../api/api/types/FinancingStartType'

export type FinancingDetailsSectionProps = {
  financingType: FinancingType
  touched: boolean,
  purchaseFinancing: PurchaseFinancingType | undefined
  purchaseFinancingValid: boolean
  setPurchaseFinancing: (purchaseFinancingOnChange: PurchaseFinancingType) => void
  financingStart: FinancingStartType | undefined
  financingStartValid: boolean
  setFinancingStart: (financingStartType: FinancingStartType) => void
  followUpFinancingStart: FollowUpFinancingStartType | undefined
  followUpFinancingStartValid: boolean
  setFollowUpFinancingStart: (followUpFinancingStartType: FollowUpFinancingStartType) => void
  financingUseType: FinancingUseType
  setFinancingUseType: (financingUseType: FinancingUseType) => void
}

const FinancingDetailsSection: React.FC<FinancingDetailsSectionProps> = (
  {
    financingType,
    touched,
    purchaseFinancing,
    purchaseFinancingValid,
    setPurchaseFinancing,
    financingStart,
    financingStartValid,
    setFinancingStart,
    followUpFinancingStart,
    followUpFinancingStartValid,
    setFollowUpFinancingStart,
    financingUseType,
    setFinancingUseType
  }) => {

  const { t } = useTranslation('widgets', { keyPrefix: 'financingCalculator.appointmentRequestModal.financingDetailsSection' })

  const getSelectField = (value: any, selectFieldName: string, valid: boolean, onChange: (value: any) => void, values: Record<string, string>) => {
    return (
      <SelectField
        className='SelectField'
        data-testid={`${selectFieldName}-select-field`}
        label={t(`${selectFieldName}.label`)}
        value={value}
        onChange={(e: ChangeEvent<HTMLSelectElement>) => onChange(e.target.value)}
        isErroneous={touched && !valid}
      >
        <option hidden value=''>{t('placeholder')}</option>
        {Object.keys(values).map(k =>
          <option key={k} value={values[ k ]}>
            {t(`${selectFieldName}.${k}`)}
          </option>
        )}
      </SelectField>
    )
  }

  const nonFollowupFinancingDropdowns = (
    <>
      {getSelectField(purchaseFinancing, 'purchaseFinancing', purchaseFinancingValid, setPurchaseFinancing, PurchaseFinancingTypeValues)}
      {getSelectField(financingStart, 'financingStart', financingStartValid, setFinancingStart, FinancingStartTypeValues)}
    </>
  )

  const followupFinancingDropdown = (
    <>
      {getSelectField(followUpFinancingStart, 'followUpFinancingStart', followUpFinancingStartValid, setFollowUpFinancingStart, FollowUpFinancingStartTypeValues)}
    </>
  )

  return (
    <Section title={t('title')}>
      <div className='FinancingDetailsSection' data-testid='financing-details-section'>
        {financingType === FinancingTypeValue.FollowupFinancing ? followupFinancingDropdown : nonFollowupFinancingDropdowns}
        <SelectField
          className='SelectField'
          data-testid='financingUse-select-field'
          label={t('financingUse.label')}
          value={financingUseType}
          onChange={(e: ChangeEvent<HTMLSelectElement>) => setFinancingUseType(e.target.value as FinancingUseType)}
        >
          {Object.keys(FinancingUseTypes)
            .filter(k => k != FinancingUseTypes.UNKNOWN)
            .map(k =>
              <option key={k} value={FinancingUseTypes[ k as keyof typeof FinancingUseTypes ]}>
                {t(`financingUse.${k}`)}
              </option>
            )}
        </SelectField>
      </div>
    </Section>
  )
}

export default FinancingDetailsSection
