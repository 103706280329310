import React, { ChangeEvent, RefObject } from 'react'
import { TextInput } from 'is24-corecss'
import { useTranslation } from 'react-i18next'

export type AddressFields = {
  postalCode?: string
  street?: string
  streetNumber?: string
  location?: string
}

export type AddressType = {
  postalCodeRef?: RefObject<HTMLInputElement>
  locationRef?: RefObject<HTMLInputElement>
  streetRef?: RefObject<HTMLInputElement>
  streetNumberRef?: RefObject<HTMLInputElement>
  value?: AddressFields
  validation: Record<keyof AddressFields, boolean>
  onChange: (address: AddressFields) => void
  onBlur?: (event: ChangeEvent<HTMLInputElement>) => void
}

export const Address: React.FC<AddressType> = ({
 value: fields = {},
 validation,
 onChange,
 onBlur,
 postalCodeRef,
 streetRef,
 streetNumberRef,
 locationRef
}) => {
  const { t } = useTranslation('leadEngine')
  const { postalCode = '', location = '', street = '', streetNumber = '' } = fields

  const handleOnChange = (field: keyof AddressFields) =>
    (e: ChangeEvent<HTMLInputElement>) =>
      onChange({ ...fields, [ field ]: e.target.value })

  return (
    <div className='grid-flex gutter-m'>
      <div className='grid-item six-eighths'>
        <TextInput
          id='street'
          name='street'
          label={t('address.street')}
          value={street}
          placeholder=''
          ref={streetRef}
          onBlur={onBlur}
          onChange={handleOnChange('street')}
          valid={validation.street}
          required
        />
      </div>

      <div className='grid-item two-eighths'>
        <TextInput
          id='streetNumber'
          name='streetNumber'
          label={t('address.streetNumber')}
          placeholder=""
          defaultValue={streetNumber}
          ref={streetNumberRef}
          valid={validation.streetNumber}
          onBlur={onBlur}
          onChange={handleOnChange('streetNumber')}
          required
        />
      </div>

      <div className='grid-item desk-one-fifth one-fourth'>
        <TextInput
          id='postalCode'
          name='postalCode'
          label={t('address.postalCode')}
          value={postalCode}
          placeholder=''
          ref={postalCodeRef}
          valid={validation.postalCode}
          onBlur={onBlur}
          onChange={handleOnChange('postalCode')}
          required
        />
      </div>

      <div className='grid-item desk-four-fifths three-fourth'>
        <TextInput
          id='location'
          name='location'
          label={t('address.location')}
          value={location}
          placeholder=''
          ref={locationRef}
          valid={validation.location}
          onChange={handleOnChange('location')}
          onBlur={onBlur}
          required
        />
      </div>
    </div>
  )
}


