import { StepWrapper } from '../StepWrapper'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { withPageViewReporting } from '../../hoc/withTrackPageView'
import { onFinancingRequestSubmit } from '../../state/thunks/app'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../../../redux/store'
import { RoadPage } from '../../FinancingRequestRoad'
import { MenuBar } from '../../MenuBar'
import FollowUpFinancingSettingsForm from './FollowUpFinancingSettingsForm'
import { Financing, getLoanAmount, isValidLoanAmount } from '../../models/Financing'
import './FollowUpFinancingSettingsPage.less'
import StickyNextButton from '../common/stickyNextButton/StickyNextButton'
import { useNavigate } from 'react-router-dom'

const FollowUpFinancingSettingsPage: React.FC<{ nextPage: string }> = ({ nextPage }) => {
  const { t } = useTranslation('leadEngine')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const financing = useSelector<ApplicationState, Financing>(state => state.leadEngine.financing)
  const [ propertyValue, setPropertyValue ] = useState(financing.propertyValue ?? 0)
  const [ remainingDebt, setRemainingDebt ] = useState(financing.remainingDebt ?? 0)
  const loanAmount = getLoanAmount({ ...financing, propertyValue, remainingDebt })
  const [ loanAmountValid, setLoanAmountValid ] = useState(true)

  useEffect(() => {
    setLoanAmountValid(isValidLoanAmount(loanAmount))
  }, [ loanAmount ])
  const handleNextPage = () => dispatch(onFinancingRequestSubmit(nextPage, navigate)({ propertyValue, remainingDebt }))

  return (
    <>
      <MenuBar page={RoadPage.FOLLOW_UP_FINANCING_SETTINGS} hideClose/>
      <StepWrapper title={t('followupFinancingSettingsPage.title')}>
        <div className='FollowUpFinancingSettingsPage grid palm-one-whole lap-two-third' style={{ margin: 'auto' }}>
          <FollowUpFinancingSettingsForm
            propertyValue={propertyValue}
            remainingDebt={remainingDebt}
            loanAmount={loanAmount}
            loanAmountValid={loanAmountValid}
            onPropertyValueChanged={setPropertyValue}
            onRemainingDebtChanged={setRemainingDebt}/>
          <StickyNextButton text={t('common:button.forward')} onClick={handleNextPage} disabled={!loanAmountValid}/>
        </div>
      </StepWrapper>
    </>
  )
}

export default withPageViewReporting(FollowUpFinancingSettingsPage, 'followupfinancingsettings')

