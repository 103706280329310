import { ButtonGroup } from './ButtonGroup'
import { StepWrapper } from './StepWrapper'
import React, { useMemo } from 'react'
import {
  FinancingStartType,
  FollowupFinancingStartType,
  InitialFinancingStartValueType
} from '../../../api/api/types/FinancingStart'
import { RoadPage } from '../FinancingRequestRoad'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../../redux/store'
import { LeadEngineState } from '../state/reducer'
import { isFollowupFinancing } from '../models/Financing'
import { Trans, useTranslation } from 'react-i18next'
import { onFinancingRequestSubmit } from '../state/thunks/app'

import { withPageViewReporting } from '../hoc/withTrackPageView'
import { MenuBar } from '../MenuBar'
import StaticStarRating from '../staticStarRating/StaticStarRating'
import { useNavigate } from 'react-router-dom'


const FollowupFinancingValues: FollowupFinancingStartType[] = [
  'IN_LESS_THAN_ONE_YEAR',
  'IN_ONE_YEAR',
  'IN_TWO_YEARS',
  'IN_THREE_YEARS',
  'IN_FOUR_YEARS',
  'IN_FIVE_YEARS',
]

const InitialFinancingValues: InitialFinancingStartValueType[] = [
  'IN_LESS_THAN_THREE_MONTH',
  'IN_BETWEEN_THREE_AND_SIX_MONTHS',
  'IN_MORE_THAN_SIX_MONTH',
]

export type FinancingStartPageProps = {
  nextPage: string,
  userFirstName?: string,
}

const FinancingStartPage: React.FC<FinancingStartPageProps> = ({nextPage, userFirstName }) => {
  const {financing: {financingStart, financingType}} = useSelector<ApplicationState, LeadEngineState>(state => state.leadEngine)
  const {t} = useTranslation('leadEngine')
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleOnClick = (financingStart: FinancingStartType) => dispatch(onFinancingRequestSubmit(nextPage, navigate)({ financingStart }))

  const values: FinancingStartType[] = isFollowupFinancing(financingType) ? FollowupFinancingValues : InitialFinancingValues

  const titleComponent = useMemo(() => <>
    <div className='font-normal padding-bottom-s'>
      {t('financingStart.titleGreeting', { name: userFirstName ? ` ${userFirstName}` : '' })}
    </div>
    <div><Trans i18nKey='financingStart.titleQuestion' t={t}><br className='desk-hide lap-hide'/></Trans></div>
    <div className='vertical-center padding-top-l padding-bottom'>
      <StaticStarRating vertical />
    </div>
  </>, [ userFirstName, t ])

  return <>
    <MenuBar page={RoadPage.FINANCING_START} hideClose/>
    <StepWrapper title={titleComponent}>
      <ButtonGroup<FinancingStartType>
        values={values}
        onClick={handleOnClick}
        value={financingStart}
      />
    </StepWrapper>
  </>
}

export default withPageViewReporting(FinancingStartPage, 'financingstart')
