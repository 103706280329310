import { Employment, EmploymentType } from '../../../api/api/types/Employment'
import { MortgageProvider, MortgageProviderResult } from './model'
import { API_V2_URL } from '../../../api/contextPaths'
import { getRequest } from '../../../api/request'
import { getRandomProviders, RandomProviderEntry, RandomProvidersResult } from '../../../api/api/RandomProviders'

export type OfferRequest = {
  additionalCosts: number
  amortizationRate: number
  employment: EmploymentType
  financingType: string
  fixedNominalInterestRate: string
  geoCode: string
  ownFunds: number
  propertyValue: number
  providerType: string
  purchasePrice: number
  remainingDebt: number
  sortOrder: string
}

type Offer = {
  mortgageProvider: {
    id: number
    name: string
    logo: {
      url: string
    }
    numberOfRatings: number
    averageRating: number
  }
}

export type OfferResponse = {
  offer: Offer[]
}

const getOffer = (query: OfferRequest): Promise<OfferResponse> =>
  getRequest({ url: `${API_V2_URL}/offer`, query })

const mapToMortgageProvider = (offer: Offer): MortgageProvider => {
  const mortgageProvider = offer.mortgageProvider;
  return {
    id: mortgageProvider.id,
    name: mortgageProvider.name,
    logo: mortgageProvider.logo?.url ?? '',
    averageRating: mortgageProvider.averageRating,
    numberOfRatings: mortgageProvider.numberOfRatings
  }
}

const PROVIDERS_LIST_LENGTH = 9

export const sortAndFilterProviders = (providers: MortgageProvider[]): MortgageProvider[] =>
  providers
    .filter(provider => provider.numberOfRatings !== 0)
    .sort(() => .5 - Math.random()).slice(0, PROVIDERS_LIST_LENGTH)

const mapToMortgageProviderResult = (response: OfferResponse): MortgageProviderResult => ({
  providers: sortAndFilterProviders(response.offer.map(mapToMortgageProvider))
})

export async function fetchMortgageProviders(geoCode: string): Promise<MortgageProviderResult> {
  const request: OfferRequest = {
    additionalCosts: 71030,
    amortizationRate: 1,
    employment: Employment.EMPLOYED,
    financingType: 'PROPERTY_PURCHASE',
    fixedNominalInterestRate: 'TEN_YEARS',
    geoCode,
    ownFunds: 123800,
    propertyValue: 200000,
    providerType: 'ALL',
    purchasePrice: 619000,
    remainingDebt: 100000,
    sortOrder: 'EFFECTIVE_INTEREST_RATE'
  }

  try {
    const response = await getOffer(request)
    return mapToMortgageProviderResult(response)
  } catch {
    return { providers: [] }
  }
}

const mapFromRandomProvider = (provider: RandomProviderEntry): MortgageProvider => ({
  id: provider.providerId,
  name: provider.name,
  logo: provider.image,
  averageRating: provider.averageRating,
  numberOfRatings: provider.numberOfRatings
})

const mapFromRandomProvidersResult = (response: RandomProvidersResult): MortgageProviderResult => ({
  providers: response.map(mapFromRandomProvider)
})

export async function fetchRandomProviders(): Promise<MortgageProviderResult> {
  try {
    const response = await getRandomProviders()
    return mapFromRandomProvidersResult(response)
  } catch {
    return { providers: [] }
  }
}
