import React, { ChangeEvent } from 'react'
import { Accordion, AccordionItem, Stepper, TextArea } from 'is24-corecss'
import { useTranslation } from 'react-i18next'
import './OthersSection.less'
import { NumberInput } from '../../../../common/input/numericInput'
import DateInputWithPlaceholder, {
  DateValidationResult
} from '../../../../common/input/dateInputWithPlaceholder/DateInputWithPlaceholder'
import { BankSelection } from '../../../../common/input/bankSelection/BankSelection'
import { BankDataObject } from '../../../../../api/api/BankData'
import {
  currentDateAsISOString, formatDateString,
  GERMAN_DATE_FORMAT,
  isDateStringBetween, ISO_DATE_FORMAT,
  isValidDateString
} from '../../../../../utils/dateUtils'

const MIN_NUMBER_OF_BORROWERS = 1
const MAX_NUMBER_OF_BORROWERS = 5

export type OthersSectionProps = {
  dateOfBirth?: string
  setDateOfBirth: (date?: string) => void
  bank?: BankDataObject
  setBank: (bank: BankDataObject) => void
  numberOfBorrowers: number
  setNumberOfBorrowers: (numberOfBorrowers: number) => void
  otherLoans: number,
  setOtherLoans: (otherLoans: number) => void
  message?: string
  setMessage: (message: string) => void
}

const isValidDateRange = (dateString: string): boolean =>
  isDateStringBetween(dateString, '1900-01-01', currentDateAsISOString())

export const validateDateString = (dateStringGermanFormat: string | undefined): DateValidationResult => {
  if (dateStringGermanFormat?.trim() === '' || dateStringGermanFormat === undefined) {
    return { isValid: true }
  }
  if (!isValidDateString(dateStringGermanFormat, GERMAN_DATE_FORMAT)) {
    return { isValid: false }
  }
  const dateString = formatDateString(dateStringGermanFormat as string, GERMAN_DATE_FORMAT, ISO_DATE_FORMAT)
  if (!isValidDateRange(dateString)) {
    return { isValid: false }
  }
  return { isValid: true }
}

const OthersSection: React.FC<OthersSectionProps> = (
  {
    dateOfBirth,
    setDateOfBirth,
    numberOfBorrowers,
    setNumberOfBorrowers,
    bank,
    setBank,
    otherLoans,
    setOtherLoans,
    message,
    setMessage
  }) => {

  const { t } = useTranslation('widgets', { keyPrefix: 'financingCalculator.appointmentRequestModal.othersSection' })

  const normalizeNumberOfBorrowers = (numberOfBorrowers: number): number => {
    if (numberOfBorrowers > MAX_NUMBER_OF_BORROWERS) {
      return MAX_NUMBER_OF_BORROWERS
    }
    if (numberOfBorrowers < MIN_NUMBER_OF_BORROWERS) {
      return MIN_NUMBER_OF_BORROWERS
    }
    return Math.floor(numberOfBorrowers)
  }

  const handleNumberOfBorrowersOnChange = (event: any): void => {
    const numberOfBorrowers = normalizeNumberOfBorrowers(Number(event.target.value))
    setNumberOfBorrowers(numberOfBorrowers)
  }

  return (
    <div className='OthersSection' data-testid='others-section'>
      <Accordion>
        <AccordionItem panelClassName={'Panel'} title={t('title')}>
          <div className='grid grid-flex'>
            <div
              className='Field grid-item desk-one-half one-whole desk-padding-right-xs palm-padding-top-m lap-padding-top-m'>
              <DateInputWithPlaceholder
                id='dateOfBirth'
                label={t('dateOfBirthLabel')}
                placeholder={t('dateOfBirthPlaceholder')}
                initialValue={dateOfBirth}
                onChange={setDateOfBirth}
                validateDateString={(e) => validateDateString(e)}
              />
            </div>
            <div
              className='Field grid-item desk-one-half one-whole desk-padding-left-xs palm-padding-top-m lap-padding-top-m'>
              <BankSelection
                value={bank}
                label={t('bankLabel')}
                placeholder={t('bankPlaceholder')}
                onBankSelect={setBank}/>
            </div>
            <div className='Field grid-item desk-one-half one-whole desk-padding-right-xs padding-top-m'>
              <Stepper
                id={'numberOfBorrowers'}
                label={t('numberOfBorrowers')}
                defaultValue={numberOfBorrowers.toString()}
                min={MIN_NUMBER_OF_BORROWERS}
                max={MAX_NUMBER_OF_BORROWERS}
                onChange={handleNumberOfBorrowersOnChange}
                onPaste={handleNumberOfBorrowersOnChange}
              />
            </div>
            <div className='Field grid-item desk-one-half one-whole desk-padding-left-xs padding-top-m'>
              <NumberInput
                id={'otherLoans'}
                placeholder=''
                label={t('otherLoansLabel')}
                min={0}
                value={otherLoans}
                onChange={setOtherLoans}
                unitRight='eur'
              />
            </div>
          </div>
          <div className='padding-top-m'>
            <TextArea
              value={message}
              id={'message'}
              placeholder={t('messagePlaceholder')}
              label={t('messageTitle')}
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setMessage(e.target.value)}
              maxLength={1024}
            />
          </div>
        </AccordionItem>
      </Accordion>
    </div>
  )
}

export default OthersSection
