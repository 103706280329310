import React, { useEffect, useState } from 'react'
import { Button, StatusMessage } from 'is24-corecss'
import { isPermanentlyEmployed } from '../../../api/api/types/Employment'

import { StepWrapper } from '../pages/StepWrapper'
import { useTranslation } from 'react-i18next'
import { RoadPage } from '../FinancingRequestRoad'
import { BorrowerIncomeAO } from '../../../api/api/types/BorrowerIncomeAO.ds'
import { getChildBenefits } from '../../../api/api/Financing'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../../redux/store'
import { BorrowersType, LeadEngineState } from '../state/reducer'
import { onBorrowersDataSubmit } from '../state/thunks/app'
import { withPageViewReporting } from '../hoc/withTrackPageView'
import { minNumberOfSalaries } from '../../common/incomeForm/IncomeSalaryStepperRow'
import { formatNumber } from '../../../services/NumberUtils'
import IncomeForm, { getIncomeTotal } from '../../common/incomeForm/IncomeForm'
import { MenuBar } from '../MenuBar'
import { useNavigate } from 'react-router-dom'

export type IncomeFormType = {
  nextPage: string,
}

const Income: React.FC<IncomeFormType> = ({ nextPage }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation('leadEngine')

  const {
    borrowers: { primary: primaryBorrower, secondary: secondaryBorrower },
    financing: { numberOfBorrowers = 1, numberOfChildren = 0 }
  } = useSelector<ApplicationState, LeadEngineState>(state => state.leadEngine)

  const [ primaryBorrowerIncome, setPrimaryBorrowerIncome ] = useState<BorrowerIncomeAO>({ numberOfSalaries: minNumberOfSalaries, ...primaryBorrower?.income })
  const [ secondaryBorrowerIncome, setSecondaryBorrowerIncome ] = useState<BorrowerIncomeAO>({ numberOfSalaries: minNumberOfSalaries, ...secondaryBorrower?.income })
  const total = getIncomeTotal([primaryBorrowerIncome, secondaryBorrowerIncome])

  const [ showError, setShowError ] = useState<boolean>(false)

  useEffect(() => {
    const fetchChildBenefits = async (children: number) => {
      const { childBenefits } = await getChildBenefits(children)
      setPrimaryBorrowerIncome(state => ({ ...state, childBenefits }))
    }

    numberOfChildren > 0 && fetchChildBenefits(numberOfChildren)
  }, [ numberOfChildren ])

  const firstLetter = (s = '') => s.substr(0, 1)

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()

    if (total > 0) {
      const data: BorrowersType = {
        primary: { income: primaryBorrowerIncome },
      }

      if (numberOfBorrowers > 1) {
        data.secondary = { income: secondaryBorrowerIncome }
      }

      dispatch(onBorrowersDataSubmit(nextPage, data, navigate))
    } else {
      setShowError(true)
    }
  }

  return <>
    <MenuBar page={RoadPage.INCOME_DETAILS}/>
    <StepWrapper title={t('incomeForm.title', { count: numberOfBorrowers })}>
      <div className={`${numberOfBorrowers === 1 ? 'one-half' : ''} horizontal-center palm-one-whole`}>
        {showError && (
          <div className='margin-bottom'>
            <StatusMessage
              headline={t('incomeForm.error.headline')}
              message={t('incomeForm.error.text')}
              statusType='error'
            />
          </div>
        )}

        <form noValidate={true} onSubmit={handleSubmit} name='income-search'>
          {numberOfBorrowers > 1 &&
          <div className="grid-flex margin-bottom flex-nowrap">
            <div className='one-whole padding-right'>
              <strong>{primaryBorrower.forename} {firstLetter(primaryBorrower.surname)}.</strong>
            </div>

            <div className='one-whole padding-left'>
              <strong>{secondaryBorrower?.forename} {firstLetter(secondaryBorrower?.surname)}.</strong>
            </div>
          </div>
          }

          <IncomeForm
            primaryIncome={primaryBorrowerIncome}
            secondaryIncome={secondaryBorrowerIncome}
            showPrimarySalary={isPermanentlyEmployed(primaryBorrower.employment)}
            showSecondarySalary={isPermanentlyEmployed(secondaryBorrower?.employment)}
            numberOfBorrowers={numberOfBorrowers}
            primaryOnChange={setPrimaryBorrowerIncome}
            secondaryOnChange={setSecondaryBorrowerIncome}
          />

          <div className='grid-flex grid-align-center border-top padding-top margin-top flex-nowrap'>
            <div className='one-whole align-right padding-right-l'>
              <span className='font-lightgray'>{t('incomeForm.total')}</span> <br/>
              <strong>{formatNumber(total)}&nbsp;€</strong>
            </div>
            <div>
              <Button appearance="secondary" type="submit">{t('common:button.acquire')}</Button>
            </div>
          </div>
        </form>
      </div>
    </StepWrapper>
  </>
}
export default withPageViewReporting(Income, 'ext.income')
