import { mapToFinancingDataLocal } from './financingDataMapper'
import { FinancingData, FinancingDataLocal } from './types'
import { setLocalStorageData, getLocalStorageData } from '../../../../../api/api/LocalStorage'

const KEY_NAME = 'financingData'

export const updateFinancingData = (financingData: FinancingData) => {
  const financingDataLocal: FinancingDataLocal = mapToFinancingDataLocal(financingData)
  const data = getLocalStorageData(KEY_NAME, '') || {}

  setLocalStorageData(KEY_NAME, { ...data, ...financingDataLocal }, '')
}
