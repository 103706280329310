import { API_V1_URL } from '../contextPaths'

const loanInfoUrl =
  API_V1_URL +
  '/marketdatarange/' +
  '?amortizationRate=2' +
  '&employment=EMPLOYED' +
  '&fixedNominalInterestRate=TEN_YEARS' +
  '&geoCode=1276' +
  '&loanAmount=200000' +
  '&loanToValueRatio=80'

export const interestRates = async () => {
  try {
    const response = await fetch(loanInfoUrl)
    if (!response.ok)  {
      return {
        averageInterestRate: 0.8,
        effectiveInterestRate: 0.8,
      }
    }
    const body = await response.json()
    const date = new Date().toISOString().slice(0,10).replace(/-/g,'')
    return body[date]['1276']['EMPLOYED']['299999.00']['80']['2.99']['TEN_YEARS']
  } catch {
    return {
      averageInterestRate: 0.8,
      effectiveInterestRate: 0.8,
    }
  }
}
