import { ButtonGroup } from './ButtonGroup'
import { StepWrapper } from './StepWrapper'
import React from 'react'
import { RoadPage } from '../FinancingRequestRoad'
import { withPageViewReporting } from '../hoc/withTrackPageView'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../../redux/store'
import { LeadEngineState } from '../state/reducer'
import { useTranslation } from 'react-i18next'
import { onPropertySubmit} from '../state/thunks/app'
import { EnergeticModernizationType } from '../../../api/api/types/EnergeticModernizationType'
import { MenuBar } from '../MenuBar'
import { useNavigate } from 'react-router-dom'

const EnergeticModernizationValues: EnergeticModernizationType[] = [
  'AFTER_PURCHASE',
  'LATER',
  'NOT_NEEDED',
  'UNCLEAR'
]

const EnergeticModernizationPage: React.FC<{ nextPage: string }> = ({ nextPage }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { property: { energeticModernization } } = useSelector<ApplicationState, LeadEngineState>(state => state.leadEngine)
  const { t } = useTranslation('leadEngine')

  const handleOnClick = (energeticModernization: EnergeticModernizationType) =>
    dispatch(onPropertySubmit(nextPage, navigate)({ energeticModernization: energeticModernization }))

  const title = t('energeticModernization.title')

  return <>
    <MenuBar page={RoadPage.ENERGETIC_MODERNIZATION} hideClose/>
    <StepWrapper title={title}>
      <ButtonGroup<EnergeticModernizationType>
        values={EnergeticModernizationValues}
        onClick={handleOnClick}
        value={energeticModernization}
      />
    </StepWrapper>
  </>
}

export default withPageViewReporting(EnergeticModernizationPage, 'energeticmodernization')

