import React, { ChangeEvent, useEffect, useState } from 'react'
import { useIsMounted } from '../../../../utils/utils'
import '../../../leadengine/financingSettings/FinancingSettings.less'
import { BankDataObject, getBankData } from '../../../../api/api/BankData'
import { TextInputSelect } from '../../locationInput/TextInputSelect'

export interface BankSelectionProps {
  onBankSelect: (bank: BankDataObject) => void,
  valid?: boolean,
  value?: BankDataObject,
  errorMessage?: string
  label?: string,
  placeholder?: string,
}

const entryTransformation = (entity: BankDataObject) => `${entity.BLZ} ${entity.Name} ${entity.BIC}`

export const BankSelection: React.FC<BankSelectionProps> = (
  {
    onBankSelect,
    valid = true,
    value,
    errorMessage,
    label,
    placeholder
  }) => {

  const [ inputValue, setInputValue ] = useState(value ? entryTransformation(value) : '')
  const [ entries, setEntries ] = useState<BankDataObject[]>([])
  const isMounted = useIsMounted();

  useEffect(() => {
    setInputValue(value ? entryTransformation(value) : '')
  }, [ value ])

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEntries([])

    const searchString = e.target.value
    setInputValue(searchString)

    if (searchString === '') return

    const interval = setTimeout(async () => {
      if (isMounted.current) {
        const result = await getBankData(searchString)
        setEntries(result)
      }
    }, 400)

    return () => clearInterval(interval)
  }

  const onSelect = async (index: number) => {
    const entityObject = entries[ index ]
    setInputValue(entryTransformation(entityObject))
    onBankSelect(entityObject)
  }

  const onFocus = () => {
    setInputValue('')
  }

  const onBlur = () => {
    if (inputValue?.length === 0) {
      setInputValue(value ? entryTransformation(value) : '')
    }

    setTimeout(() => {
      if (isMounted.current) {
        setEntries([])
      }
    }, 400)
  }

  return <TextInputSelect<BankDataObject>
    value={inputValue}
    entries={entries}
    placeholder={placeholder}
    id={'bankInput'}
    name={'bankInput'}
    label={label}
    valid={valid}
    errorMessage={errorMessage}
    entryTransformation={entryTransformation}
    onSelect={onSelect}
    onChange={onChange}
    onBlur={onBlur}
    onFocus={onFocus}
  />
}
