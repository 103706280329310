export const IncomeRange = {
  OVER_1000_UPTO_1500: 'OVER_1000_UPTO_1500',
  OVER_1500_UPTO_2000: 'OVER_1500_UPTO_2000',
  OVER_2000_UPTO_2500: 'OVER_2000_UPTO_2500',
  OVER_2500_UPTO_3000: 'OVER_2500_UPTO_3000',
  OVER_3000_UPTO_3500: 'OVER_3000_UPTO_3500',
  OVER_3500_UPTO_4000: 'OVER_3500_UPTO_4000',
  OVER_4000_UPTO_5000: 'OVER_4000_UPTO_5000',
  OVER_5000: 'OVER_5000'
} as const
export type IncomeRangeType = keyof typeof IncomeRange

export const IncomeRangeValues: IncomeRangeType[] = [
  'OVER_1000_UPTO_1500',
  'OVER_1500_UPTO_2000',
  'OVER_2000_UPTO_2500',
  'OVER_2500_UPTO_3000',
  'OVER_3000_UPTO_3500',
  'OVER_3500_UPTO_4000',
  'OVER_4000_UPTO_5000',
  'OVER_5000',
];


type IncomeRangeToMaxRangeType = Record<IncomeRangeType, number>;
export const IncomeRangeToMaxRange: IncomeRangeToMaxRangeType = {
  'OVER_1000_UPTO_1500': 1500,
  'OVER_1500_UPTO_2000': 2000,
  'OVER_2000_UPTO_2500': 2500,
  'OVER_2500_UPTO_3000': 3000,
  'OVER_3000_UPTO_3500': 3500,
  'OVER_3500_UPTO_4000': 4000,
  'OVER_4000_UPTO_5000': 5000,
  'OVER_5000': Number.MAX_SAFE_INTEGER
};

export const IncomeRangeToAverageRange: IncomeRangeToMaxRangeType = {
  'OVER_1000_UPTO_1500': 1250,
  'OVER_1500_UPTO_2000': 1750,
  'OVER_2000_UPTO_2500': 2250,
  'OVER_2500_UPTO_3000': 2750,
  'OVER_3000_UPTO_3500': 3250,
  'OVER_3500_UPTO_4000': 3750,
  'OVER_4000_UPTO_5000': 4500,
  'OVER_5000': Number.MAX_SAFE_INTEGER
};
