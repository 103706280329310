import { ADDITIONAL_COSTS_API, FINANCING_CALCULATOR_API } from '../../../api/contextPaths'
import { ContentType, getRequest, postRequest } from '../../../api/request'
import { AdditionalCostAO } from '../../../api/api/types/UserAffordabilityResultAO'
import { CalculateAmortizationResponse } from '../resultPage/model/FinancingResults'
import { Expose } from '../../../api/api/Expose'
import { OneDynamicFlowState } from '../../../redux/reducers/OneDynamicFlow'
import { getLocationFromExpose } from '../../common/oneDynamicFlow/pages/location/map/ShortlistSelectionUtils'
import { FinancingProbability } from '../../../api/preApproval/FinancingProbability'
import { CalculateAmortizationRequest } from '../resultPage/model/FinancingRequest'

const getAdditionalCostBy = (paramName: 'geocode' | 'postalcode', paramValue: string): Promise<AdditionalCostAO> => {

  return getRequest({
    url: `${ADDITIONAL_COSTS_API}?${paramName}=${paramValue}`,
    headers: {
      'Content-Type': ContentType.APPLICATION_JSON_UTF8
    }
  }).catch((e) => Promise.reject(new Error('Get additional costs api failed: ' + e)))
}

export const getAdditionalCostByGeocode = (geoCode: string): Promise<AdditionalCostAO> => getAdditionalCostBy('geocode', geoCode)

export const getAdditionalCostByPostalCode = (postalCode: string): Promise<AdditionalCostAO> => getAdditionalCostBy('postalcode', postalCode)

export const calculateProbability = (loanAmount: number, purchasePrice: number, monthlyIncome: number, borrowerAge: number): Promise<FinancingProbability> =>
  postRequest({
    url: `${FINANCING_CALCULATOR_API}/calculate-probability`,
    body: { loanAmount, purchasePrice, monthlyIncome, borrowerAge }
  }).then(response => response.probability)

export const calculateAmortization = ({
  purchasePrice,
  additionalCosts,
  ownFunds,
  amortizationRate,
  geoCode,
  postalCode,
  employmentType,
  fixedRatePeriod,
  repaymentAmount,
  repaymentType,
  repaymentYearOrMonth,
  financingType,
  propertyValue,
  remainingDebt
}: CalculateAmortizationRequest): Promise<CalculateAmortizationResponse> =>
  postRequest({
    url: `${FINANCING_CALCULATOR_API}/calculate-amortization`,
    body: {
      purchasePrice,
      additionalCosts,
      ownFunds,
      amortizationRate,
      ... (postalCode !== undefined) ? { postalCode } : { geoCode },
      employmentType,
      fixedRatePeriod,
      financingType,
      overpaymentType: repaymentType,
      overpaymentTime: repaymentYearOrMonth,
      overpaymentAmount: repaymentAmount,
      propertyValue,
      remainingDebt
    }
  })

export const extractFlowDataFrom = (expose: Expose | undefined, exposeFromShortlist: boolean | undefined): OneDynamicFlowState => ({
  exposeId: expose?.id,
  exposeFromShortlist,
  propertyCost: expose?.purchasePrice ?? undefined,
  floorArea: expose?.livingArea,
  brokerCommission: expose?.brokerCommission,
  brokerCommissionPercentage: expose?.brokerCommissionPercentage
})

export const extractFlowDataWithLocationFrom = (expose: Expose | undefined, exposeFromShortlist: boolean | undefined): OneDynamicFlowState => ({
  ...extractFlowDataFrom(expose, exposeFromShortlist),
  location: expose?.address && getLocationFromExpose(expose)
})

