import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'

export const shortlistTabPathParam = 'merkzettel'

const useShortlistTabPreselection =  () => {
  const { search } = useLocation()
  const navigate = useNavigate()
  const [ initialized, setInitialized ] = useState(false);
  const [ preselectShortlistTab, setPreselectShortlistTab ] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(search)
    if (queryParams.has(shortlistTabPathParam)) {
      queryParams.delete(shortlistTabPathParam)
      navigate({ search: queryParams.toString() }, { replace: true })
      setPreselectShortlistTab(true)
    }
    setInitialized(true)
  }, [search, navigate])

  return { initialized, preselectShortlistTab };
}

export default useShortlistTabPreselection
