const LAND_REGISTER_DEFAULT = 0.5;
const NOTARY_COSTS_DEFAULT = 1.5;

export type StateValue = {
    geoCode: string,
    landTransferTax: number,
    brokerCommission: number,
    notaryCosts: number,
    landRegister: number,
    label: string
}

export const StateValues: StateValue[] = [
  {
    geoCode: '1276001',
    landTransferTax: 5,
    brokerCommission: 3.57,
    notaryCosts: NOTARY_COSTS_DEFAULT,
    landRegister: LAND_REGISTER_DEFAULT,
    label: 'Baden-Württemberg'
  }, {
    geoCode: '1276002',
    landTransferTax: 3.5,
    brokerCommission: 3.57,
    notaryCosts: NOTARY_COSTS_DEFAULT,
    landRegister: LAND_REGISTER_DEFAULT,
    label: 'Bayern'
  }, {
    geoCode: '1276003',
    landTransferTax: 6,
    brokerCommission: 3.57,
    notaryCosts: NOTARY_COSTS_DEFAULT,
    landRegister: LAND_REGISTER_DEFAULT,
    label: 'Berlin'
  }, {
    geoCode: '1276004',
    landTransferTax: 6.5,
    brokerCommission: 3.57,
    notaryCosts: NOTARY_COSTS_DEFAULT,
    landRegister: LAND_REGISTER_DEFAULT,
    label: 'Brandenburg'
  }, {
    geoCode: '1276005',
    landTransferTax: 5,
    brokerCommission: 2.97,
    notaryCosts: NOTARY_COSTS_DEFAULT,
    landRegister: LAND_REGISTER_DEFAULT,
    label: 'Bremen'
  }, {
    geoCode: '1276006',
    landTransferTax: 5.5,
    brokerCommission: 3.12,
    notaryCosts: NOTARY_COSTS_DEFAULT,
    landRegister: LAND_REGISTER_DEFAULT,
    label: 'Hamburg'
  }, {
    geoCode: '1276007',
    landTransferTax: 6,
    brokerCommission: 2.97,
    notaryCosts: NOTARY_COSTS_DEFAULT,
    landRegister: LAND_REGISTER_DEFAULT,
    label: 'Hessen'
  }, {
    geoCode: '1276008',
    landTransferTax: 6,
    brokerCommission: 2.97,
    notaryCosts: NOTARY_COSTS_DEFAULT,
    landRegister: LAND_REGISTER_DEFAULT,
    label: 'Mecklenburg-Vorpommern'
  }, {
    geoCode: '1276009',
    landTransferTax: 5,
    brokerCommission: 3.57,
    notaryCosts: NOTARY_COSTS_DEFAULT,
    landRegister: LAND_REGISTER_DEFAULT,
    label: 'Niedersachsen'
  }, {
    geoCode: '1276010',
    landTransferTax: 6.5,
    brokerCommission: 3.57,
    notaryCosts: NOTARY_COSTS_DEFAULT,
    landRegister: LAND_REGISTER_DEFAULT,
    label: 'Nordrhein-Westfalen'
  }, {
    geoCode: '1276011',
    landTransferTax: 5,
    brokerCommission: 3.57,
    notaryCosts: NOTARY_COSTS_DEFAULT,
    landRegister: LAND_REGISTER_DEFAULT,
    label: 'Rheinland-Pfalz'
  }, {
    geoCode: '1276012',
    landTransferTax: 6.5,
    brokerCommission: 3.57,
    notaryCosts: NOTARY_COSTS_DEFAULT,
    landRegister: LAND_REGISTER_DEFAULT,
    label: 'Saarland'
  }, {
    geoCode: '1276013',
    landTransferTax: 5.5,
    brokerCommission: 3.57,
    notaryCosts: NOTARY_COSTS_DEFAULT,
    landRegister: LAND_REGISTER_DEFAULT,
    label: 'Sachsen'
  }, {
    geoCode: '1276014',
    landTransferTax: 5,
    brokerCommission: 3.57,
    notaryCosts: NOTARY_COSTS_DEFAULT,
    landRegister: LAND_REGISTER_DEFAULT,
    label: 'Sachsen-Anhalt'
  }, {
    geoCode: '1276015',
    landTransferTax: 6.5,
    brokerCommission: 3.57,
    notaryCosts: NOTARY_COSTS_DEFAULT,
    landRegister: LAND_REGISTER_DEFAULT,
    label: 'Schleswig-Holstein'
  }, {
    geoCode: '1276016',
    landTransferTax: 6.5,
    brokerCommission: 3.57,
    notaryCosts: NOTARY_COSTS_DEFAULT,
    landRegister: LAND_REGISTER_DEFAULT,
    label: 'Thüringen'
  },
]

export const getStateValueByGeoCode = (geoCode = ''): StateValue | undefined => StateValues.find((v) => v.geoCode === geoCode.substr(0, 7))
