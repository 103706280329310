import React from 'react'
import CustomTooltip from '../customTooltip/CustomTooltip'

type FormLabelType = {
  label: string
  tooltip?: string
}

export const FormLabel: React.FC<FormLabelType> = ({ label, tooltip }) => {
  const labelValue = (label === '') ? <span>&nbsp;</span> : <label className='font-bold'>{label}</label>

  return <div className={`grid-flex padding-top-m`}>
    {labelValue}
    {tooltip && <CustomTooltip title={label} text={tooltip}/>}
  </div>
}
