import React, { ReactChild, useEffect, useState } from 'react'
import './LogarithmicSliderWithInput.less'
import { NumberInput } from '../../common/input/numericInput'
import { LogarithmicSlider } from '../logarithmicSlider/LogarithmicSlider'

export const limitValue = (value: number, min: number, max: number, finalMaxRange?: number): number => {
  const effectiveMax = finalMaxRange ?? max
  return Math.max(Math.min(value, effectiveMax), min)
}

type LogarithmicSliderWithInputType = {
  topLabel: ReactChild,
  id: string,
  min: number,
  max: number,
  value: number,
  onRelease: (val: number) => void,
  finalMaxRange?: number,
}

export const LogarithmicSliderWithInput: React.FC<LogarithmicSliderWithInputType> = ({
  topLabel,
  id,
  min,
  max,
  value,
  onRelease,
  finalMaxRange,
}) => {
  const [ inputValue, setInputValue ] = useState(value);
  const [ sliderValue, setSliderValue ] = useState(value)

  const onNumberInputBlur = () => {
    const newValue = limitValue(inputValue, min, max, finalMaxRange)
    setInputValue(newValue)
    setSliderValue(newValue)
    onRelease(newValue)
  }

  const onSliderRelease = (val: number) => {
    setInputValue(val)
    onRelease(val)
  }

  useEffect(() => {
    setInputValue(value)
    setSliderValue(value)
  }, [ value ])

  return (
    <div className='LogarithmicSliderWithInput'>
      <div className='HorizontalLayoutFlex'>
        <span role='rowheader'>{topLabel}</span>
        <div className='HorizontalLayoutSlider'>
          <LogarithmicSlider
            id={id}
            min={min}
            max={max}
            onRelease={onSliderRelease}
            value={sliderValue}/>
        </div>
        <div className='number-input'>
          <NumberInput
            id={`${id}-number-input`}
            value={inputValue}
            onChange={setInputValue}
            onBlur={onNumberInputBlur}
          />
        </div>
      </div>
    </div>
  )
}
