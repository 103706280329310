import { ApexOptions } from 'apexcharts'
import '../AmortizationChart'
import { ChartColors } from '../AmortizationChart'
import { TFunction } from 'i18next'
import { toCurrency } from '../../../../../services/NumberUtils'
import { formatYearsAndMonths } from '../../loanDevelopment/useFormattedYearsAndMonths'

const MONTHS_IN_YEAR = 12

export const AmortizationChartOptions = (interestEndYear: number, t: TFunction, numberOfInstalments: number) => {

  const breakdown = (numberOfInstalments <= 16 * MONTHS_IN_YEAR ? 5 : 10) * MONTHS_IN_YEAR
  const categories = createCategoriesRoundedUpToBreakdown(numberOfInstalments, breakdown);


  const getTooltipTitle = (seriesName: string) => {
    if (seriesName === t('secondPeriod')) {
      return (
        '<div>' + t('secondPeriodDividedFirst') + '</div>' +
        '<div>' + t('secondPeriodDividedSecond') + '</div>'
      )
    } else {
      return seriesName
    }
  }

  const getTooltipText = (monthlyInstalmentIndex: number) =>
    `${t('tooltipMessage')} ${formatYearsAndMonths(monthlyInstalmentIndex, t, true)}`

  return {
    chart: {
      id: 'amortization-chart',
      type: 'line',
      toolbar: {
        show: false
      },
      animations: {
        enabled: true,
      },
      zoom: {
        enabled: false
      },
      events: {}
    },
    tooltip: {
      enabled: true,
      custom: ({ series, seriesIndex, dataPointIndex, w }) =>
        (
          '<div class="tooltip">' +
          '<div class="content">' +
          `<div class="font-bold">${getTooltipTitle(w.globals.seriesNames[ seriesIndex ])}</div>` +
          `<div>${getTooltipText(dataPointIndex)}</div>` +
          `<div>${toCurrency(series[ seriesIndex ][ dataPointIndex ] ?? 0)}</div>` +
          '</div>' +
          '</div>'
        )
    },
    annotations: {
      xaxis: [
        {
          x: interestEndYear * MONTHS_IN_YEAR,
          strokeDashArray: 0,
          borderColor: ChartColors.FixedRateEndYear,
          borderWidth: 5
        } ]
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'straight'
    },
    grid: {
      borderColor: ChartColors.Grid,
      padding: {
        right: 25
      }
    },
    xaxis: {
      categories: categories,
      type: 'numeric',
      overwriteCategories: overwriteCategories(categories, t, breakdown),
      axisTicks: {
        show: false
      },
      labels: {
        style: {
          colors: ChartColors.Grid
        },
        formatter(value: string) {
          return value == `0 ${t('years')}` ? '' : value
        },
        hideOverlappingLabels: true
      },
      tooltip: {
        enabled: false
      },
      crosshairs: {
        show: false
      }
    },
    yaxis: {
      tickAmount: 4,
      labels: {
        style: {
          colors: ChartColors.Grid
        },
        formatter: (value) => yAxisFormatter(value)
      }
    },
    legend: {
      height: 80,
      horizontalAlign: 'left',
      itemMargin: {
        horizontal: 20
      },
      onItemClick: () => undefined
    }

  } as ApexOptions
}

export const yAxisFormatter = (value: number) => {
  if (value == 0) {
    return '0'
  } else if (value >= 1000) {
    return (value - (value % 1000)) / 1000 + ' K'
  }
}
export const createCategoriesRoundedUpToBreakdown = (value: number, breakdown: number) => {
  return [ ...Array(Math.ceil(value / breakdown) * breakdown + 1).keys() ];
}

export const overwriteCategories = (categories: number[], t: TFunction, breakdown: number) => {
  return categories.filter((value) => value % breakdown == 0).map(value => `${value / MONTHS_IN_YEAR} ${t('years')}`)
}
