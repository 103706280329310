import React from 'react';
import { useTranslation } from 'react-i18next'
import FormRow from './FormRow'
import ReadOnlyField from './ReadOnlyField'
import './LoanAmountReadOnlyRow.less'

export type LoanAmountReadOnlyRowProps = {
  loanAmount: number,
  loanAmountValid: boolean
}

const LoanAmountReadOnlyRow: React.FC<LoanAmountReadOnlyRowProps> = ({ loanAmount, loanAmountValid }) => {
  const { t } = useTranslation('leadEngine', { keyPrefix: 'followupFinancingSettingsPage' })
  return <div className='LoanAmountReadOnlyRow'>
    <FormRow title={t('loanAmount')}
             className='palm-padding-vertical-m background'>
      <ReadOnlyField value={loanAmount}/>
    </FormRow>
    {!loanAmountValid &&
      <div className='grid-item absolute-reference'>
        <div className='ErrorMessageContainer absolute-content'>
          <span className='Icon'><i className='is24-icon-error-glyph font-error'/></span>
          <span className='FirstRow font-error'>{t('loanAmountTooLow1')}</span>
          <div className='SecondRow font-error'>{t('loanAmountTooLow2')}</div>
        </div>
      </div>}
  </div>
}

export default LoanAmountReadOnlyRow
