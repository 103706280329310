import React from 'react'
import NumberInput from '../../../../common/input/numericInput/NumberInput'
import CustomTooltip from '../../../../common/customTooltip/CustomTooltip'
import './InputRow.less'

export type InputRowProps = {
  text: string
  tooltipMessage?: string
  value: number
  maxValue: number,
  onChange: (value: number) => void
}

export const InputRow: React.FC<InputRowProps> = ({ text, tooltipMessage, value, onChange, maxValue }) => {
  return (
    <div className='InputRow'>
      <div className='Header'>
        <span className='Text padding-right-m'>{text}</span>
        {tooltipMessage &&
          <span className='Tooltip' data-testid='tooltip'>
            <CustomTooltip title={text} text={tooltipMessage} bodyPosition={'top'} arrowPosition={'left'}
                           fontSize={'font-l'} minWidth={220}/>
          </span>
        }
      </div>
      <NumberInput
        id={text}
        valid={value <= maxValue}
        value={value}
        onChange={onChange}
        forceSelect={false}
        unitRight='eur'
        className='Input'
        ariaLabel={text}
      />
    </div>
  )
}

export default InputRow
