export const MaritalStatusValue = {
  SINGLE: 'SINGLE',
  MARRIED: 'MARRIED',
  SEPARATED: 'SEPARATED',
  DIVORCED: 'DIVORCED',
  WIDOWED: 'WIDOWED',
  PARTNERSHIP: 'PARTNERSHIP'
} as const

export type MaritalStatusType = keyof typeof MaritalStatusValue
