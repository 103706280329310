/*
  Rent expenses within the period of residence = (R * (q ^ n-1) / (q-1))
  r = monthly rent * 12
  q = 1 + rent increase / 100 (example: = 1 + 3/100, if increase is 3%)
  n = duration of residence (years)
  Rental costs / month = total rental costs / (12 * length of residence in years)
*/

export const getMonthlyRentCost = (rent: number, rentIncrease: number, livingTime: number, operatingCostAllowance: number): number => {
  const TWELVE_MONTH = 12

  const rentIncreasePercentage = 100 * ((Math.pow(rentIncrease / rent, 1 / (livingTime - 1))) - 1)
  const q = 1 + rentIncreasePercentage / 100
  const rentCostsPerYear = rent * TWELVE_MONTH

  let rentCostOverall
  if (q > 1 && livingTime > 1) {
    rentCostOverall = (rentCostsPerYear * (Math.pow(q, livingTime) - 1) / (q - 1))
  } else {
    rentCostOverall = (rentCostsPerYear * livingTime)
  }

  const result = rentCostOverall / (TWELVE_MONTH * livingTime)

  if (!isNaN(result) && result > 0) {
    return Math.round(result) + operatingCostAllowance
  } else {
    return rent + operatingCostAllowance
  }
}

/*
  Purchase costs within the living period = (equity + total loan installments + (total maintenance costs or total housing allowance)
  Total loan installments = monthly installment * 12 * length of residence in years
  Total maintenance costs = maintenance costs per year * length of residence in years
  Total housing benefit = housing benefit per month * 12 * length of residence in years
*/

export const getMonthlyBuyCost = (purchasePrice: number, ownFunds: number, additionalCosts: number, interestRate: number, monthlyRate: number, livingTime: number, maintenanceCostsPerMonth: number,
                                  amortizationRate: number, appreciation: number, opportunityInterestRate: number) => {
  const TWELVE_MONTH = 12


  const w = appreciation - purchasePrice
  const opportunityCosts = ownFunds * Math.pow(1 + opportunityInterestRate / 100, livingTime) - ownFunds

  const loanAmount = purchasePrice - ownFunds + additionalCosts
  const lastPaymentYear = getLastPaymentYear(interestRate, monthlyRate, loanAmount)
  const expectedLastPaymentYear = lastPaymentYear < livingTime ? lastPaymentYear : livingTime
  const overtimeCosts = monthlyRate * TWELVE_MONTH * expectedLastPaymentYear
  const costOfMaintenanceOverall = maintenanceCostsPerMonth * livingTime * TWELVE_MONTH

  const result = ((opportunityCosts + overtimeCosts + costOfMaintenanceOverall) - w) / (TWELVE_MONTH * livingTime)

  if(!isNaN(result) && result > 0) {
    return  Math.round(result * 100) / 100;
  } else {
    return 0;
  }
}


export const getLastPaymentYear = (interestRate: number, monthlyRate: number, loanAmount: number): number => {
  if (!(interestRate > 0 && monthlyRate > 0 && loanAmount > 0))
    return 0

  interestRate = interestRate / 100
  const p1 = interestRate - (monthlyRate * 12 / loanAmount)
  const p2 = -monthlyRate * 12 / loanAmount
  const p3 = 1 + interestRate / 12
  const result = Math.round(Math.log(p1 / p2) / Math.log(1 / p3) / 12)

  return isNaN(result) ? 0 : result
}
