import React from 'react'
import './Loading.less'

const Scale = {
  xs: 50, s: 100, m: 150, l: 200, xl: 250
}

type LoadingType = {
  size?: keyof typeof Scale
}

export const Loading: React.FC<LoadingType> = ({ size = 'm' }) => {
  return <svg style={{width: Scale[size]}} className='spinner align-center align-middle' viewBox='0 0 300 300' version="1.1" xmlns="http://www.w3.org/2000/svg"
       x="0px" y="0px" preserveAspectRatio="none">
    <path className="path" fill="transparent" strokeWidth="8" strokeMiterlimit="3"
          d="M45,290 l0,-140 l-40,0 l140,-140 l50,50 l0,-20 l30,0 l0,40 l70,70 l-40,0 l0,140 l-100,0 l0,-80 l-50,0 l0,80 -64,0"/>
  </svg>
}
