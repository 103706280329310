import React, { FormEvent, useEffect, useState } from 'react'

import './FinancingSettings.less'
import {
  Financing,
  getLoanAmount,
  isFollowupFinancing,
  isValidLoanAmount, MIN_LOAN_AMOUNT
} from '../models/Financing'
import { BuildingFinancingSettings } from './BuildingFinancingSettings'
import { FollowUpFinancingSettings } from './FollowUpFinancingSettings'
import { Summary } from './Summary'
import { RegionSelection } from '../../common/input/regionSelection/RegionSelection'
import { FinancingTypeSelection } from './FinancingTypSelection'
import { StatusMessage } from 'is24-corecss'
import { useTranslation } from 'react-i18next'
import { isValidLocation, Property } from '../models/Property'
import { Loading } from '../../common/loading/Loading'

type ErrorMessage = {
  title: string
  text: string
}

type FinancingSettingsType = {
  loading?: boolean
  financing: Financing
  property: Property
  onChange: (data: Partial<Financing>) => void
  onLocationChange: (property: Property) => void
  onSubmit: () => void
  errors?: ErrorMessage[]
}

export const FinancingSettings: React.FC<FinancingSettingsType> = ({
                                                                     financing,
                                                                     property,
                                                                     onChange,
                                                                     onLocationChange,
                                                                     onSubmit,
                                                                     loading
                                                                   }) => {
  const { financingType } = financing
  const loanAmount = getLoanAmount(financing)

  const { t } = useTranslation('leadEngine')
  const { geoCode, locationLabel } = property
  const [ submitted, setSubmitted ] = useState(false)

  const [ validations, setValidations ] = useState<{ location: boolean; loanAmount: boolean }>({
    loanAmount: false,
    location: false
  })

  useEffect(() => {
    setValidations({
      location: isValidLocation(property),
      loanAmount: isValidLoanAmount(loanAmount)
    })
  }, [ property, loanAmount ])

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()

    setSubmitted(true)

    if (validations.location && validations.loanAmount) {
      onSubmit()
    }
  }

  const renderError = (key: string, interpolationObj?: any) => (
    <div className='margin-bottom'>
      <StatusMessage
        headline={t(`financingSettings.errors.${key}.headline`)}
        statusType='error'
        message={t(`financingSettings.errors.${key}.message`, interpolationObj) as string}
      />
    </div>
  )

  if (loading) {
    return <div
      className='grid-flex grid-align-center grid-justify-center background-white financing-settings financing-settings-loader'>
      <Loading/>
    </div>
  }

  return (
    <form noValidate={true} onSubmit={handleSubmit} className='financing-settings'>
      {submitted && !validations.location && renderError('location')}
      {submitted &&
      !validations.loanAmount &&
      renderError('loanAmount', { amount: MIN_LOAN_AMOUNT })}

      <div
        className='grid-flex grid-align-center grid-justify-space-between padding-bottom border-bottom margin-bottom'>
        <div className='one-whole'>
          <FinancingTypeSelection
            onChange={(financingType) => onChange({ financingType })}
            financingType={financingType}
          />
        </div>

        <div className='font-italic font-lightgray padding-horizontal align-center palm-one-whole lap-one-whole'>
          in
        </div>

        <div className='one-whole'>
          <RegionSelection
            value={locationLabel}
            valid={!(submitted && !validations.location)}
            onRegionSelect={location => onLocationChange(location ?? property)}
          />
        </div>
      </div>
      {isFollowupFinancing(financingType) ?
        <FollowUpFinancingSettings financing={financing} onChange={onChange}/>
        :
        <BuildingFinancingSettings financing={financing} geoCode={geoCode} onChange={onChange}/>
      }
      <Summary loanAmount={loanAmount}/>
    </form>
  )
}




