import React, { useCallback, useEffect } from 'react'
import { Button } from 'is24-corecss'
import { withPageViewReporting } from '../hoc/withTrackPageView'
import { goToRoadPage } from '../routing'
import { useTranslation } from 'react-i18next'
import { AdvisoryRecommendationOld } from './AdvisoryRecommendationOld'
import { useExperiment } from '@optimizely/react-sdk';
import { AdvisoryRecommendation } from './AdvisoryRecommendation'
import { useNavigate } from 'react-router-dom'

type BookAppointmentPageType = {
  nextPage: string,
  showAdvisoryLinkFeatureEnabled: boolean
}

const BookAppointmentPage: React.FC<BookAppointmentPageType> = ({ nextPage, showAdvisoryLinkFeatureEnabled }) => {
  const { t } = useTranslation('leadEngine')
  const navigate = useNavigate()
  const [ variation ] = useExperiment(
    'BAU_8557_CALENDLY_WIDGET_VERSION',
    { autoUpdate: true },
  );
  const handleNextPage = useCallback(() => {
    goToRoadPage(nextPage, navigate)
  }, [ nextPage, navigate ])

  const handleEarlyAdvisory = useCallback((available: boolean) => {
    if (!available) {
      handleNextPage()
    }
  }, [ handleNextPage ])

  useEffect(() => {
    if (!showAdvisoryLinkFeatureEnabled) {
      handleNextPage()
    }
  }, [ handleNextPage, showAdvisoryLinkFeatureEnabled ])

  return <>
    <p className='font-bold font-l align-center'
       style={{ whiteSpace: 'pre-wrap', paddingBottom: '20px' }}>{t('bookAppointment.title')}</p>
    {(function () {
      if (showAdvisoryLinkFeatureEnabled) {
        if (!variation || variation === 'New_Widget') {
          return <AdvisoryRecommendation onAdvisoryAvailable={handleEarlyAdvisory}
                                         showAdvisoryLinkFeatureEnabled={showAdvisoryLinkFeatureEnabled}/>
        } else if (variation === 'Old_Widget') {
          return <AdvisoryRecommendationOld onAdvisoryAvailable={handleEarlyAdvisory}/>
        }
      }
    })()}
    <div className='border-top padding-top'>
      <p className='font-m margin-bottom align-center'>
        {t('bookAppointment.text')}
      </p>
    </div>

    <div className='align-center'>
      <Button onClick={handleNextPage}>{t('bookAppointment.button')}</Button>
    </div>
  </>
}

export default withPageViewReporting(BookAppointmentPage, 'anfrage-gesendet')
