import { Button } from 'is24-corecss'
import { useTranslation } from 'react-i18next'
import React  from 'react'
import { formatNumber } from '../../../services/NumberUtils'

import { InputWithSlider } from '../../common/inputWithSlider/InputWithSlider'
import './followupCalculator.less'
import { Loading } from '../../common/loading/Loading'

type FollowupCalculatorType = {
  loanDetails: {
    propertyValue: number
    remainingDebt: number
  }
  monthlyRate: number
  targetLink?: string
  buttonColor?: string
  buttonText?: string
  headline?: string
  isLoading: boolean
  onSliderUpdate: ({ propertyValue, remainingDebt }: { propertyValue: number; remainingDebt: number }) => void
}

const FollowupCalculator = ({
                              headline,
                              loanDetails,
                              targetLink,
                              onSliderUpdate,
                              monthlyRate,
                              isLoading,
                              buttonColor,
                              buttonText
                            }: FollowupCalculatorType) => {
  const { t } = useTranslation('widgets')


  const link = `${targetLink}?financingType=FOLLOWUP_FINANCING&cmp_id=10-02404&cmp_name=finance_calculator&cmp_position=finance_content&cmp_creative=calculator&propertyvalue=${loanDetails.propertyValue}&remainingdebt=${loanDetails.remainingDebt}&additionalCosts=0`
  const loanAmount = Math.max(loanDetails.remainingDebt, 0)

  const handleSliderUpdate = (value: number, type: string) => {
    onSliderUpdate({ ...loanDetails, [ type ]: value })
  }

  return (
    <div className='grid grid-flex grid-align-center padding-bottom-m'>
      <div className='grid-flex two-thirds'>
        <div className='one-whole palm-one-whole'>
          <h3>{headline ?? t('followupCalculator.title')}</h3>
          <div className='one-whole palm-one-whole'>
            <InputWithSlider
              id='property-value'
              title={t('followupCalculator.propertyValue')}
              value={loanDetails.propertyValue}
              onChange={(value) => handleSliderUpdate(value, 'propertyValue')}
              min={100000}
            />
          </div>
          <InputWithSlider
            id='remaining-debt'
            title={t('followupCalculator.currentDebt')}
            value={loanDetails.remainingDebt}
            onChange={(value) => handleSliderUpdate(value, 'remainingDebt')}
            min={20000}
          />
        </div>
      </div>
      <div
        className='grid-flex one-fourth grid-justify-center grid-align-center margin-left-l margin-top-m calculatorButton'>
        <Button
          appearance='secondary'
          className={`palm-one-whole multiline ${buttonColor ?? ''}`}
          renderContainer={(props: Object) => (
            <a {...props} href={link}>
              {buttonText ?? t('followupCalculator.calculatorCTA')}
            </a>
          )}
        />
      </div>
      <div className='line margin-top-m dotted two-thirds'/>
      <div className='grid-flex grid-justify-space-between two-thirds padding-vertical-xs'>
        <div>{t('followupCalculator.loanAmount')}</div>
        <div className='font-bold'>{formatNumber(loanAmount)} €</div>
      </div>
      <div className='line two-thirds'/>
      <div className='grid-flex grid-justify-space-between two-thirds padding-vertical-xs'>
        <div>{t('followupCalculator.monthlyRate')}</div>
        {isLoading ? <Loading size='small'/> : <div className='font-bold'>{formatNumber(monthlyRate)} €</div>}
      </div>
      <div className='line two-thirds'/>
    </div>
  )
}

export default FollowupCalculator
