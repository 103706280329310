import React, { createRef, forwardRef, ForwardRefRenderFunction, RefObject } from 'react'
import { validatePhoneNumber } from '../../../api/api/PhoneNumber'
import PhoneNumberInput, { PhoneNumberInputType } from './PhoneNumberInput'

type ValidatedPhoneNumberInputType = Omit<PhoneNumberInputType, 'onChange'> & {
  onChange: (value: string, valid: boolean) => void
}

const ValidatedPhoneNumberInput: ForwardRefRenderFunction<HTMLInputElement, ValidatedPhoneNumberInputType> = ({
  onChange,
  onBlur,
  valid,
  ...otherProps
}, forwardedRef) => {
  const ref = (forwardedRef || createRef<HTMLInputElement>()) as RefObject<HTMLInputElement>

  const handleOnBlur = async (value: string) => {
    onBlur?.(value)

    try {
      if (value === '') return onChange('', false)
      const { formattedNumber } = await validatePhoneNumber(value ?? '')
      onChange(formattedNumber, true)
      ref.current?.setCustomValidity('')
    } catch(e: any) {
      if(e.error === 400) {
        ref.current?.setCustomValidity('format')
        onChange(value, false)
      }
    }
  }

  return <PhoneNumberInput
    {...otherProps}
    valid={valid}
    ref={ref}
    onChange={value => onChange(value, false)}
    onBlur={handleOnBlur}
  />
}

export default forwardRef(ValidatedPhoneNumberInput)
