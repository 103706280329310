import React, { useState } from 'react'
import AmortizationChart from '../../../financing/resultPage/amortizationChart/AmortizationChart'
import { useTranslation } from 'react-i18next'
import './Amortization.less'
import { Button } from 'is24-corecss'
import AmortizationModal from './modal/AmortizationModal'
import { FinancingResult } from '../../../financing/resultPage/model/FinancingResults'
import { FinancingType } from '../../../../api/api/types/FinancingType'
import { Region } from '../../../common/input/regionSelection/RegionSelection'
import { AdditionalCostsInPercents } from '../model'
import { ProductTypeAO } from '../../../../api/api/types/ReferredOffersAO'

export type AmortizationProps = {
  financingResult?: FinancingResult
  financingType: FinancingType
  purchasePrice: number
  region?: Region
  additionalCostsInPercents: AdditionalCostsInPercents
  additionalCosts: number
  ownCapital: number
  propertyValue: number
  remainingDebt: number
  providerName: string
  providerId?: number
  productType?: ProductTypeAO
  hasBrandedOffer: boolean
}

const Amortization: React.FC<AmortizationProps> = (
  {
    financingResult,
    financingType,
    purchasePrice,
    region,
    additionalCostsInPercents,
    additionalCosts,
    ownCapital,
    propertyValue,
    remainingDebt,
    providerName,
    providerId,
    productType,
    hasBrandedOffer
  }) => {
  const { t } = useTranslation('widgets', { keyPrefix: 'financingCalculator.infoSection.amortization' })
  const [ modalVisible, setModalVisible ] = useState(false)

  return (
    <div className='Amortization font-m padding-top-m no-po'>
      <div className='Header'>
        <div>{t('title')}</div>
        <Button disabled={!financingResult} className={`font-s ${!financingResult ? 'DisabledButton' : ''}`}
                textStyle={true} onClick={() => setModalVisible(true)}>
          <i className='is24-icon-chevron-right'/>{t('repaymentPlan')}
        </Button>
      </div>
      <AmortizationChart
        fixedRateEndYear={financingResult?.fixedRateEndYear ?? 5}
        monthlyRemainingDebtAmounts={financingResult?.monthlyRemainingDebtAmounts ?? []}
        height={financingResult ? 450 : 380}
      />
      {modalVisible && financingResult &&
        <AmortizationModal
          financingResult={financingResult}
          financingType={financingType}
          region={region}
          purchasePrice={purchasePrice}
          additionalCostsInPercents={additionalCostsInPercents}
          additionalCosts={additionalCosts}
          ownCapital={ownCapital}
          propertyValue={propertyValue}
          remainingDebt={remainingDebt}
          onClose={() => setModalVisible(false)}
          providerName={providerName}
          providerId={providerId}
          productType={productType}
          hasBrandedOffer={hasBrandedOffer}
        />}
    </div>
  )
}

export default Amortization
