import { StepWrapper } from '../StepWrapper'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { withPageViewReporting } from '../../hoc/withTrackPageView'
import { RoadPage } from '../../FinancingRequestRoad'
import { MenuBar } from '../../MenuBar'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../../../redux/store'
import { LeadEngineState } from '../../state/reducer'
import { onPropertySubmit, onUpdatePropertyData } from '../../state/thunks/app'
import { RegionSelection } from '../../../common/input/regionSelection/RegionSelection'
import { isValidLocation } from '../../models/Property'
import StickyNextButton from '../common/stickyNextButton/StickyNextButton'
import { useNavigate } from 'react-router-dom'

const LocationPage: React.FC<{ nextPage: string }> = ({ nextPage }) => {
  const { t } = useTranslation('leadEngine')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { property } = useSelector<ApplicationState, LeadEngineState>(state => state.leadEngine)
  const { locationLabel } = property
  const [ touched, setTouched ] = useState(false)
  const [ isLocationValid, setLocationValid ] = useState(false)

  useEffect(() => {
    setLocationValid(isValidLocation(property))
  }, [ property ])

  const handleNextPage = () => {
    setTouched(true)
    isLocationValid && dispatch(onPropertySubmit(nextPage, navigate)({}))
  }

  return (
    <>
      <MenuBar page={RoadPage.LOCATION} hideClose/>
      <StepWrapper title={t('location.title')}>
        <div className='LocationPage grid one-half palm-one-whole lap-two-third' style={{ margin: 'auto' }}>
          <RegionSelection
            value={property.geoCode ? locationLabel : ''}
            valid={!(touched && !isLocationValid)}
            onRegionSelect={location => dispatch(onUpdatePropertyData({
              ...location,
              geoCodeUpdated: true
            } ?? { ...property, geoCodeUpdated: true }))}
            label={t('location.label')}
            placeholder={t('location.placeholder')}
          />
          <StickyNextButton text={t('common:button.forward')} onClick={handleNextPage} disabled={!isLocationValid}/>
        </div>
      </StepWrapper>
    </>
  )
}

export default withPageViewReporting(LocationPage, 'location')

