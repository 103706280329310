import React, { useState } from 'react'
import { Button } from 'is24-corecss'
import { useTranslation } from 'react-i18next'
import { calcPercentage, formatNumber } from '../../../services/NumberUtils'

import CustomTooltip from '../../common/customTooltip/CustomTooltip'
import './FinancingSettings.less'
import { getStateValueByGeoCode } from '../models/StateValues'
import { AdditionalCostsPercentages, Financing, getAdditionalCosts } from '../models/Financing'
import { InputWithSlider } from '../../common/inputWithSlider/InputWithSlider'
import { AdditionalCostsDetailsModal } from './AdditionalCostsDetails'
import { NumberInput } from '../../common/input/numericInput'
import { OwnFundsDetailsModal } from './OwnFundsDetailsComponent'

type BuildingFinancingSettingsType = {
  financing: Financing
  geoCode?: string
  onChange: (data: Partial<Financing>) => void
}

export const BuildingFinancingSettings: React.FC<BuildingFinancingSettingsType> =
  ({ financing, geoCode, onChange }) => {
    const { t } = useTranslation('leadEngine')
    const [ additionalCostsModalVisible, setAdditionalCostsModalVisibility ] = useState(false)
    const [ ownFundsModalVisible, setOwnFundsModalVisibility ] = useState(false)
    const stateValue = getStateValueByGeoCode(geoCode)
    const location = stateValue?.label ? `in ${stateValue.label}` : ''

    const getSummaryPercentages = () => {
      const { brokerCommissionPercentage, entryLandRegisterPercentage, landTransferPercentage, notaryCostsPercentage } = financing.additionalCostsPercentages
      return brokerCommissionPercentage + entryLandRegisterPercentage + landTransferPercentage + notaryCostsPercentage
    }

    const handleOnChange = (prop: keyof Financing) =>
      (val: number) =>
        onChange({ [ prop ]: val })

    const handleAdditionalCostsDetailsSubmit = (additionalCostsPercentages: AdditionalCostsPercentages) => {
      onChange({ additionalCostsPercentages })
      setAdditionalCostsModalVisibility(false)
    }

    const handleOwnFundsDetailsSubmit = (total: number) => {
      onChange({ ownFunds: total })
      setOwnFundsModalVisibility(false)
    }

    return (
      <>
        <InputWithSlider id='purchasePrice'
                         onChange={handleOnChange('purchasePrice')}
                         value={financing.purchasePrice}
                         title={t('buildingFinancingSettings.purchasePrice.title')}
                         tooltipText={t('buildingFinancingSettings.purchasePrice.tooltipText')}
                         min={100000}/>

        <div className="grid-flex grid-align-center grid-justify-space-between margin-bottom-l">
          <div className='one-half'>
            <div><strong>{t('buildingFinancingSettings.additionalCosts.title')}</strong></div>
            {location}
            <Button textStyle={true} type="button" onClick={() => {
              setAdditionalCostsModalVisibility(true)
            }}>Details</Button>
          </div>

          <div className='grid-flex flex-nowrap grid-align-center grid-justify-end one-half'>
            <div className='padding-right'>
              {formatNumber(getSummaryPercentages())}&nbsp;%
            </div>
            <NumberInput id='additionalCosts'
                         readOnly={true}
                         onFocus={() => setAdditionalCostsModalVisibility(true)}
                         value={getAdditionalCosts(financing)}
            />
          </div>

          <AdditionalCostsDetailsModal visible={additionalCostsModalVisible} purchasePrice={financing.purchasePrice}
                                       onClose={() => setAdditionalCostsModalVisibility(false)}
                                       percentages={financing.additionalCostsPercentages}
                                       onSubmit={handleAdditionalCostsDetailsSubmit}/>
        </div>

        <div
          className="grid-flex flex-nowrap grid-align-center grid-justify-space-between margin-bottom-l">
          <div className='grid-flex one-fourth palm-one-half'>
            <div className='grid-flex flex-nowrap'>
              <strong>{t('buildingFinancingSettings.ownFunds.title')}</strong>
              <CustomTooltip title={t('buildingFinancingSettings.ownFunds.title')}
                             text={t('buildingFinancingSettings.ownFunds.tooltipText')}/>
            </div>
            <div className='grid-flex align-center'>
              <i className="is24-icon-calculator font-m"/>
              <Button textStyle={true} type="button" className='padding-left-xs' onClick={() => {
                setOwnFundsModalVisibility(true)
              }}>Rechner</Button>
            </div>

            <OwnFundsDetailsModal visible={ownFundsModalVisible} onSubmit={handleOwnFundsDetailsSubmit}
                                  onClose={() => setOwnFundsModalVisibility(false)}/>

          </div>

          <div className='grid-flex flex-nowrap grid-align-center grid-justify-end one-half'>
            <div className='padding-right'>
              {formatNumber(calcPercentage(financing.ownFunds, financing.purchasePrice))}&nbsp;%
            </div>
            <NumberInput id='ownFunds' value={financing.ownFunds}
                         onChange={handleOnChange('ownFunds')}/>
          </div>
        </div>
      </>
    )
  }




