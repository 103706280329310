import React, { ChangeEvent } from 'react'
import Section from '../Section'
import { useTranslation } from 'react-i18next'
import { DatePicker, SelectField } from 'is24-corecss'
import './DatetimeSection.less'
import {
  AppointmentTimeRangeType, AppointmentTimeRangeTypes
} from '../../../../../api/api/types/AppointmentTimeRanges'

export type DatetimeSectionProps = {
  submitted: boolean
  primaryAppointmentDate?: Date
  primaryAppointmentDateValid: boolean
  setPrimaryAppointmentDate: (date?: Date) => void
  primaryAppointmentTime: AppointmentTimeRangeType
  setPrimaryAppointmentTime: (timeRange: AppointmentTimeRangeType) => void
  secondaryAppointmentDate?: Date
  secondaryAppointmentDateValid: boolean
  setSecondaryAppointmentDate: (date?: Date) => void
  secondaryAppointmentTime: AppointmentTimeRangeType
  setSecondaryAppointmentTime: (timeRange: AppointmentTimeRangeType) => void
}

const DatetimeSection: React.FC<DatetimeSectionProps> = (
  {
    submitted,
    primaryAppointmentDate,
    primaryAppointmentDateValid,
    setPrimaryAppointmentDate,
    primaryAppointmentTime,
    setPrimaryAppointmentTime,
    secondaryAppointmentDate,
    secondaryAppointmentDateValid,
    setSecondaryAppointmentDate,
    secondaryAppointmentTime,
    setSecondaryAppointmentTime,
  }) => {

  const { t } = useTranslation('widgets', { keyPrefix: 'financingCalculator.appointmentRequestModal.datetimeSection' })

  return (
    <div className='DatetimeSection'>
      <Section title={t('title')}>
        <div className='grid'>
          <div className='grid-item desk-one-half one-whole grid'>
            <div className='grid-item one-half padding-right-xs'>
              <DatePicker
                preselectedDate={primaryAppointmentDate}
                valid={!submitted || primaryAppointmentDateValid}
                id='primaryAppointment'
                label={t('primaryAppointmentLabel')}
                minDate={new Date()}
                onChange={setPrimaryAppointmentDate}
                daysClassName='DataPickerDays'
              />
            </div>
            <div className='grid-item one-half padding-left-xs padding-top-l palm-padding-top-xl desk-padding-right-xs'>
              <SelectField
                className='SelectField'
                label=''
                data-testid='primaryTimeRange-select-field'
                value={primaryAppointmentTime}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => setPrimaryAppointmentTime(e.target.value as AppointmentTimeRangeType)}
              >
                {Object.keys(AppointmentTimeRangeTypes)
                  .map(k =>
                    <option key={k}
                            value={AppointmentTimeRangeTypes[ k as keyof typeof AppointmentTimeRangeTypes ]}>
                      {t(`timeRange.${k}`)}
                    </option>
                  )}
              </SelectField>
            </div>
          </div>
          <div className='grid-item desk-one-half one-whole grid palm-padding-top-m lap-padding-top-m'>
            <div className='grid-item one-half padding-right-xs desk-padding-left-xs'>
              <DatePicker
                preselectedDate={secondaryAppointmentDate}
                valid={!submitted || secondaryAppointmentDateValid}
                id='secondaryAppointmentDate'
                label={t('secondaryAppointmentLabel')}
                minDate={new Date()}
                onChange={setSecondaryAppointmentDate}
                daysClassName='DataPickerDays'
              />
            </div>
            <div className='grid-item one-half padding-left-xs padding-top-l palm-padding-top-xl'>
              <SelectField
                className='SelectField'
                label=''
                data-testid='secondaryTimeRange-select-field'
                value={secondaryAppointmentTime}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => setSecondaryAppointmentTime(e.target.value as AppointmentTimeRangeType)}
              >
                {Object.keys(AppointmentTimeRangeTypes)
                  .map(k =>
                    <option key={k}
                            value={AppointmentTimeRangeTypes[ k as keyof typeof AppointmentTimeRangeTypes ]}>
                      {t(`timeRange.${k}`)}
                    </option>
                  )}
              </SelectField>
            </div>
          </div>
        </div>
      </Section>
    </div>
  )
}

export default DatetimeSection
