import { ContentType, getRequest, putRequest } from '../api/request'
import { API_BUYERS_PROFILE_URL } from '../api/contextPaths'

export enum Salutation {
  MR = 'MR',
  MS = 'MS',
}

export type BuyersProfileUser = {
  salutation?: Salutation,
  firstName?: string,
  lastName?: string,
  phoneNumber?: string,
}

// actual buyer's profile user contains many more fields, see: https://api.membership.sandbox-immobilienscout24.de/documentation/api.html#put__application-packages_my_self-report
const updatableFields: (keyof BuyersProfileUser)[] = ['salutation', 'firstName', 'lastName', 'phoneNumber']

const filterFieldsToUpdate = (buyersProfileUser: BuyersProfileUser): BuyersProfileUser =>
  updatableFields
    .filter(key => buyersProfileUser[ key ] !== undefined)
    .reduce((acc, key) => ({ ...acc, [ key ]: buyersProfileUser[ key ] }), {});

const XSRF_TOKEN_HEADER = 'X-Xsrf-Token'

export const updateBuyersProfileUser = async (buyersProfileUser: BuyersProfileUser): Promise<BuyersProfileUser> => {
  const response = await fetch(API_BUYERS_PROFILE_URL, { credentials: 'include', headers: { Accept: ContentType.APPLICATION_JSON_UTF8 } })
  if (response.ok || response.status === 404) {
    const currentUser = response.ok ? await response.json() : undefined
    return update(currentUser, buyersProfileUser, response.headers.get(XSRF_TOKEN_HEADER))
  }
  throw new Error(`Fetching buyer's profile user failed with status code ${response.status}`)
}

const update = (currentUser: BuyersProfileUser | undefined, newUser: BuyersProfileUser, xsrfToken: string | null) => putRequest({
  url: API_BUYERS_PROFILE_URL,
  credentials: 'include',
  headers: xsrfToken ? { [ XSRF_TOKEN_HEADER ]: xsrfToken } : undefined,
  body: {
    ...currentUser,
    ...filterFieldsToUpdate(newUser)
  }
})

export const getBuyersProfileUser = (): Promise<BuyersProfileUser> => getRequest({
  url: API_BUYERS_PROFILE_URL,
  credentials: 'include'
})

export const getFullName = (user?: BuyersProfileUser) => (user?.firstName || user?.lastName) ? `${user?.firstName} ${user?.lastName}`.trim() : ''
