import { useTranslation } from 'react-i18next';
import React from 'react';
import { LogarithmicSliderWithInput } from '../../../common/logarithmicSliderWithInput/LogarithmicSliderWithInput';
import LabelWithTooltip from '../labelWithTooltip/LabelWithTooltip';

const MIN_PROPERTY_VALUE = 50_000
const MAX_PROPERTY_VALUE = 10_000_000
const MIN_REMAINING_DEBT = 20_000
const MAX_REMAINING_DEBT = 10_000_000

export type FollowupFinancingInputsProps = {
  propertyValue: number
  onPropertyValueChanged: (value: number) => void
  remainingDebt: number,
  onRemainingDebtChanged: (value: number) => void,
}
const FollowupFinancingInputs = ({
                                   propertyValue,
                                   onPropertyValueChanged,
                                   remainingDebt,
                                   onRemainingDebtChanged,
                                 }: FollowupFinancingInputsProps) => {
  const { t } = useTranslation('widgets', { keyPrefix: 'financingCalculator' })
  return <>
    <div className='padding-vertical-xl'>
      <LogarithmicSliderWithInput
        topLabel={<LabelWithTooltip title={t('propertyValue')} tooltipText={t('propertyValueTooltip')}/>}
        id='property-value'
        min={MIN_PROPERTY_VALUE}
        max={MAX_PROPERTY_VALUE}
        value={propertyValue}
        onRelease={onPropertyValueChanged}
      />
    </div>
    <div className='padding-bottom-xl border-bottom '>
      <LogarithmicSliderWithInput
        topLabel={<LabelWithTooltip title={t('remainingDebt')} tooltipText={t('remainingDebtTooltip')}/>}
        id='remaining-debt'
        min={MIN_REMAINING_DEBT}
        max={MAX_REMAINING_DEBT}
        value={remainingDebt}
        onRelease={onRemainingDebtChanged}
      />
    </div>
  </>
}

export default FollowupFinancingInputs
