import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactApexChart from 'react-apexcharts'
import { AmortizationChartOptions } from './amortizationChartOptions/AmortizationChartOptions'
import './AmortizationChart.less'

export interface ChartProps {
  fixedRateEndYear: number
  monthlyRemainingDebtAmounts: number[]
  height?: number
}

export enum ChartColors {
  FirstPeriod = '#00D9B1',
  SecondPeriod = '#F2CA26',
  FixedRateEndYear = '#3598DB',
  Grid = '#C4C4C4'
}

type Periods = {
  firstPeriod: number[]
  secondPeriod: (number | null)[]
}

export const splitInstallmentsForPeriods = (installments: number[], fixedRateEndYear: number): Periods => {
  const firstPeriod = installments && fixedRateEndYear &&
    installments.slice(0, fixedRateEndYear * 12 + 1) || []
  const secondPeriod = installments && fixedRateEndYear &&
    [...new Array(fixedRateEndYear * 12).fill(null), ...installments.slice(fixedRateEndYear * 12, installments.length)] || []
  return { firstPeriod, secondPeriod }
}

const AmortizationChart = ({ fixedRateEndYear, monthlyRemainingDebtAmounts, height = 485 }: ChartProps) => {
  const { t } = useTranslation('odf', { keyPrefix: 'financingFunnel.resultPage.loanOverviewSection.chart' })
  const initialOptions = AmortizationChartOptions(fixedRateEndYear, t, monthlyRemainingDebtAmounts.length)
  const [ firstPeriod, setFirstPeriod ] = useState<(null | number)[]>([])
  const [ secondPeriod, setSecondPeriod ] = useState<(null | number)[]>([])

  useEffect(() => {
    ApexCharts.getChartByID('amortization-chart')?.updateOptions(AmortizationChartOptions(fixedRateEndYear, t, monthlyRemainingDebtAmounts.length), true, true)
    const splitPeriods = splitInstallmentsForPeriods(monthlyRemainingDebtAmounts, fixedRateEndYear)
    setFirstPeriod(splitPeriods.firstPeriod)
    setSecondPeriod(splitPeriods.secondPeriod)
  }, [ fixedRateEndYear, monthlyRemainingDebtAmounts, t ])

  const series = useMemo(() => [
    {
      name: t('firstPeriod'),
      color: ChartColors.FirstPeriod,
      data: firstPeriod
    },
    {
      name: t('fixedRateEndYear'),
      color: ChartColors.FixedRateEndYear,
      data: []
    },
    {
      name: t('secondPeriod'),
      color: ChartColors.SecondPeriod,
      data: secondPeriod
    }
  ], [ firstPeriod, secondPeriod, t ])

  return (
    <div className='chart-container'>
      <div className='chart' data-testid='amortizationChart'>
        <ReactApexChart
          options={initialOptions}
          series={series}
          type='line'
          height={height}
        />
      </div>
    </div>
  )
}

export default AmortizationChart
