import { ButtonGroup } from './ButtonGroup'
import { StepWrapper } from './StepWrapper'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { RoadPage } from '../FinancingRequestRoad'
import { ApartmentType } from '../../../api/api/types/ApartmentType'
import { PropertyTypeValue } from '../../../api/api/types/PropertyType'
import { HouseType } from '../../../api/api/types/HouseType'
import { onUpdatePropertyData } from '../state/thunks/app'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../../redux/store'
import { LeadEngineState } from '../state/reducer'
import { ObjectSubTypeType } from '../../../api/api/types/ObjectSubType.ds'
import { withPageViewReporting } from '../hoc/withTrackPageView'
import { goToRoadPage } from '../routing'
import { MenuBar } from '../MenuBar'
import { useNavigate } from 'react-router-dom'

type PropertySubTypePageType = {
  nextPage: string
}

const ApartmentValues: ApartmentType[] = [
  'HALF_BASEMENT',
  'GROUND_FLOOR',
  'APARTMENT',
  'ROOF_STOREY',
  'MAISONETTE',
  'LOFT',
]

const HouseValues: HouseType[] = [
  'SINGLE_FAMILY_HOUSE',
  'SEMIDETACHED_HOUSE',
  'TERRACE_HOUSE',
  'MULTI_FAMILY_HOUSE',
]

const PropertySubTypePage: React.FC<PropertySubTypePageType> = ({ nextPage }) => {
  const { t } = useTranslation('leadEngine')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { property: { type, subType } } = useSelector<ApplicationState, LeadEngineState>(state => state.leadEngine)

  const values: ObjectSubTypeType[] = type === PropertyTypeValue.APARTMENT_BUY ? ApartmentValues : HouseValues

  const handleNextPage = (subType: ObjectSubTypeType) => {
    dispatch(onUpdatePropertyData({ subType }))
    goToRoadPage(nextPage, navigate)
  }

  return (<>
    <MenuBar page={RoadPage.PROPERTY_SUBTYPE}/>
    <StepWrapper title={t(`propertySubType.${type}`)}>
      <ButtonGroup<ObjectSubTypeType>
        values={values}
        onClick={handleNextPage}
        value={subType}
      />
    </StepWrapper>
  </>)
}

export default withPageViewReporting(PropertySubTypePage, 'ext.object_subtype')

