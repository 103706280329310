import React, { useEffect, useState } from 'react';
import baufilogo_big from '../../../assets/images/baufilogo_big.png';
import embrace_in_living_room from '../../../assets/images/embrace_in_living_room.png';
import checkmark from '../../../assets/images/checkmark.png';
import { Button } from 'is24-corecss';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../redux/store';
import { LeadEngineState } from '../state/reducer';
import optimizelyClient, { OptimizelyEvent } from '../../../config/optimizely'
import './AdvisoryRecommendation.less'

type Props = {
  onAdvisoryAvailable: (available: boolean) => void
  showAdvisoryLinkFeatureEnabled: boolean
};

export const AdvisoryRecommendation: React.FC<Props> = ({ onAdvisoryAvailable, showAdvisoryLinkFeatureEnabled }) => {

  const calculateTimeDifference = (expiryDate: number) => {
    return expiryDate - new Date().getTime();
  }
  const { t } = useTranslation('leadEngine');
  const { advisoryLink } = useSelector<ApplicationState, LeadEngineState>(state => state.leadEngine);
  const [ timeDifference, setTimeDifference ] = useState(calculateTimeDifference(advisoryLink?.expiryDate ?? Date.now()));

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeDifference(calculateTimeDifference(advisoryLink?.expiryDate ?? Date.now()));
    }, 1000);
    return () => {
      clearTimeout(timer);
    }
  });

  useEffect(() => {
    const isAdvisoryAvailable = (): boolean => !!advisoryLink && !!advisoryLink.link && showAdvisoryLinkFeatureEnabled;
    onAdvisoryAvailable(isAdvisoryAvailable() && timeDifference > 0);
  }, [ timeDifference, onAdvisoryAvailable, advisoryLink, showAdvisoryLinkFeatureEnabled ])

  const handleClick = (): void => {
    optimizelyClient.track(OptimizelyEvent.BOOKING_APPLICATION_WIDGET)
  }
  return (timeDifference > 0 ?
    <section data-testid='advisory-recommendation' className='AdvisoryRecommendation margin-bottom align-center'>
      <img className='BaufiLogo' alt='baufilogo_big' src={baufilogo_big} />
      <div className='padding-bottom' style={{ display: 'flex', justifyContent: 'center' }}>
        <img className='LivingRoomImage' alt='embrace_in_living_room' src={embrace_in_living_room}
        />
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
          <p className='padding-bottom-m'
             style={{ fontSize: '34px', fontWeight: '400', lineHeight: '44px', textAlign: 'start' }}>
            <Trans t={t} i18nKey='advisoryRecommendation.title'/>
          </p>
          <p className='padding-bottom-m align-left' style={{
            fontSize: '16px',
            fontWeight: '400',
            lineHeight: '24px'
          }}>{t('advisoryRecommendation.text')}</p>
          <div className='RecommendationRow padding-bottom-s'>
            <img alt='checkmark' src={checkmark} className='Checkmark'/>
            <p className='RecommendationText'>{t('advisoryRecommendation.1CheckText')}</p>
          </div>
          <div className='RecommendationRow padding-bottom-s'>
            <img alt='checkmark' src={checkmark} className='Checkmark'/>
            <p className='RecommendationText'>{t('advisoryRecommendation.2CheckText')}</p>
          </div>
          <div className='RecommendationRow padding-bottom-m'>
            <img alt='checkmark' src={checkmark} className='Checkmark'/>
            <p className='RecommendationText'>{t('advisoryRecommendation.3CheckText')}</p>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <a href={advisoryLink?.link}>
              <Button appearance='primary' onClick={handleClick}
                      data-testid='advisory-button'>{t('advisoryRecommendation.button')}</Button>
            </a>
          </div>
        </div>
      </div>
    </section> : null);
};
