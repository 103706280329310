import React from 'react'
import { useTranslation } from 'react-i18next'

import { FormLabel } from '../input/FormLabel'
import { NumberInput } from '../input/numericInput'
import { IncomeFields } from './IncomeForm'

interface Props {
  id: keyof IncomeFields,
  customLabel?: string,
  customTooltip?: string,
  primaryValue?: number,
  secondaryValue?: number,
  primaryOnChange: (id: keyof IncomeFields) => (value: number) => void,
  secondaryOnChange?: (id: keyof IncomeFields) => (value: number) => void,
  numberOfBorrowers: number,
  showTooltip?: boolean
  maxValue?: number
  odfLabel?: boolean
}

const DEFAULT_MAX_VALUE = 99_999_999;
export const checkIfValid = (value?: number, maxValue?: number) => {
  return value === undefined || (value >= 0 && value <= (maxValue ?? DEFAULT_MAX_VALUE))
}

const IncomeInputRow: React.FC<Props> = ({
  id,
  customLabel,
  customTooltip,
  primaryValue,
  secondaryValue,
  primaryOnChange,
  secondaryOnChange,
  numberOfBorrowers,
  showTooltip = true,
  maxValue,
  odfLabel= false
}) => {
  const { t } = useTranslation('leadEngine')
  const label = customLabel ? customLabel : t(`incomeForm.value.${id}.${odfLabel ? 'odfLabel' : 'label'}`)
  const tooltipFor = (name: string): string => t(`incomeForm.value.${name}.tooltip`)

  const tooltipText = customTooltip ?? (showTooltip ? tooltipFor(id) : '')
  const primaryValid = checkIfValid(primaryValue, maxValue);
  const secondaryValid = checkIfValid(secondaryValue, maxValue);
  return <>
    <FormLabel label={label} tooltip={tooltipText}/>
    <div className='grid-flex gutter-l flex-nowrap'>
      <div className='one-whole grid-item'>
        <NumberInput
          id={`primary-${id}`}
          value={primaryValue}
          ariaLabel={label}
          onChange={(value) => primaryOnChange(id)(value)}
          errorMessage={t(maxValue ? 'expensesForm.error.inputValueAlternative' : 'expensesForm.error.inputValue')}
          valid={primaryValid} forceSelect={false}/>
      </div>
      {numberOfBorrowers > 1 && (
        <div className='one-whole grid-item'>
          <NumberInput
            id={`secondary-${id}`}
            value={secondaryValue}
            ariaLabel={label} onChange={(value) => secondaryOnChange?.(id)(value)}
            errorMessage={t(maxValue ? 'expensesForm.error.inputValueAlternative' : 'expensesForm.error.inputValue')}
            valid={secondaryValid} forceSelect={false}/>
        </div>
      )}
    </div>
  </>
}

export default IncomeInputRow
