import { getRequest } from '../../../api/request'
import { API_SHORTLIST_URL } from '../../../api/contextPaths'
import { ShortlistEntryAO } from '../../../api/api/types/ShortlistEntryAO'
import { Expose, ExposeAddress } from '../../../api/api/Expose'

export const getShortlistEntries = async (): Promise<ShortlistEntryAO[]> =>{
  const shortlistEntries: ShortlistEntryAO[] = await getRequest({ url: API_SHORTLIST_URL})
  return shortlistEntries.filter((s: ShortlistEntryAO) => {
    const realEstateType = s.realEstateType.trim().toLowerCase();
    return realEstateType === 'housebuy' || realEstateType === 'apartmentbuy';
  });
}

const toFlatString = (address: ExposeAddress): string => {
  if (!address) return ''
  const { street, houseNumber, city, quarter, postcode } = address
  let result = ''
  if (street && houseNumber) {
    result = `${street} ${houseNumber}, `
  } else if (street) {
    result = `${street}, `
  }
  if (postcode) {
    result += `${postcode} `
  }
  if (quarter) {
    result += `${quarter}, `
  }
  if (city) {
    result += city
  }
  return result;
}

export const toShortlistEntryAO = (expose: Expose | undefined): ShortlistEntryAO | undefined =>
  expose ? {
    exposeId: expose?.id,
    realEstateType: expose.type,
    address: toFlatString(expose.address),
    numberOfRooms: expose.numberOfRooms ?? 0,
    publicationState: 'active',
    purchasePrice: expose.purchasePrice ?? 0,
    squareMeters: expose.livingArea,
    imageUrl: expose.picture
  } : undefined
