import React, { useEffect, useMemo, useState } from 'react'
import { FinancingTypeSelection } from '../../leadengine/financingSettings/FinancingTypSelection'
import { FinancingType, FinancingTypeValue, parseFinancingType } from '../../../api/api/types/FinancingType'
import { useTranslation } from 'react-i18next'
import { Region, RegionSelection } from '../../common/input/regionSelection/RegionSelection'
import './FinancingCalculatorWidget.less'
import ShortlistSelection from '../../common/oneDynamicFlow/pages/location/map/ShortlistSelection'
import { LocationType } from '../../mortgageOfficer/state/reducer'
import { useDispatch } from 'react-redux'
import { logInCheck } from '../../../redux/actions/Login'
import ShortlistObject from '../../common/shortlistDropdown/list/ShortlistObject'
import { Button } from 'is24-corecss'
import { useLocation } from 'react-router-dom'
import { Expose, fetchExposeEntity } from '../../../api/api/Expose'
import { getLocationFromExpose } from '../../common/oneDynamicFlow/pages/location/map/ShortlistSelectionUtils'
import { ExposeEntry } from '../../common/shortlistDropdown/ShortlistDropdown'
import { toShortlistEntryAO } from '../../common/shortlistDropdown/ShortlistService'
import {
  BrandedStatus,
  BrandedWithOwnFinancingStatus,
  calculateAmount,
  getBrandedWithOwnFinancingStatus,
  getBrandedStatus,
  getInitialAdditionalCostsInPercents,
  getInitialAdditionalCostsInPercentsForExpose,
  notBranded,
  sumPercents
} from './service'
import { validateAmortizationRate } from '../../affordability/resultPage/loanSettings/LoanSettingsService'
import Settings from './settings/Settings'
import PurchaseFinancingInputs from './purchaseFinancingInputs/PurchaseFinancingInputs'
import FollowupFinancingInputs from './followupFinancingInputs/FollowupFinancingInputs'
import { FinancingResultError } from '../../financing/resultPage/model/FinancingResults'
import { useAmortizationFinancingCalculation } from '../../financing/hook/useAmortizationFinancingCalculation'
import Summary from './summary/Summary'
import LeadEngineButton from './leadEngineButton/LeadEngineButton'
import { FinancingData, updateFinancingData } from './service/localStorage'
import Amortization from './amortizationSection/Amortization'
import FinancingCost from './financingCost/FinancingCost'
import { mapToFixedInterestValue } from '../../../api/utils'

const EXPOSE_ID_QUERY_PARAM = 'exposeId'
const DEFAULT_PURCHASE_PRICE = 300_000
const DEFAULT_PART_OF_OWN_CAPITAL_IN_PURCHASE_PRICE = 20 / 100
const DEFAULT_OWN_CAPITAL = DEFAULT_PURCHASE_PRICE * DEFAULT_PART_OF_OWN_CAPITAL_IN_PURCHASE_PRICE
const DEFAULT_PROPERTY_VALUE = 200_000
const DEFAULT_REMAINING_DEBT = 100_000
const MIN_AMORTIZATION_RATE_PERCENTAGE = 1
const MAX_AMORTIZATION_RATE_PERCENTAGE = 7.5
const DEFAULT_FIXED_RATE_END_YEAR = 10

export type FinancingCalculatorWidgetProps = {
  defaultFinancingTypeMode?: string
}

const FinancingCalculatorWidget: React.FC<FinancingCalculatorWidgetProps> = ({ defaultFinancingTypeMode = 'PROPERTY_PURCHASE' }) => {
  const { t } = useTranslation('widgets', { keyPrefix: 'financingCalculator' })
  const [ financingType, setFinancingType ] = useState<FinancingType>(parseFinancingType(defaultFinancingTypeMode) ?? FinancingTypeValue.PurchaseFinancing)
  const [ region, setRegion ] = useState<Region | undefined>(undefined)
  const [ expose, setExpose ] = useState<Expose | undefined>(undefined)
  const [ purchasePrice, setPurchasePrice ] = useState<number>(DEFAULT_PURCHASE_PRICE)
  const [ ownCapital, setOwnCapital ] = useState<number>(DEFAULT_OWN_CAPITAL)
  const [ propertyValue, setPropertyValue ] = useState<number>(DEFAULT_PROPERTY_VALUE)
  const [ remainingDebt, setRemainingDebt ] = useState<number>(DEFAULT_REMAINING_DEBT)
  const { search } = useLocation()
  const [ additionalCostsInPercents, setAdditionalCostsInPercents ] = useState(getInitialAdditionalCostsInPercents())
  const [ amortizationRate, setAmortizationRate ] = useState<number>(MIN_AMORTIZATION_RATE_PERCENTAGE)
  const [ fixedRateEndYear, setFixedRateEndYear ] = useState<number>(DEFAULT_FIXED_RATE_END_YEAR)
  const [ error, setError ] = useState<FinancingResultError | undefined>(undefined)
  const [ brandedStatus, setBrandedStatus ] = useState<BrandedStatus>(notBranded())
  const [ brandedWithOwnFinancingStatus, setBrandedWithOwnFinancingStatus ] = useState<BrandedWithOwnFinancingStatus>({ withOwnFinancing: false })

  const hasBrandedOffer = brandedWithOwnFinancingStatus.withOwnFinancing

  const additionalCostsSum = useMemo(() => {
    return calculateAmount(sumPercents(additionalCostsInPercents), purchasePrice)
  }, [ purchasePrice, additionalCostsInPercents ])

  const netLoan = useMemo(() => {
    if (financingType === FinancingTypeValue.FollowupFinancing) {
      return Math.max(remainingDebt, 0)
    }
    return purchasePrice + additionalCostsSum - ownCapital
  }, [ purchasePrice, additionalCostsSum, ownCapital, financingType, remainingDebt ])

  const amortizationFinancingCalculation = useAmortizationFinancingCalculation({
      loanAmount: netLoan,
      propertyCost: purchasePrice,
      additionalCosts: additionalCostsSum,
      ownCapital,
      amortizationRate,
      fixedRateEndYear,
      financingType,
      postalCode: region?.postalCode,
      geoCode: region?.geoCode,
      propertyValue,
      remainingDebt
    },
    setError
  )

  useEffect(() => {
    const financingData: FinancingData = {
      additionalCostsInPercents,
      amortizationRate,
      financingType,
      fixedRateEndYear,
      ownCapital,
      propertyValue,
      purchasePrice,
      region,
      remainingDebt
    }
    updateFinancingData(financingData)
  }, [
    financingType,
    region,
    purchasePrice,
    ownCapital,
    propertyValue,
    remainingDebt,
    additionalCostsInPercents,
    amortizationRate,
    fixedRateEndYear
  ])

  useEffect(() => {
    const queryParams = new URLSearchParams(search)
    if (queryParams.has(EXPOSE_ID_QUERY_PARAM)) {
      const exposeId = queryParams.get(EXPOSE_ID_QUERY_PARAM)
      if (exposeId && !isNaN(+exposeId)) {
        fetchExposeEntity(+exposeId)
          .then(expose => {
            if (expose) {
              setRegion(getLocationFromExpose(expose))
              setExpose(expose)
              loadDataFromExpose(expose)
            }
          })
          .catch(console.error)
      }
    }
  }, [ search ])

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(logInCheck())
  }, [ dispatch ])

  const loadDataFromExpose = (expose: Expose) => {
    expose.purchasePrice && setPurchasePrice(expose.purchasePrice)
    expose.purchasePrice && setOwnCapital(expose.purchasePrice * DEFAULT_PART_OF_OWN_CAPITAL_IN_PURCHASE_PRICE)
    setAdditionalCostsInPercents(getInitialAdditionalCostsInPercentsForExpose(expose))
  }

  useEffect(() => {
    if (expose) {
      getBrandedStatus(expose.cwid, expose.address.geocode || undefined).then(setBrandedStatus)
    } else {
      setBrandedStatus(notBranded())
    }
  }, [ expose ])

  useEffect(() => {
    if (expose && brandedStatus.branded) {
      getBrandedWithOwnFinancingStatus({
        viaProviderId: brandedStatus.viaProviderId,
        geoCode: expose.address.geocode || undefined,
        additionalCosts: additionalCostsSum,
        amortizationRate,
        financingType,
        fixedRateEndYear,
        ownCapital,
        propertyValue,
        purchasePrice,
        remainingDebt
      }).then((result) => {
        setBrandedWithOwnFinancingStatus(result)
      })
    } else {
      setBrandedWithOwnFinancingStatus({ withOwnFinancing: false })
    }
  }, [ expose, additionalCostsSum, amortizationRate, financingType, fixedRateEndYear, ownCapital, propertyValue,
    purchasePrice, remainingDebt, brandedStatus ])

  const handleExposeSelect = (location: LocationType | undefined, expose: Expose | undefined) => {
    setRegion(location)
    setExpose(expose)
    expose && loadDataFromExpose(expose)
  }

  const handleRegionSelect = (region: Region | undefined) => {
    setRegion(region)
    if (region?.geoCode) {
      setAdditionalCostsInPercents(getInitialAdditionalCostsInPercents(region.geoCode))
    }
    setExpose(undefined)
  }

  const onAmortizationRateStepperChange = (value: number) => {
    const validValue = validateAmortizationRate(value)
    setAmortizationRate(validValue)
  }

  const onFixedRateEndYearStepperChange = (value: number) => {
    setFixedRateEndYear(value)
  }

  const handlePurchasePriceChange = (value: number) => {
    if (ownCapital > value) {
      setOwnCapital(value)
    }
    setPurchasePrice(value)
  }

  return (
    <div className='FinancingCalculatorWidgetContainer grid'>
      <div className='FinancingCalculatorWidget background padding-l grid-item three-fifth palm-one-whole'>
        <div className='FinancingTypeAndLocation'>
          <FinancingTypeSelection financingType={financingType} onChange={setFinancingType} label={t('financingType')}
                                  disabled={hasBrandedOffer}/>
          <div className='InLabel'>{t('in')}</div>
          <RegionSelection
            label={t('location')}
            value={region?.locationLabel}
            onRegionSelect={handleRegionSelect}
            showIcon
          />
        </div>
        <div className='Shortlist'>
          <div>
            {expose ?
              <div className='SelectedObjectContainer'>
                <div className='Header padding-top-s padding-bottom-s'>
                  <div className='padding-left-m font-s'>{t('objectSelected')}</div>
                  <Button textStyle className='padding-right-m'
                          onClick={() => {
                            setExpose(undefined)
                            setRegion(undefined)
                          }}>{t('removeObject')}</Button>
                </div>
                <div className='SelectedObject'>
                  <ShortlistObject shortlistEntry={toShortlistEntryAO(expose) as ExposeEntry}/>
                </div>
              </div>
              :
              <ShortlistSelection
                onExposeSelect={handleExposeSelect} optionalPlaceholder={true}
              />}
          </div>
        </div>
        {financingType === FinancingTypeValue.FollowupFinancing ?
          <FollowupFinancingInputs
            propertyValue={propertyValue}
            onPropertyValueChanged={setPropertyValue}
            remainingDebt={remainingDebt}
            onRemainingDebtChanged={setRemainingDebt}
          />
          :
          <PurchaseFinancingInputs
            purchasePrice={purchasePrice}
            onPurchasePriceChanged={handlePurchasePriceChange}
            ownCapital={ownCapital}
            onOwnCapitalChanged={setOwnCapital}
            additionalCostsInPercents={additionalCostsInPercents}
            updateAdditionalCostsInPercents={setAdditionalCostsInPercents}
          />}
        <Settings netLoan={netLoan}
                  amortizationRate={amortizationRate}
                  onAmortizationRateChange={onAmortizationRateStepperChange}
                  fixedRateEndYear={fixedRateEndYear}
                  onFixedRateEndYearChange={onFixedRateEndYearStepperChange}
                  minAmortizationRatePercentage={MIN_AMORTIZATION_RATE_PERCENTAGE}
                  maxAmortizationRatePercentage={MAX_AMORTIZATION_RATE_PERCENTAGE}
        />
        <Summary
          monthlyInstalment={amortizationFinancingCalculation?.monthlyInstalment || 0}
          averageInterestRate={amortizationFinancingCalculation?.averageInterestRate || 0}
          effectiveInterestRate={amortizationFinancingCalculation?.effectiveInterestRate || 0}
          netLoan={netLoan}
          postalCode={region?.postalCode}
          geoCode={region?.geoCode}
          error={error}
        />
        <LeadEngineButton
          providerName={brandedWithOwnFinancingStatus.providerName || ''}
          providerId={brandedWithOwnFinancingStatus.providerId}
          productType={brandedWithOwnFinancingStatus.productType}
          financingType={financingType}
          region={region}
          purchasePrice={purchasePrice}
          additionalCostsInPercents={additionalCostsInPercents}
          additionalCosts={additionalCostsSum}
          ownCapital={ownCapital}
          propertyValue={propertyValue}
          remainingDebt={remainingDebt}
          hasBrandedOffer={hasBrandedOffer}
          amortizationRate={amortizationRate}
          fixedRateEndYear={mapToFixedInterestValue(fixedRateEndYear)}
          additionalCostsSum={additionalCostsSum}
          exposeId={expose?.id}
        />
      </div>
      <div className='calculator-info-container grid-item two-fifth palm-one-whole padding-left palm-margin-top-m'>
        <FinancingCost financingResult={amortizationFinancingCalculation}/>
        <Amortization
          financingResult={amortizationFinancingCalculation}
          financingType={financingType}
          region={region}
          purchasePrice={purchasePrice}
          additionalCostsInPercents={additionalCostsInPercents}
          additionalCosts={additionalCostsSum}
          ownCapital={ownCapital}
          propertyValue={propertyValue}
          remainingDebt={remainingDebt}
          providerName={brandedWithOwnFinancingStatus.providerName || ''}
          providerId={brandedWithOwnFinancingStatus.providerId}
          productType={brandedWithOwnFinancingStatus.productType}
          hasBrandedOffer={hasBrandedOffer}
        />
      </div>
    </div>
  )
}

export default FinancingCalculatorWidget
