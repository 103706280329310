import React, { useMemo, useState } from 'react'
import './ShortlistObject.less'
import { Button, Loader } from 'is24-corecss'
import ShortlistObject from './ShortlistObject'
import './Shortlist.less'
import { useTranslation } from 'react-i18next'
import { ExposeEntry } from '../ShortlistDropdown'

export type ShortlistProps = {
  shortlistEntries: ExposeEntry[]
  loggedIn: boolean
  isLoading: boolean
  onSelect: (selectedEntry: ExposeEntry) => void
  redirectToSso: () => void
}
const initialLimit = 5

const Shortlist: React.FC<ShortlistProps> = ({ shortlistEntries, loggedIn, isLoading, onSelect, redirectToSso }) => {
  const { t } = useTranslation()
  const [ limit, setLimit ] = useState(initialLimit)
  const entriesToDisplay = useMemo(() => shortlistEntries.slice(0, limit), [ limit, shortlistEntries ])
  return (
    <div className='expose-shortlist'>
      {isLoading ?
        <div className='shortlist-spinner font-xxl padding' data-testid='spinner-wrapper'>
          <Loader size='small'/>
        </div>
        :
        <div>
          {loggedIn ?
            <div>
              {entriesToDisplay.map(entry =>
                <ShortlistObject key={entry.exposeId} shortlistEntry={entry} onSelect={onSelect}/>)
              }

              {limit < shortlistEntries.length &&
                <div className='align-center padding-vertical'>
                  <Button appearance={'ghost'} onClick={() => setLimit(limit + initialLimit)}>
                    <i className='is24-icon-chevron-down'/> {t('shortlist.showMoreButton')} ({shortlistEntries.length - limit})
                  </Button>
                </div>
              }

              {shortlistEntries.length === 0 &&
                <div className='padding-left padding-bottom'>{t('shortlist.noObjectsFound')}</div>
              }
            </div>
            :
            <>
              {shortlistEntries.length === 1 && shortlistEntries.map(entry =>
                <div key={entry.exposeId} className='border-bottom'>
                  <ShortlistObject shortlistEntry={entry} onSelect={onSelect}/>
                </div>)
              }
              <div className='align-center padding-bottom'>
                <div className='margin-bottom-s'>{t('shortlist.loginCaption')}</div>
                <Button appearance='secondary' className='button' onClick={redirectToSso}>{t('shortlist.loginButton')}</Button>
              </div>
            </>
          }
        </div>
      }
    </div>
  )
}

export default Shortlist
